import { IRteText } from '../../interfaces/rte-text';

export const TypeNameBlockFaq = 'ParagraphFaq';

export interface IFaq {
  __typename: 'ParagraphFaq';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  chapo?: IRteText;
  content?: Array<IFaqContent>;
}

export interface IFaqContent {
  question?: string;
  answer?: IRteText;
  openState?: Boolean;
}
