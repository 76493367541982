@if(isNewArtisticDirection()) {
  <a
    effy-button
    [size]="EffyButtonSize.MEDIUM"
    [color]="buttonStyle()"
    [href]="ctaLink?.url || ''"
    [rel]="ctaLink?.options?.attributes?.rel || ''"
    [target]="ctaLink?.options?.attributes?.target || ''"
    class="tw-w-full smd:tw-w-fit">
    @if (btnIcon) {
      <mat-icon [svgIcon]="'account'" class="account-icon"></mat-icon>
    }
    <span class="tw-whitespace-nowrap" [innerHTML]="ctaLink?.title"></span>
    @if (showChevronIcon()) {
      <mat-icon [svgIcon]="'chevron-right'" class="tw-flex tw-w-4 tw-flex-shrink-0"></mat-icon>
    }
  </a>
} @else {
  <a
    [href]="ctaLink?.url || ''"
    [ngClass]="'nscf-btn ' + btnClass"
    [rel]="ctaLink?.options?.attributes?.rel || ''"
    [target]="ctaLink?.options?.attributes?.target || ''">
      <div class="effy-btn-content">
        <img *ngIf="btnIcon" class="effy-btn-content-icon" aria-hidden="true" [src]="btnIcon" />
        <span [innerHTML]="ctaLink?.title" class="effy-btn-content-text"></span>
      </div>
    </a>
}

