import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { CtaActionDirective } from '../../../directives/cta-action/cta-action.directive';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { ISubBlockCTA } from './sub-block-cta';

@Component({
    selector: 'nscf-sub-block-cta',
    templateUrl: 'sub-block-cta.component.html',
    styleUrls: ['sub-block-cta.component.scss'],
    standalone: true,
    imports: [NgIf, CtaActionDirective, CtaButtonComponent, EffyButtonComponent, NgClass],
    hostDirectives: [DirectionArtisticDirective],
    host: {
      '[class.container]': 'isNewArtisticDirectionTheme()', 
    }
})
export class SubBlockCtaComponent extends BasicThemedComponentDirective {
  @Input() element: ISubBlockCTA;
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonStyle = EffyButtonStyle;

  constructor() {
    super();
  }
}
