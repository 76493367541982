import { IWebPage } from "../../services/rich-results/rich-results.values";
import { IPageArticle, IPageWork } from "../../interfaces/pages";
import { WebsiteThemes } from "../../enums/website-themes";

export class WebPagesUtil {
  static dataToWepPageArticleMapper(data: IPageArticle, baseUrl: string, site: WebsiteThemes): IWebPage {
    return {
      name: data.title,
      headling: data.metatags.find(el => el.name === 'description').content,
      url: baseUrl + data.urlSegments,
      ...(data?.metaInfo?.author?.name
        ? {
            author: {
              name: data.metaInfo.author.name,
              description: data.metaInfo.author.job ?? '',
              imageUrl: data.metaInfo.author.imgSrc,
              sameAs: data.metaInfo.author.link,
              isEditorialTeam: data.metaInfo.author.isEditorialTeam,
            },
          }
        : {}),
        site,
    };
  }

  static dataToWepPageWorkMapper(data: IPageWork, baseUrl: string, site: WebsiteThemes): IWebPage {
    return {
      name: data.title,
      headling: data.metatags.find(el => el.name === 'description').content,
      url: baseUrl + data.urlSegments,
      site,
    };
  }
}