import { IPicto } from '../header/header';

export interface Ilink {
  url: string;
  alt?: string;
  label?: string;
  icon?: string;
}

export interface ISubItemsItemList {
  subItems: Array<ISubItem>;
}

export interface IItemList extends ISubItemsItemList {
  subItems: Array<ISubItem>;
  ctaButton?: ICtaButton;
  type: ItemType.LIST;
}

export interface IItemEmpty {
  type: ItemType.EMPTY;
}

export interface IItemImage extends IPicto {
  type: ItemType.IMAGE | ItemType.IMAGE_WAVE;
  link: Ilink;
}

export enum ItemType {
  LIST = 'list',
  EMPTY = 'empty',
  IMAGE = 'image',
  IMAGE_WAVE = 'image-wave',
}

export interface ISubItem {
  title?: string;
  url?: string;
  links: Array<Ilink>;
}

export interface ICtaButton {
  url: string;
  label?: string;
}

export type IItem = IItemList | IItemEmpty | IItemImage;
