<div class="carousel-card" [id]="slide.id">
  <!-- Player wrapper / image call -->
  <div *ngIf="slide.video" class="carousel-card__player-wrapper" [ngClass]="{ loaded: isMediaLoaded() }" (click)="loadMedia($event)">
    <!-- Image call -->
    <ng-container *ngIf="!isMediaLoaded()">
      <img loading="lazy" *ngIf="slide.videoCover" [alt]="slide.videoCover?.alt || ''" [src]="slide.videoCover.url | safeResourceUrl" />
    </ng-container>
    <!-- Iframe media -->
    <ng-container *ngIf="loadedMedias">
      <iframe
        *ngIf="slide.videoCover"
        [id]="'player-' + stepNumber"
        [src]="url | safeResourceUrl"
        [title]="slide.videoCover?.title || ''"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowfullscreen
      >
      </iframe>
      <iframe
        *ngIf="!slide.videoCover"
        [id]="'player-' + stepNumber"
        [src]="url | safeResourceUrl"
        [title]="slide.videoCover?.title || ''"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowfullscreen
        loading="lazy"
      >
      </iframe>
    </ng-container>
  </div>

  <div class="carousel-card__player" *ngIf="!slide.video && slide.videoCover">
    <!-- Image call -->
    <ng-container>
      <img loading="lazy" *ngIf="slide.videoCover" [alt]="slide.videoCover?.alt || ''" [src]="slide.videoCover.url | safeResourceUrl" />
    </ng-container>
  </div>

  <div class="carousel-card__content">
    <!-- Description / article -->
    <div
      *ngIf="slide.description"
      class="carousel-card__description"
      [ngClass]="clampingClassByIndex"
      [innerHTML]="slide.description.value | safeHtml"
    ></div>

    <!-- PDF or external link -->
    <a *ngIf="slide.pdf || slide.url" [href]="slide.pdf?.url || slide.url" class="carousel-card__link" target="_blank">Lire l'article</a>

    <!-- Informations about the media -->
    <div *ngIf="slide.picto || slide.name || slide.date" class="carousel-card__media">
      <div class="media__logo">
        <img loading="lazy" *ngIf="slide.picto" [src]="slide.picto.url" [alt]="slide.name" />
      </div>
      <div class="media__infos">
        <span class="media__name">{{ slide.name }}</span>
        <span class="media__date">{{ slide.date }}</span>
      </div>
    </div>
  </div>
</div>
