<div class="container" *ngIf="data">
    @if (isNewArtisticDirectionTheme()) {
        <p class="tw-font-600 tw-text-new-2xl slg:tw-text-new-3xl slg:tw-mr-[40px] m-0">{{ data.text }}</p>
        @if (data.cta; as cta) {
            <a class="tw-shrink-0 tw-self-center" effy-button [attr.id]="cta.options?.attributes?.id"
               [attr.rel]="cta.options?.attributes?.rel" [attr.href]="cta.url | safeResourceUrl">{{ cta.title }}</a>
        }

    } @else {
        <div class="question">
            <div class="d-flex">
                <div class="question__bar"></div>
                <div class="d-inline">
                    <div class="question__wrapper">
                        <div class="question__text">
                            <p class="question__text-content">{{ data.text }}</p>
                        </div>
                        <div class="question__cta">
                            <nscf-cta-button [theme]="theme" [btnClass]="'cta-primary big-btn'" [text]="data.cta?.title"
                                             [ctaUrl]="data.cta"></nscf-cta-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
</div>


