<ul class="header-list" [ngClass]="{'before:tw-content-none': isNewArtisticDirection()}" [style.width.px]="menuWidth"
  [ngStyle]="{ transform: 'translateX(' + -menuGapFromLeft + 'px)' }">
  @if (!isDesktop) {
    @if (isNewArtisticDirection()) {
      <label [for]="id" (click)="onGoBack()" effy-link [color]="EffyLinkStyle.MIDNIGHT_BLUE" [size]="EffyLinkSize.MEDIUM"  class="tw-my-8">
        <mat-icon [svgIcon]="'chevron-right'" class="rotate-icon"></mat-icon>
        <span>Retour</span>
      </label>
    } @else {
      <label [for]="id" (click)="onGoBack()"  class="header-list__back-btn">
        <img class="header-list__chevron" src="https://www.effy.fr/common-assets/images/pictos/other/chevron-right.svg"
          width="10" height="17" alt="" />
        <span class="header-list__back-btn-title">Retour</span>
      </label>
    }
  }
  <li *ngFor="let item of menu.list.items; let index = index" class="header-list__item">
    <nscf-header-item [item]="item" [theme]="theme"></nscf-header-item>
  </li>
  <input class="c-close-flap-element" [id]="id" name="menu-list" type="radio" />
</ul>