import { TypeNamePageArticle } from '../../interfaces/pages';
import { ArticlePageComponent } from '../article/article.component';
import { CategoriePageComponent } from '../categorie/categorie.component';
import { GuidePageComponent } from '../guide/guide.component';
import { HubPageComponent } from '../hub/hub.component';
import { ManualHubComponent } from '../manual-hub/manual-hub.component';
import { WorkPageComponent } from '../work/work.component';

export const pageMapping = {
  NodePageArticle: ArticlePageComponent,
  NodePageGuide: GuidePageComponent,
  NodePageHub: HubPageComponent,
  NodePageHubManuelle: ManualHubComponent,
  NodeCategorie: CategoriePageComponent,
  NodePageTravaux: WorkPageComponent,
};

export const shouldDisplayPhoneInsert = (typename: string) => {
  return !isPageArticle(typename);
};

export const isPageArticle = (typename: string) => {
  return typename === TypeNamePageArticle;
};
