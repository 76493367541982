import { Component, Input } from '@angular/core';
import { IVerifiedReviewsContainer } from './verified-reviews-container';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { VerifiedReviewsComponent } from '../verified-reviews/verified-reviews.component';
import { TitleComponent } from '../title/title.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nscf-verified-reviews-container-container',
    templateUrl: 'verified-reviews-container.component.html',
    standalone: true,
    imports: [NgIf, TitleComponent, VerifiedReviewsComponent]
})
export class VerifiedReviewsContainerComponent extends BasicThemedComponentDirective {
  @Input() data: IVerifiedReviewsContainer;
}
