import { Inject, Injectable, Optional } from '@angular/core';
import { IHeader } from '@effy-tech/acquisition-header';
import { EffyEnvironment, EffySite } from '@effy-tech/angular-common';
import { ERRORS_DATA, ErrorsData } from '../../components/not-found/error-model';
import { THEME, WebsiteThemes } from '../../enums/website-themes';
import { IQueriesId, QUERIES_ID } from '../../graphql/queries-id';
import { EnvironmentModel } from '../../interfaces/environment.interface';
import { ILogo } from '../../interfaces/logo';
import { BoUrlService } from '../../services/bo-url/bo-url.service';

export const ENVIRONMENT_DATA = 'ENVIRONMENT_DATA';
export const HEADER_DATA = 'HEADER_DATA';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  environment: EnvironmentModel;

  // Id query to change when a new graphql version have been created
  private readonly ID_QUERY_VERSION = 'b8072341b107c3cdee31da72d9f932343db9b01a';

  constructor(
    @Optional() @Inject(ENVIRONMENT_DATA) private readonly environments: EnvironmentModel,
    @Optional() @Inject(HEADER_DATA) private readonly headerData: IHeader,
    @Optional() @Inject(ERRORS_DATA) private readonly errorsData: ErrorsData,
    @Optional() @Inject(THEME) private theme: any,
    @Optional() @Inject(QUERIES_ID) private readonly queriesId: IQueriesId
  ) {}

  initConfig(): void {
    BoUrlService.setEnvironments(this.getEnvironment());
  }

  getTheme(): WebsiteThemes {
    return this.theme;
  }

  getSite(): EffySite {
    switch(this.theme) {
      case WebsiteThemes.effy:
        return EffySite.EFFY;
      case WebsiteThemes.calculeo:
        return EffySite.CALCULEO;
      case WebsiteThemes.lpe:
        return EffySite.LAPRIMEENERGIE;
      case WebsiteThemes.qe:
        return EffySite.QUELLEENERGIE;
      default:
        return EffySite.EFFY;
    }
  }

  setTheme(theme: WebsiteThemes) {
    this.theme = theme;
  }

  getQueriesId(): IQueriesId {
    return this.queriesId;
  }

  getEnvironment(): EnvironmentModel {
    return this.environments;
  }

  getHeaderData(): IHeader {
    return this.headerData;
  }

  getErrorsData(): ErrorsData {
    return this.errorsData;
  }

  getLogo(): ILogo {
    return this.getEnvironment().logo;
  }

  getEnv(): EffyEnvironment {
    return (this.environments?.env) ?? EffyEnvironment.Dev;
  }

  getQueryVersionId(): string {
    return this.ID_QUERY_VERSION;
  }

  getNewsletterApiUrl(baseUrl = this.environments.ecGatewayNginx, apiKey = this.environments.apiKey): string {
    const url = new URL(`/api/compose/v1/email-subscriptions`, baseUrl);
    url.searchParams.append('key', apiKey);
    return url.toString();
  }
}
