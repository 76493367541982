import {
  IColorFocusBusiness,
  ISubBlockFocusBusiness,
  TypeNameSubBlockFocusBusiness,
} from '../../components/block-text/focus-business/focus-business';
import { IPosition } from '../../interfaces/position';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { ISubBlockPresenter } from '../presenter';

export class SubBlockFocusBusinessPresenter implements ISubBlockPresenter {
  parse(data: any): ISubBlockFocusBusiness {
    const subBlock: ISubBlockFocusBusiness = {
      __typename: TypeNameSubBlockFocusBusiness,
      id: data['id'],
      description: RTEPresenter.getRTEText(data['description']),
      image: MediaImagePresenter.getImageWebp(data['image']),
      location: data['location'] ? data['location'] : IPosition.LEFT,
      ctaLabel: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
      color: data['color'] ? data['color'] : IColorFocusBusiness.GREY,
    };
    return subBlock;
  }
}
