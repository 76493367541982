<div class="card-summary__container" *ngIf="carouselItems?.length">
  <p calSummaryScrollAction class="card-summary__title container">Ceci peut aussi vous intéresser</p>

  <div 
    *ngIf="isDesktop$ | async; else mobileWrapper" 
    class="card-summary__wrapper" 
    [ngClass]="{ 'card-summary__wrapper--centered': carouselItems.length < 4 }">
    <owl-carousel-o
      class="medias-carousel"
      [options]="customOptions"
      (initialized)="getChangedData($event)"
      (translated)="getChangedData($event)"
      (change)="getChangedData($event)"
      (changed)="getChangedData($event)"
      #owlCar
    >
      <ng-container *ngFor="let slide of carouselItems; index as stepNumber">
        <ng-template carouselSlide [id]="'slide-' + stepNumber">
          <nscf-card-summary-simplified [data]="slide" [id]="stepNumber" [isNewArtisticDirection]="isNewArtisticDirection()"></nscf-card-summary-simplified>
        </ng-template>
      </ng-container>
    </owl-carousel-o>

    <button [hidden]="!hasPrev || !isBrowser" (click)="owlCar.prev()" class="carousel-button carousel-button--prev">
      contenu précédent
    </button>
    <button [hidden]="!hasNext || !isBrowser" (click)="owlCar.next()" class="carousel-button carousel-button--next">contenu suivant</button>
  </div>

  <ng-template #mobileWrapper>
    <div class="container card-summary__mobile-wrapper">
      <nscf-article-card-small 
        *ngFor="let slide of carouselItems | slice:0:3" 
        [url]="slide.link?.url"
        [img]="slide.image"
        [imgAlt]="slide.imageAlt"
        [title]="slide.title"
        [taxonomies]="$any(slide.taxonomies)"
        [isNewArtisticDirection]="isNewArtisticDirection()"
      ></nscf-article-card-small>
    </div>
  </ng-template>

  @if (isNewArtisticDirection()) {
    <img class="tw-absolute tw-bottom-0 tw-w-full tw-z-[1]" [src]="'assets/e-pattern_' + ((isDesktop$ | async) ? 'desktop' : 'mobile') + '.svg'" alt="">
  }
</div>
