<owl-carousel-o
  class="medias-carousel"
  [options]="customOptions"
  (initialized)="getChangedData($event)"
  (translated)="getChangedData($event)"
  (change)="getChangedData($event)"
  (changed)="getChangedData($event)"
  #owlCar
>
  <ng-container *ngFor="let slide of mediasCarouselItems; index as stepNumber">
    <ng-template [dataHash]="'slide-' + stepNumber" carouselSlide [dataMerge]="$any('slide-' + stepNumber)" [id]="'slide-' + stepNumber">
      <template [ngTemplateOutlet]="cardVariable" [ngTemplateOutletContext]="{ slide: slide, stepNumber: stepNumber }"></template>
    </ng-template>
  </ng-container>
</owl-carousel-o>

<button
  [hidden]="!hasPrev"
  [ngClass]="'carousel-button--h_' + cardHeight"
  (click)="owlCar.prev()"
  class="carousel-button carousel-button--prev"
>
  @if (isNewDA()) {
    <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
  } @else {
    contenu précédent
  }
</button>
<button
  [hidden]="!hasNext"
  [ngClass]="'carousel-button--h_' + cardHeight"
  (click)="owlCar.next()"
  class="carousel-button carousel-button--next"
>
  @if (isNewDA()) {
    <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
  } @else {
    contenu suivant
  }
</button>
