<div *ngIf="data.subBlocks && data.subBlocks.length" class="spacing-block">
  <div *ngFor="let subBlock of data.subBlocks; index as stepNumber">
    <div (click)="setPdfLinkTarget($event)" [ngSwitch]="subBlock.__typename" class="block-text__specification">
      <div *ngSwitchCase="typeSBFocusBusiness">
        <nscf-focus-business [bigViewPort]="bigViewPort" [element]="$any(subBlock)" [theme]="theme"></nscf-focus-business>
      </div>
      <div *ngSwitchCase="typeSBImage">
        <nscf-images [element]="$any(subBlock)" [theme]="theme"></nscf-images>
      </div>
      <div *ngSwitchCase="typeSBCta">
        <nscf-sub-block-cta [element]="$any(subBlock)" [theme]="theme"></nscf-sub-block-cta>
      </div>
      <div *ngSwitchCase="typeSBQuestionCta">
        <nscf-question-cta [data]="$any(subBlock)" [theme]="theme"></nscf-question-cta>
      </div>
      <div *ngSwitchCase="typeSBTables">
        <nscf-tables [element]="$any(subBlock)" [theme]="theme"></nscf-tables>
      </div>
      <div *ngSwitchCase="typeSBTexts">
        <nscf-texts [element]="$any(subBlock)" [isBrowser]="isBrowser" [isOverMobile]="isOverMobile" [theme]="theme"></nscf-texts>
      </div>
      <div *ngSwitchCase="typeSBVideos">
        <nscf-videos [element]="$any(subBlock)" [loadedMedias]="loadedMedias" [stepNumber]="stepNumber?.toString()" [theme]="theme"></nscf-videos>
      </div>
    </div>
  </div>
</div>
