<div class="container spacing-block discovery-categorie">
  <ng-container *ngIf="data.title">
    <h2 class="discovery-categorie__title">{{ data.title }}</h2>
    <nscf-liseret class="discovery-categorie__liseret" [theme]="theme"></nscf-liseret>
  </ng-container>
  <p class="discovery-categorie__description">{{ data.chapo }}</p>
  <div class="discovery-categorie__cards" [ngClass]="data.color ? 'discovery__card-color--' + data.color : ''">
    <nscf-cards-discovery-categorie [cards]="data.cards" [color]="data.color" [theme]="theme" [isNewArtisticDirection]="isNewArtisticDirection()"></nscf-cards-discovery-categorie>
  </div>
</div>
