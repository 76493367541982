import { TypeNameBlockCtaSticky } from '../../../components/cta-sticky/cta-sticky';
import { IBlockPresenter } from '../../presenter';

enum ParagraphCtaStickyColorBO {
  Green = 'vert',
  Blue = 'blue',
}

export interface IParagraphCtaStickyBO {
  id: number;
  barre_1: boolean;
  barre_2: boolean;
  barre_3: boolean;
  text_1: string | null;
  text_2: string | null;
  text_3: string | null;
  color: ParagraphCtaStickyColorBO;
  cta: {
    uri: string;
    title: string;
  };
  mentions: string | null;
  title: string | null;
  enableTrustPilot: boolean;
}

interface IParagraphCtaStickyText {
  value: string;
  strikethrough: boolean;
}

export enum IParagraphCtaStickyColor {
  Green = 'green',
  Blue = 'blue',
}

export interface IParagraphCtaSticky {
  __typename: 'ParagraphBlocCtaSticky';
  title: string;
  text1: IParagraphCtaStickyText;
  text2: IParagraphCtaStickyText;
  text3: IParagraphCtaStickyText;
  cta: {
    uri: string;
    title: string;
  };
  mentions: string;
  color: IParagraphCtaStickyColor;
  enableTrustPilot: boolean;
  shouldDisplayCtaWithQuestions?: boolean;
}

export class BlockCtaStickyPresenter implements IBlockPresenter {
  parse(data: IParagraphCtaStickyBO): IParagraphCtaSticky {
    if (!data) {
      return null;
    }

    return {
      __typename: TypeNameBlockCtaSticky,
      title: data.title ?? '',
      text1: { value: data.text_1, strikethrough: data.barre_1 },
      text2: { value: data.text_2, strikethrough: data.barre_2 },
      text3: { value: data.text_3, strikethrough: data.barre_3 },
      cta: data.cta,
      mentions: data.mentions,
      color: data.color === ParagraphCtaStickyColorBO.Green ? IParagraphCtaStickyColor.Green : IParagraphCtaStickyColor.Blue,
      enableTrustPilot: data.enableTrustPilot,
    };
  }
}
