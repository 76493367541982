<div *ngIf="data" class="meta-info">
  <div *ngIf="data.author" class=" meta-info--author-img">
    <img width="32" height="32"
      [src]="!!data.author.imgSrc && !data.author.isEditorialTeam ? data.author.imgSrc : imgSrcFallback()"
      [alt]="data.author.imgAlt" loading="lazy" />
  </div>
  <div class="meta-info--text">
    <div class="meta-info--author">
      <a *ngIf="data.author?.link; else authorTpl" [href]="data.author.link">
        <ng-container *ngTemplateOutlet="authorTpl"></ng-container>
      </a>
    </div>
    <ng-container *ngIf="shouldDisplayDates">
      <p *ngIf="data.date as date" class="meta-info--date">
        <span *ngIf="date.created" class="meta-info--date-created">Publié le {{ date.created | frenchFullDate }}</span>
        <span *ngIf="date.created && date.showChangedDate" class="separator"></span>
        <span *ngIf="date.showChangedDate" class="meta-info--date-changed">Mis à jour le {{ date.changed |
          frenchFullDate
          }}</span>
      </p>
    </ng-container>
    <nscf-reading-time *ngIf="data.readingTime" class="meta-info--reading-time"
      [time]="data.readingTime"></nscf-reading-time>
  </div>
</div>

<ng-template #authorTpl>
  <p>par <span class="meta-info--author-name">{{ data.author?.name ?? "L'équipe de rédaction" }}</span></p>
  <span class="separator meta-info--author-separator"></span>
</ng-template>