import { ISubBlockFocusBusiness } from './focus-business/focus-business';
import { ISubBlockImage } from './images/images';
import { ISubBlockCTA } from './sub-block-cta/sub-block-cta';
import { ISubBlockTables } from './tables/tables';
import { ISubBlockQuestionCTA } from '../../interfaces/question-cta';
import { ISubBlockVideos } from './videos/videos';
import { ISubBlockTexts } from './texts/texts';

export const TypeNameBlockTexts = 'ParagraphBlocTexte';

export interface IBlockText {
  __typename: 'ParagraphBlocTexte';
  id?: number;
  subBlocks: Array<
    ISubBlockFocusBusiness | ISubBlockImage | ISubBlockCTA | ISubBlockQuestionCTA | ISubBlockTables | ISubBlockVideos | ISubBlockTexts
  >;
}
