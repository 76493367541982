/* eslint-disable */
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostBinding, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ILink } from '../../interfaces/link';

@Directive({
  selector: 'div[appCtaAction]',
  standalone: true,
})
export class CtaActionDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = null;
  @Input() src: string;
  @Input() text: string;
  @Input() ctaUrl: ILink;
  @Input() replaceElements = false;

  constructor(
    private readonly el: ElementRef,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {}

  ngAfterViewInit() {
    this.CtaAction();
  }

  private CtaAction() {
    // only run lazy loading in the browser
    if (isPlatformBrowser(this.platformId)) {
      // if browser supports IntersectionObserver
      if ('IntersectionObserver' in window) {
        const obs = new IntersectionObserver(entries => {
          const stickyElement = document.getElementById('sticky');
          const toBeHidden = document.getElementById('to-be-shown');

          if (!toBeHidden) {
            return;
          }

          if (stickyElement && entries[0].boundingClientRect.top < 0) {
            stickyElement.classList.remove('header-at-top');
            stickyElement.classList.add('header-not-at-top');
            if (this.replaceElements) {
              const buttonTagNameNSCf = 'nscf-cta-button';
              const buttons = stickyElement.getElementsByTagName(buttonTagNameNSCf);
              const aElement = buttons[0]?.getElementsByTagName('a')[0];
              aElement.innerHTML = this.text;
              aElement.href = this.ctaUrl ? this.ctaUrl.url : '';

              const attributes =
                this.ctaUrl && this.ctaUrl.options && this.ctaUrl.options.attributes ? this.ctaUrl.options.attributes : null;
              aElement.target = attributes ? attributes.target : '';
              aElement.rel = attributes ? attributes.rel : '';
            }
            toBeHidden.hidden = true;
          } else if (stickyElement) {
            stickyElement.classList.remove('header-not-at-top');
            stickyElement.classList.add('header-at-top');
            toBeHidden.hidden = false;
          }
        });
        obs.observe(this.el.nativeElement);
      }
    }
  }
}
