import { NgClass, NgFor, NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { IFormParcoursQuestion } from '../form-parcours';

@Component({
  selector: 'nscf-form-parcours-question',
  templateUrl: './form-parcours-question.component.html',
  styleUrls: ['./form-parcours-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, NgClass, FormsModule],
  hostDirectives: [DirectionArtisticDirective],
})
export class FormParcoursQuestionComponent extends BasicThemedComponentDirective {
  @Input({ required: true }) question: IFormParcoursQuestion;
  @Input() isValid: boolean;
  @Input({ transform: booleanAttribute }) set isWarm(value: boolean) {
    this.warm = value ? 'warm_' : '';
  }
  warm: string = '';
  @Output() selectAnswer = new EventEmitter<IFormParcoursQuestion>();

  onChange(question: IFormParcoursQuestion): void {
    this.selectAnswer.emit(question);
  }
}
