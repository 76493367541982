<div *ngIf="data">
  <ng-container *ngIf="{ isMobileOrTablet: isMobileOrTablet$ | async } as vm">
    <div *ngIf="!data?.shouldDisplayCtaWithQuestions; else withQuestionsComponent" class="price-container"
      nscfIsInViewport (isInViewport)="isInViewport($event)">
      <h3 class="price-title">{{ data.title }}</h3>
      <div class="price-amount-container">
        <span *ngIf="data.text1.value" class="price-amount-text-1" [class.strikethrough]="data.text1.strikethrough">{{
          data.text1.value }}</span>
        <span *ngIf="data.text2.value" class="price-amount-text-2" [class.strikethrough]="data.text2.strikethrough">{{
          data.text2.value }}</span>
        <span *ngIf="data.text3.value" class="price-amount-text-3" [class.strikethrough]="data.text3.strikethrough">{{
          data.text3.value }}</span>
      </div>
        <ng-container *ngIf="isNewArtisticDirectionTheme(); else oldDaButtonAndMentions">
            <ng-container *ngTemplateOutlet="newDaButtonAndMentions"/>
        </ng-container>

    </div>

    <ng-container *ngIf="vm.isMobileOrTablet">
      <div *ngIf="(!data?.shouldDisplayCtaWithQuestions && !priceContainerIsInViewport) || (data?.shouldDisplayCtaWithQuestions && !questionContainerIsInViewport)"
           class="sticky-mobile">
          <ng-container *ngIf="isNewArtisticDirectionTheme(); else oldDaButtonAndMentions">
              <ng-container *ngTemplateOutlet="newDaButtonAndMentions"/>
          </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <nscf-trustpilot-micro-star *ngIf="data?.enableTrustPilot" class="trustpilot"/>
</div>

<ng-template #oldDaButtonAndMentions>
  <a *ngIf="data.cta" [attr.href]="data.cta.uri" class="cta cta__blue">
      <span>{{ data.cta.title | uppercase }}</span>
      <img class="cta__arrow" src="assets/arrow/arrow-right-white.svg" alt=""/>
  </a>
    <div *ngIf="data.mentions" class="price-mentions">
        <span>{{ data.mentions }}</span>
    </div>
</ng-template>

<ng-template #newDaButtonAndMentions>
    <a *ngIf="data.cta" [attr.href]="data.cta.uri" effy-button>
        <div class="cta-text">
            <span class="cta-label">{{ data.cta.title }}</span>
            <span class="cta-mentions">{{ data.mentions }}</span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.333252 10.3333L3.66659 6.99999L0.333252 3.66666L0.333252 0.333323L6.99992 6.99999L0.333252 13.6667L0.333252 10.3333Z"
                  fill="none"/>
        </svg>
    </a>
</ng-template>

<ng-template #withQuestionsComponent>
    <nscf-cta-sticky-with-questions nscfIsInViewport (isInViewport)="isQuestionInViewport($event)" />
</ng-template>
