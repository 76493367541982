import { TypeNameBlockInBrief } from '../../../components/in-brief/in-brief';
import { IBlockPresenter } from '../../presenter';

export interface IParagraphInBriefBO {
  id: string;
  title: string;
  description?: {
    value: string;
  };
}

export interface IParagraphInBrief {
  __typename: string;
  title: string;
  description: string | null;
}

export class BlockInBriefPresenter implements IBlockPresenter {
  parse(data: IParagraphInBriefBO): IParagraphInBrief {
    if (!data) {
      return null;
    }

    return {
      __typename: TypeNameBlockInBrief,
      title: data.title,
      description: data.description?.value,
    };
  }
}
