import { ISubBlockPresenter } from '../presenter';
import { ISubBlockQuestionCTA, TypeNameSubBlockQuestionCTA } from '../../interfaces/question-cta';

export class SubBlockQuestionCTAPresenter implements ISubBlockPresenter {
  parse(data: any): ISubBlockQuestionCTA {
    const subBlock: ISubBlockQuestionCTA = <ISubBlockQuestionCTA>{
      __typename: TypeNameSubBlockQuestionCTA,
      text: data['blocktext'],
      cta: {
        url: data['cta']?.url?.path,
        title: data['cta']?.title,
      },
    };
    return subBlock;
  }
}
