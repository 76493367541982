import { ITaxonomy } from '../../interfaces/taxonomy';

export class TaxonomyPresenter {
  public static getTaxonomy(data: any): ITaxonomy {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    return {
      targetId: data['targetId'] + '',
      id: data['entity']?.id,
      name: data['entity']?.name,
      nameFront: data['entity']?.nameFront,
      chapo: data['entity']?.chapo,
      titleHub: data['entity']?.titleHub,
      hubUrl: data['entity']?.url,
      isVisible: data['entity']?.visibility,
      promoted: true,
    };
  }
}
