import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HeaderStaticTypes, IHeaderBo, IheaderStaticBO } from '@effy-tech/acquisition-header';
import { join, filter as lodashFilter, pickBy } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { IQueryVariables } from '../interfaces/themes-query-variables';
import { ConfigService } from '../modules/config/config.service';
import { NewHeaderPresenter } from '../presenters/commons/header';
import { ValidatorUtils } from '../utils/validators/validator.utils';
import { GetUrlService } from './getUrl.service';
import { CardificationOptions, LocationError, RequestName } from './graphql';
import { IQueriesId } from './queries-id';

@Injectable({
  providedIn: 'root',
})
export class GetDataService {
  data: Observable<any>;
  queriesId: IQueriesId;

  constructor(
    public readonly getUrlService: GetUrlService,
    private readonly errorService: ErrorService,
    private readonly configService: ConfigService,
    private readonly http: HttpClient,
    @Inject(PLATFORM_ID) readonly platformId: any
  ) {
    this.queriesId = configService.getQueriesId();
  }

  public getData(urlSegments): Observable<any> {
    if (urlSegments && !ValidatorUtils.checkBOPathUrlValidity(urlSegments)) {
      return throwError(new Error(`getData() from bo : url invalid with segments "${urlSegments}"`));
    }
    return this.doGet(this.queriesId?.GETPAGE, { path: urlSegments });
  }

  public getRequestData(requestName: RequestName, queryVariables?: IQueryVariables): Observable<any> {
    let queryId;
    switch (requestName) {
      case RequestName.MENU_REQ:
        queryId = this.queriesId.GETMENU;
        break;
      case RequestName.TAXONOMY_REQ:
        queryId = this.queriesId.THEME;
        break;
      case RequestName.HEADING_QUESTIONS:
        queryId = this.queriesId.HEADING_QUESTIONS;
        break;
      case RequestName.HEADING_ARTICLES:
        queryId = this.queriesId.HEADING_ARTICLE;
        break;
      case RequestName.ARTICLE_ACTU:
        queryId = this.queriesId.ARTICLE_ACTU;
        break;
      default:
        return;
    }
    return this.doGet(queryId, queryVariables);
  }

  public getCardificationData(queryVariables: IQueryVariables, cardificationOptions?: CardificationOptions): Observable<any> {
    const queryEnum: number = this.getCardificationQueryId(cardificationOptions);
    return this.doGet(queryEnum, queryVariables).pipe(
      catchError(e => this.errorService.catchError(e, LocationError.CARDIFICATION, queryVariables))
    );
  }

  public getMediaCardsData(queryVariables: IQueryVariables): Observable<any> {
    return this.doGet(this.queriesId.CARDS_MEDIA, queryVariables).pipe(
      catchError(e => this.errorService.catchError(e, LocationError.CARDIFICATION, queryVariables))
    );
  }

  getHeaderStaticPagesData$(headerType: HeaderStaticTypes): Observable<any> {
    return this.doGet(this.queriesId.HEADER, { page_name: headerType }).pipe(
      map((headerDataBo: IheaderStaticBO) => ({
        entity: headerDataBo?.data?.entity?.data?.[0],
      })),
      switchMap((HeaderData: IHeaderBo) =>
        this.getRequestData(RequestName.MENU_REQ, { name: HeaderData?.entity?.fieldMenu?.targetId }).pipe(
          map(menuDataBo => NewHeaderPresenter.getHeader(HeaderData, menuDataBo, null))
        )
      )
    );
  }

  private doGet(idQuery: number, queryVariables?: IQueryVariables) {
    return this.http.get(
      `${
        this.configService.getEnvironment().graphqlApiEndpoint
      }?queryId=${this.configService.getQueryVersionId()}:${idQuery}&variables=${this.getVariables(queryVariables)}`
    );
  }

  private getCardificationQueryId(cardificationOptions?: CardificationOptions): number {
    if (cardificationOptions.isNews) {
      return this.queriesId.CARDIFICATION_NHP;
    }

    if (cardificationOptions.isBlockVideo) {
      return this.queriesId.VIDEO;
    }

    if (cardificationOptions.isTheme) {
      return this.queriesId.CARDIFICATION_THEME;
    }

    if (cardificationOptions.isCardification) {
      return this.queriesId.CARDIFICATION;
    }

    if (cardificationOptions.isMulti) {
      return cardificationOptions.isPromoted ? this.queriesId.CARDIFICATION_MULTI_PROMO : this.queriesId.CARDIFICATION_MERGE;
    }

    return cardificationOptions.isPromoted ? this.queriesId.CARDIFICATION_MONO_PROMO : this.queriesId.CARDIFICATION_MONO;
  }

  private getVariables(queryVariables: IQueryVariables = {}): string {
    if (!queryVariables) {
      return '';
    }
    const { path, name, theme, page, pageSize, themes, page_name } = queryVariables;
    const themesString = join(lodashFilter(theme, Boolean), ',');
    const variables = {
      path: path,
      name: name,
      theme: themesString || undefined,
      page: page,
      themes,
      pageSize: pageSize,
      page_name: page_name,
    };
    return JSON.stringify(pickBy(variables, v => v !== undefined));
  }
}
