import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { defer, of, retry, take, throwError } from 'rxjs';

let TRUSTPILOT_INSTANCE_NUMBER = 0;

export type NgStyleType = { [klass: string]: any };

export abstract class TrustpilotBase {
  abstract width: string;
  abstract height: string;
  abstract style: NgStyleType;
  // need to set an unique id for trustpilot HTML element to work properly
  readonly trustpilotId = `trustbox-${TRUSTPILOT_INSTANCE_NUMBER}`;
  private readonly platformId = inject(PLATFORM_ID);
  private isBrowser = isPlatformBrowser(this.platformId);

  constructor() {
    TRUSTPILOT_INSTANCE_NUMBER++;
  }

  init(): void {
    if (this.isBrowser) {
      const customWindow = window as any;
      defer(() => {
        const trustboxRef = document.getElementById(this.trustpilotId);
        if (!trustboxRef) {
          throwError(() => 'trustboxRef undefined')
        }
        return of(customWindow.Trustpilot.loadFromElement(trustboxRef));
      }).pipe(
        retry({
          count: 5,
          delay: 1000
        }),
        take(1)
      ).subscribe();
    }
  }

  computeStyle(): NgStyleType {
    return {
      display: 'inline-block',
      width: this.width,
      height: this.height,
      fontFamily: '"Helvetica Neue","Helvetica","Arial","sans-serif"',
      fontSize: '15px',
      color: '#032500',
      textDecoration: 'none',
      marginLeft: '8px',
    };
  }
}

export type TrustpilotTheme = 'light' | 'dark';
