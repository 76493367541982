import { Component, Input } from '@angular/core';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';
import { IFieldMediaImageWithWebp } from './../../../interfaces/field-media-image';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'nscf-article-cover',
    templateUrl: 'cover.component.html',
    styleUrls: ['cover.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class ArticleCoverComponent {
  @Input() data: IFieldMediaImageWithWebp;
  isBrowser: boolean;
  constructor(deviceManagerService: DeviceManagerService) {
    this.isBrowser = deviceManagerService.isBrowser();
  }
}
