import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash-es';

const REGIONS = [
  {
    id: 'alsace',
    label: 'Alsace',
  },
  {
    id: 'aquitaine',
    label: 'Aquitaine',
  },
  {
    id: 'auvergne',
    label: 'Auvergne',
  },
  {
    id: 'basse-normandie',
    label: 'Basse-Normandie',
  },
  {
    id: 'bourgogne',
    label: 'Bourgogne',
  },
  {
    id: 'bretagne',
    label: 'Bretagne',
  },
  {
    id: 'centre',
    label: 'Centre',
  },
  {
    id: 'champagne-ardenne',
    label: 'Champagne-Ardenne',
  },
  {
    id: 'corse',
    label: 'Corse',
  },
  {
    id: 'franche-comte',
    label: 'Franche-Comté',
  },
  {
    id: 'haute-normandie',
    label: 'Haute-Normandie',
  },
  {
    id: 'ile-de-france',
    label: 'Île-de-France',
  },
  {
    id: 'languedoc-roussillon',
    label: 'Languedoc-Roussillon',
  },
  {
    id: 'languedoc-roussillon',
    label: 'Languedoc-Roussillon',
  },
  {
    id: 'limousin',
    label: 'Limousin',
  },
  {
    id: 'lorraine',
    label: 'Lorraine',
  },
  {
    id: 'midi-pyrenees',
    label: 'Midi-Pyrénées',
  },
  {
    id: 'nord-pas-de-calais',
    label: 'Nord-Pas-de-Calais',
  },
  {
    id: 'pays-de-la-loire',
    label: 'Pays de la Loire',
  },
  {
    id: 'picardie',
    label: 'Picardie',
  },
  {
    id: 'poitou-charentes',
    label: 'Poitou-Charentes',
  },
  {
    id: 'provence-alpes-cote-d-azur',
    label: 'Provence-Alpes-Côte d’Azur',
  },
  {
    id: 'rhone-alpes',
    label: 'Rhône-Alpes',
  },
];

@Pipe({
  name: 'region',
  standalone: true,
})
export class RegionPipe implements PipeTransform {
  transform(regionId: string) {
    return find(REGIONS, region => region['id'] === regionId)?.label || '';
  }
}
