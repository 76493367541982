import { defaultsDeep, map } from 'lodash-es';
import { IManualPageHub, IPageDefault, TypeNamePageHub, TypeNamePageManualHub } from '../../interfaces/pages';
import { LinkPresenter } from '../commons/link.presenter';
import { TaxonomyPresenter } from '../commons/taxonomy.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class ManualPageHubPresenter extends PageDefaultPresenter implements IPagePresenter {
  parse(data: any): IManualPageHub {
    const defaultPage: IPageDefault = super.parse(data);
    // merge defaultPage with extra Page data
    return defaultsDeep(
      {
        __typename: TypeNamePageManualHub,
        chapo: data['chapo'],
        title: data['title'],
        taxonomy: map(data['theme'], taxonomy => {
          return {
            ...TaxonomyPresenter.getTaxonomy(taxonomy),
            themeId: taxonomy['targetId'],
            blocks: {
              __typename: TypeNamePageHub,
              cardsSummary: [],
            },
          };
        }),
        relateds: {
          name: 'Notre sélection d’articles',
          links: map(data['articles'], link => {
            return LinkPresenter.getLink(link);
          }),
        },
        promoted: true,
      },
      defaultPage
    );
  }
}
