<div class="text-video">
  <!-- Player wrapper / image call -->
  <div
    [ngClass]="{
      loaded: isMediaLoaded() || !data.cover,
    }"
    class="load-video"
    (click)="loadMedia($event)"
  >
    <!-- Image call -->
    <ng-container *ngIf="!isMediaLoaded() || !data?.cover">
      <img
        loading="lazy"
        *ngIf="data.cover"
        class="text-video__cover-video"
        [ngClass]="data.caption ? '' : 'text-video__cover-video--no-caption'"
        [alt]="data.cover?.alt || ''"
        [src]="data.cover.url | safeResourceUrl"
      />
    </ng-container>
    <!-- Iframe media -->
    <ng-container *ngIf="isMediaLoaded() || !data?.cover">
      <iframe
        *ngIf="data.cover"
        class="text-video__iframe-video"
        [ngClass]="data.caption ? '' : 'text-video__iframe-video--no-caption'"
        [src]="generateEmbedUrl(data.video, isDesktop) | safeResourceUrl"
        [title]="element?.title || ''"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="0"
      ></iframe>
      <iframe
        *ngIf="!data.cover"
        class="text-video__iframe-video"
        [ngClass]="data.caption ? '' : 'text-video__iframe-video--no-caption'"
        [src]="generateEmbedUrl(data.video) | safeResourceUrl"
        [title]="element?.title || ''"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="0"
        loading="lazy"
      ></iframe>
    </ng-container>
  </div>
  <div *ngIf="data.caption && data.video" class="legend">
    <p>{{ data.caption }}</p>
  </div>
</div>
