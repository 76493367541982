<div 
  [id]="trustpilotId" 
  class="trustpilot-widget"
  data-locale="fr-FR"
  data-template-id="5419b732fbfb950b10de65e5"
  data-businessunit-id="5f2a7644d9d39500016dc5b6"
  [attr.data-text-color]="textColor"
  [attr.data-style-height]="height" 
  [attr.data-style-width]="width"
  [attr.data-theme]="theme">
  <a href="https://fr.trustpilot.com/review/effy.fr" target="_blank" rel="noopener" [ngStyle]="style">Trustpilot</a>
</div>