import { Component, inject, Input } from '@angular/core';
import { IArticlePdfBloc } from './article-pdf';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { LiseretComponent } from '../../liseret/liseret.component';
import { NgFor } from '@angular/common';
import { DirectionArtisticDirective, EffyLinkComponent, EffyLinkSize, ThemeHelperService } from '@effy-tech/angular-common';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'nscf-article-pdf',
    templateUrl: 'article-pdf-bloc.component.html',
    styleUrls: ['article-pdf-bloc.component.scss'],
    standalone: true,
    imports: [NgFor, LiseretComponent, EffyLinkComponent, MatIconModule],
    hostDirectives: [DirectionArtisticDirective],
})
export class ArticlePdfBlocComponent extends BasicThemedComponentDirective {
  @Input() data: IArticlePdfBloc;
  @Input() isDesktop = true;

  isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  linkSize = EffyLinkSize.MEDIUM

  openPdf(url: string) {
    window.open(url, '_blank');
  }
}
