import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { EffyCommonModule } from '@effy-tech/common';
import { VideoGenerator } from '../../../classes/video-generator/video-generator';
import { WebsiteThemes } from '../../../enums/website-themes';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { IMediasCardsArticle, IMediasCardsVideo } from '../medias-card';

@Component({
  selector: 'nscf-medias-card',
  templateUrl: 'medias-card.component.html',
  styleUrls: ['medias-card.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, EffyCommonModule, SafeHtmlPipe, SafeResourceUrlPipe],
  hostDirectives: [DirectionArtisticDirective],
})
export class MediasCardComponent extends VideoGenerator implements OnInit {
  @Input() slide: any;
  @Input() stepNumber: number;
  loadedMedias: boolean;
  clampingClassByIndex: string;
  url: string;
  @Input() theme: WebsiteThemes = WebsiteThemes.effy;

  constructor() {
    super();
  }

  @HostBinding('class') get HeadingClass(): string {
    return this.theme;
  }

  ngOnInit(): void {
    // Initialize according to mediasCarouselItems
    // Update loaded media
    this.slide = <IMediasCardsArticle | IMediasCardsVideo>this.slide;
    this.loadedMedias = this.slide['video'] && !this.slide['videoCover'];
    // Update clamping
    this.clampingClassByIndex = `clamp-${this.getClampingLineValue(this.slide)}`;

    if (this.slide.video) {
      this.url = this.generateEmbedUrl(this.slide.video, !!this.slide.videoCover);
    }
  }

  loadMedia(event: MouseEvent): void {
    if (!this.isMediaLoaded()) {
      this.loadedMedias = true;
      this.url = this.generateEmbedUrl(this.slide.video, !!this.slide.videoCover);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  isMediaLoaded(): boolean {
    return this.loadedMedias;
  }

  generateEmbedUrl(videoUrl: string, autoplay?: boolean): string {
    return super.generateEmbedUrl(videoUrl, autoplay);
  }

  private getClampingLineValue(slide: IMediasCardsArticle | IMediasCardsVideo): number {
    // If there is only description, clipping at 16 lines
    let clampingValue = 16;

    // Decrement lines for Iframe/Image call block
    if (slide['video'] || slide['videoCover']) {
      clampingValue -= 9;
    }

    // Decrement lines for PDF/Link block
    if (slide['pdf'] || slide['url']) {
      clampingValue -= 3;
    }

    // Decrement lines for Media block
    if (slide.picto || slide.name || slide.date) {
      clampingValue -= 3;
    }
    return clampingValue;
  }
}
