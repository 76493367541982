import { isPlatformBrowser, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterContentInit, Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { EffyLinkComponent, EffyLinkSize, EffyLinkStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { ItemComponent } from '../item/item.component';
import { IMenu } from './header-menu';

@Component({
  selector: 'nscf-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  standalone: true,
  imports: [NgStyle, NgIf, NgFor, NgClass, ItemComponent, EffyLinkComponent, MatIcon],
})
export class MenuComponent extends BasicThemedComponentDirective implements OnInit, AfterContentInit {
  @Input() menu: IMenu;
  @Input() isDesktop: boolean;
  @Input() index: number;
  @Output() goBack: EventEmitter<boolean> = new EventEmitter();
  private readonly elementRef = inject(ElementRef);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly EffyLinkSize = EffyLinkSize;
  readonly EffyLinkStyle = EffyLinkStyle;

  menuWidth: number;
  menuGapFromLeft: number;

  columnWidth = 267;
  paddingMenu = 40;
  marginMenu = 48;
  id: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.id = `header-list-panel + ${this.index}`;
    this.menuWidth = this.isDesktop ? this.menu.list.items.length * this.columnWidth + this.paddingMenu : null;

    if (isPlatformBrowser(this.platformId)) {
      if (this.menuWidth > window.innerWidth) {
        this.menuWidth = window.innerWidth - this.marginMenu;
      }
    }
  }

  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platformId) && this.isDesktop) {
      const windowWidth: number = window.innerWidth;
      const menuPositionLeft: number = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().left;
      this.menuGapFromLeft = this.getComputedGapFromLeft(windowWidth, this.menuWidth, menuPositionLeft);
    }
  }

  onGoBack(): void {
    this.goBack.emit(true);
  }

  private getComputedGapFromLeft(windowWidth: number, menuWidth: number, menuPositionLeft: number): number {
    const reducedLinkGapFromLeft = 28;

    if (menuPositionLeft - reducedLinkGapFromLeft + menuWidth > windowWidth) {
      return menuWidth + menuPositionLeft - this.marginMenu / 2 - windowWidth;
    }

    return reducedLinkGapFromLeft;
  }
}
