import { ILink } from '../../../interfaces/link';

export const TypeNameSubBlockCTA = 'ParagraphCta';

export interface ISubBlockCTA {
  __typename: 'ParagraphCta';
  id?: number;
  ctaLabel?: string;
  ctaUrl?: ILink;
  ctaConditions?: string;
  sticky?: boolean;
}
