<details *ngIf="displayedSummaryElements()?.length" open>
  <summary>
    <span class="summary-title">
      @if (!isNewArtisticDirectionTheme()) {
      <img width="26" height="12" src="../../../../assets/icons/summary.svg" alt="" />
      }
      <span>Sommaire</span>
    </span>
    <img src="../../../../assets/arrow/arrow.svg" class="arrow-img-summary" width="16" height="16" alt="" />
  </summary>
  <nav>
    <ol>
      <li *ngFor="let element of displayedSummaryElements()">
        <a [routerLink]="'.'" [fragment]="element.url">
          <span>{{ element.title }}</span>
        </a>
      </li>
    </ol>
  </nav>
  <button *ngIf="showSeeMoreButton()" class="see-more-less-button" (click)="seeMore.set(!seeMore())">
    <span>{{ seeMoreLabel() }}</span>
    <img
      src="{{ this.isNewArtisticDirectionTheme() ? '../../../../assets/arrow/arrow-white.svg' : '../../../../assets/arrow/arrow.svg' }}"
      class="arrow-img-button" width="16" height="16" [class.see-more]="seeMore()" alt="" />
  </button>
</details>