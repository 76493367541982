import { ISubBlockPresenter } from '../presenter';

import { ISubBlockTexts, ISubBlockTextsBo, TypeNameSubBlockTexts } from '../../components/block-text/texts/texts';
import { IVideoText } from '../../components/block-text/texts/video-text/video-text';
import { IPosition } from '../../interfaces/position';
import { LinkPresenter } from '../commons/link.presenter';
import { MapPresenter } from '../commons/map.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { WidgetCalculatorPresenter } from '../commons/widget-calculator.presenter';

export class SubBlockTextsPresenter implements ISubBlockPresenter {
  parse(data: ISubBlockTextsBo): ISubBlockTexts {
    const subBlock: ISubBlockTexts = {
      __typename: TypeNameSubBlockTexts,
      id: data.id,
      title: data.title || '',
      text: RTEPresenter.getRTEText(data.text),
      image: MediaImagePresenter.getImageWebp(data.image),
      imageLocation: data.location ?? IPosition.LEFT,
      video: this.getVideoText(data),
      videoLocation: data.locationVideo ?? IPosition.LEFT,
      map: MapPresenter.getDataMap(data),
      ctaLabel: data.buttonLabel || '',
      ctaUrl: LinkPresenter.getLink(data.buttonUrl),
      widgetCalculator: WidgetCalculatorPresenter.getDataWidget(data),
    };
    return subBlock;
  }

  getVideoText(data: any) {
    const videoText: IVideoText = {
      video: data.video || '',
      cover: MediaImagePresenter.getImage(data['videoCover']),
      caption: data['caption'] || '',
    };
    return videoText;
  }
}
