<nscf-new-header *ngIf="headerDataStatic$ | async as headerDataStatic" [data]="headerDataStatic"
                 [theme]="theme"></nscf-new-header>
<div class="page-error__wrapper">
    <div class="container">
        <div class="offset-0 p-0 offset-md-1 col-12 col-md-8 col-lg-5">
            <div *ngIf="currentErrorData?.isServeurError; then serverBlock; else notfoundBlock"></div>
            <ng-template #serverBlock>
                <div>
                    <h1 class="page-error__title">Oups !</h1>
                    <p class="page-error__sub-title">Il semblerait que la page demandée rencontre un problème.</p>
                    <p class="page-error__description">
                        Pas de panique, nos équipes sont sur le sujet.
                        <span class="d-xl-block">On se retrouve très vite !</span>
                    </p>
                </div>
            </ng-template>
            <ng-template #notfoundBlock>
                <div>
                    <h1 class="page-error__title">Oups !</h1>
                    <p class="page-error__sub-title">Cette page n'existe pas.</p>
                    <p class="page-error__description">
                        Nous n'avons pas trouvé la page
                        <span class="page-error__description-display"> que vous recherchiez. </span>
                    </p>
                </div>
                <div *ngIf="currentErrorData?.links?.length" class="page-error__accessed">
                    <p class="page-error__accessed-title">Que recherchez vous ?</p>
                    <p *ngFor="let link of currentErrorData.links" class="page-error__accessed-link">
                        <a [href]="link.url">{{ link.label }}</a>
                    </p>
                </div>
                <div class="page-error__cta">
                    <a href="">Retour à l’accueil</a>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<nscf-footer [theme]="theme"></nscf-footer>
