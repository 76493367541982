@if (isNewArtisticDirectionTheme()) {
  <div
    class="tw-flex tw-flex-col tw-rounded-lg md:tw-rounded-xl md:tw-flex-row tw-mb-6 spacing-block-text tw-overflow-hidden tw-w-[312px] md:tw-w-full tw-max-w-[90vw] xs:tw-mx-auto"
    [ngClass]="[element().color ? 'focus--' + element().color : '', element().location ? 'focus--' + element().location : '']"
  >
    <img
      loading="lazy"
      [attr.width]="isDesktop() ? '268' : undefined"
      [attr.height]="isDesktop() ? undefined : '164'"
      [src]="element().image?.url"
      [alt]="element().image?.alt"
      class="tw-object-cover tw-h-[164px] tw-w-auto md:tw-w-[268px] md:tw-h-auto"
    />
    <div class="tw-p-6 md:tw-pb-8">
      <nscf-rte class="tw-block" [theme]="theme" [data]="element().description" />
      @if (element().ctaLabel) {
        <nscf-cta-button
          class="tw-mt-6 md:tw-mt-8"
          [theme]="theme"
          [withArrow]="true"
          [text]="element().ctaLabel"
          [ctaUrl]="element().ctaUrl"
          [effyStyle]="buttonColor()"
          [style.--effy-button-width]="isDesktop() ? undefined : '100%'"
        />
      }
    </div>
  </div>
} @else {
  <div *ngIf="bigViewPort" class="container">
    <div class="spacing-block-text">
      <div class="col-8 col-md-10 col-lg-8 mx-auto focus--article">
        <div
          class="row focus"
          [ngClass]="[element().color ? 'focus--' + element().color : '', element().location ? 'focus--' + element().location : '']"
        >
          <div class="col-8 col-md-10 col-lg-8 mx-auto focus__content">
            <img loading="lazy" class="text__img d-none d-md-block d-lg-none" [src]="element().image?.url" [alt]="element().image?.alt" />
            <nscf-rte
              [theme]="theme"
              class="focus__content-text"
              [ngClass]="element().ctaLabel && element().ctaUrl ? 'focus__content-text--with-cta' : ''"
              [data]="element().description"
            >
            </nscf-rte>
            <div *ngIf="element().ctaLabel" class="wrapper-cta-common-npm">
              <nscf-cta-button
                [theme]="theme"
                [btnClass]="'cta-primary big-btn'"
                [text]="element().ctaLabel"
                [ctaUrl]="element().ctaUrl"
                [effyStyle]="buttonColor()"
              />
            </div>
          </div>
          <div class="col-4 col-md-12 col-lg-4 focus__img-position">
            <img loading="lazy" class="text__img d-md-none d-lg-block" [src]="element().image?.url" [alt]="element().image?.alt" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <div *ngIf="!bigViewPort" class="container mobile">
    <div class="spacing-block-text">
      <div class="col-12 col-md-8 col-lg-8 mx-auto focus--article-mob">
        <div class="row focus" [ngClass]="element().color ? 'focus--' + element().color : ''">
          <div class="col-12 p-0 focus__img">
            <img loading="lazy" [src]="element().image?.url" [alt]="element().image?.alt" />
          </div>
          <div class="col-12 focus__content">
            <nscf-rte
              [theme]="theme"
              class="focus__content-text"
              [ngClass]="element().ctaLabel && element().ctaUrl ? 'focus__content-text--with-cta' : ''"
              [data]="element().description"
            >
            </nscf-rte>

            <div *ngIf="element().ctaLabel">
              <nscf-cta-button
                [style.--effy-button-width]="'100%'"
                [theme]="theme"
                [btnClass]="'cta-primary big-btn'"
                [text]="element().ctaLabel"
                [ctaUrl]="element().ctaUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
