import { IBlockPresenter } from '../presenter';
import { IBlocTwoImages, IBlocTwoImagesBO, TypeNameBlocTwoImages } from '../../components/two-images-block/two-images-block';
import { MediaImagePresenter } from '../commons/media-image.presenter';

export class BlockTwoImagesPresenter implements IBlockPresenter {
  parse(data: IBlocTwoImagesBO): IBlocTwoImages {
    // Avoid empty / undefined data.
    if (!data) {
      return null;
    }
    return {
      __typename: TypeNameBlocTwoImages,
      title: data?.title,
      cards: [
        {
          image: MediaImagePresenter.getImage(data?.image1),
          legend: data?.legendImage1,
        },
        {
          image: MediaImagePresenter.getImage(data?.image2),
          legend: data?.legendImage2,
        },
      ],
    };
  }
}
