import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { INPUT_NUMBER_DEFAULT_VALUE } from '../configs/household-people.config';
import { PrecarityLevelModel } from '../models/precarity-level.models';
import { LocationValues } from '../values/location.values';
import { WorksValues } from '../values/works.values';

@Injectable({
  providedIn:'root'
})
export class FormService {

  private readonly workSelectedValueSubject = new BehaviorSubject<WorksValues>(null);

  readonly workSelectedValue$ = this.workSelectedValueSubject.asObservable();

  locationValue = new BehaviorSubject(LocationValues.OtherRegions);

  locationValue$ = this.locationValue.asObservable();

  houseHoldValue = new BehaviorSubject<number>(INPUT_NUMBER_DEFAULT_VALUE);

  houseHoldValue$ = this.houseHoldValue.asObservable();

  rangeValue = new BehaviorSubject<number>(null);

  rangeValue$ = this.rangeValue.asObservable();

  precarityLevelValue = new BehaviorSubject<PrecarityLevelModel>(null);

  precarityLevelValue$ = this.precarityLevelValue.asObservable();

  constructor() { }

  setLocationValue(value: LocationValues): void {
    this.locationValue.next(value);
  }

  setWorkValue(value: WorksValues): void {
    this.workSelectedValueSubject.next(value);
  }

  setHouseHoldeValue(value: number): void {
    this.houseHoldValue.next(value);
  }

  setRangeValue(value: number): void {
    this.rangeValue.next(value);
  }

  setPrecarityLevelValue(value: PrecarityLevelModel): void {
    this.precarityLevelValue.next(value);
  }
}
