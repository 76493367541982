import { ChangeDetectionStrategy, Component, computed, inject, model } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatFormField, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatOption, MatSelect } from "@angular/material/select";
import { IconDefinitions } from '../../configs/icons-definitions.config';
import { FORM_LABELS } from '../../configs/labels/form.labels';
import { WORKS_OPTIONS_SELECTS } from '../../configs/works.config';
import { CommonAssetsService } from '../../services/common-assets/common-assets.service';
import { WorksValues } from '../../values/works.values';
import { SelectSimpleComponent } from '../shared/selects/components/select-simple/select-simple.component';

@Component({
  selector: 'calc-work-selection',
  templateUrl: './work-selection.component.html',
  styleUrls: ['./work-selection.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectSimpleComponent, MatFormField, MatSelect, MatLabel, MatPrefix, MatOption, MatIcon, FormsModule],
})
export class WorkSelectionComponent {

  workType = model<WorksValues>();
  currentValue = computed(() => this.workType());
  placeholder = FORM_LABELS.PLACEHOLDER_WORK_SELECTION;
  private readonly commonAssetsService = inject(CommonAssetsService);

  worksOptions = WORKS_OPTIONS_SELECTS.map(work => ({
    ...work,
    icon: this.commonAssetsService.getWorkImagePngUrl(work.icon as IconDefinitions),
  }));

  onSelect(workSelectedValue: WorksValues): void {
    this.workType.update(() => workSelectedValue);
  }
}
