<ng-container *ngIf="linksArticles$ | async as linksArticles">
  <div *ngIf="linksArticles?.length && bigViewPort" class="heading-article">
    <p class="heading-article__title">En savoir plus</p>
    <div class="wrapper" [ngClass]="{ 'more-item': showAllItems }">
      <div
        class="heading-article__wrapper-link"
        *ngFor="let item of linksArticles | slice: 0:(showAllItems ? undefined : nbMaxItemDisplayedAtFirst)"
      >
        <a [ngClass]="{ active: isCurrentPath(item) }" [href]="item.path">{{ item.text }}</a>
      </div>
    </div>
    <p
      *ngIf="linksArticles.length > nbMaxItemDisplayedAtFirst"
      (click)="onClickViewMore()"
      class="heading-article__item heading-article__item--show-more"
    >
      Voir plus d’articles
    </p>
  </div>

  <!-- Mobile -->
  <div *ngIf="linksArticles?.length && !bigViewPort" class="container" nscfLazyLoadMaterial>
    <mat-accordion>
      <mat-expansion-panel [expanded]="!isBrowser" class="heading-article__accordion-mat">
        <mat-expansion-panel-header [collapsedHeight]="'54px'" [expandedHeight]="'54px'">
          <mat-panel-title>
            <div class="heading-article__title heading-article__title--accordion">En savoir plus</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="heading-article__wrapper-link" *ngFor="let item of linksArticles">
          <a [ngClass]="{ active: isCurrentPath(item) }" [href]="item.path">{{ item.text }}</a>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
