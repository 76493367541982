import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { LazyLoadMaterialDirective } from '../../directives/lazy-load-material/lazy-load-material.directive';
import { ILink } from '../../interfaces/link';
import { getQueryStringFromObject } from '../../utils/object/object.utils';
import { Parcours, ParcoursUrlUtils } from '../../utils/parcours/parcours.utils';
import { QUESTIONS_DATA } from '../../values/form-parcours.values';
import { CtaBlueComponent } from '../cta-blue/cta-blue/cta-blue.component';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { HousingBuildValues, HousingTypeValues } from '../form-parcours/form-parcours';
import { FormParcoursQuestionComponent } from '../form-parcours/question/form-parcours-question.component';

@Component({
  selector: 'nscf-cta-sticky-with-questions',
  standalone: true,
  templateUrl: './cta-sticky-with-questions.component.html',
  styleUrls: ['./cta-sticky-with-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormParcoursQuestionComponent,
    CtaBlueComponent,
    CtaButtonComponent,
    AsyncPipe,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    LazyLoadMaterialDirective,
    MatDividerModule,
    NgIf,
    NgClass,
  ],
  hostDirectives: [DirectionArtisticDirective],
})
export class CtaStickyWithQuestionsComponent extends BasicThemedComponentDirective {
  private readonly questions = QUESTIONS_DATA;
  protected readonly firstQuestion = { ...this.questions[0], title: null };
  protected readonly secondQuestion = { ...this.questions[1], title: null };
  protected defaultHousingAge = HousingBuildValues.MoreThan15;
  protected readonly housingType$: BehaviorSubject<HousingTypeValues | null> = new BehaviorSubject(null);
  protected readonly housingAge$: BehaviorSubject<HousingBuildValues> = new BehaviorSubject(this.defaultHousingAge);

  readonly isNewDA = toSignal(inject(ThemeHelperService).isNewArtisticDirection$)

  protected readonly housingAges: { value: HousingBuildValues; label: string }[] = [
    { value: HousingBuildValues.LessThan2Year, label: '- de 2 ans' },
    { value: HousingBuildValues.Between2and15, label: 'Entre 2 et 15 ans' },
    { value: HousingBuildValues.MoreThan15, label: '+ de 15 ans' },
  ];
  protected readonly uri$ = combineLatest([this.housingType$, this.housingAge$]).pipe(
    map(([housingType, housingAge]) => this.computeUri(housingType, housingAge))
  );

  protected onHousingTypeChange(housingType: HousingTypeValues): void {
    this.housingType$.next(housingType);
  }

  protected onHousingAgeChange(housingAge: HousingBuildValues): void {
    this.housingAge$.next(housingAge);
  }

  private computeUri(housingType: HousingTypeValues | null, housingAge: HousingBuildValues): ILink {
    const urlParcoursParams = getQueryStringFromObject({ housingAge, ...(housingType ? { housingType } : {}) });
    const url = `${ParcoursUrlUtils.computeParcoursBaseUrl(Parcours.TravauxAides)}?${urlParcoursParams}`;
    return {url};
  }
}
