import { ICard, ICardificationResponse, ICardificationResponseObject } from '../../interfaces/cardification-response';
import { ICardSummary, ICardSummarySimplified } from '../../interfaces/cards-summary';
import { PAGE_ARTICLE } from '../../values/page-types';
import { CardSimplifiedPresenter, ICardificationSimplifiedResultBo, ICover } from './shared/card-simplified.presenter';

export interface CardificationHubData {
  cards: ICardSummarySimplified[],
  count: number;
}

export class CardificationHubPresenter {
  static mapCardBoToSimplifiedCardsHub(data: ICardificationResponse, isMulti?: boolean, isPromoted?: boolean): CardificationHubData {
    let cardsBoData: ICardificationResponseObject
    if (isMulti) {
      cardsBoData = isPromoted ? data.cardificationGraphqlPromoted : data.cardificationGraphql;
    } else {
      cardsBoData = isPromoted ? data.cardificationMonoPromotedGraphql : data.cardificationMonoGraphql;
    }
    return {
      cards: cardsBoData.results.map(cardBo => mapCardArticleOfferBoToSimplifiedCardHub(cardBo)),
      count: cardsBoData.count
    };
  }

  static mapNewsCardsTheme(obj: any): Array<ICardSummary> {
    const cards = obj?.data?.cardificationGraphqlGraphQLBlockTheme?.results;
    return cards.map(cardBo => mapCardArticleOfferBoToSimplifiedCardHub(cardBo));
  }
}

export function mapCardArticleOfferBoToSimplifiedCardHub(cardBo: ICard): ICardSummarySimplified {
  const simplifiedCardData: ICardificationSimplifiedResultBo = {
    ...getCoverData(cardBo),
    title: cardBo.title,
    metatag: cardBo.metatag,
    link: cardBo.link,
    theme: cardBo.theme,
    created: cardBo.created,
    type: cardBo.type,
  };
  return CardSimplifiedPresenter.mapCardBoToSimplifiedCard(simplifiedCardData);
}

export function removeCurrentPageFromCards(currentUrl, cards: Array<ICardSummarySimplified> = []): Array<ICardSummarySimplified> {
  return cards.filter(card => card.link.url != currentUrl);
}

export function getCoverData(cardBo: ICard): ICover {
  const isPageArticle = cardBo.type === PAGE_ARTICLE;
  const cover = isPageArticle ? cardBo.articleCover : cardBo.offerCover;
  const coverWebp = isPageArticle ? cardBo.articleCoverWebp : cardBo.offerCoverWebp;
  const coverAlt = isPageArticle ? cardBo.articleCoverAlt : cardBo.offerCoverAlt;
  return {cover, coverWebp, coverAlt};
}
