import { Injectable } from '@angular/core';
import { ACCEPTED_SITES } from '../../configs/accepted-sites.config';
import { DEFAULT_SITE } from '../../configs/accepted-sites.config';
import { Site } from '../../models/widget-calculator.models';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  private site: Site;

  constructor() { }

  setSite(site: Site): void {
    this.site = ACCEPTED_SITES.map(c => c.toString()).includes(site.toString()) ? site : DEFAULT_SITE;
  }

  getSite(): Site {
    return this.site;
  }
}
