import { EffyEnvironment } from "@effy-tech/angular-common";
import { AllSites, Site } from '../enums/sites.enum';

const sitesUrls: { [key in EffyEnvironment]: { [key in AllSites]: string } } = {
  [EffyEnvironment.Dev]: {
    [Site.CALCULEO]: 'https://dev.calculeo.quelleenergie.net',
    [Site.EFFY]: 'https://dev.effy.quelleenergie.net',
    [Site.LPE]: 'https://dev.lpe.quelleenergie.net',
    [Site.QE]: 'https://dev.qe.quelleenergie.net',
  },
  [EffyEnvironment.Rct]: {
    [Site.CALCULEO]: 'https://rct.calculeo.quelleenergie.net',
    [Site.EFFY]: 'https://rct.effy.quelleenergie.net',
    [Site.LPE]: 'https://rct.lpe.quelleenergie.net',
    [Site.QE]: 'https://rct.qe.quelleenergie.net',
  },
  [EffyEnvironment.Prod]: {
    [Site.CALCULEO]: 'https://www.calculeo.fr',
    [Site.EFFY]: 'https://www.effy.fr',
    [Site.LPE]: 'https://www.laprimeenergie.fr',
    [Site.QE]: 'https://www.quelleenergie.fr',
  },
};

export const getSiteUrl = (site: AllSites, env: EffyEnvironment): string => sitesUrls[env][site];
