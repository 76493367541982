import { LoggerService } from '@effy-tech/common/loggers';
import { forEach } from 'lodash-es';
import { ISubBlockFocusBusiness, TypeNameSubBlockFocusBusiness } from '../components/block-text/focus-business/focus-business';
import { ISubBlockImage, TypeNameSubBlockImage } from '../components/block-text/images/images';
import { ISubBlockCTA, TypeNameSubBlockCTA } from '../components/block-text/sub-block-cta/sub-block-cta';
import { ISubBlockTables, TypeNameSubBlockTables } from '../components/block-text/tables/tables';
import { ISubBlockTexts, TypeNameSubBlockTexts } from '../components/block-text/texts/texts';
import { ISubBlockVideos, TypeNameSubBlockVideos } from '../components/block-text/videos/videos';
import { ISubBlockQuestionCTA, TypeNameSubBlockQuestionCTA } from '../interfaces/question-cta';
import { SubBlockFocusBusinessPresenter } from './sub-blocks/focus-business.presenter';
import { SubBlockImagePresenter } from './sub-blocks/image.presenter';
import { SubBlockQuestionCTAPresenter } from './sub-blocks/question-cta.presenter';
import { SubBlockCTAPresenter } from './sub-blocks/sub-block-cta.presenter';
import { SubBlockTablesPresenter } from './sub-blocks/tables.presenter';
import { SubBlockTextsPresenter } from './sub-blocks/texts.presenter';
import { SubBlockVideosPresenter } from './sub-blocks/videos.presenter';

export class MainSubBlockPresenter {
  constructor(private readonly logger: LoggerService) {}

  parse(
    data: Array<any>
  ): Array<
    ISubBlockFocusBusiness | ISubBlockImage | ISubBlockCTA | ISubBlockQuestionCTA | ISubBlockTables | ISubBlockTexts | ISubBlockVideos
  > {
    const subBlocks = [];
    forEach(data, (dataObject: any) => {
      const subBlockPresenter = this.getPresenter(dataObject, dataObject.__typename || dataObject.entity.__typename);
      const isFromLibrary = dataObject.entity.fieldReusableParagraph;
      // parse the subBlock data
      if (subBlockPresenter) {
        subBlocks.push(
          subBlockPresenter.parse(isFromLibrary ? dataObject.entity.fieldReusableParagraph.entity.paragraphs.entity : dataObject.entity)
        );
      }
    });
    return subBlocks;
  }

  getPresenter(dataObject: any, typename: string): any {
    // determine Presenter according to the typename
    let dataFromLibrary;
    switch (typename) {
      // Focus Business
      case TypeNameSubBlockFocusBusiness:
        return new SubBlockFocusBusinessPresenter();
      // Image
      case TypeNameSubBlockImage:
        return new SubBlockImagePresenter();
      // CTA
      case TypeNameSubBlockCTA:
        return new SubBlockCTAPresenter();
      // Question CTA
      case TypeNameSubBlockQuestionCTA:
        return new SubBlockQuestionCTAPresenter();
      // Tables
      case TypeNameSubBlockTables:
        return new SubBlockTablesPresenter();
      // Texts
      case TypeNameSubBlockTexts:
        return new SubBlockTextsPresenter();
      // Videos
      case TypeNameSubBlockVideos:
        return new SubBlockVideosPresenter();
      case 'ParagraphFromLibrary':
        dataFromLibrary = dataObject.entity.fieldReusableParagraph.entity.paragraphs;
        return this.getPresenter(dataObject, dataFromLibrary.entity.__typename);
      default:
        this.logger.error(`MainSubBlockPresenter: SubBlock Presenter not found (typename "${typename}")`, dataObject);
        return null;
    }
  }
}
