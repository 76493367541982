import { IFormParcours, IFormParcoursQuestion, IParcoursProperties } from '../../components/form-parcours/form-parcours';
import { FORM_PARCOURS_DATA } from '../../values/form-parcours.values';

export class FormParcoursPresenter {
  public static parse(title: string, showSecondQuestion = true): IFormParcours {
    return {
      ...FORM_PARCOURS_DATA,
      title,
      questions: removeSecondQuestion(FORM_PARCOURS_DATA.questions, showSecondQuestion),
    };
  }
}

function removeSecondQuestion(questions: Array<IFormParcoursQuestion>, showSecondQuestion: boolean): Array<IFormParcoursQuestion> {
  return FORM_PARCOURS_DATA.questions.filter(question => showSecondQuestion || question.property === IParcoursProperties.HOUSING_TYPE);
}
