import { LANDING_SOLAR_PATH } from '@acq-static/landing-solar/landing-solar.config';
import { Routes } from '@angular/router';
import { ErrorValue, PreviewComponent } from '@effy-tech/acquisition-core';
import { LinksPageCorp } from './static/page-corp/page-corp';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./static/content-home-c/content-home-c.component').then(c => c.ContentHomeCComponent),
    pathMatch: 'full',
  },
  {
    path: 'previewbo',
    component: PreviewComponent,
    pathMatch: 'full',
  },
  {
    path: LinksPageCorp.ABOUT_US,
    loadChildren: () => import('./static/page-corp/page-corp-routing.module').then(m => m.PageCorpRoutingModule),
  },
  {
    path: 'magazine',
    loadComponent: () => import('./static/magazine/magazine.component').then(c => c.MagazineComponent),
  },
  {
    path: LANDING_SOLAR_PATH,
    loadComponent: () => import('./static/landing-solar/landing-solar.component').then(c => c.LandingSolarComponent),
  },
  {
    path: 'graphql',
    loadChildren: () => import('./static/static-routing.module').then(m => m.StaticRoutingModule),
    data: {
      statusCode: ErrorValue.NOT_FOUND_CODE.statusCode,
      message: ErrorValue.NOT_FOUND_CODE.message,
    },
  },
  {
    path: 'erreur',
    loadChildren: () => import('./static/static-routing.module').then(m => m.StaticRoutingModule),
    pathMatch: 'full',
    data: {
      statusCode: ErrorValue.SERVER_ERROR_CODE.statusCode,
      message: ErrorValue.SERVER_ERROR_CODE.message,
    },
  },
  {
    path: '**',
    loadComponent: () => import('./graphql/graphql.component').then(c => c.GraphqlComponent),
  },
];
