/* eslint-disable no-useless-escape */
import { BoUrlService } from '../../services/bo-url/bo-url.service';

export class PdfHelper {
  public static setPdfLinkTarget(event: any, target?: string): void {
    const htmlLink = event.target;
    if (htmlLink?.nodeName.toLowerCase() !== 'a') {
      return;
    }

    if (htmlLink.href.split('.').pop().toLowerCase() === 'pdf' && !htmlLink.target) {
      htmlLink.target = target || '_self';
    }
  }

  public static rewritePdfsUrl(content: string): string {
    return content.replace(new RegExp('[^"\\\'=\\s]+\\.(pdf)'), BoUrlService.rewriteBoUrl);
  }
}
