import { INewsletter, TypeNameBlocNewsletter } from '../../../components/newsletter/newsletter';
import { IBlockPresenter } from '../../presenter';

interface BlocNewsletterDataBO {
  __typename: string;
  showNewsletter: boolean;
}

export class BlocNewsletterPresenter implements IBlockPresenter {
  parse(data: BlocNewsletterDataBO): INewsletter {
    return data
      ? {
          __typename: TypeNameBlocNewsletter,
          isActive: data?.showNewsletter,
        }
      : null;
  }
}
