import { map } from 'lodash-es';
import { IMediasCarouselType } from '../../components/carousel/carousel';
import { ImagesService } from '../../services/images-service/images.service';

export class CardificationVideoPresenter {
  static mapNewsCardsVideo(results): Array<IMediasCarouselType> {
    const cards = results.data?.mediaQuery?.entities;
    let i = 0;
    return map(cards, card => {
      const media = card?.fieldMedia?.entity?.fieldMediaImage;
      if (media) {
        media.url = ImagesService.rewriteImagesUrl(media.url);
      }
      return {
        id: card.id || 'card_' + i++,
        description: {
          format: 'full_html',
          value: card.fieldTextPlain1v1,
        },
        picto: null,
        name: '',
        date: '',
        __typename: 'ParagraphCardsVideos',
        video: card.fieldMediaOembedVideo,
        videoCover: media,
      };
    });
  }
}
