<div class="spacing-block">
  <div class="container">
    <nscf-title *ngIf="data?.title" [title]="data.title"></nscf-title>
    <div *ngIf="data" [id]="trustpilotId" data-locale="fr-FR" data-template-id="54ad5defc6454f065c28af8b"
      data-businessunit-id="5f2a7644d9d39500016dc5b6" [attr.data-style-height]="height" [attr.data-style-width]="width"
      data-theme="light" [attr.data-tags]="data.filterTag" [attr.data-stars]="data.filterRating"
      data-review-languages="fr">
      <a href="https://fr.trustpilot.com/review/effy.fr" target="_blank" rel="noopener" [ngStyle]="style">Trustpilot</a>
    </div>
  </div>
</div>