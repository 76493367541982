import {
  HousingBuildValues,
  HousingTypeValues,
  IFormParcours,
  IFormParcoursQuestion,
  IParcoursProperties,
} from '../components/form-parcours/form-parcours';

export const QUESTIONS_DATA: Array<IFormParcoursQuestion> = [
  {
    title: 'Vos travaux concernent :',
    property: IParcoursProperties.HOUSING_TYPE,
    hasBiggerGap: true,
    options: [
      {
        label: 'Une maison',
        key: HousingTypeValues.House,
        iconUrl: 'house.svg',
      },
      {
        label: 'Un appartement',
        key: HousingTypeValues.Apartment,
        iconUrl: 'apartment.svg',
      },
    ],
  },
  {
    title: 'Votre logement a été construit :',
    isSmallOptinText: true,
    property: IParcoursProperties.HOUSING_AGE,
    options: [
      {
        label: '- de 2 ans',
        key: HousingBuildValues.LessThan2Year,
      },
      {
        label: 'Entre <br> 2 et 15 ans',
        key: HousingBuildValues.Between2and15,
      },
      {
        label: '+ de 15 ans',
        key: HousingBuildValues.MoreThan15,
      },
    ],
  },
];

export const FORM_PARCOURS_DATA = <IFormParcours>{
  title: 'Isolez vos combles à partir de 26€ pendant 60 mois*',
  redirectionUrl: 'redirect url',
  questions: QUESTIONS_DATA,
};
