import { ITopPageMosaic, ITopPageMosaicBlocBo } from '../../../components/top-page-mosaic/top-page-mosaic';
import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { MediaImagePresenter } from '../../commons/media-image.presenter';
import { IBlockPresenter } from '../../presenter';

export class BlockTopPageMosaicPresenter implements IBlockPresenter {
  parse(data: ITopPageMosaicBlocBo): ITopPageMosaic {
    if (!data) {
      return null;
    }

    return {
      __typename: 'ParagraphTopPageMozaique',
      title: data.title,
      carousel: this.getCarouselImages(data),
      enableTrustPilot: data.enableTrustPilot
    };
  }

  private getCarouselImages(data: ITopPageMosaicBlocBo): IFieldMediaImage[] {
    let carouselImages: IFieldMediaImage[] = [];

    for (const image of data.images) {
      carouselImages.push(MediaImagePresenter.getImageWebp(image));
    }

    return carouselImages.length === 5 ? carouselImages : [carouselImages[0]];
  }
}
