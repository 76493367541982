import { IRenovationType } from './renovation-types';

export const TypeNameBlockEligibleRenovations = 'ParagraphWorks';

export interface IEligibleRenovations {
  __typename: 'ParagraphWorks';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  subtitle?: string; // chapo
  renovationTypes?: Array<IRenovationType>;
}

// @TODO : From there, this has been generated from data
export interface IParagraphWorksBo {
  __typename: string;
  entityType: string;
  id: number;
  title: string;
  chapo: string;
  blockWorks: BlockWork[];
}

export interface BlockWork {
  entity: BlockWorkEntity;
}

export interface BlockWorkEntity {
  id: number;
  label: string;
  image: EntityImage;
  picto: Picto;
  title: string;
  chapo: null;
  buttonText: string;
  buttonUrl: ButtonURL;
  description: Description;
  gaugeTitle: Description;
  gauge: number;
}

export interface ButtonURL {
  url: URL;
  title: string;
  options: Options;
}

export interface Options {
  attributes: Attributes;
}

export interface Attributes {
  target: string;
}

export interface URL {
  path: string;
}

export interface Description {
  value: string;
  format: string;
  processed: string;
}

export interface EntityImage {
  entity: ImageEntity;
}

export interface ImageEntity {
  fieldMediaImage: PurpleFieldMediaImage;
}

export interface PurpleFieldMediaImage {
  originalImage: string;
  width: number;
  height: number;
  alt: string;
  title: string;
  image: FieldMediaImageImage;
}

export interface FieldMediaImageImage {
  url: string;
  width: number;
  height: number;
}

export interface Picto {
  entity: PictoEntity;
}

export interface PictoEntity {
  fieldMediaImage: FluffyFieldMediaImage;
}

export interface FluffyFieldMediaImage {
  alt: string;
  width: number;
  height: number;
  title: string;
  url: string;
  scale: Scale;
}

export interface Scale {
  url: string;
}
