import { Component } from '@angular/core';
import { EffyCommonModule } from '@effy-tech/common';
import { SafeResourceUrlPipe } from '../../pipes/safe-resource-url/safe-resource-url.pipe';

@Component({
  selector: 'nscf-verified-reviews',
  templateUrl: 'verified-reviews.component.html',
  styleUrls: ['verified-reviews.component.scss'],
  standalone: true,
  imports: [EffyCommonModule, SafeResourceUrlPipe],
})
export class VerifiedReviewsComponent {}
