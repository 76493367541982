import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { ILink } from '../../../interfaces/link';
import { IPosition } from '../../../interfaces/position';
import { IRteText } from '../../../interfaces/rte-text';

export const TypeNameSubBlockFocusBusiness = 'ParagraphFocusBusiness';

export interface ISubBlockFocusBusiness {
  __typename: 'ParagraphFocusBusiness';
  id?: number;
  description?: IRteText;
  image?: IFieldMediaImage;
  location?: IPosition;
  ctaLabel?: string;
  ctaUrl?: ILink;
  color?: IColorFocusBusiness;
}

export enum IColorFocusBusiness {
  BLUE = 'blue',
  GREY = 'grey',
  GREEN = 'green',
}
