import { NgFor, NgIf } from '@angular/common';
import { Component, inject, input, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { LoadImagesService } from '../../services/load-images.service.ts/load-images.service';
import { IConnection } from '../connection/connection';
import { ConnectionComponent } from '../connection/connection.component';
import { ICtaLink } from '../cta/cta-button/cta-button';
import { CtaButtonComponent } from '../cta/cta-button/cta-button.component';
import { IHeaderPhoneInsert } from '../header/header';
import { LinkComponent } from '../link/link.component';
import { PhoneBlockComponent } from '../phone-block/phone-block.component';
import { INavbar } from './navbar';

@Component({
    selector: 'nscf-navbar',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, LinkComponent, ConnectionComponent, CtaButtonComponent, PhoneBlockComponent]
})
export class NavbarComponent extends BasicThemedComponentDirective {
  private readonly loadImagesService = inject(LoadImagesService);
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly scrollOverHeight = input(false); 
  @Input() navbar: INavbar;
  @Input() connection: IConnection;
  @Input() phoneInsert: IHeaderPhoneInsert;
  @Input() linkPart: ICtaLink;
  @Input() isDesktop: boolean;
  isSubMenuMobileOpened = false;
  loadMenu = false;

  constructor() {
    super();
  }

  onChecked(isChecked: boolean): void {
    this.isSubMenuMobileOpened = isChecked;
  }

  loadImages() {
    this.loadImagesService.loadImages$.next(true);
  }
}
