<!-- desktop -->
<div *ngIf="bigViewPort" [ngClass]="{ 'no-summary': !summaryElements?.length && !promotionalBlock?.showPromoBlock }"
  class="article-summary">
  <nscf-article-related [bigViewPort]="bigViewPort" [data]="related"></nscf-article-related>
  <nscf-article-theme [data]="taxonomyTheme"></nscf-article-theme>
  <nscf-heading-article *ngIf="visibleHeading" [bigViewPort]="bigViewPort" [data]="headingTheme"
    [isBrowser]="isBrowser"></nscf-heading-article>
  <ng-container *ngIf="showSummary && summaryElements.length">
    <div class="article-summary__title">Sommaire</div>
    <ul class="article-summary__list">
      <li *ngFor="let element of summaryElements; index as i" [ngClass]="{ active: element.completion > 0 }"
        class="article-summary__item">
        <span [style.width]="getProgressWidth(element.completion)" class="article-summary__progress"></span>
        <span class="article-summary__text">
          <a (click)="index = i" [href]="urlSegments + '#anchor_' + element.id">{{ element.title }}</a>
        </span>
      </li>
    </ul>
  </ng-container>
  <nscf-article-promo *ngIf="promotionalBlock?.showPromoBlock" [data]="promotionalBlock"
    [theme]="theme"></nscf-article-promo>
</div>

<!-- Mobile -->
<!-- Component Select used for eligible renovation to change for summary -->
<div *ngIf="showSummary && !bigViewPort && summaryElements.length"
  [ngClass]="{ 'article-summary__select--multi-themes': typePage === typeNamePageManualHub }"
  class="article-summary__select">
  <nscf-select (selectionChanged)="navigateToElementSummary($event)" [current]="null" [displayedProperty]="'title'"
    [placeHolder]="typePage === typeNamePageManualHub ? 'Sélectionnez un thème' : 'Sommaire'" [source]="summaryElements"
    class="summary__select">
  </nscf-select>
</div>