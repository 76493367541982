import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { EffyCommonModule } from '@effy-tech/common';
import { VideoGenerator } from '../../../classes/video-generator/video-generator';
import { WebsiteThemes } from '../../../enums/website-themes';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { TitleComponent } from '../../title/title.component';
import { VideoPlayBackgroundComponent } from '../../video-play-background/video-play-background.component';
import { ISubBlockVideos } from './videos';

@Component({
  selector: 'nscf-videos',
  templateUrl: 'videos.component.html',
  styleUrls: ['videos.component.scss'],
  standalone: true,
  imports: [NgIf, TitleComponent, NgClass, CtaButtonComponent, EffyCommonModule, SafeResourceUrlPipe, VideoPlayBackgroundComponent, EffyButtonComponent],
  hostDirectives: [DirectionArtisticDirective],
})
export class VideosComponent extends VideoGenerator {
  @Input() element: ISubBlockVideos;
  @Input() loadedMedias;
  @Input() stepNumber: string;
  @Input() theme: WebsiteThemes = WebsiteThemes.effy;
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonStyle = EffyButtonStyle;

  constructor() {
    super();
  }

  @HostBinding('class') get HeadingClass(): string {
    return this.theme;
  }

  isMediaLoaded(stepNumber: string): boolean {
    return this.loadedMedias[parseInt(stepNumber, 10)];
  }

  loadMedia(event: MouseEvent, stepNumber: string): void {
    event.preventDefault();
    if (this.isMediaLoaded(stepNumber)) {
      event.stopPropagation();
    } else {
      this.loadedMedias[parseInt(stepNumber, 10)] = true;
    }
  }

  generateEmbedUrl(videoUrl: string, autoplay?: boolean): string {
    return super.generateEmbedUrl(videoUrl, autoplay);
  }
}

