import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { LoggerService } from '@effy-tech/common/loggers';
import { PreviewService } from '../services/preview/preview.service';
import { PageScrollingService } from '../services/page-scrolling/page-scrolling.service';
import { ComponentInitializerDirective } from '../classes/component-initializer/component-initializer';
import { MetaTagsService, MetaTagsTitleService } from '../services/meta-tags';
import { DeviceManagerService } from '../services/device-manager/device-manager.service';
import { ConfigService } from '../modules/config/config.service';
import { SafeResourceUrlPipe } from '../pipes/safe-resource-url/safe-resource-url.pipe';
import { EffyCommonModule } from '@effy-tech/common';

@Component({
    selector: 'nscf-preview',
    templateUrl: './preview.component.html',
    styles: [],
    standalone: true,
    imports: [EffyCommonModule, SafeResourceUrlPipe]
})
export class PreviewComponent extends ComponentInitializerDirective implements OnInit {
  backUrl: string;

  constructor(
    private readonly previewService: PreviewService,
    private readonly activatedRoute: ActivatedRoute,
    metaTagsService: MetaTagsService,
    metaTagsTitleService: MetaTagsTitleService,
    pageScrollingService: PageScrollingService,
    router: Router,
    location: Location,
    @Inject(DOCUMENT) document: any,
    renderer: Renderer2,
    protected readonly deviceManagerService: DeviceManagerService,
    protected readonly logger: LoggerService,
    protected readonly configService: ConfigService
  ) {
    super(metaTagsService, metaTagsTitleService, pageScrollingService, router, location, document, renderer, deviceManagerService, logger);
  }

  ngOnInit() {
    this.theme = this.configService.getTheme();
    this.previewService.getData(this.activatedRoute.snapshot.queryParamMap.get('key')).subscribe((result: any) => {
      this.data = this.previewService.parseData(result.pageRowData, result.menuHeaderResult);
      this.backUrl = result.previewGraphQLUrlCallback;
      this.preparePage();
    });
  }
}
