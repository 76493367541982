<div *ngIf="data && data.name && !data.isEditorialTeam" class="container">
  <div class="author">
    <div class="author__container">
      <div *ngIf="data.imgSrc" class="d-inline author__image-container">
        <img class="author__image" width="65" height="65" [src]="data.imgSrc" [alt]="data.imgAlt" loading="lazy" />
      </div>
      <div [ngClass]="isNewArtisticDirectionTheme ? 'author__info-container' : 'd-inline'">
        <div class="author__info">
          <p class="author__name">
            <a *ngIf="data.link; else authorName" [href]="data.link">{{ data.name }}</a>
            <ng-template #authorName>{{ data.name }}</ng-template>
          </p>
          <p *ngIf="data.job" class="author__job">{{ data.job }}</p>
        </div>
        <ng-container *ngIf="data.details">
          @if (isNewArtisticDirectionTheme() === false) {
            <nscf-liseret [theme]="theme"></nscf-liseret>
          }
          <p class="author__detail">{{ data.details }}</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
