/* eslint-disable no-useless-escape */
export class VideoGenerator {
  video: string;

  generateEmbedUrl(videoUrl: string, autoplay?: boolean): string {
    // Handle youtube videos
    const youtubeVideoId = VideoGenerator.youtubeParser(videoUrl);
    if (youtubeVideoId) {
      const autoplayConfig = autoplay ? '&autoplay=1' : '';
      const videoUc = `https://www.youtube.com/embed/${youtubeVideoId}?fs=1&controls=2${autoplayConfig}`;
      if (this.video !== videoUc) {
        this.video = videoUc;
      }
      return this.video;
    }

    // Handle vimeo videos
    const vimeoVideoId = this.vimeoParser(videoUrl);
    if (vimeoVideoId) {
      return `https://player.vimeo.com/video/${vimeoVideoId}`;
    }

    // Return the video URL as fallback
    return videoUrl;
  }

  static generateThumbnailUrl(videoUrl: string): string {
    const youtubeVideoId = VideoGenerator.youtubeParser(videoUrl);
    if (youtubeVideoId) {
      return `https://img.youtube.com/vi_webp/${youtubeVideoId}/mqdefault.webp`;
    }

    return null;
  }

  static youtubeParser(url: string): string {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url?.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  }

  private vimeoParser(url: string): string {
    const m = url.match(/^.+vimeo.com\/(.*\/)?([^#\?]*)/);
    return m ? m[2] || m[1] : null;
  }
}
