export function isEmpty(object: Object): boolean {
  return object != null ? !Object.keys(object).length : true;
}

export function isAllObjectPropertiesDefined(object: object): boolean {
  if (!object || !Object.keys(object).length) {
    return false;
  }
  return Object.keys(object).every(key => object[key] !== undefined);
}

export function getQueryStringFromObject(objectParams: object): string {
  if (!objectParams) {
    return '';
  }
  return Object.keys(objectParams)
    .map(key => `${key}=${objectParams[key]}`)
    .join('&');
}
