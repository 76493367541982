import { ISubBlockPresenter } from '../presenter';
import { LinkPresenter } from '../commons/link.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { ISubBlockTables, TypeNameSubBlockTables } from '../../components/block-text/tables/tables';

export class SubBlockTablesPresenter implements ISubBlockPresenter {
  parse(data: any): ISubBlockTables {
    const subBlock: ISubBlockTables = {
      __typename: TypeNameSubBlockTables,
      id: data['id'],
      title: data['title'] || '',
      text: RTEPresenter.getRTEText(data['text']),
      ctaLabel: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
    };
    return subBlock;
  }
}
