import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IParagraphInBrief } from '../../presenters/blocks/in-brief/in-brief.presenter';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';

@Component({
  selector: 'nscf-in-brief',
  templateUrl: './in-brief.component.html',
  styleUrls: ['./in-brief.component.scss'],
  standalone: true,
  imports: [NgIf],
  hostDirectives: [DirectionArtisticDirective],
})
export class InBriefComponent {
  @Input() data: IParagraphInBrief;
}
