<a class="card-summary-simplified" [attr.href]="data?.link?.url" alt="">
  <picture *ngIf="data.image; else img_placeholder">
    <source [attr.srcset]="data.imageWebp" />
    <img [attr.src]="data.image" [attr.alt]="data.imageAlt" loading="lazy" height="164" width="350" />
  </picture>
  <div class="card-summary-simplified__container">
    <p *ngIf="data.taxonomies" class="card-summary-simplified__taxonomy">
      <ng-container *ngFor="let taxonomy of data.taxonomies">
        @if (isNewArtisticDirection()) {
          <span effy-hashtag>#{{ taxonomy | lowercase }}&nbsp;</span>
        } @else {
          #{{ taxonomy | lowercase }}&nbsp;
        }
      </ng-container>
    </p>
    <p *ngIf="data.title" class="card-summary-simplified__title" [innerHTML]="data.title | safeHtml"></p>
    <p *ngIf="data.description" class="card-summary-simplified__description">
      {{ data.description }}
    </p>
  </div>

  <div>
    @if (isNewArtisticDirection()) {
      <a effy-tiny-button class="tw-absolute tw-right-[24px] tw-bottom-[24px]" [size]="tinyButtonSize.SMALL" [arrowRight]="true" [href]="data?.link?.url">Lire</a>
    }
  </div>
</a>

<ng-template #img_placeholder>
  <div class="card-summary-simplified__img_placeholder"></div>
</ng-template>
