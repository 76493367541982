<div class="spacing-block">
  <div class="eligible-renovations" *ngIf="data">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-12 m-auto">
          <nscf-title
            [shouldDisplaySeparator]="true"
            [selector]="data.isFirstTitle ? 'h1' : 'h2'"
            class="text-center"
            [ngClass]="data.subtitle ? '' : 'h2-title--without-subtitle'"
            [ngClass]="{ 'tw-flex tw-flex-col tw-items-center': isNewArtisticDirectionTheme() }"
            [title]="data.title"
            [id]="'anchor_' + data.id"
          />
          <p [ngClass]="data.title ? '' : 'h2-title--without-title'" class="eligible-renovations__main-subtitle">
            {{ data.subtitle }}
          </p>

          <div *ngIf="isBrowser">
            @if (isNewArtisticDirectionTheme() && data.renovationTypes?.length > 1) {
              @if (isDesktop()) {
                <div class="tw-flex tw-gap-6 tw-justify-center tw-mb-m slg:tw-mb-[64px]">
                  @for (renovationType of data.renovationTypes; track renovationType.id) {
                    <button effy-tag (click)="selectRenovationType(renovationType)" [selected]="renovationType === currentRenovationType">
                      {{ renovationType.label }}
                    </button>
                  }
                </div>
              } @else {
                <mat-form-field class="tw-w-full">
                  <mat-select [(value)]="currentRenovationType" (selectionChange)="selectRenovationType($event.value)">
                    <mat-option *ngFor="let renovationType of data.renovationTypes" [value]="renovationType">
                      {{ renovationType.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              }
            } @else {
              <div *ngIf="data.renovationTypes?.length > 1" class="eligible-renovations__desktop">
                <ul class="text-center p-0">
                  <li
                    class="eligible-renovations__label {{ renovationType === currentRenovationType ? 'active' : '' }}"
                    *ngFor="let renovationType of data.renovationTypes"
                    (click)="selectRenovationType(renovationType)"
                  >
                    {{ renovationType.label }}
                  </li>
                </ul>
              </div>

              <div *ngIf="data.renovationTypes?.length > 1" class="eligible-renovations__mobile">
                <nscf-select
                  [source]="data.renovationTypes"
                  displayedProperty="label"
                  [current]="currentRenovationType"
                  (selectionChanged)="selectRenovationType($event)"
                ></nscf-select>
              </div>
            }

            <div class="eligible-renovations__main-content">
              <div class="eligible-renovations__block-img" *ngIf="currentRenovationType.image">
                <img loading="lazy" [src]="currentRenovationType.image.url" [alt]="currentRenovationType.image.alt" />
              </div>
              <div class="eligible-renovations__block-description">
                <div
                  *ngIf="currentRenovationType.gaugeTitle || currentRenovationType.customGaugeTitle"
                  class="col-4 text-center eligible-renovations__desktop"
                >
                  <nscf-rte
                    *ngIf="currentRenovationType.gaugeTitle && !currentRenovationType.customGaugeTitle"
                    [theme]="theme"
                    class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                    [data]="currentRenovationType.gaugeTitle"
                  ></nscf-rte>

                  <!--TODO: penser au cas où nous avons plusieurs entrées-->

                  <nscf-rte
                    *ngIf="currentRenovationType.customGaugeTitle"
                    [theme]="theme"
                    class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                    [data]="currentRenovationType.gaugeTitle"
                    [toBeAppended]="[
                      currentRenovationType.customGaugeTitle.firstPart.value,
                      currentRenovationType.customGaugeTitle.percent,
                      currentRenovationType.customGaugeTitle.lastPart.value,
                    ]"
                  ></nscf-rte>
                  <div class="block-gauge">
                    <div class="block-gauge__gauge">
                      <div
                        class="block-gauge__gauge-percent"
                        [ngStyle]="{
                          height: currentRenovationType.dynamicPercent + '%',
                        }"
                      ></div>
                    </div>
                    <div class="block-gauge__grid">
                      <span class="block-gauge__grid-50">{{ currentRenovationType.gaugeMax }}%</span>
                      <span class="block-gauge__grid-20">{{ currentRenovationType.gaugeMax / 2 }}%</span>
                      <span class="block-gauge__grid-0">0%</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-8 text-center">
                  <div class="eligible-renovations__desktop">
                    <img
                      class="tw-size-[60px]"
                      *ngIf="currentRenovationType.picto"
                      [src]="currentRenovationType.picto.url"
                      [alt]="currentRenovationType.picto.alt"
                      loading="lazy"
                      width="60"
                      height="60"
                    />
                  </div>
                  <p class="eligible-renovations__block-description-title">
                    {{ currentRenovationType.title }}
                  </p>
                  <div class="eligible-renovations__block-description-chapo">
                    <nscf-rte [theme]="theme" [data]="currentRenovationType.chapo"></nscf-rte>
                  </div>
                  <nscf-cta-button
                    *ngIf="currentRenovationType.buttonUrl"
                    [theme]="theme"
                    class="cta-button"
                    [btnClass]="'cta-primary big-btn'"
                    [text]="currentRenovationType.buttonText"
                    [ctaUrl]="currentRenovationType.buttonUrl"
                    [ngClass]="{ 'tw-flex tw-justify-center': isNewArtisticDirectionTheme() }"
                  >
                  </nscf-cta-button>
                  <div class="eligible-renovations">
                    <nscf-rte
                      [theme]="theme"
                      selector="p"
                      class="eligible-renovations__block-description-chapo"
                      [data]="currentRenovationType.description"
                    ></nscf-rte>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isBrowser">
            <div *ngFor="let renovationType of data.renovationTypes">
              <h3 class="eligible-renovations__main-subtitle text-center h2-title-ssr">
                {{ renovationType.label }}
              </h3>
              <div class="eligible-renovations__main-content">
                <div class="eligible-renovations__block-img" *ngIf="renovationType.image">
                  <img loading="lazy" [src]="renovationType.image.url" [alt]="renovationType.image.alt" />
                </div>
                <div class="eligible-renovations__block-description">
                  <div class="col-4 text-center eligible-renovations__desktop">
                    <nscf-rte
                      *ngIf="renovationType.gaugeTitle && !renovationType.customGaugeTitle"
                      [theme]="theme"
                      class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                      [data]="renovationType.gaugeTitle"
                    ></nscf-rte>

                    <nscf-rte
                      *ngIf="renovationType.customGaugeTitle"
                      [theme]="theme"
                      class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                      [data]="renovationType.gaugeTitle"
                      [toBeAppended]="[
                        renovationType.customGaugeTitle.firstPart.value,
                        renovationType.customGaugeTitle.percent,
                        renovationType.customGaugeTitle.lastPart.value,
                      ]"
                    ></nscf-rte>
                    <div class="block-gauge">
                      <div class="block-gauge__gauge">
                        <div
                          class="block-gauge__gauge-percent"
                          [ngStyle]="{
                            height: renovationType.gauge + '%',
                          }"
                        ></div>
                      </div>
                      <div class="block-gauge__grid">
                        <span class="block-gauge__grid-50">{{ renovationType.gaugeMax }}%</span>
                        <span class="block-gauge__grid-20">{{ renovationType.gaugeMax / 2 }}%</span>
                        <span class="block-gauge__grid-0">0%</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-8 text-center">
                    <div class="eligible-renovations__desktop">
                      <img
                        *ngIf="renovationType.picto"
                        class="tw-size-[60px]"
                        [src]="renovationType.picto.url"
                        [alt]="renovationType.picto.alt"
                        loading="lazy"
                        width="60"
                        height="60"
                      />
                    </div>
                    <p class="eligible-renovations__block-description-title">
                      {{ renovationType.title }}
                    </p>
                    <div class="eligible-renovations__block-description-chapo">
                      <nscf-rte [theme]="theme" [data]="currentRenovationType.chapo"></nscf-rte>
                    </div>
                    <nscf-cta-button
                      class="cta-button"
                      *ngIf="renovationType.buttonUrl"
                      [theme]="theme"
                      [btnClass]="'cta-secondary big-btn'"
                      [text]="renovationType.buttonText"
                      [ctaUrl]="renovationType.buttonUrl"
                    ></nscf-cta-button>
                    <div class="eligible-renovations">
                      <nscf-rte
                        [theme]="theme"
                        selector="p"
                        class="eligible-renovations__block-description-chapo"
                        [data]="currentRenovationType.description"
                      ></nscf-rte>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
