<div *ngIf="data && themeKeys.length" class="article-theme" [class.container]="isInPageArticle">
  <div *ngIf="!isInPageArticle" class="article-theme__title">Thème(s) associé(s)</div>
  <ul class="article-theme__list">
    <li *ngFor="let key of themeKeys | slice: 0:(showAllItems ? undefined : nbMaxItemDisplayedAtFirst)"
      class="article-theme__item" (click)="navigateToHub('tag' + data[key].targetId)">
      <span *ngIf="!data[key].hubUrl || !data[key].hubUrl.length">{{ data[key].nameFront }}</span>
      <a *ngIf="data[key].hubUrl && data[key].hubUrl.length" [id]="'tag' + data[key].targetId"
        [href]="data[key].hubUrl">
        {{ data[key].nameFront }}
      </a>
    </li>
    <li *ngIf="themeKeys.length > nbMaxItemDisplayedAtFirst && !showAllItems" (click)="showAllItems = true"
      class="article-theme__item article-theme__item--show-more"></li>
  </ul>
</div>