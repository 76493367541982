import { NgClass, NgFor, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, inject, Input, OnInit, TemplateRef } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarouselContainer } from '../../classes/carousel-container/carousel-container';
import { CardsHeight, IMediasCarouselType } from './carousel';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'nscf-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
  standalone: true,
  imports: [CarouselModule, NgFor, NgTemplateOutlet, NgClass, MatIconModule],
  hostDirectives: [DirectionArtisticDirective],
})
export class CarouselComponent extends CarouselContainer implements OnInit {
  @Input() mediasCarouselItems: Array<IMediasCarouselType>;
  @Input() cardHeight: CardsHeight;
  @ContentChild(TemplateRef) cardVariable: TemplateRef<any>;
  isNewDA = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);

  ngOnInit(): void {
    this.setCarouselOptions();
    this.setCarouselItems(this.mediasCarouselItems);
  }
}
