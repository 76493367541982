import { Component, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { IDiscoveryCategorie } from './discovery-categorie';
import { CardsDiscoveryCategorieComponent } from './cards-discovery-categorie/cards-discovery-categorie.component';
import { LiseretComponent } from '../../liseret/liseret.component';
import { NgIf, NgClass } from '@angular/common';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'nscf-discovery-categorie',
    templateUrl: './discovery-categorie.component.html',
    styleUrls: ['./discovery-categorie.component.scss'],
    standalone: true,
    imports: [NgIf, LiseretComponent, NgClass, CardsDiscoveryCategorieComponent],
    hostDirectives: [DirectionArtisticDirective],
})
export class DiscoveryCategorieComponent extends BasicThemedComponentDirective {
  @Input()
  data: IDiscoveryCategorie;

  isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);

  constructor(private readonly themeHelperService: ThemeHelperService) {
    super();
  }
}
