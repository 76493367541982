<div class="focus-categorie spacing-block">
  <div class="container">
    <div class="focus-categorie__header">
      <h2 class="focus-categorie__title">{{ data.title }}</h2>
      <nscf-liseret class="focus-categorie__liseret" *ngIf="data.title" [theme]="theme"></nscf-liseret>
      <p class="focus-categorie__description">{{ data.chapo }}</p>
    </div>
  </div>
  <div class="container first-container">
    <div class="focus-categorie__primary-card">
      <a class="focus-categorie__link" [href]="data.mainArticle.url">
        <div class="focus-categorie__wrapper-content-primary-card">
          @if (isNewArtisticDirection()) {
            <p *ngIf="data.mainArticle.theme" effy-hashtag [size]="hashtagSizeMedium" [color]="hashtagStylePrimary" class="tw-mb-[8px]">
              {{ '#' + data.mainArticle.theme }}
            </p>
          } @else {
            <p *ngIf="data.mainArticle.theme" class="focus-categorie__theme focus-categorie__theme--primary-card">
              {{ '#' + data.mainArticle.theme }}
            </p>
          }
          <p class="focus-categorie__primary-card-title">
            {{ data.mainArticle.title }}
          </p>
        </div>
        <picture class="focus-categorie__illustration" *ngIf="data.mainArticle.cover?.url; else primaryWithoutImg">
          <source [attr.srcset]="data.mainArticle.cover?.webpUrl" />
          <img [attr.src]="data.mainArticle.cover?.url" [attr.alt]="data.mainArticle.cover?.alt" loading="lazy" />
        </picture>

        <ng-template #primaryWithoutImg>
          <div class="focus-categorie__primary-without-image"></div>
        </ng-template>
      </a>
    </div>
  </div>
  <div class="container">
    <div class="focus-categorie__secondaries-card">
      <div
        class="focus-categorie__secondaries-card-item focus-categorie__secondaries-card-item-{{ index }}"
        *ngFor="let secondaryArticle of data.secondariesArticles; let index = index"
      >
        <a class="focus-categorie__link" [href]="secondaryArticle.url">
          <div class="focus-categorie__secondary-card-wrapper">
            <div class="wrapper-secondary-card">
              <picture *ngIf="secondaryArticle?.cover?.url; else secondaryWithoutImg">
                <source [attr.srcset]="secondaryArticle.cover?.webpUrl" />
                <img [attr.src]="secondaryArticle.cover?.url" [attr.alt]="secondaryArticle.cover?.alt" loading="lazy" />
              </picture>
            </div>
            <ng-template #secondaryWithoutImg>
              <div class="focus-categorie__secondary-without-image"></div>
            </ng-template>
            @if (isNewArtisticDirection()) {
              <p *ngIf="data.mainArticle.theme" effy-hashtag [size]="hashtagSizeMedium" [color]="hashtagStylePrimary" class="tw-mb-[4px] tw-mt-[16px]">
                {{ '#' + secondaryArticle?.theme }}
              </p>
            } @else {
              <p *ngIf="secondaryArticle?.theme" class="focus-categorie__theme">
                {{ '#' + secondaryArticle?.theme }}
              </p>
            }
            <p class="focus-categorie__secondary-card-title" [ngClass]="{ 'no-theme': !secondaryArticle?.theme }">
              {{ secondaryArticle?.title }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
