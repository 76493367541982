import { NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrustpilotBase, TrustpilotTheme } from '../../config/trustpilot-base';

@Component({
  selector: 'nscf-trustpilot-micro-star',
  standalone: true,
  imports: [NgStyle],
  templateUrl: 'trustpilot-micro-star.component.html',
  styleUrls: ['./trustpilot-micro-star.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustpilotMicroStarComponent extends TrustpilotBase implements AfterViewInit {
  readonly width = '100%';
  readonly height = '24px';
  readonly style = this.computeStyle();
  textColor = '#003250';
  private _theme: TrustpilotTheme = 'light';
  @Input() set theme(theme: TrustpilotTheme) {
    this._theme = theme;
    this.textColor = theme === 'light' ? '#003250' : '#FFFFFF';
  }

  get theme() {
    return this._theme;
  }

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.init();
  }
}
