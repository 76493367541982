import { IFieldMediaImage } from '../../../interfaces/field-media-image';

export const TypeNameBlockArticlePDFBloc = 'ParagraphPdfBlock';

export interface IArticlePdfBloc {
  __typename: string;
  documents: Array<{
    title: string;
    url: string;
    description: string;
    image: IFieldMediaImage;
  }>;
}
