import { IHeader } from '@effy-tech/acquisition-header';

export const HEADER_DATA = {
  navbar: {
    links: [
      {
        url: '',
        label: 'Isolation',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Les combles',
                    url: '/travaux-energetique/isolation/isolation-combles',
                    links: [
                      {
                        url: '/travaux-energetique/isolation/isolation-combles/combles-perdus',
                        alt: 'isolation-combles',
                        label: 'Combles perdus',
                        icon: 'https://bo.effy.fr/sites/default/files/2021-12/isolation-combles-2.svg',
                      },
                      {
                        url: '/travaux-energetique/isolation/isolation-combles/combles-amenageables',
                        alt: 'Combles aménageables',
                        label: 'Combles aménageables',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/combles-amenages.svg',
                      },
                      {
                        url: '/travaux-energetique/isolation/isolation-toit/toiture-terrasse',
                        alt: 'Isolation toiture terrasse',
                        label: 'Toiture terrasse',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/isolation-toiture-terrasse.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: '/parcours/1/isolation/logement-type',
                  label: 'Je simule mon projet',
                },
              },
              {
                subItems: [
                  {
                    title: 'Les murs',
                    url: '/travaux-energetique/isolation/isolation-murs',
                    links: [
                      {
                        url: '/travaux-energetique/isolation/isolation-murs/par-l-exterieur',
                        alt: "Isolation des murs par l'extérieur",
                        label: 'Isolation extérieure',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/isolation-exterieure.svg',
                      },
                      {
                        url: '/travaux-energetique/isolation/isolation-murs/par-l-interieur',
                        alt: "Isolation des murs par l'intérieur",
                        label: 'Isolation intérieure',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/isolation-interieure.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                subItems: [
                  {
                    title: 'Autres travaux',
                    url: '',
                    links: [
                      {
                        url: '/travaux-energetique/isolation/isolation-sol',
                        alt: 'isolation-sol',
                        label: 'Isolation du sol',
                        icon: 'https://bo.effy.fr/sites/default/files/2021-12/isolation-sol.svg',
                      },
                      {
                        url: '/travaux-energetique/isolation/fenetre',
                        alt: 'fenetres',
                        label: 'Isolation des fenêtres',
                        icon: 'https://bo.effy.fr/sites/default/files/2021-12/fenetres.svg',
                      },
                      {
                        url: '/travaux-energetique/ventilation/vmc-double-flux',
                        alt: 'vmc-double-flux',
                        label: 'VMC double flux',
                        icon: 'https://bo.effy.fr/sites/default/files/2021-12/vmc-double-flux.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Chauffage',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'La pompe à chaleur',
                    url: '/travaux-energetique/chauffage/pompe-a-chaleur',
                    links: [
                      {
                        url: '/travaux-energetique/chauffage/pompe-a-chaleur/pac-air-air',
                        alt: 'Pompe à chaleur air-air ',
                        label: 'Pompe à chaleur air-air ',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/pac-air-air.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/pompe-a-chaleur/pac-air-eau',
                        alt: 'Pompe à chaleur air-eau',
                        label: 'Pompe à chaleur air-eau',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/pac-air-eau.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/pompe-a-chaleur/pac-geothermique',
                        alt: 'Pompe à chaleur géothermique',
                        label: 'Pompe à chaleur géothermique',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/pac-geothermique.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: '/parcours/1/pompe-a-chaleur/logement-type',
                  label: 'Je simule mon projet',
                },
              },
              {
                subItems: [
                  {
                    title: 'La chaudière',
                    url: '/travaux-energetique/chauffage/chaudiere/differents-types-de-chaudieres-laquelle-choisir',
                    links: [
                      {
                        url: '/travaux-energetique/chauffage/chaudiere/chaudiere-condensation',
                        alt: 'Chaudière à condensation',
                        label: 'Chaudière à condensation',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/chaudiere-gaz.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/chaudiere/chaudiere-granules',
                        alt: 'Chaudière à granulés',
                        label: 'Chaudière à granulés',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/chaudiere-granules.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/chaudiere/chaudiere-bois',
                        alt: 'Chaudière à bûches',
                        label: 'Chaudière à bûches',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/chaudiere-buches.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                subItems: [
                  {
                    title: 'Le poêle',
                    url: '/travaux-energetique/chauffage/poele',
                    links: [
                      {
                        url: '/travaux-energetique/chauffage/poele/poele-granules',
                        alt: 'Poêle à granulés',
                        label: 'Poêle à granulés',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/poele-granules.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/poele/poele-bois',
                        alt: 'Poêle à bûches',
                        label: 'Poêle à bûches',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/poele-buches.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                subItems: [
                  {
                    title: 'Autres travaux',
                    url: '',
                    links: [
                      {
                        url: '/travaux-energetique/chauffage/cheminee/insert',
                        alt: 'Insert cheminée',
                        label: 'Insert cheminée',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/insert-cheminee.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffe-eau/chauffe-eau-thermodynamique',
                        alt: 'Chauffe-eau thermodynamique',
                        label: 'Chauffe-eau thermodynamique',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/chauffe-eau-thermodynamique.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/radiateur',
                        alt: 'Radiateur électrique',
                        label: 'Radiateur électrique',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/radiateur-electrique-regulation.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Solaire',
        menu: {
          list: {
            items: [
              {
                type: 'image-wave',
                link: {
                  url: '/nos-offres/solaire',
                  label: 'Notre offre solaire',
                },
                url: 'https://bo.effy.fr/sites/default/files/2023-10/Rectangle%207104.jpg',
                width: 450,
                height: 450,
                alt: 'Notre offre solaire',
              },
              {
                subItems: [
                  {
                    title: 'Notre offre solaire',
                    url: '',
                    links: [
                      {
                        url: '/nos-offres/solaire/caracteristiques-techniques',
                        alt: 'Caractéristiques techniques',
                        label: 'Caractéristiques techniques',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/caracte%CC%81ristiques.svg',
                      },
                      {
                        url: '/nos-offres/solaire/comment-ca-marche',
                        alt: 'Comment ça marche ?',
                        label: 'Comment ça marche ?',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/Product.svg',
                      },
                      {
                        url: '/nos-offres/solaire/installation-avec-effy',
                        alt: 'Installation avec Effy',
                        label: 'Installation avec Effy',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/Installation.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: '/formulaire/solaire/1/travaux-adresse',
                  label: 'Je simule mon projet',
                },
              },
              {
                subItems: [
                  {
                    title: 'Tout le solaire ',
                    url: '',
                    links: [
                      {
                        url: '/travaux-energetique/solaire/panneau-solaire-photovoltaique',
                        alt: 'Panneaux solaires photovoltaïques',
                        label: 'Panneaux solaires photovoltaïques',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/panneaux-photovoltaiques.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffage/systeme-solaire-combine',
                        alt: 'Système solaire combiné',
                        label: 'Système solaire combiné',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/systeme-solaire-combine.svg',
                      },
                      {
                        url: '/travaux-energetique/chauffe-eau/chauffe-eau-solaire',
                        alt: 'Chauffe-eau solaire',
                        label: 'Chauffe-eau solaire',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/chauffe-eau-solaire.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Rénovation globale',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Rénovation globale',
                    url: '',
                    links: [
                      {
                        url: '/renovation-energetique/bilan-energetique-maison',
                        alt: 'Bilan énergétique',
                        label: 'Bilan énergétique',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/augmenter_DPE.svg',
                      },
                      {
                        url: '/renovation-energetique/audit-energetique',
                        alt: 'Audit énergétique',
                        label: 'Audit énergétique',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/audit-2.svg',
                      },
                      {
                        url: '/travaux-energetique/renovation-globale/quels-travaux',
                        alt: 'Rénovation globale',
                        label: 'Rénovation globale',
                        icon: 'https://bo.effy.fr/sites/default/files/2023-10/maison.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                type: 'image-wave',
                link: {
                  url: '/simulateur/bilan-energetique',
                  label: 'Bilan énergétique gratuit',
                },
                url: 'https://bo.effy.fr/sites/default/files/2023-10/Rectangle%207104-1.jpg',
                width: 450,
                height: 450,
                alt: 'Bilan énergétique gratuit',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Aides et Primes',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Aides et primes',
                    url: '',
                    links: [
                      {
                        url: '/aide-energetique/prime-energie',
                        label: 'Prime énergie',
                      },
                      {
                        url: '/aide-energetique/ma-prime-renov',
                        label: "MaPrimeRénov'",
                      },
                      {
                        url: '/aide-energetique/cheque-energie',
                        label: 'Le chèque énergie',
                      },
                      {
                        url: '/aide-energetique/tva-reduite',
                        label: 'TVA 5,5%',
                      },
                      {
                        url: '/aide-energetique/eco-ptz',
                        label: "L'éco-PTZ",
                      },
                      {
                        url: '/nos-offres/coup-de-pouce-renovation-globale-coproprietes',
                        label: 'Les aides pour la copropriété',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                type: 'image-wave',
                link: {
                  url: '/nos-offres/prime-effy',
                  label: 'Découvrir la prime Effy',
                },
                url: 'https://bo.effy.fr/sites/default/files/2023-10/Rectangle%207104-2.jpg',
                width: 450,
                height: 450,
                alt: 'Découvrir la prime Effy',
              },
              {
                type: 'image-wave',
                link: {
                  url: '/parcours/1/travaux-aides/',
                  label: 'Simuler mes aides',
                },
                url: 'https://bo.effy.fr/sites/default/files/2023-10/Rectangle%207104-3.jpg',
                width: 450,
                height: 450,
                alt: 'Simuler mes aides',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Actualités',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: '',
                    url: '',
                    links: [
                      {
                        url: '/guide/actualite-aide-renovation',
                        label: "L'actualité des aides et primes",
                      },
                      {
                        url: '/guide/bien-etre-chez-vous',
                        label: 'Conseils pour économiser',
                      },
                      {
                        url: '/guide/chiffre-renovation-energetique',
                        label: "L'actu en chiffres",
                      },
                      {
                        url: '/actualite-interview-expert',
                        label: "Témoignages d'experts",
                      },
                      {
                        url: '/flash-info',
                        label: 'Effy décrypte',
                      },
                      {
                        url: '/nous-connaitre/notre-espace-presse',
                        label: 'Ils parlent de nous',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                subItems: [
                  {
                    title: 'À la une',
                    url: '',
                    links: [
                      {
                        url: '/guide/tarif-energie',
                        label: "Hausse des prix de l'énergie",
                      },
                      {
                        url: '/guide/dossier-fin-du-fioul',
                        label: 'Quelles alternatives au fioul ? ',
                      },
                      {
                        url: '/guide/dossier-passoires-thermiques',
                        label: 'Les passoires thermiques en France',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: '/inscription-newsletter',
                  label: 'Recevoir la newsletter',
                },
              },
              {
                type: 'image-wave',
                link: {
                  url: '/magazine',
                  label: 'Le magazine de la rénovation ',
                },
                url: 'https://bo.effy.fr/sites/default/files/2023-10/EFFY_Header_Part_Actualite%CC%81_264x254_%402x.jpg',
                width: 528,
                height: 508,
                alt: 'Le magazine de la rénovation ',
              },
            ],
          },
        },
      },
      {
        url: '/pro',
        label: 'Accès pro',
        menu: {
          list: {
            items: [],
          },
        },
      },
    ],
  },
  logo: {
    url: 'https://bo.effy.fr/sites/default/files/2019-10/Effy_logo_blue.svg',
    title: 'Spécialiste rénovation énergétique',
    alt: 'Spécialiste rénovation énergétique',
    link: '/',
  },
  connection: {
    link: {
      url: '/espace-client/se-connecter',
      title: 'Espace client',
      options: {
        attributes: {
          target: '_self',
          rel: 'nofollow',
        },
      },
      icon: {
        url: 'https://bo.effy.fr/sites/default/files/2021-12/connexion-particulier-effy.svg',
        width: 24,
        height: 24,
        alt: 'connexion-particulier',
      },
    },
    isConnected: false,
  },
  phoneInsert: {
    phoneHook: 'Appelez-nous !',
    phoneType: 'reglementaire',
    phoneNumber: '1',
    accompanimentPhone: {
      format: 'full_html',
      processed: '<p>du lundi au vendredi - 8h à 19h</p>\n',
      value: '<p>du lundi au vendredi - 8h à 19h</p>\r\n',
    },
  },
  linkPart: {
    url: '/parcours/1/travaux-aides',
    title: 'DEMANDER UN DEVIS',
    options: {
      attributes: {
        target: '_self',
        rel: '',
      },
    },
  },
  env: null,
  isPro: false,
} as unknown as IHeader;
