import { ParamMap } from '@angular/router';

export const CAMPAIGN_COOKIE_NAME = 'campaign';

export const UTM_LABELS = {
  source: 'utm_source',
  medium: 'utm_medium',
  campaign: 'utm_campaign',
  content: 'utm_content',
  term: 'utm_term',
};

export function getUtmFromParams(qp: ParamMap): object {
  return Object.keys(UTM_LABELS)
    .filter(utmKey => qp[UTM_LABELS[utmKey]])
    .reduce((utmObjet, utmKey) => {
      return { ...utmObjet, [UTM_LABELS[utmKey]]: qp[UTM_LABELS[utmKey]] };
    }, {});
}
