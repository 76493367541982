// @dynamic

import { EnvironmentModel } from '../../interfaces/environment.interface';

export class BoUrlService {
  private static environments = <EnvironmentModel>{};

  public static setEnvironments(environments: EnvironmentModel): void {
    BoUrlService.environments = environments;
  }

  public static getEnvironments(): EnvironmentModel {
    return BoUrlService.environments;
  }

  public static rewriteBoUrl(str: string): string {
    const newUrl = str.replace(BoUrlService.environments?.url_base_bo_local, BoUrlService.environments?.url_base_bo);
    return BoUrlService.rewriteHttpToHttps(newUrl);
  }

  public static rewriteHttpToHttps(str: string): string {
    return str.replace('http:', 'https:');
  }
}
