<div class="spacing-block">
  <div class="container">
      <ng-container *ngIf="data">
          <ng-template [ngIf]="isNewArtisticDirectionTheme()" [ngIfElse]="oldDa">
              <div class="faq" [ngClass]="'faq-'+size()">
                  <nscf-title [id]="'anchor_' + data.id"
                              [selector]="data.isFirstTitle ? 'h1' : 'h2'"
                              [title]="data.title"
                              class="faq__title"/>
                  <div class="liseret"></div>
                  <nscf-rte [data]="data.chapo" [theme]="theme" class="faq__chapo"/>
                  <effy-collapse *ngFor="let content of data.content"
                                 [title]="content.question"
                                 [size]="size()">
                      <nscf-rte *ngIf="content.answer" [data]="content.answer" [theme]="theme" class="faq__rte"/>
                  </effy-collapse>
              </div>
          </ng-template>
      </ng-container>
  </div>
</div>

<ng-template #oldDa>
    <div class="faq" nscfLazyLoadMaterial>
        <nscf-title
                [id]="'anchor_' + data.id"
                [selector]="data.isFirstTitle ? 'h1' : 'h2'"
                [title]="data.title"
                class="text-center"
        ></nscf-title>
        <nscf-rte [data]="data.chapo" [theme]="theme" class="faq__chapo"></nscf-rte>
        <mat-accordion>
            <mat-expansion-panel
                    (closed)="toggle(content, false, i + 1)"
                    (opened)="toggle(content, true, i + 1)"
                    *ngFor="let content of data.content; let i = index; let isFirst = first"
                    [expanded]="isFirst"
                    [id]="'faq_' + data.id + '_' + i"
                    class="faqContent"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <ul>
                            <li>
                                <label [for]="'faq_' + data.id + '_' + i"
                                       [ngClass]="{ faqOpened: content.openState }">
                                    {{ content.question }}
                                </label>
                            </li>
                        </ul>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <nscf-rte *ngIf="content.answer" [data]="content.answer" [theme]="theme"
                          class="faq__rte"></nscf-rte>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>
