import { ISubBlockImage, TypeNameSubBlockImage } from '../../components/block-text/images/images';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { ISubBlockPresenter } from '../presenter';

export class SubBlockImagePresenter implements ISubBlockPresenter {
  parse(data: any): ISubBlockImage {
    return {
      __typename: TypeNameSubBlockImage,
      id: data['id'],
      title: data['title'] || '',
      image: MediaImagePresenter.getImageWebp(data['image']),
      legend: data['legend'] || '',
      ctaLabel: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
    };
  }
}
