import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { Subscription } from 'rxjs';
import { DeviceManagerService, DeviceType } from '../../services/device-manager/device-manager.service';
import { IGuide } from '../../interfaces/guide';
import { GuideChapterComponent } from '../../components/chapter/chapter.component';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nscf-guide',
    templateUrl: 'guide.component.html',
    styleUrls: ['guide.component.scss'],
    standalone: true,
    imports: [NgIf, BreadcrumbComponent, NgFor, GuideChapterComponent]
})
export class GuidePageComponent extends BasicThemedComponentDirective implements OnInit, OnDestroy {
  @Input() data: IGuide;
  isDesktop: Boolean;
  subscription: Subscription;

  constructor(protected readonly deviceManagerService: DeviceManagerService) {
    super();
  }

  ngOnInit(): void {
    this.subscription = this.deviceManagerService
      .deviceType$()
      .pipe(
        tap((deviceType: DeviceType) => {
          this.isDesktop = deviceType.isDesktop;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
