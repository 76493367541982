import { SITES, WidgetCalculatorConfig } from '../../models/widget-calculator.models';

export const ENV_RCT: WidgetCalculatorConfig = {
  production: false,
  api: {
    costEstimator: 'https://rct.costestimator.quelleenergie.net/api/v3/',
    precarityLevel: 'https://precaritylevel.rct.api.quelleenergie.net/',
  },
  sitesUri: {
    [SITES.CALCULEO]: 'https://rct.calculeo.quelleenergie.net',
    [SITES.LPE]: 'https://rct.lpe.quelleenergie.net',
    [SITES.EFFY]: 'https://rct.effy.quelleenergie.net',
    [SITES.QE]: 'https://rct.qe.quelleenergie.net',
  },
};
