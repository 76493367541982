<div [ngSwitch]="selector" class="rte">
  <ng-container *ngSwitchCase="'div'">
    <ng-container *ngTemplateOutlet="divContent"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'p'">
    <ng-container *ngTemplateOutlet="pContent"></ng-container>
  </ng-container>
</div>

<ng-template #divContent>
  <ng-container *ngIf="data; then divUniqueElementBlock; else divManyElementBlock"></ng-container>

  <ng-template #divUniqueElementBlock>
    <div *ngIf="data.processed" [innerHTML]="data.processed | safeHtml" class="text__content"></div>
  </ng-template>

  <ng-template #divManyElementBlock>
    <div *ngIf="toBeAppended" [innerHTML]="toBeAppended.join() | safeHtml" class="text__content"></div>
  </ng-template>
</ng-template>

<ng-template #pContent>
  <ng-container *ngIf="data; then pUniqueElementBlock; else pManyElementBlock"></ng-container>
  <ng-template #pUniqueElementBlock>
    <p #uniqueElementBlock *ngIf="data.processed" [innerHTML]="data.processed | safeHtml"></p>
  </ng-template>
  <ng-template #pManyElementBlock>
    <p *ngIf="toBeAppended" [innerHTML]="toBeAppended.join() | safeHtml"></p>
  </ng-template>
</ng-template>
