import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toSignal } from "@angular/core/rxjs-interop";
import { DirectionArtisticDirective, EffyCollapseSize, ThemeHelperService } from "@effy-tech/angular-common";
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { DeviceManagerService } from "../../services/device-manager/device-manager.service";
import { LiseretComponent } from '../liseret/liseret.component';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { BlocNumberSize, IBlockNumber } from './numbers';

@Component({
  selector: 'nscf-numbers',
  templateUrl: 'numbers.component.html',
  styleUrls: ['numbers.component.scss'],
  standalone: true,
  hostDirectives: [DirectionArtisticDirective],
  imports: [TitleComponent, RteComponent, NgFor, NgClass, NgIf, LiseretComponent],
})
export class NumbersComponent extends BasicThemedComponentDirective {
  @Input() data: IBlockNumber;
  readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  readonly isMobileOrTablet = toSignal(inject(DeviceManagerService).isMobileOrTablet());
  readonly size = computed(() => this.isMobileOrTablet() ? BlocNumberSize.SMALL : BlocNumberSize.MEDIUM);


  constructor() {
    super();
  }
}
