import { IContactLinks, ISocialLink } from '../components/author-contact/author.contact';

export class AuthorContactPresenter {
  public static getAuthorLinks(data: any): IContactLinks {
    // Avoid empty / undefined data.
    if (!data) {
      return [];
    }

    return Object.keys(data)
      .filter(name => data[name])
      .map(name => this.getContactLink(data, name));
  }

  private static getContactLink(data: any, name: string): ISocialLink {
    return {
      name,
      url: this.getUrl(data, name),
    };
  }

  private static getUrl(data: any, name: string): string {
    return name === 'email' ? `mailto:${data[name]}` : data[name];
  }
}
