export enum ImageSize {
  SMALL = 'smallImage',
  MEDIUM = 'mediumImage',
  LARGE = 'mediumLargeImage',
  XLARGE = 'largeImage',
}

export function getAllImageSizeName(): Array<string> {
  return Object.keys(ImageSize);
}
