<div *ngIf="data?.links?.length" class="container article-related">
    <div class="article-related__title">
        {{ data.name }}
    </div>
    <ul class="article-related__list" [ngClass]="{ 'display_column' : bigViewPort}">
        <li class="article-related__item" *ngFor="let related of data.links">
            <ng-template [ngIf]="isNewArtisticDirectionTheme() === true" [ngIfElse]="oldDa">
                <a effy-link [size]="size()" [color]="EffyLinkStyle.POWER_BLUE" [href]="related.url | safeResourceUrl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.333252 10.3333L3.66659 6.99996L0.333252 3.66663L0.333252 0.333292L6.99992 6.99996L0.333252 13.6666L0.333252 10.3333Z"
                              fill="currentColor"/>
                    </svg>
                    <span>{{ related.title }}</span>
                </a>
            </ng-template>
            <ng-template #oldDa>
                <a class="article-related__link" [href]="related.url | safeResourceUrl">
                    <span class="arrow-right">&nbsp</span>
                    <span>{{ related.title }}</span>
                </a>
            </ng-template>
        </li>
    </ul>
</div>