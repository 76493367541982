import { Component, inject, input, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { NgClass, NgIf } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { MatIcon } from '@angular/material/icon';
@Component({
    selector: 'nscf-header-cta-button-connected',
    templateUrl: './cta-button-connected.component.html',
    styleUrls: ['./cta-button-connected.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, EffyButtonComponent, MatIcon]
})
export class CtaButtonConnectedComponent extends BasicThemedComponentDirective {
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly buttonSize = EffyButtonSize.MEDIUM;

  @Input() labelUser: string;
  @Input() btnIcon: string;
  @Input() btnClass: string;
  readonly buttonStyle = input(EffyButtonStyle.PRIMARY);
  link = '/espace-client';
  title = 'Accéder à mon espace';
  constructor() {
    super();
  }
}
