@if (isNewDirectionArtistic()) {
  <div class="tw-relative tw-order-1 slg:tw-order-[0]">
    @if (data.image && isDesktop()) {
      <img
        class="tw-absolute tw-max-w-full tw-rounded-xl tw-object-cover tw-h-full slg:tw-mt-[-52px]"
        [src]="data.image.url"
        [alt]="data.image.title"
        fetchPriority="high"
      />
    }
    <div
      class="tw-flex tw-flex-col tw-justify-center slg:tw-absolute tw-max-w-[445px] tw-right-[32px] tw-mt-[40px] slg:tw-mt-[60px] tw-mx-auto sm:tw-min-w-[360px]"
    >
      <nscf-form-parcours
        class="tw-bg-midnight-blue tw-p-[32px] tw-rounded-[12px]"
        (changeAnswer)="redirectToParcours($event)"
        [data]="data.formParcours"
        [enableTrustpilot]="data.enableTrustPilot"
      />
      @if (data.enableTrustPilot) {
        <nscf-trustpilot-micro-star class="trustpilot-rating tw-my-[16px]" />
      }
    </div>
  </div>
  <div class="tw-flex tw-flex-col">
    <nscf-title selector="h1" [title]="data.title" shouldDisplaySeparator />
    <nscf-rte [data]="description()" />
  </div>
} @else {
  <div *ngIf="data" class="spacing-block handle-height">
    <div class="container">
      <div class="top-page-parcours" [class.has-two-questions]="data.hasTwoQuestions">
        <div class="top-page-parcours__heading">
          <nscf-title selector="h1" [title]="data.title" [ngClass]="{ 'top-page-parcours__title-without-score': !data.enableTrustPilot }">
          </nscf-title>
          <nscf-rte class="top-page-parcours__description" [data]="description()" [theme]="theme"></nscf-rte>
        </div>
        <div class="top-page-parcours__form">
          <nscf-form-parcours
            class="top-page-parcours__form-widget"
            (changeAnswer)="redirectToParcours($event)"
            [data]="data.formParcours"
            [enableTrustpilot]="data.enableTrustPilot"
            [theme]="theme"
          ></nscf-form-parcours>
        </div>
      </div>
    </div>
    <div *ngIf="data.image && isDesktop()" class="top-page-parcours__form-image">
      <img [src]="data.image.url" [alt]="data.image.title" />
    </div>
  </div>
}
