import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '@effy-tech/common/loggers';
import { IPageOffer } from '../../interfaces/pages';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { DynamicPageDirective } from '../dynamic-page';
import { TypeNameBlockActuTheme } from '../../components/categorie/actu-theme';

@Component({
    selector: 'nscf-default-page',
    templateUrl: 'default.component.html',
    standalone: true
})
export class DefaultPageComponent extends DynamicPageDirective implements OnInit, OnDestroy {
  data: IPageOffer;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(protected readonly logger: LoggerService, protected readonly deviceManagerService: DeviceManagerService) {
    super(logger, deviceManagerService);
  }

  ngOnInit() {
    this.getDeviceType().subscribe();
  }

  buildPage(): void {
    this.clearPage();
    let dynamicComponentsData = super.fieldBlocksAsDynamicComponentData(this.data.blocks);
    dynamicComponentsData = this.shouldDisplayBreadCrumb(dynamicComponentsData)
      ? dynamicComponentsData
      : this.removeBreadCrumb(dynamicComponentsData);

    const components = super.AddActualiteThemeIdToBlockActuTheme(dynamicComponentsData, this.data.actualiteThemeId);
    super.appendDynamicComponentData(this.container, this.updatePositionBreadCrumb(components));
  }

  clearPage(): void {
    this.container?.clear();
  }

  ngOnDestroy() {
    this.doUnsubscribe();
  }
}
