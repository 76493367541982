<nscf-breadcrumb [theme]="theme" *ngIf="data.breadcrumb && isDesktop" [data]="data.breadcrumb" [isDesktop]="isDesktop"></nscf-breadcrumb>
<div class="container">
  <div class="row spacing-block">
    <div class="col-12 col-lg-8 mx-auto">
      <h1 class="guide__title">{{ data?.title }}</h1>
      <p class="guide__description">{{ data?.chapo }}</p>
    </div>
    <div class="col-12 col-sm-12 col-lg-6 guide__cell" *ngFor="let chapter of data?.chapters">
      <nscf-guide-chapter [theme]="theme" [chapter]="chapter"> </nscf-guide-chapter>
    </div>
  </div>
</div>
<nscf-breadcrumb [theme]="theme" *ngIf="data.breadcrumb && !isDesktop" [data]="data.breadcrumb" [isDesktop]="isDesktop"></nscf-breadcrumb>
