@if (isNewArtisticDirection()) {
  <a
    effy-button
    [size]="effySize()"
    [color]="effyStyle()"
    [attr.href]="ctaUrl?.url"
    [attr.rel]="ctaUrl?.options?.attributes?.rel"
    [attr.target]="ctaUrl?.options?.attributes?.target"
    [attr.id]="ctaUrl?.options?.attributes?.id"
    [ngClass]="ctaUrl?.options?.attributes?.class"
  >
    <span [innerHTML]="text"></span>
    @if (withArrow()) {
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.333252 10.3333L3.66659 6.99999L0.333252 3.66666L0.333252 0.333323L6.99992 6.99999L0.333252 13.6667L0.333252 10.3333Z"
          fill="none"
        />
      </svg>
    }
  </a>
} @else {
  <a
    [attr.rel]="ctaUrl?.options?.attributes?.rel"
    [attr.id]="ctaUrl?.options?.attributes?.id"
    [href]="href | safeResourceUrl"
    [attr.target]="ctaUrl?.options?.attributes?.target"
    [ngClass]="btnClasses"
  >
    <div class="effy-btn-content">
      <i *ngIf="btnIcon" class="effy-btn-content-icon icon-backarrow" aria-hidden="true"></i>
      <span class="effy-btn-content-text" [innerHTML]="text | safeHtml"></span>
    </div>
  </a>
}
