import { IFieldMediaImage, IFieldMediaImageTopPagesWebpBo, IFieldMediaImageWithWebpBo, IScale } from '../../interfaces/field-media-image';
import { ImagesService } from '../../services/images-service/images.service';
import { IFieldMediaImageWithWebp } from './../../interfaces/field-media-image';

export class MediaImagePresenter {
  private static readonly currentSize = ImagesService.getCurrentImageSize();
  private static readonly IMAGE_EXTENSIONS_TO_KEEP_ORIGINAL = ['gif', 'svg'];

  public static getImage(data: any): IFieldMediaImage {
    // Avoid empty / undefined data.
    if (!data?.entity?.fieldMediaImage) return null;

    const mediaImage = data.entity.fieldMediaImage;

    if (mediaImage.scale) {
      mediaImage.url = mediaImage.scale.url;
    } else if (mediaImage[this.currentSize]) {
      mediaImage.url = mediaImage[this.currentSize].url;
    }

    return {
      url: ImagesService.rewriteImagesUrl(mediaImage.url || ''),
      alt: mediaImage.alt || '',
      height: mediaImage.height || null,
      width: mediaImage.width || null,
      title: mediaImage.title || '',
      scale: this.getScale(mediaImage.scale),
      smallImage: this.getScale(mediaImage.smallImage),
      mediumImage: this.getScale(mediaImage.mediumImage),
      mediumLargeImage: this.getScale(mediaImage.mediumLargeImage),
      largeImage: this.getScale(mediaImage.largeImage),
    };
  }

  public static getImageWebp(data: IFieldMediaImageTopPagesWebpBo): IFieldMediaImage {
    // Avoid empty / undefined data.
    if (!data?.entity?.fieldMediaImage) return null;

    const fieldImage = data.entity.fieldMediaImage;

    const mediaImage = fieldImage.image;

    const shouldUseOriginalImage = this.shouldUseOriginalImage(fieldImage?.originalImage);
    const imageUrl = shouldUseOriginalImage ? fieldImage?.originalImage : mediaImage?.url;
    const width = shouldUseOriginalImage ? fieldImage?.width : mediaImage?.width;
    const height = shouldUseOriginalImage ? fieldImage?.height : mediaImage?.height;

    return {
      url: ImagesService.rewriteImagesUrl(imageUrl || ''),
      alt: fieldImage.alt || '',
      height: height || null,
      width: width || null,
      title: fieldImage.title || '',
    };
  }

  private static shouldUseOriginalImage(originalImageSrc: string): boolean {
    if (!originalImageSrc) {
      return false;
    }

    const imageUrl = new URL(originalImageSrc);
    const imageUrlWithoutQueryParams = imageUrl?.pathname;

    const shouldKeepInOriginalFormat = this.IMAGE_EXTENSIONS_TO_KEEP_ORIGINAL.some(extensionToKeepInOriginal =>
      imageUrlWithoutQueryParams.endsWith(`.${extensionToKeepInOriginal}`)
    );

    return shouldKeepInOriginalFormat;
  }

  public static getCoverWithWebp(data: IFieldMediaImageWithWebpBo): IFieldMediaImageWithWebp {
    // avoid empty / undefined data
    if (!data || !data['entity'] || !data['entity'].fieldMediaImage) {
      return null;
    }

    const mediaImage = data['entity'].fieldMediaImage;

    return {
      url: ImagesService.rewriteImagesUrl(mediaImage.url || ''),
      alt: mediaImage.alt || '',
      webpUrl: ImagesService.rewriteImagesUrl(mediaImage?.webpCover?.url || ''),
    };
  }

  private static getScale(data: any): IScale {
    if (!data || !data['url']) {
      return null;
    }
    return <IScale>{
      url: ImagesService.rewriteImagesUrl(data['url']),
    };
  }
}
