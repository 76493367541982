<div class="guide__container" [ngClass]="chapter?.cover ? 'guide__container--padding' : ''">
  <h2 class="guide__sub-title">
    <a *ngIf="chapter.url" [href]="chapter.url?.url | safeResourceUrl">
      {{ chapter.title }}
    </a>
    <span *ngIf="!chapter.url">{{ chapter.title }}</span>
  </h2>
  <nscf-liseret [theme]="theme"></nscf-liseret>
  <p class="guide__sub-description">
    {{ chapter.description }}
  </p>
  <div class="guide__wrapper-link">
    <a
      class="guide__link"
      *ngFor="let item of chapter.links"
      [href]="item.url | safeResourceUrl"
      [target]="item.options?.attributes?.target || '_self'"
    >
      {{ item.title }}
    </a>
  </div>
  <a class="guide__all-article" *ngIf="chapter.url" [href]="chapter.url?.url | safeResourceUrl"> Voir tous les articles </a>
  <div *ngIf="chapter?.cover" class="guide__wrapper-position">
    <div class="guide__wrapper">
      <img
        loading="lazy"
        class="guide__image-position"
        [ngClass]="isSvg ? 'guide__image-position--svg' : ''"
        *ngIf="chapter.cover"
        [src]="chapter.cover?.url"
        alt=""
      />
    </div>
  </div>
</div>
