import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { IImageBo } from '../../presenters/blocks/top-page-categorie/top-page-categorie';

export const TypeNameBlocTwoImages = 'ParagraphTwoImagesBlock';

export interface IBlocTwoImagesBO {
  __typename: string;
  title: string;
  image1: {
    entity: {
      fieldMediaImage: IImageBo;
    };
  };
  legendImage1: string;
  image2: {
    entity: {
      fieldMediaImage: IImageBo;
    };
  };
  legendImage2: string;
}

export interface IBlocTwoImages {
  __typename: string;
  title: string;
  cards: Array<IImageCard>;
}

export interface IImageCard {
  image?: IFieldMediaImage;
  legend?: string;
}
