/* eslint-disable no-shadow */

import { WorksValues } from '../values/works.values';

export const ENVS = {
  DEV: 'dev',
  RCT: 'rct',
  PROD: 'prod',
} as const;

export type Env = (typeof ENVS)[keyof typeof ENVS];

export const SITES = {
  CALCULEO: 'calculeo',
  EFFY: 'effy',
  LPE: 'lpe',
  QE: 'qe',
} as const;

export type Site = (typeof SITES)[keyof typeof SITES];

export interface WidgetCalculatorConfig {
  production: boolean;
  api: {
    costEstimator: string;
    precarityLevel: string;
  };
  sitesUri: {
    [P in Site]: string;
  };
}

export interface WidgetCalculatorLabels {
  label: string;
}

export type WorkTypeOrNull = WorksValues | null | undefined;
