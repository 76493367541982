import { ITrustpilotCarousel, ITrustpilotCarouselBO, TypeNameBlockTrustpilot } from "../../../components/trustpilot-carousel/trustpilot-carousel";
import { IBlockPresenter } from "../../presenter";

export class TrustpilotPresenter implements IBlockPresenter {
  parse(data: ITrustpilotCarouselBO): ITrustpilotCarousel {
    if (!data) {
      return null;
    }

    return {
      __typename: TypeNameBlockTrustpilot,
      title: data.title,
      filterTag: data.filter_tag,
      filterRating: data.filter_rating,
    };
  }
}