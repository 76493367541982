import { InjectionToken } from '@angular/core';
import { IRteText } from '../../interfaces/rte-text';
import { IMediasCardsArticle, IMediasCardsVideo } from './medias-card';

export const TypeNameBlockMedia = 'ParagraphMedias';
export const IS_SITE_EFFY = new InjectionToken<string>('IS_SITE_EFFY');

export interface IMedias {
  __typename: 'ParagraphMedias';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  chapo?: IRteText;
  cards?: Array<IMediasCardsArticle | IMediasCardsVideo>;
  filters?: Array<IFilter>;
  display?: DisplayValues;
}

export interface IFilter {
  title: string;
  id: string;
}

export enum DisplayValues {
  CARDIFICATION = 'cardification',
  CARROUSEL = 'carrousel',
}

export enum FilterValues {
  television = 'Télévision',
  radio = 'Radio',
  presse = 'Presse',
  web = 'Web',
  tous = 'tous',
}
