import { EffyEnvironment } from '@effy-tech/angular-common';

export const mythicUrls: { [key in EffyEnvironment]: string } = {
  [EffyEnvironment.Dev]: 'https://dev.mythic.quelleenergie.net',
  [EffyEnvironment.Rct]: 'https://rct.mythic.quelleenergie.net',
  [EffyEnvironment.Prod]: 'https://pro.effy.fr',
};

const inscriptionPath = '/mon-compte/inscription/contact';
const getMythicBaseUrl = (env: EffyEnvironment): string => mythicUrls[env];
export const getMythicInscriptionUrl = (env: EffyEnvironment): string => `${getMythicBaseUrl(env)}${inscriptionPath}`;
