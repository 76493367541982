import { LowerCasePipe, NgFor, NgIf } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { DirectionArtisticDirective, EffyHashtagComponent, EffyTinyButtonComponent, EffyTinyButtonSize } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { ICardSummarySimplified } from '../../interfaces/cards-summary';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';

@Component({
  selector: 'nscf-card-summary-simplified',
  templateUrl: 'card-summary-simplified.component.html',
  styleUrls: ['card-summary-simplified.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, EffyCommonModule, SafeHtmlPipe, LowerCasePipe, EffyHashtagComponent, EffyTinyButtonComponent],
  hostDirectives: [DirectionArtisticDirective],
})
export class CardSummarySimplifiedComponent extends BasicThemedComponentDirective {
  @Input() data: ICardSummarySimplified;
  @Input() id: string | number;
  isNewArtisticDirection = input<boolean>(false);
  component: { description: string; image: string; imageWebp: string; imageAlt: string; link: { url: string } };

  tinyButtonSize = EffyTinyButtonSize;
}
