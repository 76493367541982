<div class="actuality" *ngIf="cardsActu$ | async as cardsSummary">
  <div class="container" *ngIf="cardsSummary?.length">
    <div class="actuality__wrapper-title">
      <h2>{{ data.title }}</h2>
      <nscf-liseret class="actuality__liseret" [theme]="theme"></nscf-liseret>
    </div>
    <p class="actuality__description">{{ data.chapo }}</p>
    <div class="actuality__cards-wrapper">
      <nscf-news-card
        [theme]="theme"
        [isDesktop]="isDesktop"
        [data]="cardsSummary[0]"
        [isSticky]="true"
        [isNewDA]="isNewArtisticDirection()"
      ></nscf-news-card>

      <div class="actuality__cards-wrapper-secondary">
        <nscf-news-card
          [theme]="theme"
          *ngFor="let card of cardsSummary.slice(1, 4)"
          [isDesktop]="isDesktop"
          [data]="card"
          [isSticky]="false"
          [isNewDA]="isNewArtisticDirection()"
        ></nscf-news-card>
      </div>
    </div>
  </div>
</div>
