import { throttle } from 'lodash-es';
import { Observable } from 'rxjs';
import { IConnection } from '../connection/connection';
import { ICtaLink } from '../cta/cta-button/cta-button';
import { ILogo } from '../logo/logo';
import { INavbar } from '../navbar/navbar';

export const HEADER_HEIGHT = 80;

export enum PhoneType {
  Reglementaire = 'reglementaire',
  Normal = 'normal',
}

export interface IheaderStaticBO {
  data: {
    entity: {
      data: [IHeaderEntityBo];
    };
  };
}

export interface IHeader {
  navbar: INavbar;
  logo?: ILogo;
  connection: IConnection;
  phoneInsert?: IHeaderPhoneInsert;
  linkPart: ICtaLink;
  isDesktop$: Observable<boolean>;
  env?: EnvironmentModel;
  isPro?: boolean;
  shouldDisplayPhoneInsert?: boolean;
}

export enum ITemplate {
  PARTS = 'parts',
  PRO = 'pro',
}

export interface EnvironmentModel {
  graphqlApiEndpoint: string;
  url_base_bo_local: string;
  url_base_bo: string;
  gtmTag: string;
  logo?: ILogo;
  env: string;
}

export interface IHeaderRteText {
  format?: string;
  value?: string;
}

export interface IHeaderPhoneInsert {
  phoneType?: PhoneType | null;
  phoneHook?: string;
  phoneNumber?: string;
  accompanimentPhone?: IHeaderRteText;
}

export interface ILinkBo {
  title: string;
  options?: IOptionsBo;
  url: IUrlPathBo;
}

export interface IOptionsBo {
  attributes: {
    target: '_blank' | '_self';
    rel: string;
  } | null;
}

interface IUrlPathBo {
  path: string;
}

export interface IHeaderEntityBo {
  accompanimentPhone: IHeaderRteText;
  brandLogo: {
    entity: {
      fieldMediaImage: IPicto;
    };
  };
  button: string;
  logoLink: {
    url: IUrlPathBo;
  };
  pictoUser: IPicto;
  pictoConnexion: IPicto;
  phoneType?: PhoneType | null;
  phoneHook: string;
  phoneNumber: string;
  link: ILinkBo;
  headerCTA: {
    url: IUrlPathBo;
    title: string;
    options?: IOptionsBo;
  };
  fieldMenu?: {
    targetId?: string;
  };
}

export interface IHeaderBo extends IHeaderPhoneInsert {
  entity: IHeaderEntityBo;
}

export interface IMenuBo {
  data: {
    menu: {
      level1: ILevel1Bo[];
    };
  };
}

interface ILevelBaseBo {
  title: string;
  url: {
    path: string;
  };
}

export interface IHiddenLink {
  isLinkHidden: boolean;
}

export interface IPicto {
  url?: string;
  width?: number;
  height?: number;
  alt?: string;
}

interface IPictoBo {
  picto?: IPicto | null;
}

interface ILinkCTABo {
  isLinkCTA?: boolean;
}

export interface ILevel1Bo extends ILevelBaseBo {
  level2: ILevel2Bo[];
}

export type ISubLevelBaseBo = ILevelBaseBo & IHiddenLink & IPictoBo & ILinkCTABo;

export enum cardImageTypes {
  Simple = 'simple',
  Wave = 'wave',
}

export interface ILevel2Bo extends ISubLevelBaseBo {
  level3: ILevel3Bo[];
  isCardImage?: boolean;
  cardImageType?: cardImageTypes.Simple | cardImageTypes.Wave | '';
  cardImage?: IPicto;
}

export interface ILevel3Bo extends ISubLevelBaseBo {
  level4: ILevel4Bo[];
}

export type ILevel4Bo = ISubLevelBaseBo;

export enum HeaderStaticTypes {
  PageCorp = 'pages_corps',
  HP = 'hp',
  PageCorpMagazine = 'pages_corps_parle_de_nous',
  Magazine = 'hp_magazine',
  PagesErreur = 'pages_erreur',
  LandingSolar = 'landing_solar',
}

export function throttleScroll(milliseconds = 200): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;
    descriptor.value = throttle(original, milliseconds);
    return descriptor;
  };
}
