import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener, Input, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { TruncatePipe } from '../../../pipes/truncate/truncate.pipe';
import { IBreadCrumbItem } from '../breadcrumb';

@Component({
    selector: 'nscf-dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrls: ['dropdown.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, TruncatePipe],
    hostDirectives: [DirectionArtisticDirective]
})
export class DropdownComponent {
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  @Input() links: IBreadCrumbItem[];
  text: String;
  isVisible = false;

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    this.isVisible = this.eRef.nativeElement.contains(event.target) ? !this.isVisible : false;
  }

  constructor(private readonly eRef: ElementRef) {}
}
