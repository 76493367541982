import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { TrustpilotRates } from "../../interfaces";
import { HttpClient } from "@angular/common/http";
import { TrustpilotRatesUrlApi } from "../../config/ws.config";

@Injectable({
  providedIn: 'root',
})
export class TrustpilotRatesService {
  private readonly http = inject(HttpClient);

  getTrustpilotRates(): Observable<TrustpilotRates> {
    return this.http.get<TrustpilotRates>(TrustpilotRatesUrlApi);
  }
}