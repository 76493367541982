/* eslint-disable */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { merge } from 'lodash-es';
@Injectable({
  providedIn: 'root',
})
export class PageScrollingService {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {}

  public scrollTo(target: string, offset: number = this.getOffsetForScroll(), options?: any): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const defaultOptions = { behavior: 'smooth', block: 'start' };
    options = merge(defaultOptions, options);
    const top = this.getTopWithOffset(target, offset);
    this.scrollToElement(top, options);
    setTimeout(() => {
      const newTop = this.getTopWithOffset(target, offset);
      if (top !== newTop) {
        this.scrollToElement(newTop, options);
      }
    }, 300);
  }

  private scrollToElement(top, options) {
    setTimeout(() => {
      window.scrollTo({ top, ...options });
    }, 10);
  }

  private getTopWithOffset(target, offset) {
    const el = document.getElementById(target.replace('#', ''));

    if (el) {
      let top = el.getBoundingClientRect().top + window.pageYOffset;
      if (offset) {
        top -= offset;
      }
      return top;
    }
  }

  private getOffsetForScroll() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isFirefox = userAgent.indexOf('firefox') > -1;
    const SCROLL_PADDING_TOP_IN_PX = 80;
    return isFirefox ? SCROLL_PADDING_TOP_IN_PX + 40 : SCROLL_PADDING_TOP_IN_PX;
  }
}
