import { NgIf, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type SpecialTargetValues = '_self' | '_blank' | '_parent' | '_top';

@Component({
  selector: 'nscf-cta-blue',
  standalone: true,
  imports: [UpperCasePipe, NgIf],
  templateUrl: './cta-blue.component.html',
  styleUrls: ['./cta-blue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaBlueComponent {
  @Input({ required: true }) protected label: string;
  @Input() protected target: SpecialTargetValues = '_blank';
  @Input({ required: true }) protected uri: string;
  @Input() protected withArrow = false;
}
