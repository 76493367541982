import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';

@Component({
  selector: 'nscf-pre-footer',
  standalone: true,
  imports: [],
  templateUrl: './pre-footer.component.html',
  styleUrls: ['./pre-footer.component.scss'],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class PreFooterComponent extends BasicThemedComponentDirective {
  readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'new-da' : ''}`);

  constructor() {
    super();
  }
}
