import { NgFor, NgIf, isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, PLATFORM_ID, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { map } from 'rxjs';
import { TypeNamePageManualHub } from '../../../interfaces/pages';
import { ISummaryElement } from '../../../interfaces/summary';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';
import { RichResultsService } from '../../../services/rich-results/rich-results.service';
import { SchemaTypes } from '../../../services/rich-results/rich-results.values';

@Component({
  selector: 'nscf-article-summary-details',
  templateUrl: 'article-summary-details.component.html',
  styleUrls: ['article-summary-details.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, RouterLink, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'hostClasses()',
  },
})
export class ArticleSummaryDetailsComponent extends BasicThemedComponentDirective implements OnInit {
  @Input({ required: true }) set data(data: ISummaryElement[]) {
    data.forEach(el => {
      el.url = 'anchor_' + el.id;
    });
    this.summaryElements.set(data);
  }
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'new-da' : 'old-da'}`);
  readonly typeNamePageManualHub = TypeNamePageManualHub;
  private readonly deviceManagerService = inject(DeviceManagerService);
  private readonly richResultsService = inject(RichResultsService);
  private readonly platformID = inject(PLATFORM_ID);
  private readonly isDesktop$ = this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.isDesktop));
  private readonly summaryElements = signal<ISummaryElement[]>([]);
  private readonly firstElementsMaxCount$ = this.isDesktop$.pipe(map(isDesktop => (isDesktop ? 4 : 2)));
  private readonly firstElementsMaxCount = toSignal(this.firstElementsMaxCount$);
  readonly displayedSummaryElements = computed(() => {
    if (this.seeMore()) {
      return this.summaryElements();
    }
    return this.summaryElements().slice(0, this.firstElementsMaxCount());
  });
  readonly showSeeMoreButton = computed(() => this.summaryElements().length > this.firstElementsMaxCount());
  readonly seeMore = signal(false);
  readonly seeMoreLabel = computed(() => (this.seeMore() ? 'Voir moins' : 'Voir plus'));

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformID)) {
      this.richResultsService.setJsonLd(SchemaTypes.ITEM_LIST, {
        elements: this.summaryElements(),
        url: this.deviceManagerService.getFullUrlSSr(),
      });
    }
  }
}
