import { Component, inject, Input } from '@angular/core';
import { ILogo } from './logo';
import { MatIcon } from '@angular/material/icon';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'nscf-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    imports: [MatIcon],
    hostDirectives: [DirectionArtisticDirective]
})
export class LogoComponent {
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  @Input() logo: ILogo;
}
