import { PdfHelper } from '../../classes/pdf/pdf.helper';
import { IFieldMediaFile } from '../../interfaces/field-media-file';

export class MediaFilePresenter {
  public static getFile(data: any): IFieldMediaFile {
    // avoid empty / undefined data
    if (!data || !data['entity'] || !data['entity'].fieldMediaFile1) {
      return null;
    }
    const mediaFile = data['entity'].fieldMediaFile1;
    return {
      url: PdfHelper.rewritePdfsUrl(mediaFile.entity.url || ''),
      created: mediaFile.entity.created || null,
      changed: mediaFile.entity.changed || null,
    };
  }
}
