<a class="article-promo__link-text" [href]="data?.link?.url | safeResourceUrl">
  <div [ngClass]="classTheme" class="article-promo">
    <p [innerHTML]="data?.text?.processed | safeHtml" class="article-promo__text"></p>
    <div class="article-promo__wrapper-wave">
      <img class="article-promo__wave" src="../../../../assets/wave/wave.svg" loading="lazy" alt="" />
      <div class="article-promo__wrapper-link">
        <span>
          {{ data?.link?.title }}
        </span>
        <span>
          <img class="article-promo__link-arrow" src="../../../../assets/arrow/arrow-right-white.svg" loading="lazy" alt="flèche blanche" />
        </span>
      </div>
    </div>
  </div>
</a>
