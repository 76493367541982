import { DOCUMENT } from '@angular/common';
import { DestroyRef, inject, Injectable, RendererFactory2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ARTISTIC_DIRECTION_QUERY_PARAM,
  ArtisticDirectionQueryValue,
  DEFAULT_THEME,
  EffyCommonAssetsService,
} from '@effy-tech/angular-common';
import { Theme } from '@effy-tech/design-system';
import { filter, map, of, shareReplay, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcquisitionThemeHelperService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly document = inject(DOCUMENT);
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly renderer = this.rendererFactory.createRenderer(null, null);
  private readonly defaultTheme = inject(DEFAULT_THEME) ?? Theme.OLD_EFFY;
  private readonly effyCommonAssets = inject(EffyCommonAssetsService);
  private readonly location = inject(DOCUMENT)?.defaultView?.location;
  private readonly directionArtistic = new URLSearchParams(this.location?.search)?.get(
    ARTISTIC_DIRECTION_QUERY_PARAM
  ) as ArtisticDirectionQueryValue;
  private readonly routeQueryParams = of(this.directionArtistic);

  private readonly routeTheme$ = this.routeQueryParams.pipe(
    filter(artisticDirection => Object.values(ArtisticDirectionQueryValue).includes(artisticDirection)),
    map(artisticDirection => {
      switch (artisticDirection) {
        case ArtisticDirectionQueryValue.OLD:
          switch (this.defaultTheme) {
            case Theme.CALCULEO:
              return Theme.OLD_CALCULEO;
            case Theme.EFFY:
              return Theme.OLD_EFFY;
            case Theme.LPE:
              return Theme.OLD_LPE;
            case Theme.QE:
              return Theme.OLD_QE;
          }
          return this.defaultTheme;
        case ArtisticDirectionQueryValue.NEW:
          switch (this.defaultTheme) {
            case Theme.OLD_CALCULEO:
              return Theme.CALCULEO;
            case Theme.OLD_EFFY:
              return Theme.EFFY;
            case Theme.OLD_LPE:
              return Theme.LPE;
            case Theme.OLD_QE:
              return Theme.QE;
          }
          return this.defaultTheme;
      }
    })
  );
  private newFontLoaded = false;
  readonly theme$ = this.routeTheme$.pipe(shareReplay({ bufferSize: 1, refCount: true }), startWith(this.defaultTheme));
  readonly artisticDirection$ = this.theme$.pipe(map(theme => this.getArtisticDirectionFromTheme(theme)));
  readonly isNewArtisticDirection$ = this.artisticDirection$.pipe(
    map(artisticDirection => artisticDirection === ArtisticDirectionQueryValue.NEW)
  );

  init() {
    this.theme$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(theme => {
      // Updates theme class
      this.setTheme(theme);
      // Loads the new font only when a new theme has been selected
      if (this.isNewArtisticDirectionTheme(theme)) {
        this.loadNewFont();
      }
    });
  }

  private getArtisticDirectionFromTheme(theme: Theme) {
    return [Theme.EFFY, Theme.CALCULEO, Theme.LPE, Theme.QE, Theme.PRO].includes(theme)
      ? ArtisticDirectionQueryValue.NEW
      : ArtisticDirectionQueryValue.OLD;
  }

  private isNewArtisticDirectionTheme(theme: Theme) {
    return this.getArtisticDirectionFromTheme(theme) === ArtisticDirectionQueryValue.NEW;
  }

  private loadNewFont() {
    if (this.newFontLoaded) {
      return;
    }
    const link = this.renderer.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${this.effyCommonAssets.computeBaseUrl(false)}fonts/buenos-aires.css`;
    link.onload = () => {
      this.newFontLoaded = true;
    };
    link.onerror = () => {
      console.error('Error occurred while loading new font.');
    };
    this.renderer.appendChild(this.document.head, link);
  }

  private setTheme(selectedTheme: Theme): void {
    Object.values(Theme)
      .filter(theme => theme !== selectedTheme)
      .forEach(theme => this.renderer.removeClass(this.document.body, theme));
    this.renderer.addClass(this.document.body, selectedTheme);
  }
}
