// eslint-disable-next-line no-shadow
export const WORK_VALUES = {
  AtticInsulation: 'attic_insulation',
  AirWaterHeatPump: 'air_water_heat_pump',
  CombinedSolarSystem: 'combined_solar_system',
  DoubleGlazing: 'double_glazing',
  GeothermalHeatPump: 'geothermal_heat_pump',
  PelletBoiler: 'pellet_boiler',
  PelletStove: 'pellet_stove',
  RoofTerrace: 'roof_terrace',
  ThermodynamicWaterHeater: 'thermodynamic_water_heater',
  WallInsulation: 'wall_insulation',
  WoodBoiler: 'wood_boiler',
  // This value is deprecated and is treated just like WoodBoiler, wait for backend to stop sending it.
  WoodPelletBoiler: 'wood_pellet_boiler',
  WoodStove: 'wood_stove',
} as const;

export type WorksValues = (typeof WORK_VALUES)[keyof typeof WORK_VALUES];

export const getWorkValueKey = (value: string): WorksValues =>
  Object.entries(WORK_VALUES)
    .filter(([key, ovalue]) => ovalue === value)
    .map(([key, ovalue]) => key as WorksValues)[0];
