import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { LoggerService } from '@effy-tech/common/loggers';
import { WebsiteThemes } from '@effy-tech/core/enums';
import { filter as filterLodash, some } from 'lodash-es';
import { Subscription } from 'rxjs';
import { fadeInCardsAnimation } from '../../../animations/animations';
import { CardificationService } from '../../../services/cardification/cardification.service';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';
import { IMediasCarouselType } from '../../carousel/carousel';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { SelectComponent } from '../../select/select.component';
import { FilterValues, IFilter } from '../medias';
import { MediasCardComponent } from '../medias-card/medias-card.component';

@Component({
  selector: 'nscf-medias-cardification',
  templateUrl: 'medias-cardification.component.html',
  styleUrls: ['medias-cardification.component.scss'],
  animations: [fadeInCardsAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    SelectComponent,
    MediasCardComponent,
    CtaButtonComponent,
    MatSelectModule,
    MatFormFieldModule,
    MatDividerModule,
    MatTabsModule,
  ],
  hostDirectives: [DirectionArtisticDirective],
})
export class MediasCardificationComponent extends BasicThemedComponentDirective implements OnInit, OnDestroy {
  @Input() cards: Array<IMediasCarouselType> = [];
  visibleCards: Array<IMediasCarouselType> = [];
  currentPage = 0;
  pageSize = 9;
  @Input() filters: Array<IFilter>;
  currentFilter: IFilter;
  isBigDesktop: boolean;
  isBrowser: boolean;
  animate = true;
  isEffyTheme: boolean;
  filterValues = FilterValues;
  subscription: Subscription;
  isNewDA = toSignal(this.themeHelperService.isNewArtisticDirection$);

  constructor(
    private readonly deviceManagerService: DeviceManagerService,
    private readonly cardificationService: CardificationService,
    private readonly logger: LoggerService,
    private readonly themeHelperService: ThemeHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isEffyTheme = this.theme == WebsiteThemes.effy;
    this.currentFilter = this.filters[0] || null;
    this.deviceManagerService.isBigDesktop().subscribe(isDesktop => {
      this.isBigDesktop = isDesktop;
    });
    this.isBrowser = this.deviceManagerService.isBrowser();
    if (this.cards?.length) {
      // standard behaviour
      this.loadCards();
    } else if (this.isEffyTheme) {
      // new cards
      this.getMediaCards();
    }
  }

  loadCards() {
    const start = this.pageSize * this.currentPage;
    this.visibleCards.push(...this.cards.slice(start, start + this.pageSize));
    if (this.isBrowser && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  loadMore() {
    this.currentPage++;
    this.loadCards();
  }

  changeFilterTab(label: string) {
    const filter = this.filters.find(filterElement => (label === filterElement.title));
    this.changeFilter(filter);
  }

  changeFilterSelect(id: string) {
    const filter = this.filters.find(filterElement => (id === filterElement.id));
    this.changeFilter(filter);
  }

  changeFilter(filter) {
    this.animate = false;
    this.currentFilter = filter;
    if (this.currentFilter.id !== this.filterValues.tous) {
      this.visibleCards = [
        ...filterLodash(this.cards, card => {
          return (card as any).type.indexOf(this.currentFilter.id) > -1;
        }),
      ];
    } else {
      this.visibleCards = this.cards.slice(0, this.pageSize * this.currentPage + this.pageSize);
    }
    if (this.isBrowser) {
      setTimeout(() => {
        this.animate = true;
      }, 400);
    }
  }

  private getMediaCards() {
    try {
      const mediasCards = this.cardificationService.getMediaCards({ isTheme: true }, 200);

      this.subscription = mediasCards.pipe().subscribe(res => {
        this.cards = res || [];
        this.setFilters();
        this.loadCards();
      });
    } catch (e) {
      this.logger.error('ThemeComponent: error "getCardificationThemeData()"', e);
    }
  }

  private setFilters(): void {
    for (let i = 0; i < this.cards?.length; i++) {
      const filterType = this.cards[i]['type'];
      if (
        !some(this.filters, filter => {
          return filter.id === filterType;
        })
      ) {
        if (filterType) {
          this.filters.push({
            id: filterType,
            title: FilterValues[filterType],
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
