import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { WidgetCalculatorComponent as WidgetCalculatorLibComponent } from '@effy-tech/widget-calculator';
import { IWidgetCalculator } from './widget-calculator';

@Component({
  selector: 'nscf-widget-calculator',
  templateUrl: 'widget-calculator.component.html',
  styleUrls: ['widget-calculator.component.scss'],
  standalone: true,
  imports: [NgIf, WidgetCalculatorLibComponent],
})
export class WidgetCalculatorComponent extends BasicThemedComponentDirective {
  @Input() data: IWidgetCalculator;
  @Input() isBrowser: boolean;
}
