import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatIcon } from "@angular/material/icon";
import { SymbolePictosDefinitions } from '../../configs/icons-definitions.config';
import { WorkTypeOrNull } from '../../models/widget-calculator.models';
import { CommonAssetsService } from '../../services/common-assets/common-assets.service';
import { FormService } from '../../services/form.service';
import { WorkEstimateComponent } from '../work-estimate/work-estimate.component';
import { WorkSelectionComponent } from '../work-selection/work-selection.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'calc-my-prime-renov',
  templateUrl: './my-prime-renov.component.html',
  styleUrls: ['./my-prime-renov.component.scss'],
  providers: [FormService],
  standalone: true,
  imports: [WorkEstimateComponent, WorkSelectionComponent, NgIf, MatIcon],
})
export class MyPrimeRenovComponent implements OnInit {

  private readonly destroyRef = inject(DestroyRef);
  private readonly formService = inject(FormService);
  private readonly commonAssetsService = inject(CommonAssetsService);
  @Input() workSelectedValue: WorkTypeOrNull;
  srcBubbleInfo = this.commonAssetsService.getSymbolePictoSvgUrl(SymbolePictosDefinitions.BubbleInfo);
  isPreselectedWork: boolean;

  ngOnInit(): void {
    if (this.workSelectedValue) {
      this.isPreselectedWork = true;
    }
    this.formService.setWorkValue(this.workSelectedValue);
    this.formService.workSelectedValue$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.workSelectedValue = value);
  }

  onWorkTypeReset(): void {
    this.workSelectedValue = null;
  }
}
