import { Location, AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestName } from '../../graphql/graphql';
import { GetDataService } from '../../graphql/graphql.service';
import { IQueryVariables } from '../../interfaces/themes-query-variables';
import { IQuestionsThemeQuestionsBo } from '../../presenters/commons/questions.presenter';
import { IHeadingQuestionsQuestions, THEME_QUESTION_ID } from './heading-questions';
import { UiHeadingQuestionsComponent } from './ui-heading-questions/ui-heading-questions.component';

@Component({
    selector: 'nscf-heading-questions',
    templateUrl: './heading-questions.component.html',
    styleUrls: ['./heading-questions.component.scss'],
    standalone: true,
    imports: [UiHeadingQuestionsComponent, AsyncPipe]
})
export class HeadingQuestionsComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() data: { title: string; currentThemeId: number; currentArticleID: string };
  questions$: Observable<IHeadingQuestionsQuestions>;

  constructor(private readonly getDataService: GetDataService, private readonly location: Location) {
    super();
  }

  ngOnInit(): void {
    this.questions$ = this.getQuestions$(this.getQueryVariables(this.data?.currentThemeId + ''));
  }

  getQuestions$(queryVariables: IQueryVariables): Observable<IHeadingQuestionsQuestions> {
    return this.getDataService.getRequestData(RequestName.HEADING_QUESTIONS, queryVariables).pipe(
      map(this.mapDataToQuestions),
      map(questions => this.removeCurrentPage(questions, this.data?.currentArticleID)),
    );
  }

  private getQueryVariables(currentThemeId: string): IQueryVariables {
    return {
      themes: [currentThemeId, THEME_QUESTION_ID],
    };
  }

  private removeCurrentPage(questions: IHeadingQuestionsQuestions, currentArticleID): IHeadingQuestionsQuestions {
    return questions.filter(question => {
      return question?.nid !== currentArticleID;
    });
  }

  private mapDataToQuestions(questionsBo: IQuestionsThemeQuestionsBo): IHeadingQuestionsQuestions {
    return questionsBo?.data?.articleQuestionsGraphqlView?.results.map(question => ({
      nid: question?.nid,
      text: question?.title,
      url: question?.url,
    }));
  }
}
