export const TypeNameBlockVerifiedReviews = 'ParagraphBlocAvisVerifie';

export interface IVerifiedReviewsContainer {
  __typename: 'ParagraphBlocAvisVerifie';
  title?: string;
  isFirstTitle?: boolean;
  show?: boolean;
  extra?: any;
}

export interface IVerifiedReviewsUrls {
  url: string;
  desktopSuffix: string;
  mobileSuffix: string;
}
