import { IBlockPresenter } from '../presenter';
import { MainSubBlockPresenter } from '../main-sub-blocks.presenter';
import { LoggerService } from '@effy-tech/common/loggers';
import { IBlockText, TypeNameBlockTexts } from '../../components/block-text/block-text';

export class BlockTextPresenter implements IBlockPresenter {
  constructor(protected readonly logger: LoggerService) {}

  parse(data: any): IBlockText {
    const mainSubBlockPresenter = new MainSubBlockPresenter(this.logger);
    const block: IBlockText = {
      __typename: TypeNameBlockTexts,
      id: data['id'],
      subBlocks: mainSubBlockPresenter.parse(data['SubBlock']),
    };
    return block;
  }
}
