export enum Devices {
  MOBILE_SE,
  MOBILE,
  MOBILE_LANDSCAPE,
  IPAD_LANDSCAPE,
  IPAD_PORTRAIT,
  DESKTOP,
  ALL,
}

export function getAllDevicesName(): Array<string> {
  return Object.keys(Devices).filter(key => Devices[key] !== Devices.ALL && typeof Devices[key] === 'number');
}
