import {
  EnvironmentModel,
  IConnectionLink,
  ICtaLink,
  ICtaLinkAttributes,
  IHeader,
  IHeaderBo,
  ILinkBo,
  ILogo,
  IMenuBo,
  IOptionsBo,
} from '@effy-tech/acquisition-header';
import { IHeaderPhoneInsert } from '../../../interfaces/header';
import { ImagesService } from '../../../services/images-service/images.service';
import { MediaImagePresenter } from '../media-image.presenter';
import { RTEPresenter } from '../rte.presenter';
import { HeaderMenuPresenter } from './header-menu.presenter';

// @dynamic
export class NewHeaderPresenter {
  static getHeader(data: IHeaderBo, dataMenu: IMenuBo, env: EnvironmentModel): IHeader {
    if (!data || !data['entity']) {
      return null;
    }

    return {
      navbar: new HeaderMenuPresenter().mapApiMenuToView(dataMenu),
      logo: this.getLogo(data),
      connection: {
        link: this.getConnectionLink(data),
        isConnected: false,
      },
      phoneInsert: this.getPhoneInsert(data),
      linkPart: this.getLinkPart(data),
      isDesktop$: null,
      env,
      isPro: data.entity?.fieldMenu?.targetId === 'pro',
    };
  }

  private static getLogo(data: IHeaderBo): ILogo {
    const { url, title, alt } = MediaImagePresenter.getImage(data['entity'].brandLogo);

    return {
      url,
      title,
      alt,
      link: data?.entity?.logoLink?.url?.path,
    };
  }

  private static getLink(link: ILinkBo): ICtaLink {
    return link
      ? {
          url: link?.url?.path,
          title: link?.title,
          options: this.getOptions(link?.options),
        }
      : null;
  }

  private static getOptions(options: IOptionsBo): ICtaLinkAttributes {
    return {
      attributes: {
        target: options?.attributes?.target ?? '_self',
        rel: options?.attributes?.rel ?? '',
      },
    };
  }

  private static getConnectionLink(data: IHeaderBo): IConnectionLink {
    return {
      ...this.getLink(data?.entity?.link),
      title: data?.entity?.button,
      icon: { ...data?.entity?.pictoConnexion, url: ImagesService.rewriteImagesUrl(data?.entity?.pictoConnexion?.url) },
    };
  }

  private static getLinkPart(data: IHeaderBo): ICtaLink {
    return this.getLink(data?.entity?.headerCTA);
  }

  private static getPhoneInsert(data: IHeaderBo): IHeaderPhoneInsert | null {
    const phoneEntity = data.entity;
    if (!phoneEntity) return null;
    return {
      phoneHook: phoneEntity.phoneHook,
      phoneType: phoneEntity?.phoneType,
      phoneNumber: phoneEntity.phoneNumber,
      accompanimentPhone: RTEPresenter.getRTEText(phoneEntity.accompanimentPhone),
    };
  }
}
