import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, Input, OnInit, SecurityContext } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { IHeaderPhoneInsert, PhoneType } from '../header/header';
import { RegulatoryPhoneComponent } from '../regulatory-phone/regulatory-phone.component';

@Component({
  selector: 'nscf-new-phone-block',
  templateUrl: 'phone-block.component.html',
  styleUrls: ['phone-block.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgTemplateOutlet, RegulatoryPhoneComponent, EffyCommonModule],
  host: {
    '[class]': 'hostClasses()'
  }
})
export class PhoneBlockComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() phoneInsert: IHeaderPhoneInsert;
  @Input() isDesktop: boolean;
  isRegulatory: boolean;
  private readonly sanitizer = inject(DomSanitizer);
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly hostClasses = computed(() => this.isNewArtisticDirection() ? 'new-da' : '');

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.isRegulatory = this.phoneInsert?.phoneType === PhoneType.Reglementaire;
    if (this.phoneInsert?.accompanimentPhone?.value) {
      this.phoneInsert.accompanimentPhone.value = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(this.phoneInsert.accompanimentPhone?.value)
      );
    }
  }
}
