import { Injectable } from '@angular/core';
import { BoUrlService } from '../bo-url/bo-url.service';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private static readonly pdfRegex: RegExp = /([a-zA-Z0-9\s_\\.\-\(\):])+(.pdf)$/g;
  private static readonly pdfRegex2: RegExp = /[^"\'=\s]+\.(pdf)/g;

  public static rewritePdfsUrl(content: string): string {
    // retrieve all images from content
    return content.replace(this.pdfRegex2, match => {
      // then replace URLs as possible
      return match.replace(BoUrlService.getEnvironments().url_base_bo_local, BoUrlService.getEnvironments().url_base_bo);
    });
  }

  public static setPdfLinkTarget(event: any, target?: string): void {
    const htmlLink = event.target;
    if (htmlLink?.nodeName.toLowerCase() !== 'a') {
      return;
    }

    if (htmlLink.href.split('.').pop().toLowerCase() === 'pdf' && !htmlLink.target) {
      htmlLink.target = target || '_self';
    }
  }
}
