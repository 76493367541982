import { Component, Input } from '@angular/core';

@Component({
    selector: 'nscf-svg',
    templateUrl: 'svg.component.html',
    standalone: true
})
export class SvgComponent {
  @Input() offset: string | number = 0;
  @Input() color: string;

  getOpacity(offset: number | string, val: number): 0 | 1 {
    return Number(Number(offset).toFixed()) === val ? 1 : 0;
  }
}
