import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';

@Component({
    selector: 'nscf-wave',
    templateUrl: './wave.component.svg',
    styleUrls: ['./wave.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class WaveComponent extends BasicThemedComponentDirective {}
