import { DecimalPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, computed, inject, Input, OnInit } from '@angular/core';
import { CtaActionDirective } from '../../directives/cta-action/cta-action.directive';
import { ImagesService } from '../../services/images-service/images.service';
import { PageScrollingService } from '../../services/page-scrolling/page-scrolling.service';
import { LiseretComponent } from '../liseret/liseret.component';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { IFormSTPCard, ISuperTopPage } from './super-top-page';
import { toSignal } from '@angular/core/rxjs-interop';
import { EffyTileComponent, EffyTileInputType, EffyTileSize, ThemeHelperService } from '@effy-tech/angular-common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'nscf-super-top-page',
  templateUrl: 'super-top-page.component.html',
  styleUrls: ['super-top-page.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    TitleComponent,
    NgFor,
    CtaActionDirective,
    LiseretComponent,
    NgStyle,
    DecimalPipe,
    TrustpilotMicroStarComponent,
    EffyTileComponent,
  ],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class SuperTopPageComponent implements OnInit {
  isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirection() ? 'new-da' : 'old-da'}`);

  @Input() data: ISuperTopPage;
  @Input() bigViewPort: boolean;
  @Input() isBrowser: boolean;
  urlCover: any;
  displayScoring: boolean;
  formGroup: FormGroup;

  readonly tileSize = EffyTileSize.SMALL;
  readonly tileType = EffyTileInputType.RADIO;

  constructor(private readonly pageScrollingService: PageScrollingService) {}

  get cardControls(): FormControl[] {
    return (this.formGroup.get('cards') as FormArray).controls as FormControl[];
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({});
    this.data.formCards.forEach((card, index) => {
      this.formGroup.addControl('card' + index, new FormControl(null));
    });

    this.urlCover = ImagesService.setBackGroundCoverUrl(this.data.mediaCover);
  }

  redirectForm(evt: any, card: IFormSTPCard): void {
    if (card.url) {
      setTimeout(() => {
        window.location.href = card.url.url;
      }, 500);
    }
  }
}
