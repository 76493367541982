@if (isNewDirectionArtistic()) {
  @for (card of data(); track card.title) {
    @if (card.title || card.image) {
      <div
        class="tw-flex-1 tw-gap-[16px] tw-items-center tw-flex tw-flex-col tw-text-center tw-border-border-card tw-border-[1px] tw-border-solid tw-rounded-[12px] tw-p-[32px] tw-bg-white"
      >
        @if (card.image) {
          <div class="tw-h-[35px]">
            <img class="tw-h-full" [src]="card.image.url" [alt]="card.image.alt" height="35" />
          </div>
        }
        <p class="tw-mb-0 tw-font-600 tw-text-new-lg">{{ card.title }}</p>
      </div>
    }
  }
} @else {
  <div *ngIf="data" class="container new-top-page-cards">
    <div class="row new-top-page-cards__block-position">
      <div class="row col-12 col-md-4 text-center new-top-page-cards__block" *ngFor="let d of data()">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <div class="w-100">
            <img *ngIf="d.image" class="new-top-page-cards__img" [src]="d.image.url" [alt]="d.image.alt" />
            <p class="new-top-page-cards__title m-0">{{ d.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
}
