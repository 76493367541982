@if(isNewArtisticDirection()) {
  <a [href]="link" effy-button [color]="buttonStyle()" [size]="buttonSize" class="tw-w-full smd:tw-w-fit">
    <mat-icon [svgIcon]="'account'" class="account-icon"></mat-icon>
    <div class="tw-flex tw-flex-col tw-text-left tw-whitespace-nowrap">
      <p data-cs-mask class="tw-text-new-xs tw-h-4 tw-mb-0 tw-font-300">{{ labelUser }}</p>
      <p class="tw-text-new-sm tw-font-600 tw-h-4 tw-mb-0 tw-flex tw-items-center tw-gap-[2px]">
        <span>{{ title }}</span>
        <mat-icon [svgIcon]="'chevron-right'" class="tw-w-3 tw-flex tw-flex-shrink-0"></mat-icon>
      </p>
    </div>
  </a>
} @else {
  <a [href]="link" class="d-flex" [ngClass]="'nscf-btn ' + btnClass">
    <div class="effy-btn-content">
      <img *ngIf="btnIcon" class="effy-btn-content-icon" aria-hidden="true" [src]="btnIcon" />
      <div class="client-space__wrapper-name">
        <p data-cs-mask>{{ labelUser }}</p>
        <span class="effy-btn-content-text">
          {{ title }}
          <img class="client-space__arrow arrow-menu" src="../../../../../../../assets/arrow/icon_arrow_black.svg" alt="" />
        </span>
      </div>
    </div>
  </a>
}

