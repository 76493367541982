import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { ICtaLink } from '@effy-tech/acquisition-header';

@Component({
    selector: 'nscf-link-button',
    templateUrl: 'link-button.component.html',
    styleUrls: ['link-button.component.scss'],
    standalone: true
})
export class LinkButtonComponent extends BasicThemedComponentDirective {
  @HostBinding('class.link-button-cta') someField = true;

  @HostBinding('style.--mouse-x')
  private mouseX: number;

  @HostBinding('style.--mouse-y')
  private mouseY: number;

  @Input() link: ICtaLink;

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    this.mouseX = (event.offsetX / event?.srcElement?.clientWidth) * 100;
    this.mouseY = (event.offsetY / event?.srcElement?.clientHeight) * 100;
  }

  @HostListener('mouseout')
  mouseout() {
    this.mouseX = 100;
    this.mouseY = 100;
  }
}
