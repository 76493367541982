import { NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { CarouselModule, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { RteComponent } from '../../rte/rte.component';
import { ITopPage } from '../top-page';

@Component({
  selector: 'nscf-top-page-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
  standalone: true,
  imports: [CarouselModule, NgFor, NgIf, RteComponent, NgClass, UpperCasePipe],
})
export class TopPageCarouselComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() topPage: ITopPage;

  customOptions: OwlOptions;
  activeSlides: SlidesOutputData;
  currentSlideId: string;

  ngOnInit(): void {
    this.setCarouselOptions();
    this.currentSlideId = 'slide-1';
  }

  setCarouselOptions(): void {
    const delay = 10000;
    const transitionSpeed = 700;

    this.customOptions = {
      autoplay: true,
      autoplayTimeout: delay,
      autoplaySpeed: transitionSpeed,
      navSpeed: transitionSpeed,
      smartSpeed: transitionSpeed,
      loop: true,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      nav: false,
      items: 1,
      autoWidth: true,
      responsive: {
        0: {
          items: 1,
          responsiveRefreshRate: 200,
        },
      },
    };
  }

  getPassedData(data: any): void {
    this.activeSlides = data;
  }

  getChangeData(data: any): void {
    this.activeSlides = data;
  }

  getChangedData(data: any): void {
    this.activeSlides = data;

    if (data.slides.length) {
      this.currentSlideId = data.slides[0].id;
    }
  }
}
