<div class="spacing-block stp__wrapper-container" [ngClass]="{ 'stp__wrapper-container--without': !data.mediaCover }">
  <div class="container">
    <div class="row">
      <div class="col-12">
        @if (data.title) {
          <nscf-title class="stp__title" [selector]="'h1'" [title]="data.title"></nscf-title>
        }
        @if (data.heading) {
          <p class="stp__description">{{ data.heading }}</p>
        }
        @if (data.enableTrustPilot) {
          <nscf-trustpilot-micro-star class="stp__wrapper-avis"></nscf-trustpilot-micro-star>
        }
      </div>
    </div>
  </div>

  <div class="stp__card-wrapper-position-bg">
    <div class="container">
      <div
        class="stp__card-container col-12 col-sm-8 col-md-6 col-lg-10 m-auto" 
        [class.stp__card-container--animate]="isBrowser"
      >
        <p class="stp__card-title">{{ data.formTitle }}</p>
        @if (isNewArtisticDirection()) {
          <div class="stp__card-wrapper">
            <effy-tile 
              *ngFor="let formCardInput of data.formCards; index as i"
              class="stp__card-item"
              [control]="$any(formGroup.get('card' + i))"
              [label]="formCardInput.title"
              [value]="'formCardInput-' + i"
              [id]="'formCardInput-' + i"
              [image]="formCardInput.picto?.url"
              [size]="tileSize"
              [inputType]="tileType"
              (valueChanged)="redirectForm($event, formCardInput)"
            >
            </effy-tile>
          </div>
        } @else {
          <div class="stp__card-wrapper">
            <div class="stp__card-radio" *ngFor="let formCardInput of data.formCards; index as i">
              <input class="stp__card-input" (change)="redirectForm($event, formCardInput)" type="radio"
                [id]="'formCardInput-' + i" name="formCard" />
              <label class="stp__card-label" [for]="'formCardInput-' + i">
                <img [src]="formCardInput.picto?.url" width="36" height="36" alt="" />
                {{ formCardInput.title }}
              </label>
            </div>
          </div>
        }

        @if (data.formDescription) {
          <p class="stp__card-description mx-auto">
            {{ data.formDescription }}
          </p>
        }
        <div appCtaAction [text]="data.ctaLabel" [ctaUrl]="data.ctaUrl" [replaceElements]="true"></div>
        @if (!isNewArtisticDirection()) {
          <nscf-liseret></nscf-liseret>
        }
      </div>
    </div>

    @if (data.mediaCover) {
      <div class="container-fluid stp__card-bg-image" [ngStyle]="urlCover" #cover></div>
    }
  </div>
</div>
