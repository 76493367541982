import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { IArticleMetaInfoAuthor } from '../../../interfaces/article-meta-info';
import { LiseretComponent } from '../../liseret/liseret.component';

@Component({
  selector: 'nscf-article-author',
  standalone: true,
  imports: [LiseretComponent, NgIf, NgClass, JsonPipe],
  templateUrl: './article-author.component.html',
  styleUrls: ['./article-author.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'hostClasses()',
  },
})
export class ArticleAuthorComponent extends BasicThemedComponentDirective {
  readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'new-da' : 'old-da'}`);

  @Input() data: IArticleMetaInfoAuthor;

  constructor() {
    super();
  }
}
