import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsTitleService {
  constructor(private readonly title: Title) {}

  public setTitleTag(_title: string) {
    this.title.setTitle(_title);
  }
}
