import { IArticle, IFocusCategorie } from '../../../components/categorie/focus-categorie/focus-categorie';
import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { TypeNameBlockFocus } from '../../../interfaces/focus-categorie';
import { MediaImagePresenter } from '../../commons/media-image.presenter';
import { IBlockPresenter } from '../../presenter';
import { IImageBo } from '../top-page-categorie/top-page-categorie';

export interface ITargetTaxonomyBO {
  chapo: string;
  name: string;
  nameFront: string;
  titleHub: string;
  url: string;
  visibility: string;
}

export interface IThemeBO {
  entity: ITargetTaxonomyBO;
  targetId: number;
}

export interface IImageEntityBO {
  entity: {
    cover: {
      entity: {
        fieldMediaImage: IImageBo;
      };
    };
  };
}

export interface IArticleBO {
  entityUrl: {
    path: string;
    routed: true;
  };
  image?: Array<IImageEntityBO>;
  theme: Array<IThemeBO>;
  title: string;
  cover?: {
    entity: {
      fieldMediaImage: IImageBo;
    };
  };
}

export interface IEntityArticleBO {
  entity: IArticleBO;
}

export interface IParagraphBlockFocusBO {
  chapo: string;
  title: string;
  mainArticle: {
    entity: IArticleBO;
  };
  secondaryArticle: Array<IEntityArticleBO>;
}

export function findImageInArticleBO(iArticle: IArticleBO): IFieldMediaImage {
  const cover = iArticle?.cover || iArticle?.image?.[0]?.entity?.cover;
  return MediaImagePresenter.getCoverWithWebp(cover);
}

export function mapArticleBOToArticle(iArticle: IArticleBO): IArticle {
  const theme = iArticle?.theme?.find((item: IThemeBO) => item?.entity?.visibility);
  return {
    title: iArticle?.title,
    theme: theme?.entity?.nameFront,
    cover: findImageInArticleBO(iArticle),
    url: iArticle?.entityUrl?.path,
  };
}

export class FocusCategoriePresenter implements IBlockPresenter {
  parse(data: IParagraphBlockFocusBO): IFocusCategorie {
    const op = {
      __typename: TypeNameBlockFocus,
      chapo: data['chapo'],
      title: data['title'],
      mainArticle: mapArticleBOToArticle(data.mainArticle.entity),
      secondariesArticles: data['secondaryArticle'].map(c => mapArticleBOToArticle(c.entity)),
    } as IFocusCategorie;
    return op;
  }
}
