<div class="r-phone-block">
  <div class="r-phone-block__number">
    <a [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a>
  </div>
  <div class="r-phone-block__price">
    <i class="r-phone-block__price-arrow-right"></i>
    <div class="r-phone-block__price-text">
      Service gratuit
      <span>+ prix appel </span>
    </div>
  </div>
</div>
