import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { WIDGET_CALCULATOR_API_KEY } from '../../configs/injection-tokens.config';
import { PrecarityLevelApiResponseModel, PrecarityLevelModel } from '../../models/precarity-level.models';
import { LocationValues } from '../../values/location.values';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class PrecarityLevelService {
  currentPrecarityLevel: PrecarityLevelModel;
  private readonly apiKey = inject(WIDGET_CALCULATOR_API_KEY);
  private readonly PROVINCE_ZIPCODE = 55100; // Province - Reims chosen arbitrarily
  private readonly ILE_DE_FRANCE_ZIPCODE = 75015; // Ile-de-France - Paris chosen arbitrarily

  constructor(
    private readonly configService: ConfigService,
    private readonly httpClient: HttpClient
  ) {}

  getBaseUrl(): string {
    return this.configService.getConfig().api.precarityLevel;
  }

  getPrecarityLevel(location: LocationValues, houseHoldSize: number): Observable<PrecarityLevelApiResponseModel> {
    return this.httpClient.get<PrecarityLevelApiResponseModel>(this.getFullUrl(location, houseHoldSize), {
      params: {
        key: this.apiKey,
      },
    });
  }

  private getFullUrl(location: LocationValues, houseHoldSize: number): string {
    return `${this.getBaseUrl()}v2/tax_revenue?zipcode=${this.getDepartmentFromLocation(
      location
    )}&household_size=${houseHoldSize}&referencial=4levels`;
  }

  private getDepartmentFromLocation(location: LocationValues): number {
    return location === LocationValues.OtherRegions ? this.PROVINCE_ZIPCODE : this.ILE_DE_FRANCE_ZIPCODE;
  }
}
