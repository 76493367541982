import { IOpinionsCard } from './opinions-card';
import { IFieldMediaImage } from '../../interfaces/field-media-image';

export const TypeNameBlockOpinions = 'ParagraphOpinion';
export const IOpinionsMaxScore = 5;

export interface IOpinions {
  __typename: 'ParagraphOpinion';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  chapo?: string;
  maxScore?: number;
  numberOfReview?: number;
  mediaCover?: IFieldMediaImage;
  cardsOpinions?: Array<IOpinionsCard>;
}
