import { DatePipe, NgIf, AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LazyLoadScriptService } from '../../services/lazy-load-script/lazy-load-script.service';
import { getQueryStringFromObject, isEmpty } from '../../utils/object/object.utils';
import { getUtmFromParams } from '../utm-campaign/utm-campaign';
import { SafeResourceUrlPipe } from '../../pipes/safe-resource-url/safe-resource-url.pipe';
import { EffyCommonModule } from '@effy-tech/common';

const CALENDLY_EFFY_URL = `https://calendly.com/effy-nouveau-partenaire/devenez-partenaire-effy`;
const WIDGET_LINK = 'https://assets.calendly.com/assets/external/widget.js';

@Component({
    selector: 'nscf-calendly',
    templateUrl: 'calendly.component.html',
    styleUrls: ['calendly.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, EffyCommonModule, SafeResourceUrlPipe]
})
export class CalendlyComponent implements OnInit {
  url$: Observable<string>;

  ngOnInit() {
    this.url$ = this.getUrl$();
    this.lazyLoadScriptService.load$(WIDGET_LINK, false).subscribe();
  }

  constructor(
    private readonly lazyLoadScriptService: LazyLoadScriptService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly datePipe: DatePipe,
    private readonly deviceServiceManager: DeviceManagerService,
  ) {}

  private getUrl$() {
    const domain = this.deviceServiceManager.getLocation();
    return this.activatedRoute.queryParams.pipe(
      map((qp: ParamMap) => getUtmFromParams(qp)),
      map(
        utmObject =>
          `${CALENDLY_EFFY_URL}?hide_gdpr_banner=1&embed_domain=${domain}&embed_type=Inline&${this.getCalendlyUrlParams(utmObject)}`,
      ),
    );
  }

  private getCalendlyUrlParams(utmObject): string {
    return `month=${this.getYearMonth()}${isEmpty(utmObject) ? '' : '&'}${getQueryStringFromObject(utmObject)}`;
  }

  private getYearMonth(): string {
    return this.datePipe.transform(Date.now(), 'yyyy-MM');
  }
}
