import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';

@Component({
    selector: 'nscf-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class LinkComponent extends BasicThemedComponentDirective {
  @Input() url: string;
  @Input() text: string;

  constructor() {
    super();
  }
}
