<div class="article-files container">
  <div (click)="openPdf(card.url)" *ngFor="let card of data.documents" class="article-files__card-container">
    <div class="article-files__image-container">
      <img [src]="card.image?.url || '../../../../assets/backgrounds/article-file.webp'" loading="lazy" alt="" />
    </div>
    <div class="article-files__content">
      <div class="article-files__title">
        {{ card.title }}
      </div>

      <nscf-liseret class="article-files__liseret" [isCentered]="!isDesktop" [theme]="theme"></nscf-liseret>
      <div class="article-files__info">
        {{ card.description }}
      </div>
      <div class="article-files__cta-container">
        @if (isNewArtisticDirection()) {
          <a effy-link [size]="linkSize" [href]="card.url" target="_blank"><mat-icon class="material-icons-outlined">visibility</mat-icon> Visualiser</a>
        } @else {
          <span class="article-files__cta"> Visualiser </span>
        }
      </div>
    </div>
  </div>
</div>