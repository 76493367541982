export function toPercentage(value: number | undefined): number {
  if (!value) {
    return 0;
  }

  if (value > 100) {
    return 100;
  }

  return value;
}
