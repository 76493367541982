import { SOCIAL_NETWORK_LINKS } from '../../values/social-network.values';

export interface INewsletter {
  __typename: string;
  isActive: boolean;
}

export const TypeNameBlocNewsletter = 'ParagraphNewsletterBlock';

export const NEWSLETTER_NETWORK_CONFIG = [
  {
    src: 'assets/icons/social/facebook-base.svg',
    alt: 'Facebook logo',
    href: SOCIAL_NETWORK_LINKS.facebook,
  },
  {
    src: 'assets/icons/social/x-base.svg',
    alt: 'X logo',
    href: SOCIAL_NETWORK_LINKS.x,
  },
  {
    src: 'assets/icons/social/youtube-base.svg',
    alt: 'YouTube logo',
    href: SOCIAL_NETWORK_LINKS.youTube,
  },
  {
    src: 'assets/icons/social/instagram-base.svg',
    alt: 'Instagram logo',
    href: SOCIAL_NETWORK_LINKS.instagram,
  },
];
