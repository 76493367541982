<ng-container>
  <section class="top-page container-fluid">
    <h1 class="top-page__title">{{ data.title }}</h1>

    @if ((isMobileOrTablet$ | async) && !isSinglePicture) {
      <nscf-top-page-mosaic-carousel
        class="top-page__photos"
        [isScrollable]="isCarouselLengthFive"
        [imagesSrc]="photosSrc"
        (openCarouselOnImageIndex)="openCarouselOnImageIndex($event)"
      ></nscf-top-page-mosaic-carousel>
    } @else {
      <div class="top-page__photos top-page__photos--mosaic" [class.top-page__photos--single]="isSinglePicture">
        <ng-container *ngTemplateOutlet="scoring"></ng-container>
        @if (data?.carousel) {
          <button (click)="openCarouselOnImageIndex(0)" class="top-page-photo top-page-photo-1">
            <img [src]="data?.carousel[0]?.url" [attr.alt]="data?.carousel[0]?.alt" fetchPriority="high" />
          </button>
        }

        @for (image of data?.carousel | slice: 1; track $index) {
          <button (click)="openCarouselOnImageIndex($index + 1)" class="top-page-photo">
            <img class="top-page-photo-{{ $index + 2 }}" [src]="image.url" [attr.alt]="image?.alt" />
          </button>
        }
      </div>
    }
  </section>
</ng-container>

<ng-template #scoring>
  @if (data.enableTrustPilot) {
    <nscf-trustpilot-micro-star></nscf-trustpilot-micro-star>
  }
</ng-template>

@if (openedCarousel()) {
  <div (click)="openedCarousel.set(false)" class="overlay">
    <button class="overlay-button close-button" alt="fermer carousel">
      <img src="assets/icons/cross.svg" alt="croix" width="40" height="40" />
    </button>

    <div class="carousel">
      @for (image of data?.carousel; track $index) {
        <button
          class="carousel-button"
          [ngClass]="{ 'carousel-button-selected': $index === selectedImageIndex }"
          (click)="selectImageIndex($event, $index)"
        >
          <img
            #carouselImages
            class="carousel-image"
            [src]="image.url"
            [attr.alt]="image?.alt"
            [width]="image.width"
            [height]="image.height"
            loading="lazy"
          />
        </button>
      }
    </div>

    @if (data?.carousel?.length > 1) {
      <div class="overlay-button-container">
        <button (click)="selectImageIndex($event, selectedImageIndex - 1)" class="overlay-button" alt="image précedente">
          <img src="assets/arrow/arrow-right-white.svg" alt="flêche précédente" class="previous-arrow" width="22" height="22" />
        </button>
        <button (click)="selectImageIndex($event, selectedImageIndex + 1)" class="overlay-button" alt="image suivante">
          <img src="assets/arrow/arrow-right-white.svg" alt="flêche suivante" width="22" height="22" />
        </button>
      </div>
    }
  </div>
}
