import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, PLATFORM_ID, inject } from '@angular/core';
import { DestroyerBaseComponent } from '@effy-tech/common';

@Directive({
  selector: '[nscfIsInViewport]',
  standalone: true,
})
export class IsInViewportDirective extends DestroyerBaseComponent implements OnInit {
  @Input() isInViewportOptions: IntersectionObserverInit = {};
  @Output() readonly isInViewport = new EventEmitter<boolean>();
  private readonly platformId = inject(PLATFORM_ID);
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  constructor(private readonly elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const obs = new IntersectionObserver(entries => {
        entries.forEach(({ isIntersecting }) => {
          this.isInViewport.emit(isIntersecting);
        });
      }, this.isInViewportOptions);

      obs.observe(this.elementRef.nativeElement);
    }
  }
}
