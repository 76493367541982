@if (isNewArtisticDirection()) {
  <a [href]="logo?.link" class="tw-py-3.5 tw-block tw-h-full smd:tw-mr-8" [attr.aria-label]="logo?.title">
    <mat-icon [svgIcon]="'site-logo'" class="tw-h-full tw-w-auto tw-text-main-color"></mat-icon>
    <span class="tw-hidden">{{ logo?.title }}</span>
  </a>
} @else {
  <div class="logo">
    <a [href]="logo?.link">
      <img [alt]="logo?.alt" [src]="logo?.url" [title]="logo?.title" />
    </a>
  </div>
}

