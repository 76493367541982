import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { IRteText } from '../../interfaces/rte-text';

export const TypeNameBlockNumber = 'ParagraphChiffres';

export interface IBlockNumber {
  __typename: 'ParagraphChiffres';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  chapo?: IRteText;
  cardsNumbers?: Array<IBlockNumberCard>;
}

export interface IBlockNumberCard {
  title?: string;
  chapo?: string;
  image?: IFieldMediaImage;
}

export enum BlocNumberSize {
  SMALL = "small",
  MEDIUM = "medium"
}