import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { LiseretComponent } from '../liseret/liseret.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { computeParamsFormQuestions, IFormParcours, IParamsFromQuestion } from './form-parcours';
import { FormParcoursQuestionComponent } from './question/form-parcours-question.component';

@Component({
  selector: 'nscf-form-parcours',
  templateUrl: './form-parcours.component.html',
  styleUrls: ['./form-parcours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LiseretComponent, NgFor, FormParcoursQuestionComponent, TrustpilotMicroStarComponent, NgClass],
  hostDirectives: [DirectionArtisticDirective],
})
export class FormParcoursComponent extends BasicThemedComponentDirective {
  @Input() data: IFormParcours;
  @Input() enableTrustpilot = false;
  @Output() changeAnswer = new EventEmitter<IParamsFromQuestion>();
  objectParams: IParamsFromQuestion;

  protected readonly isNewDirectionArtistic = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);

  onChangeAnswer() {
    this.objectParams = computeParamsFormQuestions(this.data.questions);
    this.changeAnswer.emit(this.objectParams);
  }
}
