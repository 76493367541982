import { NgFor, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { IPagerLinks } from './pager-link';

@Component({
    selector: 'nscf-pager',
    templateUrl: 'pager.component.html',
    styleUrls: ['pager.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MatIcon],
    hostDirectives: [DirectionArtisticDirective]
})
export class PagerComponent extends BasicThemedComponentDirective {
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);

  @Input() data: IPagerLinks;

  constructor() {
    super();
  }
}
