import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { TitleComponent } from '../title/title.component';
import { IBlocTwoImages } from './two-images-block';

@Component({
  selector: 'nscf-two-images-block',
  templateUrl: './two-images-block.component.html',
  styleUrls: ['./two-images-block.component.scss'],
  standalone: true,
  imports: [NgIf, TitleComponent, NgFor],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoImagesBlockComponent extends BasicThemedComponentDirective {
  @Input() data: IBlocTwoImages;

  constructor() {
    super();
  }
}
