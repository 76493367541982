<div class="container">
  <div class="spacing-block-text text-table">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 scrollablecontainer">
        <nscf-title
          [id]="'anchor_' + element.id"
          [selector]="element.isFirstTitle ? 'h1' : 'h2'"
          [title]="element.title"
          class="block-title text-center"
        ></nscf-title>
        <div class="text-tableau__table">
          <nscf-rte class="text-tableau__table--rte" [theme]="theme" [data]="element.text"></nscf-rte>
        </div>
        <nscf-cta-button
          *ngIf="element.ctaLabel"
          [theme]="theme"
          [btnClass]="'cta-primary big-btn'"
          [ctaUrl]="element.ctaUrl"
          [text]="element.ctaLabel"
          [class.tw-mt-4]="isNewArtisticDirectionTheme()"
        ></nscf-cta-button>
      </div>
    </div>
  </div>
</div>
