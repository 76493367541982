import { IBlockPresenter } from '../../presenter';
import { IBlocActuTheme, TypeNameBlockActuTheme } from '../../../components/categorie/actu-theme';

export class ActuThemePresenter implements IBlockPresenter {
  parse(data: any): IBlocActuTheme {
    if (!data) {
      return null;
    }
    return {
      __typename: TypeNameBlockActuTheme,
      title: data['actuThemeTitle'],
      chapo: data['actuThemeSummary'],
      taxonomy: data['actuTheme']?.tid + '',
    };
  }
}
