import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { IRteText } from '../../interfaces/rte-text';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';

@Component({
  selector: 'nscf-rte',
  templateUrl: 'rte.component.html',
  styleUrls: ['rte.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgTemplateOutlet, NgIf, EffyCommonModule, SafeHtmlPipe],
  hostDirectives: [DirectionArtisticDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'rte',
  },
})
export class RteComponent extends BasicThemedComponentDirective {
  @Input() data: IRteText;
  @Input() selector = 'div';
  @Input() toBeAppended: string[];

  constructor() {
    super();
  }
}
