import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CoverImageLazyLoadDirective } from '../../directives/cover-image-lazy-load/cover-image-lazy-load.directive';
import { LazyLoadMaterialDirective } from '../../directives/lazy-load-material/lazy-load-material.directive';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { StarComponent } from '../stars/star.component';
import { TitleComponent } from '../title/title.component';
import { IOpinions } from './opinions';

@Component({
  selector: 'nscf-opinions',
  templateUrl: 'opinions.component.html',
  styleUrls: ['opinions.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle, TitleComponent, LazyLoadMaterialDirective, NgFor, MatCardModule, StarComponent, CtaButtonComponent],
})
export class OpinionComponent extends CoverImageLazyLoadDirective implements OnInit {
  @Input() data: IOpinions;
  urlCover: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.setFieldImage(this.data.mediaCover);
  }
}
