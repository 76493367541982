import { isAllObjectPropertiesDefined } from '../../utils/object/object.utils';

export interface IFormParcoursOption {
  label: string;
  key: HousingTypeValues | HousingBuildValues;
  iconUrl?: string;
}

export interface IFormParcoursQuestion {
  title?: string;
  options: Array<IFormParcoursOption>;
  answer?: string | number;
  property: IParcoursProperties;
  hasBiggerGap?: boolean;
  isSmallOptinText?: boolean;
}

export interface IFormParcours {
  title: string;
  questions: Array<IFormParcoursQuestion>;
  redirectionUrl: string;
}

export enum IParcoursProperties {
  HOUSING_TYPE = 'housingType',
  HOUSING_AGE = 'housingAge',
}

export type IParamsFromQuestionAnswers = {
  [key in IParcoursProperties]?: string | number;
};

export interface IParamsFromQuestion {
  questionAnswers: IParamsFromQuestionAnswers;
  isValid?: boolean;
}

export function computeParamsFormQuestions<T extends IFormParcoursQuestion>(questions: T[]): IParamsFromQuestion | undefined {
  if (!questions) {
    return undefined;
  }
  const questionAnswers = questions.reduce((params: IParamsFromQuestionAnswers, question: T) => {
    params[question.property] = question.answer;
    return params;
  }, {});
  return {
    questionAnswers,
    isValid: isAllObjectPropertiesDefined(questionAnswers),
  };
}

export enum HousingTypeValues {
  Apartment = 'apartment',
  House = 'house',
}

export enum HousingBuildValues {
  LessThan2Year = 'less_than_2_years',
  Between2and15 = 'between_2_and_15_years',
  MoreThan15 = 'more_than_15_years',
}
