import { ROUNDED_AMOUNT } from '../../configs/default.config';
import { ICostEstimatorPrecarityLevel } from '../../models/cost-estimator.models';
import { PrecarityLevelApiResponseModel } from '../../models/precarity-level.models';
import { IncomeRange } from './work-estimate.component';
export namespace WorkEstimateUtils {
  export const computePrecarityLevel = (
    annualIncome: number,
    precarityLevels: PrecarityLevelApiResponseModel
  ): ICostEstimatorPrecarityLevel => {
    if (annualIncome < precarityLevels?.modest.min) {
      // Very modest
      return ICostEstimatorPrecarityLevel.VeryModest;
    }
    if (annualIncome > precarityLevels?.modest.max) {
      // Intermediate
      return annualIncome >= precarityLevels.high.min ? ICostEstimatorPrecarityLevel.HighIncome : ICostEstimatorPrecarityLevel.Intermediate;
    }
    return ICostEstimatorPrecarityLevel.Modest;
  };

  export const computeRange = (
    precarityLevels: PrecarityLevelApiResponseModel,
    valueFromUser?: number,
    padding: number = 0
  ): IncomeRange => {
    const { min, max } = WorkEstimateUtils.computeMinMax(precarityLevels, padding);
    let value = valueFromUser ?? (WorkEstimateUtils.round(WorkEstimateUtils.average(min, max)) || 0);
    if (value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }
    return {
      min,
      max,
      value,
    };
  };

  export const addCustomPaddingRange = (min: number, max: number, padding: number): { min: number; max: number } => ({
    min: min - padding,
    max: max + padding,
  });

  export const computeMinMax = (
    precarityLevels: PrecarityLevelApiResponseModel,
    padding: number
  ): { min: number; max: number } => {
    const min = WorkEstimateUtils.round(precarityLevels?.very_modest?.max) || 0;
    const max = WorkEstimateUtils.round(precarityLevels?.standard?.max) || 0;
    return addCustomPaddingRange(min, max, padding);
  };

  export const average = (n1: number, n2: number): number => (n1 + n2) / 2;

  export const round = (value: number): number => Math.floor(value / ROUNDED_AMOUNT) * ROUNDED_AMOUNT;
}
