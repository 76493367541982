import { LowerCasePipe, NgFor, NgIf } from '@angular/common';
import { Component, computed, input, Input } from '@angular/core';
import { DirectionArtisticDirective, EffyHashtagComponent } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';

@Component({
  selector: 'nscf-article-card-small',
  templateUrl: './article-card-small.component.html',
  styleUrls: ['./article-card-small.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, LowerCasePipe, EffyHashtagComponent],
  hostDirectives: [DirectionArtisticDirective]
})
export class ArticleCardSmallComponent extends BasicThemedComponentDirective {
  @Input() url = '';
  @Input() img = '';
  @Input() imgAlt = '';
  @Input() title = '';
  @Input() taxonomies: string[] = [];
  isNewArtisticDirection = input<boolean>(false);
}
