<div class="header-item" *ngIf="{ loadImages: loadImages$ | async } as context">
  <div *ngIf="item.type === ItemType.LIST" class="header-item__column">
    <div *ngFor="let subItem of item.subItems" class="header-item__column-item">
      <p *ngIf="subItem.title" class="header-item__column-item-title">
        <ng-container *ngIf="subItem.url; else titleWithoutLink">
          <a *ngIf="subItem.title" [href]="subItem.url">{{ subItem.title }}</a>
        </ng-container>
        <ng-template #titleWithoutLink>
          {{ subItem.title }}
        </ng-template>
      </p>
      <ng-container *ngIf="subItem.links">
        <div
          *ngFor="let linkSubItem of subItem.links"
          [ngClass]="{ 'no-cta': !item.ctaButton?.url }"
          class="header-item__column-wrapper-link"
        >
          <img *ngIf="linkSubItem.icon && context.loadImages" [src]="linkSubItem.icon" alt="img" class="header-item__column-item-img" />
          <a [href]="linkSubItem.url" class="header-item__column-item-link">
            {{ linkSubItem.label }}
          </a>
        </div>
      </ng-container>
    </div>
    @if (item.ctaButton) {
      @if (isNewArtisticDirection()) {
        <a effy-button class="tw-w-full" [size]="buttonSize">
          <span class="tw-whitespace-nowrap">{{ item.ctaButton?.label }}</span>
          <mat-icon [svgIcon]="'chevron-right'" class="tw-w-4 tw-flex tw-flex-shrink-0"></mat-icon>
        </a>
      } @else {
        <a class="header-item__cta" [href]="item.ctaButton.url"
          >{{ item.ctaButton?.label }}
          <img
            *ngIf="context.loadImages"
            src="../../../../../../assets/arrow/arrow-right-circle-blue.svg"
            alt="circle arrow"
            width="22"
            height="22"
        /></a>
      }
    }
  </div>
  <div *ngIf="item.type === ItemType.EMPTY" class="header-item__column header-item__column--empty"></div>
  <div *ngIf="item.type === ItemType.IMAGE" class="header-item__column">
    <a *ngIf="item?.link.url; else simpleImg" [href]="item?.link.url" class="header-item__column-img-link">
      <img *ngIf="item?.url && context.loadImages" [src]="item.url" class="header-item__column--img" alt="" />
    </a>
    <ng-template #simpleImg>
      <img *ngIf="item?.url && context.loadImages" [src]="item.url" class="header-item__column--img" alt="" />
    </ng-template>
  </div>
  @if (item.type === ItemType.IMAGE_WAVE) {
    @if (isNewArtisticDirection()) {
      <a [href]="item?.link.url" class="image-item">
        @if (item?.url && context.loadImages) {
          <img class="tw-absolute tw-object-cover tw-h-full tw-w-full tw-z-10" [src]="item.url" alt="" />
        }
        <span class="image-item-text">
          <span class="tw-font-family tw-font-600">{{ item?.link.label }}</span>
          <mat-icon [svgIcon]="'chevron-right'" class="tw-w-4 tw-flex tw-flex-shrink-0"></mat-icon>
        </span>
      </a>
    } @else {
      <div class="header-item__column header-item__column--img-wave">
        <a [href]="item?.link.url" class="header-item__column-img-link-wave">
          <div class="header-item__column-img-link-wrapper">
            <img *ngIf="item?.url && context.loadImages" [src]="item.url" class="header-item__column--img-cta" alt="" />
            <div class="header-item__column-img-wrapper">
              <div class="header-item__column-img-content">
                <nscf-wave class="header-item__column-img-wave" [theme]="theme"></nscf-wave>
                <div class="header-item__wrapper-link-wave">
                  <p class="header-item__column-img-text">
                    <span>
                      {{ item?.link.label }}
                    </span>
                  </p>
                  <img
                    *ngIf="context.loadImages"
                    width="22"
                    height="22"
                    class="header-item__column-img-arrow"
                    src="../../../../../../assets/arrow/arrow-right-circle-blue.svg"
                    alt="circle arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    }
  }
  
 
</div>
