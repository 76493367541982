import { AsyncPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, inject, input, Input, OnInit, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle, EffyIcons, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { Theme } from '@effy-tech/design-system';
import { distinctUntilChanged, map, share } from 'rxjs/operators';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';
import { DeviceManagerService, DeviceType } from '../../services/device-manager/device-manager.service';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { INewFooter } from './footer';
import { FooterService } from './footer.service';
import { LinkButtonComponent } from './link-button/link-button.component';

@Component({
  selector: 'nscf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    LinkButtonComponent,
    NgClass,
    NgFor,
    AsyncPipe,
    EffyCommonModule,
    SafeHtmlPipe,
    PreFooterComponent,
    MatIcon,
    EffyButtonComponent
  ],
  host: {
    '[class]': 'hostClasses()'
  }
})
export class FooterComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly footerService = inject(FooterService);
  protected readonly deviceManagerService = inject(DeviceManagerService);
  private readonly themeHelperService = inject(ThemeHelperService);
  private readonly themeSig = toSignal(this.themeHelperService.theme$);
  readonly isPro = signal(false);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly hostClasses = computed(() => `${this.isNewArtisticDirection() ? 'new-da' : 'old-da'} ${this.isPro() ? Theme.PRO : Theme.EFFY}`);
  readonly ctaStyle = computed(() => 
    this.isSolar() ? EffyButtonStyle.QUATERNARY :
    this.themeSig() === Theme.EFFY ? EffyButtonStyle.PRIMARY : 
    EffyButtonStyle.QUATERNARY
  );
  readonly siteLogo = computed<EffyIcons | 'site-logo'>(() => this.isPro() ? EffyIcons.PRO_ALT_LOGO : 'site-logo');
  readonly isSolar = input(false);
  readonly bannerTheme = computed(() => this.isPro() ? Theme.EFFY : Theme.PRO);
  readonly bannerButtonSize = computed(() => this.isDesktop() ? EffyButtonSize.LARGE : EffyButtonSize.MEDIUM);
  readonly isDesktop = toSignal(this.deviceManagerService.deviceType$().pipe(
    map((deviceType: DeviceType) => deviceType.isDesktop),
    distinctUntilChanged(),
    share()
  ));
  @Input() data = {
    isPro: false,
    showPreFooter: false,
  };
  content: INewFooter = {} as INewFooter;
  
  @Output() scrollSolar = new EventEmitter<void>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.content = this.footerService.getContent(this.data?.isPro),
    this.isPro.set(this.data?.isPro);
  }

  scrollToTop() {
    if (this.isSolar) {
      this.scrollSolar.emit();
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
