import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { forEach } from 'lodash-es';
import { Observable, asyncScheduler, empty, fromEvent } from 'rxjs';
import { map, share, throttleTime } from 'rxjs/operators';
import { SummaryScrollDirective } from '../../directives/summary-scroll';
import { IMessageScroll } from '../../interfaces/message-scroll';

@Injectable({
  providedIn: 'root',
})
export class SummaryScrollService {
  public scroll$: Observable<IMessageScroll>;
  private readonly HEADER_OFFSET = 80;

  constructor(@Inject(DOCUMENT) private readonly document: any, @Inject(PLATFORM_ID) private readonly platformId: Object) {
    const throttleConfig = {
      leading: false,
      trailing: true,
    };

    if (isPlatformBrowser(this.platformId)) {
      this.scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(300, asyncScheduler, throttleConfig),
        map(event => {
          const scrollValue = window.scrollY || this.document.documentElement.scrollTop;
          let currentIndex = 0;
          let currentValue = 1;

          const nodeList: NodeListOf<HTMLElement> = this.document.querySelectorAll(`.${SummaryScrollDirective.SUMMARY_SCROLLING_CLASS}`);
          const topElementPositions = [];

          // retrieve all top positions
          forEach(nodeList, (node: HTMLElement, index: number) => {
            const elementTop = node.getBoundingClientRect().top + scrollValue - this.HEADER_OFFSET - 20; // 20px for extra offset
            topElementPositions.push(elementTop);
            if (scrollValue > elementTop) {
              currentIndex = index;
            }
          });

          // break if there is no elements of scroll under the first item
          if (topElementPositions.length === 0 || scrollValue < topElementPositions[0]) {
            return {
              index: 0,
              value: 1,
            };
          }

          // calculate the next step
          const mainContent = this.document.querySelector('.article__main-content');
          const nextTopPosition =
            currentIndex >= topElementPositions.length - 1
              ? mainContent.getBoundingClientRect().top + scrollValue + mainContent.offsetHeight - this.HEADER_OFFSET
              : topElementPositions[currentIndex + 1];

          // calculate the percent of the completion
          currentValue = Math.round(
            ((scrollValue - topElementPositions[currentIndex]) * 100) / (nextTopPosition - topElementPositions[currentIndex])
          );

          return {
            index: currentIndex,
            value: Math.min(currentValue, 100), // avoid to overlap 100 percents
          };
        }),
        share()
      );
    } else {
      // in non-browser environments, provide an empty observable so you can safely subscribe to scroll$
      this.scroll$ = empty();
    }
  }
}
