import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { ILink } from '../../../interfaces/link';
import { IPosition } from '../../../interfaces/position';
import { IRteText } from '../../../interfaces/rte-text';
import { IMap } from '../../map/map';
import { IWidgetCalculator } from '../../widget-calculator/widget-calculator';
import { IVideoText } from './video-text/video-text';

export const TypeNameSubBlockTexts = 'ParagraphText';

export interface ISubBlockTexts {
  __typename: 'ParagraphText';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  text?: IRteText;
  image?: IFieldMediaImage;
  imageLocation?: IPosition;
  video?: IVideoText;
  videoLocation?: IPosition;
  ctaLabel?: string;
  ctaUrl?: ILink;
  map?: IMap;
  widgetCalculator?: IWidgetCalculator;
}

// @TODO : From there, this has been generated from data
export interface ISubBlockTextsBo {
  __typename: string;
  id: number;
  title: string;
  text: Text;
  image: ISubBlockTextsBoImage;
  location: IPosition;
  video: any;
  videoCover: any;
  caption: any;
  locationVideo: IPosition;
  widgetMPR: any;
  screenMPR: any;
  map: any;
  locationMap: any;
  region: any;
  buttonLabel: string;
  buttonUrl: ButtonURL;
}

export interface ButtonURL {
  url: URL;
  title: string;
  options: Options;
}

export interface Options {
  attributes: unknown[];
}

export interface URL {
  path: string;
}

export interface ISubBlockTextsBoImage {
  entity: Entity;
}

export interface Entity {
  fieldMediaImage: FieldMediaImage;
}

export interface FieldMediaImage {
  originalImage: string;
  width: number;
  height: number;
  alt: string;
  title: string;
  image: FieldMediaImageImage;
}

export interface FieldMediaImageImage {
  url: string;
  width: number;
  height: number;
}

export interface Text {
  value: string;
  format: string;
  processed: string;
}
