import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { IArticlePromo } from './article-promo';

@Component({
  selector: 'nscf-article-promo',
  templateUrl: './article-promo.component.html',
  styleUrls: ['./article-promo.component.scss'],
  standalone: true,
  imports: [NgClass, EffyCommonModule, SafeHtmlPipe, SafeResourceUrlPipe],
})
export class ArticlePromoComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() data: IArticlePromo;
  classTheme: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.classTheme = this.data?.site ?? this.theme;
  }
}
