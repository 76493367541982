import { NgIf } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, EffyButtonComponent, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { ILink } from '../../../interfaces/link';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';

@Component({
  selector: 'nscf-question-cta',
  templateUrl: 'question-cta.component.html',
  styleUrls: ['question-cta.component.scss'],
  standalone: true,
  imports: [NgIf, CtaButtonComponent, EffyButtonComponent, SafeResourceUrlPipe],
  hostDirectives: [DirectionArtisticDirective]
})
export class QuestionCtaComponent extends BasicThemedComponentDirective {
  @Input() data: { text: string; cta: ILink };
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);

  constructor() {
    super();
  }
}
