import { ICardsDiscoveryCategorie } from '../../../components/categorie/discovery-categorie/cards-discovery-categorie/cards-discovery-categorie';
import {
  IColorCardsDiscovery,
  IDiscoveryCategorie,
  TypeNameDiscoveryCategorie,
} from '../../../components/categorie/discovery-categorie/discovery-categorie';
import { IFieldMediaImageWithWebpBo } from '../../../interfaces/field-media-image';
import { IBlockPresenter } from '../../presenter';
import { MediaImagePresenter } from './../../commons/media-image.presenter';

interface IFieldMediaImageBo {
  alt: string;
  height: number;
  title: string;
  url: string;
  width: number;
}

interface ICoverBo {
  entity: {
    fieldMediaImage: IFieldMediaImageBo;
  };
}

export interface ICoverWithWepbBo {
  entity: {
    fieldMediaImage: IFieldMediaImageWithWebpBo;
  };
}

interface ICardDiscoveryCategorieBO {
  entity: {
    title: string;
    CTA: {
      entity: {
        entityUrl: {
          path: string;
        };

        cover?: ICoverBo;

        image?: {
          entity: {
            cover: ICoverBo;
          };
        }[];
      };
    };
  };
}

export interface IDiscoveryCategorieBO {
  title: string;
  chapo: string;
  style: string;
  cards: ICardDiscoveryCategorieBO;
  __typename: string;
}

export class BlockDiscoveryPresenter implements IBlockPresenter {
  parse(data: IDiscoveryCategorieBO): IDiscoveryCategorie {
    return <IDiscoveryCategorie>{
      __typename: TypeNameDiscoveryCategorie,
      title: data.title,
      chapo: data.chapo,
      color: data['style'] || IColorCardsDiscovery.BLUE,
      cards: mapCardsDiscovery(data['SubBlock']),
    };
  }
}

function findImageInDiscoveryCategoryBO(card: ICardDiscoveryCategorieBO): ICoverBo {
  return card?.entity?.CTA?.entity?.cover || card?.entity?.CTA?.entity?.image?.[0]?.entity?.cover;
}

function mapCardsDiscovery(data: Array<ICardDiscoveryCategorieBO>): ICardsDiscoveryCategorie {
  return data.map((card: ICardDiscoveryCategorieBO) => {
    return {
      title: card?.entity?.title,
      ctaUrl: card?.entity?.CTA?.entity?.entityUrl?.path,
      cover: MediaImagePresenter.getCoverWithWebp(findImageInDiscoveryCategoryBO(card)),
    };
  });
}
