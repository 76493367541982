import { flatten, forEach, filter as lodashFilter, map } from 'lodash-es';
import { VideoGenerator } from '../../classes/video-generator/video-generator';
import { DisplayValues, FilterValues, IMedias, TypeNameBlockMedia } from '../../components/medias/medias';
import {
  IMediasCardsArticle,
  IMediasCardsVideo,
  TypeNameMediaCardArticle,
  TypeNameMediaCardVideo,
} from '../../components/medias/medias-card';
import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { MediaFilePresenter } from '../commons/media-file.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockMediasPresenter implements IBlockPresenter {
  constructor() {}

  parse(data: any): IMedias {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const cards = this.getCards(data['cards']);
    const display = data['display'] || DisplayValues.CARROUSEL;

    return {
      __typename: TypeNameBlockMedia,
      id: data['id'],
      title: data['title'] || '',
      chapo: data['chap'],
      cards: cards,
      display: display,
      filters: display === DisplayValues.CARDIFICATION ? this.getFilters(cards) : [],
    };
  }

  private getFilters(cards: any[]): any[] {
    return [
      {
        title: 'Tous les médias',
        id: 'tous',
      },
      ...lodashFilter(
        map([...new Set(flatten(map(cards, 'type')))], filter => ({
          id: filter,
          title: FilterValues[filter],
        })),
        filter => filter.id
      ),
    ];
  }

  private getCards(data: any[]): Array<IMediasCardsArticle | IMediasCardsVideo> {
    const cards: Array<IMediasCardsArticle | IMediasCardsVideo> = [];
    forEach(data, (item: any) => {
      let mainObject = {
        description: RTEPresenter.getRTEText(item.entity.description),
        picto: MediaImagePresenter.getImage(item.entity.picto),
        name: item.entity.name || '',
        date: item.entity.date || '',
        id: item.entity.id || '',
        type: item.entity.type || [],
      };
      // Add specific cases for the different types
      switch (item.entity.__typename) {
        case TypeNameMediaCardArticle:
          mainObject = {
            ...mainObject,
            ...{
              __typename: TypeNameMediaCardArticle,
              pdf: MediaFilePresenter.getFile(item.entity.pdf),
              url: item.entity.url || '',
            },
          };
          break;
        case TypeNameMediaCardVideo:
          mainObject = {
            ...mainObject,
            ...{
              __typename: TypeNameMediaCardVideo,
              video: item.entity.video || '',
              videoCover: this.getVideoCover(item.entity.video, item.entity.videoCover),
            },
          };
          break;
        default:
          break;
      }

      cards.push(mainObject);
    });
    return cards;
  }

  private getVideoCover(video: string | null, videoCover: { entity: { fieldMediaImage: IFieldMediaImage } }): IFieldMediaImage | null {
    return (
      MediaImagePresenter.getImage(videoCover) ??
      (VideoGenerator.generateThumbnailUrl(video) ? { url: VideoGenerator.generateThumbnailUrl(video) } : null)
    );
  }
}
