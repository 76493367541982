import { ISubBlockPresenter } from '../presenter';
import { LinkPresenter } from '../commons/link.presenter';
import { ISubBlockCTA, TypeNameSubBlockCTA } from '../../components/block-text/sub-block-cta/sub-block-cta';

export class SubBlockCTAPresenter implements ISubBlockPresenter {
  parse(data: any): ISubBlockCTA {
    const subBlock: ISubBlockCTA = {
      __typename: TypeNameSubBlockCTA,
      id: data['id'],
      ctaLabel: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
      ctaConditions: data['CTACondition'],
      sticky: false,
    };
    return subBlock;
  }
}
