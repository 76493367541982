@if (isNewDirectionArtistic()) {
  @if (bigViewPort && data.mediaCover) {
    <img
      fetchPriority="high"
      [attr.width]="imageWidth"
      [src]="data.mediaCover.url"
      class="tw-absolute tw-top-0 smd:tw-max-h-[calc(100%+80px+52px)] tw-max-w-[45%] smd:tw-mt-[-80px] tw-right-0 tw-bottom-0 tw-object-cover"
    />
  }
  <div class="container">
    <div class="tw-relative smd:tw-w-[50%]">
      <nscf-title class="title" selector="h1" [title]="data.title" [id]="'anchor_' + data.id" />
      @if (data.enableTrustPilot) {
        <nscf-trustpilot-micro-star class="tw-inline-block tw-mb-[24px] tw-max-w-[273px]" />
      }
      <nscf-rte class="tw-block tw-mb-[48px]" [data]="!bigViewPort && data.descriptionMobile ? data.descriptionMobile : data.description" />
      @if (data.ctaUrl) {
        <nscf-cta-button centered class="cta-button tw-w-auto tw-mb-[32px]" [text]="data.buttonText" [ctaUrl]="data.ctaUrl" />
      }
      @if (data.termsTitle) {
        <p class="tw-text-new-xs tw-font-300 tw-text-center tw-mt-[-24px] tw-mb-[24px]">{{ data.termsTitle }}</p>
      }
      @if (data.textUnderCta) {
        <nscf-rte class="tw-mb-[12px]" [data]="data.textUnderCta" />
      }
    </div>
    @if (data.cardsArguments) {
      <nscf-cards class="tw-relative tw-pt-[24px]" [data]="data.cardsArguments" />
    }
  </div>
} @else {
  <div class="spacing-block">
    <div class="new-top-page-card__position" [class.no-cards]="!(data.cardsArguments && data.cardsArguments.length)">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-md-6 align-self-start new-top-page-card__position-of-cards"
            [ngClass]="data.cardsArguments && data.cardsArguments.length ? '' : 'new-top-page-card__position-of-cards--without-card'"
          >
            <div class="d-flex align-items-center">
              <div class="new-top-page-card__block">
                <nscf-title [selector]="'h1'" [title]="data.title" [id]="'anchor_' + data.id"></nscf-title>
                <div id="top-of-site-pixel-anchor"></div>

                <div *ngIf="isOverMobile; then desktopContent; else mobileContent"></div>
                <ng-template #desktopContent>
                  <nscf-rte [theme]="theme" class="new-top-page-card__description" [data]="data.description"></nscf-rte>
                </ng-template>

                <ng-template #mobileContent>
                  <nscf-rte
                    *ngIf="data?.descriptionMobile"
                    [theme]="theme"
                    class="new-top-page-card__description"
                    [data]="data?.descriptionMobile"
                  ></nscf-rte>
                  <nscf-rte
                    *ngIf="!data?.descriptionMobile"
                    [theme]="theme"
                    class="new-top-page-card__description"
                    [data]="data?.description"
                  ></nscf-rte>
                </ng-template>
                <div appCtaAction></div>
                <nscf-cta-button
                  *ngIf="data.ctaUrl"
                  [theme]="theme"
                  class="new-top-page-card__cta cta-button"
                  [btnClass]="'cta-primary big-btn full-width'"
                  [text]="data.buttonText"
                  [ctaUrl]="data.ctaUrl"
                >
                </nscf-cta-button>
                <nscf-trustpilot-micro-star *ngIf="data.enableTrustPilot"></nscf-trustpilot-micro-star>
                <div class="text-center new-top-page-card__legal-notice">
                  {{ data.termsTitle }}
                </div>
                <nscf-rte
                  *ngIf="data.textUnderCta"
                  [theme]="theme"
                  class="new-top-page-card__description"
                  [data]="data.textUnderCta"
                ></nscf-rte>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 new-top-page-card__background" [ngStyle]="urlCover" #cover></div>
        </div>
        <div *ngIf="data.cardsArguments" class="row">
          <div class="new-top-page-card__wrapper-card">
            <nscf-cards [data]="data.cardsArguments"></nscf-cards>
          </div>
        </div>
      </div>
    </div>
  </div>
}
