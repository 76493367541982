export const QUERIES_ID = 'QUERIES_ID';

export interface IQueriesId {
  HEADING_ARTICLE: number;
  HEADING_QUESTIONS: number;
  CARDIFICATION_NHP: number;
  CARDIFICATION_MERGE: number;
  CARDIFICATION_THEME: number;
  CARDIFICATION_MONO: number;
  CARDIFICATION_MONO_PROMO: number;
  CARDIFICATION_MULTI_PROMO: number;
  CARDIFICATION: number;
  GETPAGE: number;
  GETMENU: number;
  PREVIEW: number;
  VIDEO: number;
  THEME: number;
  CARDS_MEDIA?: number;
  ARTICLE_ACTU?: number;
  HEADER?: number;
}
