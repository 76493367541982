import { NgClass, NgIf } from '@angular/common';
import { Component, inject, input, Input, OnInit, SecurityContext } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { ICtaLink } from './cta-button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'nscf-header-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, EffyCommonModule, EffyButtonComponent, MatIcon],
})
export class CtaButtonComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly sanitizer = inject(DomSanitizer);
  private readonly themeHelperService = inject(ThemeHelperService);

  @Input() ctaLink: ICtaLink;
  @Input() btnClass = '';
  @Input() btnIcon: string;
  readonly showChevronIcon = input(false);
  readonly buttonStyle = input<EffyButtonStyle>(EffyButtonStyle.PRIMARY);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly EffyButtonSize = EffyButtonSize;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // @TODO : Handle it better
    if (this.theme === 'lpe' && this.btnClass !== '') {
      // Use cta-secondary instead of cta-primary
      this.btnClass = this.btnClass.replace('cta-primary', 'cta-secondary');
    }
    if (this.ctaLink?.url) {
      this.ctaLink.url = this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.sanitizer.bypassSecurityTrustResourceUrl(this.ctaLink.url)
      );
    }
    if (this.ctaLink?.title) {
      this.ctaLink.title = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.ctaLink.title));
    }
  }
}
