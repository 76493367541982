import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { ILink } from '../../../interfaces/link';

export const TypeNameSubBlockImage = 'ParagraphImage';

export interface ISubBlockImage {
  __typename: 'ParagraphImage';
  id?: number;
  title?: string;
  image?: IFieldMediaImage;
  legend?: string;
  ctaLabel?: string;
  ctaUrl?: ILink;
  isFirstTitle?: boolean;
}
