import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService } from '../../modules/config/config.service';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';

@Component({
  selector: 'nscf-video-play-background',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './video-play-background.component.html',
  styleUrl: './video-play-background.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayBackgroundComponent {
  private readonly deviceManagerService = inject(DeviceManagerService);
  private readonly isDesktop = toSignal(this.deviceManagerService.isBigDesktop());
  protected readonly size = computed(() => this.isDesktop() ? '124' : '64');
  private readonly theme = inject(ConfigService).getTheme();
  protected readonly src = computed(() => `assets/backgrounds/icon-play/icon-play-${this.theme}.svg`);
}
