import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { ILink } from '../../../interfaces/link';

export const TypeNameSubBlockVideos = 'ParagraphVideos';

export interface ISubBlockVideos {
  __typename: 'ParagraphVideos';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  video?: string;
  cover?: IFieldMediaImage;
  legend?: string;
  ctaLabel?: string;
  ctaUrl?: ILink;
  image?: { mobile: string; desktop: string; alt: string };
}
