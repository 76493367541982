import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { IRteText } from '../../interfaces/rte-text';
import { ILink } from '../../interfaces/link';

export const TypeNameBlockSuperTopPage = 'ParagraphSuperTopPage';
export const ISuperTopPageMaxScore = 5;

export interface ISuperTopPage {
  __typename: 'ParagraphSuperTopPage';
  id?: number;
  title?: string; // Titre
  heading?: IRteText; // Chapô
  mediaCover?: IFieldMediaImage; // Cover
  enableTrustPilot: boolean;
  ctaLabel?: string; // CTA - Bouton
  ctaUrl?: ILink; // CTA - URL
  formTitle?: string; // Formulaire - Titre
  formDescription?: string; // Formulaire - Description
  formCards?: Array<IFormSTPCard>; // Formulaire - Cards
}

export interface IFormSTPCard {
  picto?: IFieldMediaImage; // Card - Picto
  title?: string; // Card - Titre
  url?: ILink; // Card - URL
}

export enum AnimationPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
}
