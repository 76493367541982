import { NgIf } from '@angular/common';
import { Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { MenuComponent } from '../menu/header-menu.component';
import { ILinkNavbar } from '../navbar/navbar';

@Component({
    selector: 'nscf-header-link',
    templateUrl: 'link.component.html',
    styleUrls: ['link.component.scss'],
    standalone: true,
    imports: [NgIf, MenuComponent, MatIcon],
    host: {
      '[class]': 'hostClasses()'
    }
})
export class LinkComponent extends BasicThemedComponentDirective {
  @Input() link: ILinkNavbar;
  @Input() index: number;
  @Input() isDesktop: boolean;
  @Output() checked: EventEmitter<boolean> = new EventEmitter();
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly hostClasses = computed(() => this.isNewArtisticDirection() ? 'new-da' : 'old-da');

  constructor() {
    super();
  }

  onInputChecked(): void {
    this.checked.emit(true);
  }

  onGoBack(): void {
    this.checked.emit(false);
  }
}
