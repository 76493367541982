import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { EffyButtonComponent, EffyButtonSize, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LoadImagesService } from '../../services/load-images.service.ts/load-images.service';
import { WaveComponent } from '../wave/wave.component';
import { IItem, ItemType } from './item';

@Component({
    selector: 'nscf-header-item',
    templateUrl: 'item.component.html',
    styleUrls: ['item.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, WaveComponent, AsyncPipe, EffyButtonComponent, MatIcon],
    host: {
      '[class]': 'hostClasses()'
    }
})
export class ItemComponent extends BasicThemedComponentDirective {
  private readonly loadImagesService = inject(LoadImagesService);
  private readonly themeHelperService = inject(ThemeHelperService);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly buttonSize = EffyButtonSize.MEDIUM;
  readonly hostClasses = computed(() => this.isNewArtisticDirection() ? 'new-da' : 'old-da');

  ItemType = ItemType;
  loadImages$: Observable<boolean> = this.loadImagesService.loadImages$.pipe(
    filter(loadImages => loadImages),
    take(1),
  );
  @Input() item: IItem;
  
  constructor() {
    super();
  }
}
