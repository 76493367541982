import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { IFieldMediaFile } from '../../interfaces/field-media-file';
import { IRteText } from '../../interfaces/rte-text';

export const TypeNameMediaCardArticle = 'ParagraphCardsArticles';
export const TypeNameMediaCardVideo = 'ParagraphCardsVideos';

export interface IMediasCard {
  description?: IRteText;
  picto?: IFieldMediaImage;
  name?: string;
  date?: string;
}

export interface IMediasCardsVideo extends IMediasCard {
  __typename?: 'ParagraphCardsVideos';
  video?: string;
  videoCover?: IFieldMediaImage;
}

export interface IMediasCardsArticle extends IMediasCard {
  __typename?: 'ParagraphCardsArticles';
  pdf?: IFieldMediaFile;
  url?: string;
}
