<div class="pre-footer__container">
  <div class="pre-footer__texts-container">
    <div class="pre-footer__title-container">
      <span class="pre-footer__animation-container">Effy <span class="pre-footer__animation-1">réalise vos travaux</span></span>
      <span class="pre-footer__animation-container">Effy <span class="pre-footer__animation-2">améliore votre confort</span></span>
      <span class="pre-footer__animation-container">Effy <span class="pre-footer__animation-3">réduit vos factures d’énergie</span></span>
      <span class="pre-footer__animation-container">Effy <span class="pre-footer__animation-4">valorise votre logement</span></span>
    </div>
    <div>
      <div class="pre-footer__argument-container">
        <div class="pre-footer__argument">
          <span class="pre-footer__argument-bold">+15 ans </span>
          <p>d'expérience</p>
        </div>
        <div class="pre-footer__argument">
          <span class="pre-footer__argument-bold">+1 million </span>
          <p>de foyers accompagnés</p>
        </div>
      </div>
    </div>
    @if (isNewArtisticDirectionTheme() === false) {
      <span class="liseret"></span>
    }
  </div>
  @if (isNewArtisticDirectionTheme() === false) {
    <div class="pre-footer__illu-background">
      <img class="tw-ml-[-160px]" src="assets/backgrounds/illu_casa_left.svg" alt="" />
      <img class="tw-mr-[-160px]" src="assets/backgrounds/illu_casa_right.svg" alt="" />
    </div>
  }
</div>
