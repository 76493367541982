import { Injectable } from '@angular/core';
import { GtmEvent } from '../../interfaces/gtm';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  pushEvent(event: GtmEvent): void {
    globalThis.dataLayer.push(event);
  }
}
