import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Input, input } from '@angular/core';
import { toSignal } from "@angular/core/rxjs-interop";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from "@angular/material/icon";
import {
  DirectionArtisticDirective,
  EffyLinkComponent,
  EffyLinkSize,
  EffyLinkStyle,
  ThemeHelperService
} from "@effy-tech/angular-common";
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { LazyLoadMaterialDirective } from '../../../directives/lazy-load-material/lazy-load-material.directive';
import { IRelatedItem } from '../../../interfaces/pages';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';

@Component({
    selector: 'nscf-article-related',
    templateUrl: 'related.component.html',
    styleUrls: ['related.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgFor, LazyLoadMaterialDirective, MatExpansionModule, EffyCommonModule, SafeResourceUrlPipe, NgClass, MatIconModule, NgIf, EffyLinkComponent],
    hostDirectives: [DirectionArtisticDirective],
    host: {
      '[class]': 'hostClasses()',
    },
})
export class ArticleRelatedComponent extends BasicThemedComponentDirective {
  @Input()  data :IRelatedItem;
  readonly bigViewPort = input<boolean>(false);
  readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly size = computed(() => this.bigViewPort ? EffyLinkSize.MEDIUM : EffyLinkSize.SMALL);
  protected readonly hostClasses = computed(() => `effy-related-${this.size()}`);
  protected readonly EffyLinkStyle = EffyLinkStyle;
}
