import {Component, Input} from '@angular/core';
import {NgIf} from '@angular/common';
import { IMap } from "../../map/map";
import { RegionPipe } from "../../../pipes/region/region.pipe";
import {MapComponent} from '../../map/map.component';
import {BasicThemedComponentDirective} from "@effy-tech/common";

export const TypeNameMapBlock = 'mapBlockComponent';

@Component({
    selector: 'nscf-map-block',
    standalone: true,
    imports: [NgIf, MapComponent, RegionPipe],
    templateUrl: './map-block.component.html',
    styleUrls: ['./map-block.component.scss']
})
export class MapBlockComponent extends BasicThemedComponentDirective {

    @Input() map: IMap;

}
