<svg width="16" height="16" id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
  <defs>
    <style>
      .cls-2 {
        opacity: 0;
      }
    </style>
  </defs>
  <g id="Page-1">
    <g id="Star_fill" data-name="Star fill">
      <path
        id="border"
        [attr.fill]="color"
        d="M8,3.63,6.5,6.92l-3.18.32L5.6,9.52,5,13.08l3-1.79,3,1.79-.59-3.55,2.25-2.29L9.5,7ZM8,0l2.5,5.5L16,6l-4,4,1,6L8,13,3,16l1-6L0,6l5.5-.5Z"
      />
      <polygon
        id="fill_10"
        data-name="fill 10"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 10)"
        points="8 2.63 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 8.01 12.29 11.01 14.08 11.42 9.53 13.67 7.24 9.5 5.96 8 2.63"
      />
      <polygon
        id="fill_9"
        data-name="fill 9"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 9)"
        points="8 2.63 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 8.01 12.29 11.01 14.08 11.42 9.53 12.01 8.92 12.01 6.18 9.5 5.96 8 2.63"
      />
      <polygon
        id="fill_8"
        data-name="fill 8"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 8)"
        points="8 2.63 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 8.01 12.29 11.01 14.08 11.42 9.53 11.03 9.91 11.02 6.09 9.5 5.96 8 2.63"
      />
      <polygon
        id="fill_7"
        data-name="fill 7"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 7)"
        points="8 2.63 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 8.01 12.29 10.01 13.48 10.01 6 9.5 5.96 8 2.63"
      />
      <polygon
        id="fill_6"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 6)"
        points="8 2.63 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 8.01 12.29 9 12.88 8.99 4.84 8 2.63"
      />
      <polygon
        id="fill_5"
        data-name="fill 5"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 5)"
        points="8 2.63 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 8.01 12.29 8 2.63"
      />
      <polygon
        id="fill_4"
        data-name="fill 4"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 4)"
        points="7.01 4.8 6.5 5.92 3.32 7.24 4.6 9.52 5.01 14.08 7.02 12.88 7.01 4.8"
      />
      <polygon
        id="fill_3"
        data-name="fill 3"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 3)"
        points="6.03 5.97 3.32 7.24 4.6 9.52 4.01 13.08 6.02 13.48 6.03 5.97"
      />
      <polygon
        id="fill_2"
        data-name="fill 2"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 2)"
        points="5 6.07 3.32 7.24 4.99 9.91 5 6.07"
      />
      <polygon
        id="fill_1"
        data-name="fill 1"
        [attr.fill]="color"
        [attr.opacity]="getOpacity(offset, 1)"
        points="4 6.17 2.32 7.24 3.98 8.9 4 6.17"
      />
    </g>
  </g>
</svg>
