import { ITopPageCarousel } from './top-page-carousel';
import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { ILink } from '../../interfaces/link';

export const TypeNameBlockTopPage = 'ParagraphTopPage';

export interface ITopPage {
  __typename: 'ParagraphTopPage';
  id?: number;
  title?: string;
  enableTrustPilot: boolean;
  subtitle?: string; // chapo
  buttonText?: string; // CTA-Bouton
  ctaUrl?: ILink;
  termsTitle?: string; // CTA-Conditions générales
  carouselTitle?: string;
  carousel?: Array<ITopPageCarousel>;
  video?: string;
  mediaCover?: IFieldMediaImage;
}
