import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { WIDGET_CALCULATOR_API_KEY } from '../../configs/injection-tokens.config';
import { WORKS_SPECIFIC_ESTIMATION_LABEL } from '../../configs/works.config';
import { ICostEstimatorBodyRequestAPI, ICostEstimatorPrecarityLevel, ICostEstimatorResponseAPI } from '../../models/cost-estimator.models';
import { LocationValues } from '../../values/location.values';
import { WORK_VALUES, WorksValues } from '../../values/works.values';
import { AmountService } from '../amount/amount.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CostEstimatorService {
  private readonly apiKey = inject(WIDGET_CALCULATOR_API_KEY);
  private readonly amountService = inject(AmountService);
  private readonly configService = inject(ConfigService);
  private readonly httpClient = inject(HttpClient);

  private getUrl(workType: WorksValues): string {
    const URL_BY_WORK_MAPPER: { [key in WorksValues]: string } = {
      attic_insulation: 'attics_insulation',
      air_water_heat_pump: 'air_water_heat_pump',
      combined_solar_system: 'combined_solar_system',
      double_glazing: 'double_glazing',
      geothermal_heat_pump: 'geothermal_heat_pump',
      pellet_boiler: 'wood_boiler',
      pellet_stove: 'wood_stove',
      roof_terrace: 'roof_terrace_insulation',
      thermodynamic_water_heater: 'thermodynamic_water_heater',
      wall_insulation: 'wall_insulation',
      wood_boiler: 'wood_boiler',
      wood_pellet_boiler: 'wood_boiler',
      wood_stove: 'wood_stove'
    };

    let baseUrl = new URL(`${this.configService.getConfig().api.costEstimator}estimate/${URL_BY_WORK_MAPPER[workType]}`);
    baseUrl.searchParams.append('key', this.apiKey);
    return baseUrl.toString();
  }

  getEstimate$(workType: WorksValues, precarityLevel: ICostEstimatorPrecarityLevel, location: LocationValues): Observable<string> {
    return this.httpClient
      .post<ICostEstimatorResponseAPI>(this.getUrl(workType), this.computeEstimateBody(precarityLevel, workType, location))
      .pipe(
        map(res => WORKS_SPECIFIC_ESTIMATION_LABEL(this.amountService.formatAmount(res.offre_liberte.premiums.renov), workType)),
        catchError(() => of(null))
      );
  }

  private computeEstimateBody(
    precarityLevel: ICostEstimatorPrecarityLevel,
    workType: WorksValues,
    location: LocationValues
  ): ICostEstimatorBodyRequestAPI {
    let specificWorkBody = {};
    
    switch(workType) {
      case WORK_VALUES.AtticInsulation:
        specificWorkBody = { attics_type: 'outdoor', insulating_surface: 1 };
        break;
      case WORK_VALUES.AirWaterHeatPump:
        specificWorkBody = { with_ecs: true };
        break;
      case WORK_VALUES.DoubleGlazing:
        specificWorkBody = { window_material: 'mixed', windows_count: 1, existing_glazing_material: true };
        break;
      case WORK_VALUES.PelletBoiler:
        specificWorkBody = { boiler_type: 'pellet' };
        break;
      case WORK_VALUES.PelletStove:
        specificWorkBody = { stove_type: 'pellet' };
        break;
      case WORK_VALUES.RoofTerrace:
        specificWorkBody = { insulating_surface: 1 };
        break;
      case WORK_VALUES.WallInsulation:
        specificWorkBody = { wall_insulation_type: 'coated', insulating_surface: 1 };
        break;
      case WORK_VALUES.WoodBoiler:
        specificWorkBody = { boiler_type: 'wood' };
        break;
      case WORK_VALUES.WoodPelletBoiler:
        specificWorkBody = { boiler_type: 'wood' };
        break;
      case WORK_VALUES.WoodStove:
        specificWorkBody = { stove_type: 'wood' };
        break;
    }

    return {
      zip_code: location === LocationValues.IleDeFrance ? '75015' : '61000',
      precarity_level: precarityLevel,
      owner_status: 'resident_owner',
      housing_age: 'more_than_15_years',
      ...specificWorkBody,
    };
  }
}
