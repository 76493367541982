import {
  IItem,
  IItemEmpty,
  IItemImage,
  IItemList,
  ILevel1Bo,
  ILevel2Bo,
  ILevel3Bo,
  ILinkNavbar,
  IMenuBo,
  INavbar,
  ISubItemsItemList,
  ISubLevelBaseBo,
  Ilink,
  ItemType,
  cardImageTypes,
} from '@effy-tech/acquisition-header';
import { ImagesService } from '../../../services/images-service/images.service';

export class HeaderMenuPresenter {
  mapApiMenuToView(menu: IMenuBo): INavbar {
    return {
      links: this.getLinks(menu),
    };
  }

  private getItems(level: ILevel1Bo): IItem[] {
    const hasEmptyColumn = (level: ILevel2Bo): boolean => !level.level3.length && level.isLinkHidden;
    const has4Levels = (level: ILevel3Bo): boolean => !!level.level4.length;
    const filterCtaFromLevel3 = (level3: ILevel3Bo[]): ILevel3Bo[] => level3.filter((lvl: ILevel3Bo) => !lvl.isLinkCTA);
    const getCtaLinkLevel = (level3: ILevel3Bo[]): ILevel3Bo => level3.find((lvl: ILevel3Bo) => lvl.isLinkCTA);
    const getItemList = (level: ILevel2Bo): IItemList => {
      const cteLvlButton = getCtaLinkLevel(level.level3);
      const itemList: ISubItemsItemList = level.level3.some(lvl => has4Levels(lvl))
        ? getSubItemsItemListLevel4(level)
        : getSubItemsItemListLevel3(level);
      return {
        ...itemList,
        type: ItemType.LIST,
        ...(cteLvlButton && {
          ctaButton: {
            url: cteLvlButton?.url?.path,
            label: cteLvlButton?.title,
          },
        }),
      };
    };

    const getSubItemsItemListLevel4 = (level: ILevel2Bo): ISubItemsItemList => ({
      subItems: filterCtaFromLevel3(level.level3).map(lvl3 => ({
        title: lvl3.title,
        url: lvl3.url?.path,
        links: lvl3.level4.map(lvl4 => this.getLinksMenu(lvl4)),
      })),
    });

    const getSubItemsItemListLevel3 = (level: ILevel2Bo): ISubItemsItemList => ({
      subItems: [
        {
          title: this.getLabel(level),
          url: level.url?.path,
          links: filterCtaFromLevel3(level.level3).map((lvl: ILevel3Bo) => this.getLinksMenu(lvl)),
        },
      ],
    });
    const getImageCard = (level: ILevel2Bo) => {
      return <IItemImage>{
        type: level.cardImageType === cardImageTypes.Simple ? ItemType.IMAGE : ItemType.IMAGE_WAVE,
        link: {
          url: level.url?.path,
          label: level.title,
        },
        ...level.cardImage,
        url: ImagesService.rewriteImagesUrl(level.cardImage?.url),
      };
    };
    return level.level2.map((level: ILevel2Bo) => {
      if (hasEmptyColumn(level)) return <IItemEmpty>{ type: ItemType.EMPTY };
      if (level.isCardImage) return getImageCard(level);
      return getItemList(level);
    });
  }

  private getLabel(level: ISubLevelBaseBo): string {
    return level.isLinkHidden ? '' : level.title;
  }

  private getLinksMenu(level: ISubLevelBaseBo): Ilink {
    return {
      url: level.url.path,
      ...(level.picto?.alt && { alt: level.picto?.alt }),
      label: this.getLabel(level),
      ...(level.picto?.url && { icon: ImagesService.rewriteImagesUrl(level.picto?.url) }),
    };
  }

  private getLinks(menu: IMenuBo): ILinkNavbar[] {
    return menu?.data?.menu?.level1.map((level: ILevel1Bo) => ({
      url: level.url.path,
      label: level.title,
      menu: {
        list: {
          items: this.getItems(level),
        },
      },
    }));
  }
}
