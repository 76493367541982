import { Component, input, Input } from '@angular/core';
import { NewsCardMode } from './news-card';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { ICardSummary } from '../../interfaces/cards-summary';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';
import { FrenchDatePipe } from '../../pipes/frensh-pipe/french-date.pipe';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { DirectionArtisticDirective, EffyHashtagComponent, EffyHashtagSize, EffyHashtagStyle } from '@effy-tech/angular-common';

@Component({
    selector: 'nscf-news-card',
    templateUrl: 'news-card.component.html',
    styleUrls: ['news-card.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgFor, EffyCommonModule, FrenchDatePipe, SafeHtmlPipe, EffyHashtagComponent],
    hostDirectives: [DirectionArtisticDirective],
})
export class NewsCardComponent extends BasicThemedComponentDirective {
  @Input() isSticky: boolean;
  @Input() data: ICardSummary = {} as ICardSummary;
  @Input() isDesktop: boolean;
  @Input() isHeadCard: boolean;
  @Input() mode: NewsCardMode;
  newsCardMode = NewsCardMode;
  isNewDA = input<boolean>(false);

  hashtagSize = EffyHashtagSize.MEDIUM;
  hashtagColorHeadline = EffyHashtagStyle.SECONDARY;
}
