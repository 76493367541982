export const SOCIAL_NETWORK_VALUES = {
  Facebook: 'facebook',
  FacebookPro: 'facebookPro',
  Twitter: 'twitter',
  YouTube: 'youTube',
  Linkedin: 'linkedin',
  Instagram: 'instagram',
} as const;

export type SocialNetworkValue = (typeof SOCIAL_NETWORK_VALUES)[keyof typeof SOCIAL_NETWORK_VALUES];

export const SOCIAL_NETWORK_LABELS = {
  facebook: 'facebook',
  facebookPro: 'facebook',
  twitter: 'twitter',
  youTube: 'youtube',
  linkedin: 'linkedin',
  instagram: 'instagram',
} as const;

export const SOCIAL_NETWORK_LINKS = {
  facebook: 'https://www.facebook.com/effygroupe/',
  facebookPro: 'https://www.facebook.com/EffyPartenaires',
  x: 'https://x.com/EffyGroupe',
  youTube: 'https://www.youtube.com/channel/UC6AOIl7ICHej0v31o46majw/videos',
  linkedin: 'https://www.linkedin.com/company/effy/',
  instagram: 'https://www.instagram.com/effy_groupe/',
} as const;
