import { Directive, ViewContainerRef } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { LoggerService } from '@effy-tech/common/loggers';
import { find, findIndex, findLastIndex, forEach } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ArticlePdfBlocComponent } from '../components/article-blocs';
import { TypeNameBlockArticlePDFBloc } from '../components/article-blocs/article-pdf/article-pdf';
import { TypeNameBlockTexts } from '../components/block-text/block-text';
import { BlockTextComponent } from '../components/block-text/block-text.component';
import { FocusBusinessComponent } from '../components/block-text/focus-business';
import { TypeNameSubBlockFocusBusiness } from '../components/block-text/focus-business/focus-business';
import { SubBlockCtaComponent } from '../components/block-text/sub-block-cta/sub-block-cta.component';
import { IBreadCrumbName } from '../components/breadcrumb/breadcrumb';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { CalendlyComponent } from '../components/calendly/calendly.component';
import { ActuThemeComponent, TypeNameBlockActuTheme } from '../components/categorie/actu-theme';
import { DiscoveryCategorieComponent } from '../components/categorie/discovery-categorie/discovery-categorie.component';
import { FocusCategorieComponent } from '../components/categorie/focus-categorie/focus-categorie.component';
import { CtaStickyComponent } from '../components/cta-sticky/cta-sticky.component';
import { EligibleRenovationsComponent } from '../components/eligible-renovations/eligible-renovations.component';
import { TypeNameBlockFaq } from '../components/faq/faq';
import { FaqComponent } from '../components/faq/faq.component';
import { InBriefComponent } from '../components/in-brief/in-brief.component';
import { TypeNameBlockMedia } from '../components/medias/medias';
import { MediasComponent } from '../components/medias/medias.component';
import { CardsComponent } from '../components/new-top-page-card/cards';
import { NewTopPageCardComponent } from '../components/new-top-page-card/new-top-page-card.component';
import { TypeNameBlocNewsletter } from '../components/newsletter/newsletter';
import { NewsletterComponent } from '../components/newsletter/newsletter.component';
import { TypeNameBlockNumber } from '../components/numbers/numbers';
import { NumbersComponent } from '../components/numbers/numbers.component';
import { TypeNameBlockOpinions } from '../components/opinions/opinions';
import { OpinionComponent } from '../components/opinions/opinions.component';
import { TypeNameBlockSuperTopPage } from '../components/super-top-page/super-top-page';
import { SuperTopPageComponent } from '../components/super-top-page/super-top-page.component';
import { ThemeComponent } from '../components/theme/theme.component';
import { TopPageMosaicComponent } from '../components/top-page-mosaic/top-page-mosaic.component';
import { TypeNameBlocTopPageForm } from '../components/top-page-parcours/top-page-parcours';
import { TopPageParcoursComponent } from '../components/top-page-parcours/top-page-parcours.component';
import { TypeNameBlockTopPage } from '../components/top-page/top-page';
import { TopPageComponent } from '../components/top-page/top-page.component';
import { TrustpilotCarouselComponent } from '../components/trustpilot-carousel/trustpilot-carousel.component';
import { TwoImagesBlockComponent } from '../components/two-images-block/two-images-block.component';
import { VerifiedReviewsContainerComponent } from '../components/verified-reviews-container/verified-reviews-container.component';
import { TypeNameSubBlockCardsSummary } from '../interfaces/cards-summary';
import { DynamicComponentData, Extra } from '../interfaces/dynamic-types';
import { TypeNameBlockEligibleRenovations } from '../interfaces/eligible-renovations';
import { TypeNameBlockNewTopPage } from '../interfaces/new-top-page';
import { PagesName, TypeNamePageArticle, TypeNamePageManualHub } from '../interfaces/pages';
import { DeviceManagerService, DeviceType } from '../services/device-manager/device-manager.service';

@Directive()
export abstract class DynamicPageDirective extends BasicThemedComponentDirective {
  firstSetted = false;
  bigViewPort: boolean;
  isDesktop: boolean;
  ngUnsubscribe = new Subject<void>();

  protected constructor(protected readonly logger: LoggerService, protected readonly deviceManagerService: DeviceManagerService) {
    super();
  }

  abstract buildPage(): void;

  abstract clearPage(): void;

  fieldBlocksAsDynamicComponentData(fieldBlock: any, caller: PagesName = null): DynamicComponentData[] {
    const dynamicComponentsData: DynamicComponentData[] = [];
    const blocksMapping = {
      ParagraphTopPage: TopPageComponent,
      ParagraphSuperTopPage: SuperTopPageComponent,
      ParagraphNewTopPage: NewTopPageCardComponent,
      ParagraphCards: CardsComponent,
      ParagraphChiffres: NumbersComponent,
      ParagraphWorks: EligibleRenovationsComponent,
      ParagraphFaq: FaqComponent,
      ParagraphOpinion: OpinionComponent,
      ParagraphBlocTexte: BlockTextComponent,
      ParagraphMedias: MediasComponent,
      ParagraphCta: SubBlockCtaComponent,
      ParagraphFocusBusiness: FocusBusinessComponent,
      ParagraphBreadcrumbComponent: BreadcrumbComponent,
      ParagraphBlocAvisVerifie: VerifiedReviewsContainerComponent,
      ParagraphTheme: ThemeComponent,
      ParagraphBlockFocus: FocusCategorieComponent,
      ParagraphBlocDecouverte: DiscoveryCategorieComponent,
      ParagraphActuThemeBlock: ActuThemeComponent,
      ParagraphNewsletterBlock: NewsletterComponent,
      ParagraphTopPageForm: TopPageParcoursComponent,
      ParagraphTwoImagesBlock: TwoImagesBlockComponent,
      ParagraphPdfBlock: ArticlePdfBlocComponent,
      ParagraphCalendlyBlock: CalendlyComponent,
      ParagraphTopPageMozaique: TopPageMosaicComponent,
      ParagraphBlocBref: InBriefComponent,
      ParagraphBlocCtaSticky: CtaStickyComponent,
      ParagraphAvisTrustpilot: TrustpilotCarouselComponent,
    };

    forEach(fieldBlock, block => {
      dynamicComponentsData.push({
        type: blocksMapping[block.__typename],
        content: block,
        typename: block.__typename,
      });
    });

    return dynamicComponentsData;
  }

  appendDynamicComponentData(container: ViewContainerRef, dynamicComponentsData: DynamicComponentData[], shouldSetFirstTitle = true): void {
    if (shouldSetFirstTitle) {
      this.setFirstTitle(dynamicComponentsData);
    }

    for (const data of dynamicComponentsData) {
      try {
        if (data.type) {
          data.content.extra = { ...data.content.extra, ...this.getExtraData(data.typename) };
          this.createComponent(container, data.type, data);
        }
      } catch (e) {
        this.logger.error('DynamicPageDirective: Erreur à la création du component lié à ' + data.typename, e);
      }
    }
  }

  public getExtraData(nameComponent): Extra {
    const extra: Extra = {};
    const isDesktopOrIPAD_landscape = this.deviceManagerService.isDesktopOrIPAD_landscape();
    const isOverMobile = !this.deviceManagerService.isMobileOrMobileSE();
    const isDesktop = this.deviceManagerService.isDesktop();
    switch (nameComponent) {
      case TypeNameBlockSuperTopPage:
        extra.bigViewPort = isDesktopOrIPAD_landscape;
        extra.isBrowser = this.deviceManagerService.isBrowser();
        extra.isOverMobile = isOverMobile;
        break;
      case TypeNameBlockNewTopPage:
      case TypeNameBlockTexts:
      case TypeNameSubBlockFocusBusiness:
        extra.bigViewPort = isDesktopOrIPAD_landscape;
        extra.isOverMobile = isOverMobile;
        extra.isBrowser = this.deviceManagerService.isBrowser();
        break;
      case TypeNamePageArticle:
      case TypeNameBlockActuTheme:
      case TypeNamePageManualHub:
        extra.bigViewPort = isDesktopOrIPAD_landscape;
        extra.isBrowser = this.deviceManagerService.isBrowser();
        extra.isOverMobile = isOverMobile;
        break;
      case IBreadCrumbName:
      case TypeNameBlocNewsletter:
      case TypeNameBlockArticlePDFBloc:
        extra.isDesktop = isDesktop;
        break;
      case TypeNameBlockTopPage:
        extra.isOverMobile = isOverMobile;
        extra.isBrowser = this.deviceManagerService.isBrowser();
        break;
      case TypeNameBlockMedia:
      case TypeNameSubBlockCardsSummary:
        extra.isBrowser = this.deviceManagerService.isBrowser();
        break;
    }
    return extra;
  }

  protected getDeviceType(): Observable<DeviceType> {
    return this.deviceManagerService.deviceType$().pipe(
      tap((deviceType: DeviceType) => {
        this.isDesktop = deviceType.isDesktop;
        this.bigViewPort = deviceType.bigViewPort;
        this.buildPage();
      }),
      takeUntil(this.ngUnsubscribe)
    );
  }

  protected setFirstTitle(dynamicComponentsData: DynamicComponentData[]): void {
    if (!this.isNewSuperTopPage(dynamicComponentsData)) {
      const firstComponent = find(
        dynamicComponentsData,
        component =>
          component.typename === TypeNameBlockTexts ||
          // tslint:disable-next-line: max-line-length
          component.typename === TypeNameBlockMedia ||
          component.typename === TypeNameBlockFaq ||
          component.typename === TypeNameBlockNumber ||
          component.typename === TypeNameBlockOpinions ||
          component.typename === TypeNameBlockEligibleRenovations
      );
      if (firstComponent?.content) {
        if (firstComponent.typename === TypeNameBlockTexts && firstComponent.content.subBlocks[0]) {
          firstComponent.content.subBlocks[0].isFirstTitle = true;
        } else {
          firstComponent.content.isFirstTitle = true;
        }
      }
    }
  }

  protected updatePositionBreadCrumb(dynamicComponentsData: DynamicComponentData[]): DynamicComponentData[] {
    const breadCrumbCallback = component => component.typename === IBreadCrumbName;
    const firstIndex = findIndex(dynamicComponentsData, breadCrumbCallback);
    const lastIndex = findLastIndex(dynamicComponentsData, breadCrumbCallback);
    if (firstIndex !== lastIndex) {
      this.deviceManagerService.isDesktop() ? dynamicComponentsData.splice(lastIndex, 1) : dynamicComponentsData.splice(firstIndex, 1);
    }
    return dynamicComponentsData;
  }

  protected shouldDisplayBreadCrumb(dynamicComponentsData: DynamicComponentData[]): boolean {
    const componentsTypeNameWithoutBreadCrumb = [TypeNameBlocTopPageForm];
    return dynamicComponentsData.every(component => !componentsTypeNameWithoutBreadCrumb.includes(component.typename));
  }

  protected removeBreadCrumb(dynamicComponentsData: DynamicComponentData[]): DynamicComponentData[] {
    return dynamicComponentsData.filter(componentData => componentData.typename !== IBreadCrumbName);
  }

  protected doUnsubscribe(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected AddActualiteThemeIdToBlockActuTheme(
    dynamicComponentsData: DynamicComponentData[],
    actualiteThemeId: string
  ): DynamicComponentData[] {
    return dynamicComponentsData.map(componentData => {
      return {
        ...componentData,
        content:
          componentData.typename === TypeNameBlockActuTheme
            ? {
                ...componentData.content,
                actualiteThemeId,
              }
            : componentData.content,
      };
    });
  }

  private isNewSuperTopPage(dynamicComponentsData: DynamicComponentData[]): any {
    // tslint:disable-next-line:max-line-length
    return find(
      dynamicComponentsData,
      component => component.typename === (TypeNameBlockNewTopPage || TypeNameBlockSuperTopPage || TypeNameBlockTopPage)
    );
  }

  private createComponent(container: ViewContainerRef, type, data: DynamicComponentData): void {
    const component = container.createComponent(type).instance;
    component['data'] = data.content;
    component['theme'] = this.theme;
    // Set extra properties.
    const extra: Extra = data.content.extra;
    Object.keys(extra).forEach(property => {
      component[property] = extra[property];
    });
  }
}
