import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { forEach } from 'lodash-es';
import { IEligibleRenovations } from '../../../interfaces/eligible-renovations';
import { IMessageScroll } from '../../../interfaces/message-scroll';
import { IRelatedItem, TypeNamePageManualHub } from '../../../interfaces/pages';
import { ISummaryElement } from '../../../interfaces/summary';
import { ITaxonomy } from '../../../interfaces/taxonomy';
import { PageScrollingService } from '../../../services/page-scrolling/page-scrolling.service';
import { SummaryScrollService } from '../../../services/summary-scroll';
import { IBlockText, TypeNameBlockTexts } from '../../block-text/block-text';
import { IFaq } from '../../faq/faq';
import { HeadingArticleComponent } from '../../heading-article/heading-article.component';
import { IHeadingQuestions } from '../../heading-questions/heading-questions';
import { IMedias } from '../../medias/medias';
import { IBlockNumber } from '../../numbers/numbers';
import { IOpinions } from '../../opinions/opinions';
import { SelectComponent } from '../../select/select.component';
import { IArticlePromo } from '../article-promo/article-promo';
import { ArticlePromoComponent } from '../article-promo/article-promo.component';
import { ArticleRelatedComponent } from '../article-related/related.component';
import { ArticleThemeComponent } from '../article-theme/theme.component';

@Component({
  selector: 'nscf-article-summary',
  templateUrl: 'summary.component.html',
  styleUrls: ['summary.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ArticleRelatedComponent,
    ArticleThemeComponent,
    HeadingArticleComponent,
    NgFor,
    ArticlePromoComponent,
    SelectComponent,
  ],
})
export class ArticleSummaryComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() data: {
    blocks: Array<IEligibleRenovations | IFaq | IMedias | IBlockNumber | IOpinions | IBlockText | ITaxonomy>;
  };
  summaryElements: ISummaryElement[] = [];
  index = 0;
  @Input() bigViewPort: boolean;
  @Input() isBrowser: boolean;
  @Input() typePage: string;
  @Input() showSummary: boolean;
  @Input() urlSegments: string;
  @Input() promotionalBlock: IArticlePromo;
  @Input() readingTime: string;
  @Input() headingTheme: {
    headingThemeId: string;
  };
  @Input() related: IRelatedItem;
  @Input() headingQuestion: IHeadingQuestions;
  @Input() taxonomyTheme: Array<ITaxonomy>;
  @Input() visibleHeading: boolean;

  typeNamePageManualHub = TypeNamePageManualHub;

  constructor(
    private readonly summaryScrollService: SummaryScrollService,
    private readonly pageScrollingService: PageScrollingService,
    private readonly sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.mapData();
    // if there is summary elements, bind events&
    if (this.summaryElements.length) {
      this.bindEventElement();
    }
  }

  bindEventElement() {
    this.summaryElements[0].completion = 1;

    this.summaryScrollService.scroll$.subscribe((msgScroll: IMessageScroll) => {
      // fetch all summary elements
      forEach(this.summaryElements, (element: ISummaryElement, i: number) => {
        // set the completion for the current and reset the nexts
        if (i === msgScroll.index) {
          element['completion'] = msgScroll.value;
        } else {
          element['completion'] = 0;
        }
      });
    });
  }

  mapData(): void {
    forEach(this.data.blocks, (block: IEligibleRenovations | IFaq | IMedias | IBlockNumber | IOpinions | ITaxonomy | IBlockText) => {
      // fetch all subBlocs
      if (block['__typename'] === TypeNameBlockTexts) {
        forEach((<IBlockText>block).subBlocks, subBlock => {
          this.generateSummaryElement(<ISummaryElement>{
            title: subBlock['title'],
            id: subBlock['id'],
          });
        });
      } else if (!block['__typename']) {
        this.generateSummaryElement(<ISummaryElement>{
          title: block['nameFront'],
          id: block['targetId'],
        });
      } else {
        this.generateSummaryElement(<ISummaryElement>{
          title: block['title'],
          id: block['id'],
        });
      }
    });
  }

  generateSummaryElement(entity: ISummaryElement): void {
    // Do not generate menu entry without id nor title
    if (entity['title'] && entity['id']) {
      this.summaryElements.push({ ...entity, completion: 0 });
    }
  }

  getProgressWidth(width: number) {
    if (width === 0) {
      return this.sanitizer.bypassSecurityTrustStyle(`0px`);
    }

    if (width > 50) {
      // Add 16px to the width (after 50%) to balance the translateX on animation !
      return this.sanitizer.bypassSecurityTrustStyle(`calc(${width}% + 16px)`);
    }
    return this.sanitizer.bypassSecurityTrustStyle(`${width}%`);
  }

  navigateToElementSummary(element: ISummaryElement) {
    this.pageScrollingService.scrollTo('#anchor_' + element.id, 80);
  }
}
