import { NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { EffyLinkComponent, ThemeHelperService } from '@effy-tech/angular-common';
import { tap } from 'rxjs/operators';
import { CoverImageLazyLoadDirective } from '../../../directives/cover-image-lazy-load/cover-image-lazy-load.directive';
import { DeviceManagerService, DeviceType } from '../../../services/device-manager/device-manager.service';
import { LinkComponent } from '../../link/link.component';
import { LiseretComponent } from '../../liseret/liseret.component';
import { HEADING_QUESTIONS_NB_MAX_QUESTIONS_DISPLAYED, IHeadingQuestionsQuestions } from '../heading-questions';

@Component({
  selector: 'nscf-ui-heading-questions',
  templateUrl: './ui-heading-questions.component.html',
  styleUrls: ['./ui-heading-questions.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle, LiseretComponent, NgFor, LinkComponent, SlicePipe, EffyLinkComponent, MatIconModule],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class UiHeadingQuestionsComponent extends CoverImageLazyLoadDirective implements OnInit {
  @Input() nbMaxQuestionsDisplayed = HEADING_QUESTIONS_NB_MAX_QUESTIONS_DISPLAYED;
  @Input() title: string;
  @Input() questions: IHeadingQuestionsQuestions;
  private readonly destroyRef = inject(DestroyRef);
  protected readonly deviceManagerService = inject(DeviceManagerService);
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'new-da' : 'old-da'}`);

  ngOnInit(): void {
    this.deviceManagerService
      .deviceType$()
      .pipe(
        tap((deviceType: DeviceType) => {
          super.setFieldImage({ url: this.getBgImageUrl(deviceType) });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  getBgImageUrl(deviceType: DeviceType): string {
    return deviceType.isDesktop
      ? 'assets/backgrounds/questions-illustration-desktop.svg'
      : 'assets/backgrounds/questions-illustration-mobile.svg';
  }
}
