import { ILink } from './link';

export const TypeNameSubBlockQuestionCTA = 'ParagraphQuestionCta';

export declare interface ISubBlockQuestionCTA {
  __typename: string;
  id?: number;
  text: string;
  cta: ILink;
  sticky?: boolean;
}
