import { IBlockPresenter } from '../../presenter';
import { MediaImagePresenter } from '../../commons/media-image.presenter';
import { IArticlePdfBloc } from '../../../components/article-blocs/article-pdf/article-pdf';
import { BoUrlService } from '../../../services/bo-url/bo-url.service';

interface IFieldMediaImageBo {
  alt: string;
  height: number;
  title: string;
  url: string;
  width: number;
}

interface ICoverBo {
  entity: {
    fieldMediaImage: IFieldMediaImageBo;
  };
}

interface IfieldPdfBlocBo {
  entity: {
    title: string;
    subTitle: string;
    image?: {
      entity: {
        cover: ICoverBo;
      };
    };
    document: {
      entity: {
        filename: string;
        url: string;
      };
    };
  };
}

export interface IArticlePdfBO {
  __typename: string;
  fieldPdfBlock: Array<IfieldPdfBlocBo>;
}

export class BlockArticlePdfPresenter implements IBlockPresenter {
  parse(data: IArticlePdfBO): IArticlePdfBloc {
    return {
      __typename: data.__typename,
      documents: data.fieldPdfBlock ? mapFieldPdfBloc(data.fieldPdfBlock) : [],
    };
  }
}

export function mapFieldPdfBloc(fieldPdfBlock: Array<IfieldPdfBlocBo>) {
  return fieldPdfBlock.map(data => ({
    title: data.entity?.title,
    description: data.entity?.subTitle,
    image: MediaImagePresenter.getImage(data.entity?.image),
    filename: data.entity?.document?.entity?.filename,
    url: BoUrlService.rewriteBoUrl(data.entity.document?.entity?.url),
  }));
}
