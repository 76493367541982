export enum ErrorType {
  SERVER_ERROR_CODE = '503',
  NOT_FOUND_CODE = '404',
  REDIRECT_URL_CHANGE = '301',
  NO_ERROR = '200',
}

export const ErrorValue = {
  SERVER_ERROR_CODE: { statusCode: 503, message: 'erreur interne du serveur' },
  NOT_FOUND_CODE: { statusCode: 404, message: 'page non trouvée' },
  REDIRECT_URL_CHANGE: { statusCode: 301, message: 'Redirection' },
  NO_ERROR: { statusCode: 200, message: 'page rendue avec succès' },
};