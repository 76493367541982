import { DatePipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeHelperService } from '@effy-tech/angular-common';
import { IArticleMetaInfo } from '../../../interfaces/article-meta-info';
import { FrenchFullDatePipe } from '../../../pipes/french-full-date/french-full-date.pipe';
import { ReadingTimeComponent } from '../reading-time/reading-time.component';

@Component({
  selector: 'nscf-meta-info',
  standalone: true,
  templateUrl: './meta-info.component.html',
  styleUrls: ['./meta-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ReadingTimeComponent, FrenchFullDatePipe, DatePipe, NgTemplateOutlet],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class MetaInfoComponent {
  @Input({ required: true }) data: IArticleMetaInfo;
  @Input() shouldDisplayDates: boolean;
  protected readonly imgSrcFallback = computed(() =>
    this.isNewArtisticDirectionTheme() ? 'assets/author-editorial-effy.svg' : 'assets/author-effy.svg'
  );
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'new-da' : 'old-da'}`);
}
