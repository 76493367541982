import { AsyncPipe, LowerCasePipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { DirectionArtisticDirective, EffyLinkComponent, EffyLinkSize, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICardSummary } from '../../../interfaces/cards-summary';
import { IMagazineArticle } from '../../../interfaces/magazine-block';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { CardificationService } from '../../../services/cardification/cardification.service';
import { ArticleCardSmallComponent } from '../../article-card-small/article-card-small.component';
import { LiseretComponent } from '../../liseret/liseret.component';

export enum DefaultThemesActu {
  LA_UNE = 'A la une',
  ACTUALITE = 'Actualité',
  ACTUALITY_PRO = 'PRO - Actus pros',
  COMMUNIQUE_PRESSE = 'Communiqués de presse',
}

enum URL_MAGAZINE {
  PART = '/magazine',
  PRO = '/pro/actualite',
}

@Component({
  selector: 'nscf-magazine-block',
  templateUrl: './magazine-block.component.html',
  styleUrls: ['./magazine-block.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LiseretComponent,
    NgFor,
    AsyncPipe,
    EffyCommonModule,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    LowerCasePipe,
    ArticleCardSmallComponent,
    EffyLinkComponent,
    MatIconModule,
  ],
  hostDirectives: [DirectionArtisticDirective],
})
export class MagazineBlockComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly cardificationService = inject(CardificationService);
  private readonly router = inject(Router);

  @Input() data: {
    showBlockMagazinePro: boolean;
  };
  articles$: Observable<Array<IMagazineArticle>>;
  urlMagazine = URL_MAGAZINE.PART;

  readonly isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  linkSize = EffyLinkSize;

  constructor() {
    super();
  }

  ngOnInit() {
    this.articles$ = this.cardificationService
      .getNewsCards(this.getMagazineThemes(this.data.showBlockMagazinePro), { isNews: true })
      .pipe(map(cardsActu => this.mapCardsActusToMagazineBlock(cardsActu, this.router.url)));

    this.urlMagazine = this.data.showBlockMagazinePro ? URL_MAGAZINE.PRO : URL_MAGAZINE.PART;
  }

  getMagazineThemes(showBlockMagazinePro: boolean): Array<DefaultThemesActu> {
    return showBlockMagazinePro ? [DefaultThemesActu.ACTUALITY_PRO] : [DefaultThemesActu.ACTUALITE];
  }

  mapCardsActusToMagazineBlock(cardsActus: Array<ICardSummary>, url: string): Array<IMagazineArticle> {
    const articles = cardsActus.map(
      (card: ICardSummary) =>
        <IMagazineArticle>{
          title: card.title,
          url: card.link.url,
          mediaCover: card.wideCoverWebp,
          taxonomies: card.taxonomies,
        }
    );

    return this.isCurrentURLOnMagazineBlock(articles.slice(0, 3), url);
  }

  private isCurrentURLOnMagazineBlock(articles: Array<IMagazineArticle>, currentUrl: string): Array<IMagazineArticle> {
    return articles.filter(article => article.url != currentUrl);
  }
}
