import { ISubBlockVideos, TypeNameSubBlockVideos } from '../../components/block-text/videos/videos';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { ISubBlockPresenter } from '../presenter';

export class SubBlockVideosPresenter implements ISubBlockPresenter {
  parse(data: any): ISubBlockVideos {
    const subBlock: ISubBlockVideos = {
      __typename: TypeNameSubBlockVideos,
      id: data['id'],
      title: data['title'] || '',
      video: data['video'] || '',
      cover: MediaImagePresenter.getImage(data['imageCover']),
      legend: data['legend'] || '',
      ctaLabel: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
    };
    return subBlock;
  }
}
