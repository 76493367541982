<div #container>
  <a
    [href]="backUrl | safeResourceUrl"
    style="
      z-index: 10000000000;
      color: #003250;
      position: absolute;
      top: 12px;
      left: 5px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      height: 50px;
      width: 50px;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    "
  >
    <i class="fas fa-pen" style="color: #003250"></i>
  </a>
</div>
