import { JupiterAmountApiRequestCriteriasModel } from '../models/jupiter-amount.models';
import { WorksValues, WORK_VALUES } from '../values/works.values';
import { IconDefinitions } from './icons-definitions.config';
import { WORKS_LABEL_VALUES } from './labels/works.labels';

export const WORKS_OPTIONS_SELECTS: { value: WorksValues; label: string; icon: IconDefinitions | string }[] = [
  {
    value: WORK_VALUES.AtticInsulation,
    label: WORKS_LABEL_VALUES[WORK_VALUES.AtticInsulation],
    icon: IconDefinitions.AtticsInsulation,
  },
  {
    value: WORK_VALUES.AirWaterHeatPump,
    label: WORKS_LABEL_VALUES[WORK_VALUES.AirWaterHeatPump],
    icon: IconDefinitions.AirWaterHeatPump,
  },
  {
    value: WORK_VALUES.CombinedSolarSystem,
    label: WORKS_LABEL_VALUES[WORK_VALUES.CombinedSolarSystem],
    icon: IconDefinitions.CombinedSolarSystem,
  },
  {
    value: WORK_VALUES.DoubleGlazing,
    label: WORKS_LABEL_VALUES[WORK_VALUES.DoubleGlazing],
    icon: IconDefinitions.DoubleGlazing,
  },
  {
    value: WORK_VALUES.RoofTerrace,
    label: WORKS_LABEL_VALUES[WORK_VALUES.RoofTerrace],
    icon: IconDefinitions.RoofTerrace,
  },
  {
    value: WORK_VALUES.GeothermalHeatPump,
    label: WORKS_LABEL_VALUES[WORK_VALUES.GeothermalHeatPump],
    icon: IconDefinitions.GeothermalHeatPump,
  },
  {
    value: WORK_VALUES.PelletBoiler,
    label: WORKS_LABEL_VALUES[WORK_VALUES.PelletBoiler],
    icon: IconDefinitions.PelletBoiler,
  },
  {
    value: WORK_VALUES.PelletStove,
    label: WORKS_LABEL_VALUES[WORK_VALUES.PelletStove],
    icon: IconDefinitions.PelletStove,
  },
  {
    value: WORK_VALUES.ThermodynamicWaterHeater,
    label: WORKS_LABEL_VALUES[WORK_VALUES.ThermodynamicWaterHeater],
    icon: IconDefinitions.ThermodynamicWaterHeater,
  },
  {
    value: WORK_VALUES.WallInsulation,
    label: WORKS_LABEL_VALUES[WORK_VALUES.WallInsulation],
    icon: IconDefinitions.WallsInsulation,
  },
  // TODO: Uncomment when WoodPelletBoiler is deleted
  // {
  //   value: WORK_VALUES.WoodBoiler,
  //   label: WORKS_LABEL_VALUES[WORK_VALUES.WoodBoiler],
  //   icon: IconDefinitions.WoodBoiler,
  // },
  {
    value: WORK_VALUES.WoodPelletBoiler,
    label: WORKS_LABEL_VALUES[WORK_VALUES.WoodBoiler],
    icon: IconDefinitions.WoodBoiler,
  },
  {
    value: WORK_VALUES.WoodStove,
    label: WORKS_LABEL_VALUES[WORK_VALUES.WoodStove],
    icon: IconDefinitions.WoodStove,
  },
].sort((a,b) => a.label.localeCompare(b.label));

type WorksSpecificCriterias = {
  [key in WorksValues]: JupiterAmountApiRequestCriteriasModel;
};

export const WORKS_SPECIFIC_CRITERIAS: WorksSpecificCriterias = {
  [WORK_VALUES.AtticInsulation]: [
    { code: 'accommodation_roof_type', valeur: 'habitable_attics' },
    { code: 'attics_insulation.workproject_insulation_surface', valeur: 1 },
  ],
  [WORK_VALUES.WallInsulation]: [
    { code: 'wall_insulation.workproject_insulation_surface', valeur: 1 }
  ],
  [WORK_VALUES.RoofTerrace]: [
    { code: 'roof_terrace_insulation.workproject_insulation_surface', valeur: 1 }
  ],
  [WORK_VALUES.DoubleGlazing]: [
    { code: 'double_glazing.workproject_windows_count', valeur: 1 },
  ],
  [WORK_VALUES.AirWaterHeatPump]: [],
  [WORK_VALUES.GeothermalHeatPump]: [],
  [WORK_VALUES.WoodBoiler]: [],
  [WORK_VALUES.WoodPelletBoiler]: [],
  [WORK_VALUES.WoodStove]: [],
  [WORK_VALUES.PelletBoiler]: [],
  [WORK_VALUES.PelletStove]: [],
  [WORK_VALUES.CombinedSolarSystem]: [],
  [WORK_VALUES.ThermodynamicWaterHeater]: [],
};

export const WORKS_SPECIFIC_ESTIMATION_LABEL = (amount: string, work: WorksValues): string => {
  switch (work) {
    case WORK_VALUES.AtticInsulation:
    case WORK_VALUES.WallInsulation:
    case WORK_VALUES.RoofTerrace:
      return `${amount} / m2`;

    case WORK_VALUES.DoubleGlazing:
      return `${amount} / fenêtre`;

    default:
      return `${amount}`;
  }
};

