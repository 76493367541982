import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MyPrimeRenovComponent } from './components/my-prime-renov/my-prime-renov.component';
import { SITES, Site, WidgetCalculatorLabels, WorkTypeOrNull } from './models/widget-calculator.models';
import { ConfigService } from './services/config/config.service';
import { SiteService } from './services/site/site.service';

@Component({
  selector: 'calc-widget-calculator',
  templateUrl: './widget-calculator.component.html',
  styleUrls: ['./widget-calculator.component.scss'],
  standalone: true,
  imports: [MyPrimeRenovComponent, NgClass],
})
export class WidgetCalculatorComponent implements OnInit {
  @Input() site: Site = SITES.EFFY;

  @Input() workType?: WorkTypeOrNull;

  labels?: WidgetCalculatorLabels;

  constructor(private readonly configService: ConfigService, private readonly siteService: SiteService) {}

  ngOnInit(): void {
    if (!this.configService.getEnv()) {
      throw new Error('Environment have to be precised to this widget (examples: dev, rct) ');
    }
    this.siteService.setSite(this.site);
  }
}
