// eslint-disable-next-line no-shadow
export enum IconDefinitions {
  Aerovoltaic = 'aerovoltaic',
  AirWaterHeatPump = 'air-water-heat-pump',
  AtticsInsulation = 'attics-insulation',
  Chevron = 'chevron',
  CombinedSolarSystem = 'combined-solar-system',
  DoubleGlazing = 'double-glazing',
  FloorInsulation = 'floor-insulation',
  GeothermalHeatPump = 'geothermal-heat-pump',
  PelletBoiler = 'boiler-type-pellet',
  PelletStove = 'stove-type-pellet',
  RoofTerrace = 'roof-terrace',
  ThermodynamicWaterHeater = 'thermodynamic-water-heater',
  WallsInsulation = 'walls-insulation',
  WoodBoiler = 'boiler-type-wood',
  WoodStove = 'stove-type-wood',
}

export enum LinePictosDefinitions {
  Person = 'person-alt',
  Pin = 'pin-alt',
  Money = 'money-alt',
  Lightbulb = 'lightbulb-alt',
}

export enum OtherPictosDefinitions {
  ArrowLeft = 'arrow-left--dark',
  ChevronDown = 'chevron-down--dark',
  DoubleChevron = 'chevron-left-right--white',
}

export enum SymbolePictosDefinitions {
  BubbleInfo = 'icon-info',
}
