import { isPlatformBrowser, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DirectionArtisticDirective, EffyTagComponent, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { forEach } from 'lodash-es';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { IEligibleRenovations } from '../../interfaces/eligible-renovations';
import { IRenovationType } from '../../interfaces/renovation-types';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { RteComponent } from '../rte/rte.component';
import { SelectComponent } from '../select/select.component';
import { TitleComponent } from '../title/title.component';

@Component({
  selector: 'nscf-eligible-renovations',
  templateUrl: 'eligible-renovations.component.html',
  styleUrls: ['eligible-renovations.component.scss'],
  standalone: true,
  imports: [NgIf, TitleComponent, NgClass, NgFor, SelectComponent, RteComponent, NgStyle, CtaButtonComponent, EffyTagComponent, MatFormFieldModule, MatSelectModule],
  hostDirectives: [DirectionArtisticDirective],
})
export class EligibleRenovationsComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() data: IEligibleRenovations;
  currentRenovationType: IRenovationType;
  isBrowser: boolean;
  private readonly platformId = inject(PLATFORM_ID);

  readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  readonly isDesktop = toSignal(inject(DeviceManagerService).isBigDesktop());

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    // Update customGaugeTitle
    forEach(this.data.renovationTypes, (item: IRenovationType) => {
      this.setGaugeTitle(item);
    });

    this.selectRenovationType(this.data.renovationTypes[0]);
  }

  selectRenovationType(renovationType: IRenovationType): void {
    this.currentRenovationType = renovationType;
    renovationType.dynamicPercent = 0;

    const counter = interval(7);
    const limit = counter.pipe(take(Math.floor((renovationType.gauge * 100) / renovationType.gaugeMax)));

    limit.subscribe(n => {
      renovationType.dynamicPercent = n;
    });

    if (renovationType.customGaugeTitle) {
      const titleCounter = interval(14);
      const titleLimit = titleCounter.pipe(take(renovationType.gauge));
      renovationType.customGaugeTitle.percent = '0';
      titleLimit.subscribe(n => {
        renovationType.customGaugeTitle.percent = (n + 1).toString();
      });
    }
  }

  setGaugeTitle(renovationType: IRenovationType): void {
    // Ce code est pour dynamiser l'affichage pour le que le pourcentage affiché dans le titre
    // (s'il y a lieu), monte en même temps que la jauge.
    const title = renovationType?.gaugeTitle?.value;
    const idx: number = title?.indexOf(renovationType?.gauge + '%') + title?.indexOf(renovationType?.gauge + ' %') + 1;

    if (idx > -1) {
      renovationType.customGaugeTitle = {
        firstPart: {
          value: title.substring(0, idx),
        },
        lastPart: {
          value: title.substring(idx + renovationType.gauge.toString().length),
        },
      };
    } else {
      renovationType.customGaugeTitle = null;
    }
  }
}
