export const TypeNameBlockTrustpilot = 'ParagraphAvisTrustpilot';

export enum TrustpilotFilterTag {
  WidgetGenerique = 'widget_generique',
  WidgetPac = 'widget_pac',
  WidgetPag = 'widget_pag',
  WidgetSolaire = 'widget_solaire',
  WidgetIsolation = 'widget_isolation',
  WidgetFenetre = 'widget_fenetre',
  WidgetRg = 'widget_rg',
}

export interface ITrustpilotCarouselBO {
  __typename: typeof TypeNameBlockTrustpilot;
  id?: number;
  title?: string;
  filter_tag: TrustpilotFilterTag;
  filter_rating: number[];
}

export interface ITrustpilotCarousel {
  __typename?: typeof TypeNameBlockTrustpilot;
  title?: string;
  filterTag: TrustpilotFilterTag;
  filterRating: number[];
}