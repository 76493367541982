<ng-container #breadcrumbContent>
  <!-- breadcrumb content zone -->
</ng-container>
<main class="work-container">
  <section class="top-page">
    <ng-container #topContent>
      <!-- top content zone -->
    </ng-container>
  </section>
  <section class="main-page">
    <section class="content-container">
      <ng-container #mainContent>
        <!-- main content zone -->
      </ng-container>
    </section>
    <aside class="aside-container container">
      <ng-container #asideContent>
        <!-- aside content zone -->
      </ng-container>
    </aside>
  </section>
</main>
<ng-container #beforeFooterContent>
  <!-- cardification content zone -->
</ng-container>