import { ICardsDiscoveryCategorie } from './cards-discovery-categorie/cards-discovery-categorie';

export const TypeNameDiscoveryCategorie = 'ParagraphBlocDecouverte';

export enum IColorCardsDiscovery {
  BLUE = 'blue',
  WHITE = 'white',
}

export interface IDiscoveryCategorie {
  __typename: 'ParagraphBlocDecouverte';
  id?: number;
  title?: string;
  chapo?: string;
  color?: IColorCardsDiscovery;
  cards?: ICardsDiscoveryCategorie;
}
