import { DecimalPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, EffyButtonComponent, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { CtaActionDirective } from '../../directives/cta-action/cta-action.directive';
import { INewTopPage } from '../../interfaces/new-top-page';
import { ImagesService } from '../../services/images-service/images.service';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { RteComponent } from '../rte/rte.component';
import { StarComponent } from '../stars/star.component';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { CardsComponent } from './cards/cards.component';

@Component({
  selector: 'nscf-new-top-page-card',
  templateUrl: 'new-top-page-card.component.html',
  styleUrls: ['new-top-page-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TitleComponent,
    StarComponent,
    RteComponent,
    CtaActionDirective,
    CtaButtonComponent,
    NgStyle,
    CardsComponent,
    DecimalPipe,
    TrustpilotMicroStarComponent,
    EffyButtonComponent,
  ],
  hostDirectives: [DirectionArtisticDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTopPageCardComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() data: INewTopPage;
  @Input() isOverMobile: boolean;
  @Input() bigViewPort: boolean;
  urlCover: any;

  protected imageWidth = window.innerWidth / 2;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.imageWidth = window.innerWidth / 2;
  }

  protected readonly isNewDirectionArtistic = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.urlCover = ImagesService.setBackGroundCoverUrl(this.data.mediaCover);
  }
}
