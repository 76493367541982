import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostBinding, Inject, Input, PLATFORM_ID } from '@angular/core';
import { CardificationService } from '../../services/cardification/cardification.service';

/* eslint-disable */
@Directive({
  selector: 'div[loadNewPage]',
  standalone: true,
})
export class LoadNewPageDirective implements AfterViewInit {
  @HostBinding('attr.id') idAttr = null;
  @Input() id: string;
  @Input() go: boolean;
  @Input() disableIntersection: boolean;

  constructor(
    private readonly el: ElementRef,
    private readonly cardificationService: CardificationService,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadNewPage();
    }
  }

  private loadNewPage() {
    // if browser supports IntersectionObserver
    if ('IntersectionObserver' in window && !this.disableIntersection) {
      const obs = new IntersectionObserver(entries => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) {
            this.cardificationService.setCurrentPage(parseInt(this.id.substring(5), 10));
          }
        });
      });
      obs.observe(this.el.nativeElement);
    }
  }
}
