<h3 class="cta-sticky__title">Financez jusqu'à 80% des travaux grâce aux primes</h3>
<p class="cta-sticky__questions" [ngClass]="{ 'tw-font-gilroybold': !isNewDA() }">Vos travaux concernent :</p>
<nscf-form-parcours-question [question]="firstQuestion" isWarm="true" (selectAnswer)="onHousingTypeChange($any($event.answer))" />
<p class="cta-sticky__questions" [ngClass]="{ 'tw-font-gilroybold': !isNewDA() }">Votre logement a été construit :</p>
<mat-form-field nscfLazyLoadMaterial [ngClass]="{ 'tw-border-0': !isNewDA() }" class="form-field" appearance="outline">
  <mat-select [(value)]="defaultHousingAge" (selectionChange)="onHousingAgeChange($event.value)">
    <ng-container *ngFor="let option of housingAges; let last = last">
      <mat-option [ngClass]="{ 'tw-font-gilroyregular tw-text-[16px]': !isNewDA() }" [value]="option.value">{{ option.label }}</mat-option>
      <mat-divider class="tw-w-11/12 mx-auto" *ngIf="!last"></mat-divider>
    </ng-container>
  </mat-select>
</mat-form-field>

@if (isNewDA()) {
  <nscf-cta-button class="cta-sticky__button" text="Je découvre mes primes" [ctaUrl]="uri$ | async" [withArrow]="true" [fullWidth]="true"></nscf-cta-button>
} @else {
  <nscf-cta-blue label="Je découvre mes primes" [uri]="(uri$ | async).url" target="_blank" />
}
