export interface CardificationOptions {
  isMulti?: boolean;
  isPromoted?: boolean;
  isNews?: boolean;
  isTheme?: boolean;
  isCardification?: boolean;
  isBlockVideo?: boolean;
}

export enum RequestName {
  MENU_REQ,
  TAXONOMY_REQ,
  HEADING_QUESTIONS,
  HEADING_ARTICLES,
  ARTICLE_ACTU,
}

export enum LocationError {
  CARDIFICATION = 'cardification_query',
  PRINCIPAL_QUERY = 'principal_query',
}
