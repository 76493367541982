import { merge } from 'lodash-es';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { ICardSummary } from '../../interfaces/cards-summary';

export class CarouselContainer {
  carouselItems: Array<ICardSummary>;
  customOptions: OwlOptions;
  activeSlides: SlidesOutputData;
  currentSlideId: string;
  hasPrev: boolean;
  hasNext: boolean;

  setCarouselItems(carouselItems: any): void {
    this.carouselItems = carouselItems;
  }

  getChangedData(data: any) {
    this.activeSlides = data;
    setTimeout(() => {
      this.hasPrev = this.displayPrev();
      this.hasNext = this.displayNext();
    }, 10);

    if (data?.slides?.length) {
      this.currentSlideId = data.slides[0].id;
    }
  }

  setCarouselOptions(options?: OwlOptions): void {
    const transitionSpeed = 700;

    // valeur configurée très élevée pour faire fonctionner les vidéos au changement
    // de breakpoint dans le carousel
    const responsiveRefreshRate = 86400000;

    const defaultOptions: OwlOptions = {
      autoplaySpeed: transitionSpeed,
      navSpeed: transitionSpeed,
      smartSpeed: transitionSpeed,
      mouseDrag: false,
      pullDrag: false,
      dots: false,
      items: 1,
      responsive: {
        0: {
          items: 1,
          responsiveRefreshRate: responsiveRefreshRate,
          stagePadding: 32,
          margin: 10,
        },
        600: {
          items: 2,
          responsiveRefreshRate: responsiveRefreshRate,
          stagePadding: 50,
          margin: 20,
        },
        1200: {
          items: 3,
          responsiveRefreshRate: responsiveRefreshRate,
          stagePadding: 50,
          margin: 30,
        },
        1600: {
          items: 4,
          responsiveRefreshRate: responsiveRefreshRate,
          stagePadding: 100,
          margin: 30,
        },
      },
    };

    this.customOptions = merge(defaultOptions, options);
  }

  displayPrev(): boolean {
    if (this.activeSlides) {
      return !this.activeSlides.slides.filter(slide => slide.id === 'slide-0').length;
    }
    return false;
  }

  displayNext(): boolean {
    if (this.activeSlides) {
      const lastSlideId = `slide-${this.carouselItems.length - 1}`;
      return !this.activeSlides.slides.filter(slide => slide.id === lastSlideId).length;
    }
    return false;
  }
}
