import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { RteComponent } from '../../rte/rte.component';
import { IColorFocusBusiness, ISubBlockFocusBusiness } from './focus-business';

@Component({
  selector: 'nscf-focus-business',
  templateUrl: 'focus-business.component.html',
  styleUrls: ['focus-business.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, RteComponent, CtaButtonComponent, EffyButtonComponent],
  hostDirectives: [DirectionArtisticDirective],
  host: {
    '[class]': 'hostClasses()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FocusBusinessComponent extends BasicThemedComponentDirective {
  @Input() bigViewPort: boolean;
  element = input.required<ISubBlockFocusBusiness>();
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonStyle = EffyButtonStyle;
  protected readonly buttonColor = computed(() => this.element()?.color === IColorFocusBusiness.GREEN ? EffyButtonStyle.SECONDARY : EffyButtonStyle.PRIMARY);
  
  private readonly themeHelperService = inject(ThemeHelperService);
  protected readonly isNewArtisticDirectionTheme = toSignal(this.themeHelperService.isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'tw-block container' : ''}`);
  protected readonly isDesktop = toSignal(inject(DeviceManagerService).isAtLeastWidth('1200px'));
}
