<ng-container #breadcrumbContent>
  <!-- breadcrumb content zone -->
</ng-container>
<div class="container p-0 article-wrapper">
  <ng-container #topContent>
    <!-- top content zone -->
  </ng-container>
  <div class="article__container">
    <div class="article__sidebar col-3 d-none d-lg-block">
      <ng-container #asideContent>
        <!-- aside content zone -->
      </ng-container>
    </div>
    <div class="article__content offset-0 offset-lg-1 col-12 col-lg-8">
      <div class="article__main-content article__main-content--hub-multi-themes">
        <div class="container multi-themes__wrapper-container">
          <h1 class="multi-themes__title" [ngClass]="data?.chapo || !bigViewPort ? '' : 'multi-themes__title--without-description'">
            {{ data?.title }}
          </h1>
          <p *ngIf="data?.chapo" class="multi-themes__description">
            {{ data?.chapo }}
          </p>
          <ng-container #summaryContentMobile>
            <!-- summary mobile content zone -->
          </ng-container>
        </div>
        <ng-container #mainContent>
          <!-- main content zone -->
        </ng-container>
      </div>
    </div>
  </div>
  <div class="multi-themes__bottom">
    <!-- bottom content zone -->
    <ng-container #bottomContent> </ng-container>
  </div>
  <ng-container #beforeFooterContent>
    <!-- cardification content zone -->
  </ng-container>
</div>
