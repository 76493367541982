import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DirectionArtisticDirective, EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { GtmClickEventList, GtmEventCategory } from '../../enums/gtm.enum';
import { GtmClickEvent } from '../../interfaces/gtm';
import { UserSubscribeNewsletterSource } from '../../interfaces/newsletter';
import { GtmService } from '../../services/gtm/gtm.service';
import { NewsletterService } from '../../services/newsletter/newsletter.service';
import { INewsletter, NEWSLETTER_NETWORK_CONFIG } from './newsletter';

@Component({
  selector: 'nscf-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, FormsModule, ReactiveFormsModule, NgFor, EffyButtonComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  hostDirectives: [DirectionArtisticDirective],
})
export class NewsletterComponent {
  @Input() data: INewsletter;
  emailPattern = /^[0-9a-zA-Z][\+\w-\_\.]+@([0-9a-zA-Z][a-zA-Z0-9.-]+\.)+[\w-]{2,4}$/;

  emailFC = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  readonly emailFG = new FormGroup ({
    email: this.emailFC,
  })
  isSuccessfullySubscribed = false;
  private readonly newsletterService = inject(NewsletterService);
  private readonly gtmService = inject(GtmService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  buttonSize = EffyButtonSize.MEDIUM;
  buttonStyle = EffyButtonStyle.PRIMARY;

  readonly newsletterNetworkConfig = NEWSLETTER_NETWORK_CONFIG;

  subscribeNewsletter() {
    this.newsletterService.subscribeToNewsletter(this.emailFC.value, UserSubscribeNewsletterSource.OptinNewsletterSites).subscribe(() => {
      const subscribeEvent: GtmClickEvent = {
        event: GtmEventCategory.Click,
        'gtm.elementId': GtmClickEventList.Newsletter,
      };
      this.gtmService.pushEvent(subscribeEvent);
      this.isSuccessfullySubscribed = true;
      this.changeDetectorRef.detectChanges();
    });
  }
}
