import { NgIf } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeHelperService } from '@effy-tech/angular-common';

@Component({
  selector: 'nscf-reading-time',
  templateUrl: './reading-time.component.html',
  styleUrls: ['./reading-time.component.scss'],
  standalone: true,
  imports: [NgIf],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class ReadingTimeComponent {
  @Input({ required: true }) time: string;
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly hostClasses = computed(() => `${this.isNewArtisticDirectionTheme() ? 'new-da' : 'old-da'}`);
}
