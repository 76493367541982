<div class="header-link">
  <a *ngIf="link.url" class="header-link__link tw-cursor-pointer" [href]="link.url" title="{{ link.label }}">{{ link.label }}</a>
  <div *ngIf="!link.url" class="header-link__wrapper-link">
    <span class="header-link__link" title="{{ link.label }}">{{ link.label }}</span>
    <label *ngIf="!isDesktop" [for]="'header-link-panel-' + index" class="c-menu-icon-open">
      @if (isNewArtisticDirection()) {
        <mat-icon svgIcon="chevron-right"></mat-icon>
      } @else {
        <img src="https://www.effy.fr/common-assets/images/pictos/other/chevron-right.svg" alt="" width="10"
        height="17" />
      }
      
    </label>
    <input type="radio" [id]="'header-link-panel-' + index" name="menu-list" class="c-menu-radio-list"
      (change)="onInputChecked()" />
    <nscf-header-menu [menu]="link.menu" [isDesktop]="isDesktop" [theme]="theme" (goBack)="onGoBack()"
      [index]="index"></nscf-header-menu>
  </div>
</div>
@if (!isNewArtisticDirection()) {
  <div class="liseret-link"></div>
}