import { Directive, OnInit } from '@angular/core';
import { LazyLoadStyleService } from '../../services/lazy-load-style/lazy-load-style.service';
import { DestroyerBaseComponent } from '@effy-tech/common';
import { take, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[nscfLazyLoadMaterial]',
    standalone: true
})
export class LazyLoadMaterialDirective extends DestroyerBaseComponent implements OnInit {
  constructor(private readonly lazyLoadStyleService: LazyLoadStyleService) {
    super();
  }

  ngOnInit(): void {
    this.lazyLoadStyleService.loadMaterial$().pipe(take(1), takeUntil(this.unsubscribe$)).subscribe();
  }
}
