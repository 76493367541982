@if(isNewArtisticDirection()) {
  <div class="container tw-flex tw-justify-between smd:tw-justify-center tw-items-center smd:tw-gap-12 tw-text-blue-power">
    @if(data.firstHref || true) {
      <a [href]="data.firstHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
        <mat-icon [svgIcon]="'double-chevron-right'" class="icon rotate-icon"></mat-icon>
      </a>
    }
    @if(data.prevHref) {
      <a [href]="data.prevHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
        <mat-icon [svgIcon]="'chevron-right'" class="icon rotate-icon"></mat-icon>
      </a>
    }
    @for (link of data.links; track $index) {
      @if (link.isCurrent) {
        <span class="tw-w-6 tw-text-center tw-text-new-lg tw-font-600 tw-border-b-[1px] tw-border-blue-power">
          {{ link.label }}
        </span>
      } @else {
        <a [href]="link.href" class="tw-w-6 tw-text-center tw-text-new-lg tw-font-300 hover:tw-text-blue-power-dark">
          {{ link.label }}
        </a>
      }    
    }
    @if (data.nextHref) {
      <a [href]="data.nextHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
        <mat-icon [svgIcon]="'chevron-right'" class="icon"></mat-icon>
      </a>
    }
    @if (data.lastHref) {
      <a [href]="data.lastHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
        <mat-icon [svgIcon]="'double-chevron-right'" class="icon"></mat-icon>
      </a>
    }
  </div>
} @else {
  <div class="pager">
    <a *ngIf="data.firstHref" [href]="data.firstHref" class="pager__arrow-double pager__arrow-double--left">
      <img src="../../../../assets/icons/chevron-double-right.svg" alt="précédent" loading="lazy" />
    </a>
    <a *ngIf="data.prevHref" [href]="data.prevHref" class="pager__arrow pager__arrow--left">
      <img src="../../../../assets/icons/chevron-left-bis.svg" alt="précédant" loading="lazy" />
    </a>
    <span *ngFor="let link of data.links">
      <span *ngIf="link.isCurrent" class="pager__link active">
        {{ link.label }}
      </span>
      <a *ngIf="!link.isCurrent" [href]="link.href" class="pager__link">
        {{ link.label }}
      </a>
    </span>
    <a *ngIf="data.nextHref" [href]="data.nextHref" class="pager__arrow pager__arrow--right">
      <img src="../../../../assets/icons/chevron-left-bis.svg" alt="suivant" loading="lazy" />
    </a>
    <a *ngIf="data.lastHref" [href]="data.lastHref" class="pager__arrow-double pager__arrow-double--right">
      <img src="../../../../assets/icons/chevron-double-right.svg" alt="suivant" loading="lazy" />
    </a>
  </div>
}

