<div *ngIf="articles$ | async as articles" class="container">
  <ng-container *ngIf="articles?.length">
    @if (isNewArtisticDirection() === false) {
      <span class="magazine-block__divider"></span>
    }
    <p class="magazine-block__title">Les articles les + récents</p>
    <div class="magazine-block__cards-container">
      <nscf-article-card-small
        *ngFor="let article of articles"
        [url]="article.url"
        [img]="article.mediaCover"
        [imgAlt]="'image de l\'article'"
        [title]="article.title"
        [taxonomies]="article.taxonomies"
        [isNewArtisticDirection]="isNewArtisticDirection()"
      ></nscf-article-card-small>
    </div>
    @if (isNewArtisticDirection()) {
      <a effy-link [size]="linkSize.MEDIUM" class="magazine-block__to-magazine-new-da" [href]="urlMagazine"
        >Le magazine de la rénovation
        <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
      </a>
    } @else {
      <a class="magazine-block__to-magazine" [href]="urlMagazine">
        Le magazine de la rénovation
        <div class="magazine-block__to-magazine__arrow"></div>
      </a>
    }
  </ng-container>
</div>
