import { NgClass, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { SummaryScrollDirective } from '../../directives/summary-scroll/summary-scroll.directive';
import { getAlphabeticalString } from '../../utils/string/string.utils';


@Component({
    selector: 'nscf-title',
    templateUrl: 'title.component.html',
    styleUrls: ['title.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, NgTemplateOutlet, SummaryScrollDirective, NgClass],
    hostDirectives: [DirectionArtisticDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent implements OnInit {
  @Input() title: string;
  @Input() addedClass: string;
  @Input() id = '';
  @Input() selector = 'h2';

  shouldDisplaySeparator = input(false, { transform: booleanAttribute });
  
  protected readonly isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);

  ngOnInit(): void {
    if (!this.id && this.title) {
      this.id = this.getFallbackId(this.title);
    }
  }

  private getFallbackId(title: string): string {
    return getAlphabeticalString(title, '-');
  }
}
