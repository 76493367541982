<ng-container *ngIf="data">
  <div *ngIf="mode === newsCardMode.HEADLINES; then headlines; else news"></div>
  <ng-template #headlines>
    <!-- PREMIÈRE CARTE À LA UNE -->
    <div *ngIf="isHeadCard">
      <div class="news--big-card">
        <a
          [href]="data.link?.url"
          class="actuality__card"
          [ngClass]="isSticky ? 'actuality__card--secondary-actu' : 'actuality__card--first-actu'"
        >
          <div class="actuality__card-image">
            <picture>
              <source [attr.srcset]="data.wideCoverWebp" />
              <img [attr.src]="data.wideCover" [attr.alt]="data.imageAlt" loading="lazy" />
            </picture>
            <div class="actuality__card--calque"></div>
            <div class="actuality__card-content">
              <div class="actuality__card-content-wrapper">
                <div class="actuality__card-content-theme">
                  @if (isNewDA()) {
                    <a effy-hashtag [size]="hashtagSize" [color]="hashtagColorHeadline" *ngFor="let theme of $any(data).taxonomies"
                      >#{{ theme }}</a
                    >
                  } @else {
                    <span *ngFor="let theme of $any(data).taxonomies">#{{ theme }}</span>
                  }
                </div>
                <div class="actuality__card-content-date">
                  <span>{{ data.created }}</span>
                </div>
              </div>
              <p class="actuality__title" [innerHTML]="data?.title | safeHtml"></p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- FIN PREMIÈRE CARTE À LA UNE -->
    <!-- CARTES 2 ET 3 À LA UNE -->
    <div
      *ngIf="!isHeadCard"
      class="actuality__card col-12 col-md-6 news--simple-card"
      [ngClass]="{
        'actuality__card--first-actu': isSticky,
        'actuality__card--secondary-actu': !isSticky,
      }"
    >
      <a [href]="data.link?.url">
        <div [ngClass]="{ 'actuality__card-wrapper': !isSticky }">
          <div class="actuality__card-image" *ngIf="data.image">
            <picture>
              <source [attr.srcset]="data.imageWebp" />
              <img [attr.src]="data.image" [attr.alt]="data.imageAlt" loading="lazy" />
            </picture>
          </div>
          <div class="actuality__card-content">
            <div class="actuality__card-content-wrapper">
              <div class="actuality__card-content-theme">
                @if (isNewDA()) {
                  <a effy-hashtag [size]="hashtagSize" *ngFor="let theme of $any(data).taxonomies">#{{ theme }}</a>
                } @else {
                  <span *ngFor="let theme of $any(data).taxonomies">#{{ theme }}</span>
                }
              </div>
              @if (isDesktop) {
                <div class="actuality__card-content-date actuality__card-content-date--desktop">
                  <span>{{ data.created }}</span>
                </div>
              } @else {
                <div class="actuality__card-content-date actuality__card-content-date--mobile">
                  <span>{{ data.created }}</span>
                </div>
              }
            </div>
            <p class="actuality__title" [innerHTML]="data?.title | safeHtml"></p>
          </div>
        </div>
      </a>
    </div>
    <!-- FIN CARTES 2 ET 3 À LA UNE -->
  </ng-template>
  <ng-template #news>
    <div class="actuality__card">
      <a [href]="data.link?.url" [ngClass]="isSticky ? 'actuality__card--first-actu' : 'actuality__card--secondary-actu'">
        <div [ngClass]="{ 'actuality__card-wrapper': !isSticky }">
          <div class="actuality__card-image" *ngIf="data.image">
            <div class="wrapper-image">
              <picture>
                <source [attr.srcset]="isSticky ? data.wideCover : data.imageWebp" />
                <img [attr.src]="data.image" [attr.alt]="data.imageAlt" loading="lazy" />
              </picture>
            </div>
          </div>
          <div class="actuality__card-content">
            <div class="actuality__card-content-wrapper">
              <div class="actuality__card-content-theme">
                @if (isNewDA()) {
                  <a effy-hashtag [size]="hashtagSize" *ngFor="let theme of $any(data).taxonomies">#{{ theme }}</a>
                } @else {
                  <span *ngFor="let theme of $any(data).taxonomies">#{{ theme }}</span>
                }
              </div>
              @if (isDesktop) {
                <div class="actuality__card-content-date actuality__card-content-date--desktop">
                  <span>{{ data.created }}</span>
                </div>
              } @else {
                <div class="actuality__card-content-date actuality__card-content-date--mobile">
                  <span>{{ data.created }}</span>
                </div>
              }
            </div>
            <p class="actuality__title" [innerHTML]="data?.title | safeHtml"></p>
            @if (!isNewDA) {
              <p *ngIf="data?.description" class="actuality__description">
                {{ data.description }}
              </p>
            }
          </div>
        </div>
      </a>
    </div>
  </ng-template>
</ng-container>
