import { Location } from '@angular/common';
import { LoggerService } from '@effy-tech/common/loggers';
import { defaultsDeep, map } from 'lodash-es';
import { IArticleMetaInfo, IArticleMetaInfoAuthor } from '../../interfaces/article-meta-info';
import { IDataAuthorBo, IPageArticle, IPageArticleBo, IPageDefault, TypeNamePageArticle } from '../../interfaces/pages';
import { ITaxonomy } from '../../interfaces/taxonomy';
import { ImagesService } from '../../services/images-service/images.service';
import { PageArticleUtils } from '../../utils/page-article/page-article.utils';
import { BlockPromoPresenter } from '../blocks/promo.presenter';
import { LinkPresenter } from '../commons/link.presenter';
import { ManualCardificationPresenter } from '../commons/manual-cardification.presenter';
import { MapPresenter } from '../commons/map.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { QuestionsPresenter } from '../commons/questions.presenter';
import { ReadingTimePresenter } from '../commons/reading-time.presenter';
import { TaxonomyPresenter } from '../commons/taxonomy.presenter';
import { MainBlockPresenter } from '../main-blocks.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class PageArticlePresenter extends PageDefaultPresenter implements IPagePresenter {
  constructor(
    private readonly logger: LoggerService,
    private readonly location: Location
  ) {
    super();
  }

  parse(data: IPageArticleBo): IPageArticle {
    const defaultPage: IPageDefault = super.parse(data);
    const mainBlockPresenter = new MainBlockPresenter(this.logger);
    const shouldDisplayEffyAdsBasedOnPageUrl = PageArticleUtils.shouldDisplayEffyAdsBasedOnPageUrl(
      this.location.path(),
      data.entityMetatags
    );
    const shouldDisplayCtaWithQuestions = PageArticleUtils.shouldDisplayCtaWithQuestions(this.location.path(), data.entityMetatags);

    // Merge defaultPage with extra Page data.
    return defaultsDeep(
      {
        __typename: TypeNamePageArticle,
        nid: data.nid,
        taxonomy: map(data.fieldTaxonomy1, taxonomy => {
          return TaxonomyPresenter.getTaxonomy(taxonomy);
        }).filter((taxonomy: ITaxonomy) => taxonomy.isVisible),
        cover: MediaImagePresenter.getCoverWithWebp(data.cover),
        coverVisibleOnMobile: data.coverVisibleOnMobile,
        metaInfo: this.getMetaInfo(data),
        relateds: {
          name: 'Articles liés',
          links: map(data.articleLinks, link => {
            return LinkPresenter.getLink(link);
          }),
        },
        blocks: mainBlockPresenter.parse(data.fieldBlock),
        moreArticle: ManualCardificationPresenter.getCards(data.moreArticle),
        map: MapPresenter.getDataMap(data),
        promotionalBlock: BlockPromoPresenter.parsePromoBlock(data.promotionalBlock),
        visibleHeading: data.visibleHeading,
        visibleQuestions: data.VisibleQuestions,
        questions: QuestionsPresenter.getQuestions(data.questionsTheme),
        headingThemeId: data.headingTheme?.tid,
        showSummary: data.showSummary,
        showBlockMagazinePart: data.showBlockMagazinePart,
        showBlockMagazinePro: data.showBlockMagazinePro,
        showPostHeader: data.showPostHeader && shouldDisplayEffyAdsBasedOnPageUrl,
        showCtaSticky: shouldDisplayEffyAdsBasedOnPageUrl,
        shouldDisplayAuthorDates: !shouldDisplayEffyAdsBasedOnPageUrl,
        shouldDisplayCtaWithQuestions,
      },
      defaultPage
    );
  }

  private getAuthor(data: IDataAuthorBo): IArticleMetaInfoAuthor | null {
    if (!data) {
      return null;
    }

    return {
      name: data.fieldAuthor,
      link: data.fieldAuthorPage?.url?.path,
      imgAlt: data.userPicture?.picto?.alt,
      imgSrc: ImagesService.rewriteImagesUrl(data.userPicture?.picto?.url),
      isEditorialTeam: this.isEditorialTeamAuthor(data.fieldAuthor),
      job: data.fieldPosition,
      details: data.fieldDescription,
    };
  }

  private isEditorialTeamAuthor(authorName: string): boolean {
    return ['redaction', 'rédaction'].some(word => authorName?.includes(word));
  }

  private getMetaInfo(data: IPageArticleBo) {
    if (!data) {
      return null;
    }

    const created = +data['created'] * 1000;
    const changed = +data['changed'] * 1000;
    const hoursDifference = this.timeHoursDifference(created, changed);
    const isMoreThanOneHourDiff: boolean = hoursDifference > 1;

    const metaInfoArticle: IArticleMetaInfo = {
      author: this.getAuthor(data?.entityOwner),
      date: { created, changed, showDate: data.createdDateShow, showChangedDate: isMoreThanOneHourDiff },
      readingTime: ReadingTimePresenter.getReadingTime(data?.ContentReadTime),
    };

    return metaInfoArticle;
  }

  private timeHoursDifference(timeStampsStart: number, timeStampsEnd: number): number {
    const difference = timeStampsEnd - timeStampsStart;
    return Math.floor(difference / 1000 / 60 / 60);
  }
}
