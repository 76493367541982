import { find } from 'lodash-es';
import { ICardSummary } from '../../interfaces/cards-summary';
import { LinkPresenter } from './link.presenter';
import { MediaImagePresenter } from './media-image.presenter';

export class ManualCardificationPresenter {
  public static getCards(data: Array<any>): Array<ICardSummary> {
    if (!data) {
      return [];
    }
    return data
      .filter(item => {
        return item.entity?.entityPublished;
      })
      .map(({ entity }: any) => {
        const mediaImage = MediaImagePresenter.getImage(entity.fieldCover);
        return <ICardSummary>{
          title: entity.title,
          description: find(entity.entityMetatags, meta => meta.name === 'description')?.content,
          mediaCover: entity.fieldCover ? mediaImage : null,
          link: entity.entityUrl ? LinkPresenter.getLink({ url: { path: entity.entityUrl.path } }) : null,
          created: entity.created,
          image: mediaImage?.url,
        };
      });
  }
}
