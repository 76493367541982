import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { WebsiteThemes } from '../../../enums/website-themes';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { LiseretComponent } from '../../liseret/liseret.component';
import { RteComponent } from '../../rte/rte.component';
import { ITopPageCategorie } from './top-page-categorie';

@Component({
  selector: 'nscf-top-page-categorie',
  templateUrl: './top-page-categorie.component.html',
  styleUrls: ['./top-page-categorie.component.scss'],
  standalone: true,
  imports: [LiseretComponent, RteComponent, NgIf, CtaButtonComponent],
  hostDirectives: [DirectionArtisticDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopPageCategorieComponent extends BasicThemedComponentDirective implements OnInit {
  @Input()
  data: ITopPageCategorie;

  isDesktop: boolean;

  waveSrc = this.getWaveSrcUrl();
  private readonly deviceManagerService = inject(DeviceManagerService);
  protected readonly isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected isMobileOrTablet = toSignal(this.deviceManagerService.isMobileOrTablet());
  protected imageWidth = window.innerWidth / 2;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.imageWidth = window.innerWidth / 2;
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceManagerService.isDesktop();
    if (this.theme === WebsiteThemes.qe) {
      this.waveSrc = this.getWaveSrcUrl('white');
    }
  }

  private getWaveSrcUrl(whiteOrBlue: 'white' | 'blue' = 'blue'): string {
    return `../../../../assets/backgrounds/wave-${whiteOrBlue}.svg`;
  }
}
