import { Injectable } from '@angular/core';
import { INewFooter } from './footer';

export interface Footer {
  getContent(isPro?: boolean): INewFooter;
}

@Injectable({
  providedIn: 'root',
})
export abstract class FooterService implements Footer {
  abstract getContent(isPro?: boolean): INewFooter;
}
