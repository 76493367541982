import { DisplayValues } from '../medias/medias';
import { ITaxonomy } from '../../interfaces/taxonomy';
import { ICardSummary } from '../../interfaces/cards-summary';
import { IRteText } from '../../interfaces/rte-text';

export const TypeNameBlockTheme = 'ParagraphTheme';

export interface IBlocTheme {
  __typename: 'ParagraphTheme';
  id?: number;
  title?: string;
  chapo?: IRteText;
  isFirstTitle?: boolean;
  cards?: Array<ICardSummary>;
  taxonomy?: Array<ITaxonomy>;
  display?: DisplayValues;
}
