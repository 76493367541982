import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, fromEvent, map, of, sampleTime, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollProgressPageService {
  private readonly document = inject(DOCUMENT);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  readonly progress$: Observable<number> = this.isBrowser
    ? fromEvent(this.document.defaultView, 'scroll').pipe(
        startWith(null),
        sampleTime(100),
        map(() => this.computeProgress())
      )
    : of(0);

  private computeProgress() {
    const scrollPosition = this.document.defaultView.scrollY;
    const maxScroll = this.document.documentElement.scrollHeight - this.document.defaultView.innerHeight;
    const hasNoScroll = this.document.documentElement.scrollHeight === this.document.defaultView.innerHeight;
    return hasNoScroll ? 100 : (scrollPosition / maxScroll) * 100;
  }
}
