<mat-form-field>
    <mat-label>Sélectionnez votre travaux</mat-label>
    <mat-select [ngModel]="currentValue()" (ngModelChange)="onSelect($event)" [disableOptionCentering]="true">
        @for (option of worksOptions; track $index) {
            <mat-option [value]="option.value">
                <img
                        class="picto"
                        src="{{ option.icon }}"
                        alt="{{ option.label }}"
                />
                {{ option.label }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>

