import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { SiteService } from '../site/site.service';

/* Usage of dynamic to fix this issue : https://github.com/angular/angular/issues/20351 */
/** @dynamic */
@Injectable({
  providedIn:'root'
})
export class RedirectionService {

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly configService: ConfigService,
    private readonly siteService: SiteService) { }

  redirectToHelpsAndWorks(): void {
    if (this.document) {
      this.document.location.href = `${this.getBaseUrlParcours()}travaux-aides?theme=prime-effy`;
    }
  }

  private getBaseUrlParcours(): string {
    return `${this.getBaseUrl()}/parcours/1/`;
  }

  private getBaseUrl(): string {
    return this.configService.getConfig().sitesUri[this.siteService.getSite()];
  }
}
