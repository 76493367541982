import { ITheme } from '../../interfaces';
import { ICardSummarySimplified } from '../../interfaces/cards-summary';
import { mapStringThemesToArray } from '../../services/cardification/cardification.service';
import { removeCurrentPageFromCards } from './cardification-hub.presenter';
import { CardSimplifiedPresenter, ICardificationSimplifiedResultBo } from './shared/card-simplified.presenter';

export interface ICardificationGraphqlBo {
  count: number;
  results: ICardificationSimplifiedResultBo[];
}

export interface ICardificationDataBo {
  data: {
    cardificationOfferGraphql: ICardificationGraphqlBo;
    cardificationClassicGraphql: ICardificationGraphqlBo;
  };
}

export class CardificationSummaryPresenter {
  static mapCardificationSummary(
    cardificationBo: ICardificationDataBo, 
    currentUrl: string,
    currentThemeIds: string[], 
    everyVisibleThemes: ITheme[]
  ): Array<ICardSummarySimplified> {
      const offerCardification = cardificationBo?.data?.cardificationOfferGraphql;
      const classicCardification = cardificationBo?.data?.cardificationClassicGraphql;
      const allCards = concactAllCards(offerCardification, classicCardification);
      const cards = allCards.map((cardBo): ICardSummarySimplified => {
      const cardThemeIds = mapStringThemesToArray(cardBo.theme);
      const cardThemes = everyVisibleThemes.filter(
        theme => cardThemeIds.includes(theme.tid.toString()) && currentThemeIds.includes(theme.tid.toString())
      ).map(theme => theme.name).slice(0, 1);
      return {
        ...CardSimplifiedPresenter.mapCardBoToSimplifiedCard(cardBo),
        taxonomies: cardThemes
      }});
    return removeCurrentPageFromCards(currentUrl, cards);
  }
}

function getCardsFromQueryResult(cardificationData: ICardificationGraphqlBo | ICardificationGraphqlBo) {
  return cardificationData?.results ?? [];
}

function concactAllCards(offerCardification: ICardificationGraphqlBo, classicCardification: ICardificationGraphqlBo) {
  return [...getCardsFromQueryResult(offerCardification), ...getCardsFromQueryResult(classicCardification)];
}
