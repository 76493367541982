import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DirectionArtisticDirective, ThemeHelperService } from '@effy-tech/angular-common';
import { INewTopPageCards } from '../../../interfaces/new-top-page-cards';

@Component({
  selector: 'nscf-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['cards.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DirectionArtisticDirective],
})
export class CardsComponent {
  data = input.required<Array<INewTopPageCards>>();
  protected readonly isNewDirectionArtistic = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
}
