import { defaultsDeep } from 'lodash-es';
import { IPageDefault, IPageHub, TypeNamePageHub } from '../../interfaces/pages';
import { AuthorContactPresenter } from '../author.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class PageHubPresenter extends PageDefaultPresenter implements IPagePresenter {
  parse(data: any): IPageHub {
    const defaultPage: IPageDefault = super.parse(data);
    // Merge defaultPage with extra Page data.
    return defaultsDeep(
      <IPageHub>{
        __typename: TypeNamePageHub,
        themeId: data['fieldTaxonomy2'].targetId,
        chapo: data['chapo'] || '',
        blocks: {
          __typename: TypeNamePageHub,
          cardsSummary: [],
        },
        promoted: true,
        descriptionSeo: RTEPresenter.getRTEText(data['fieldDescriptionSeo']),
        authorContact: AuthorContactPresenter.getAuthorLinks(data['authorContact']?.contactLinks),
      },
      defaultPage
    );
  }
}
