<div
  *ngFor="let card of cards; let index = index"
  class="cards-discovery-categorie__content  cards-discovery-categorie__content-{{ index }}"
>
  <a href="{{ card.ctaUrl }}" class="cards-discovery-categorie__wrapper-link">
    <div class="cards-discovery-categorie__card cards-discovery-categorie__card-{{ index }}">
      <div class="wrapper-image">
        @if (isNewArtisticDirection() === false) {
          <div
            *ngIf="computeWaveUrl$(index) | async as waveUrl"
            class="wave wave-{{ index }}"
            [ngStyle]="{ 'background-image': 'url(' + waveUrl + ')' }"
          ></div>
        }
        <div *ngIf="card?.cover?.url" class="illustration illustration-{{ index }}">
          <picture *ngIf="card.cover">
            <source [attr.srcset]="card.cover?.webpUrl" />
            <img [attr.src]="card.cover?.url" [attr.alt]="card.cover?.alt" loading="lazy" />
          </picture>
        </div>
      </div>

      <div class="wrapper-content" [ngClass]="!card?.cover?.url ? 'without-image' : ''">
        <p *ngIf="card.title" class="cards-discovery-categorie__title">{{ card.title }}</p>
        @if (isNewArtisticDirection()) {
          <a class="tw-flex tw-items-center" effy-link [color]="linkStyle.YELLOW" [size]="(isDesktop$ | async) ? linkSize.MEDIUM : linkSize.SMALL" [href]="card?.ctaUrl">{{ linkText$ | async }} <mat-icon class="tw-flex tw-items-center" [svgIcon]="'chevron-right'"></mat-icon></a>
        } @else {
          <a class="cards-discovery-categorie__link" [href]="card?.ctaUrl" rel="noopener noreferrer">{{ linkText$ | async }}</a>
        }
      </div>
    </div>
  </a>
</div>
