import { PdfHelper } from '../../classes/pdf/pdf.helper';
import { BoUrlService } from '../../services/bo-url/bo-url.service';
import { IRteText } from '../../interfaces/rte-text';
import { ImagesService } from '../../services/images-service/images.service';

export class RTEPresenter {
  public static getRTEText(data: any): IRteText {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }
    return {
      format: data['format'] || 'full_html',
      processed: rewriteUrlBo(this.rewritePdfAndImagesUrls(data['processed'] || '')),
      value: this.rewritePdfAndImagesUrls(data['value'] || ''),
    };
  }

  private static rewritePdfAndImagesUrls(text: string): string {
    // rewrite PDFs
    text = PdfHelper.rewritePdfsUrl(text);
    // rewrite images
    return ImagesService.rewriteImagesUrl(text);
  }
}

function rewriteUrlBo(value) {
  return value?.replace(new RegExp(BoUrlService.getEnvironments().url_base_bo_local, 'g'), BoUrlService.getEnvironments().url_base_bo);
}
