@if(isNewArtisticDirectionTheme()) {
@if (time) {
<img src="../../../../assets/icons/icon_clock.svg" width="16" height="16">
<p class="tw-font-300 tw-my-0">{{ time }} de lecture</p>
}
} @else {
<div *ngIf="time" class="reading-time">
  <div class="reading-time__wrapper">
    <p class="reading-time--bold">{{ time }}</p>
    de lecture
  </div>
</div>
}