interface IHeadingQuestionsQuestion {
  text: string;
  url: string;
  nid: string;
}

export type IHeadingQuestionsQuestions = IHeadingQuestionsQuestion[];

export const HEADING_QUESTIONS_NB_MAX_QUESTIONS_DISPLAYED = 5;

export interface IHeadingQuestions {
  title: string;
  currentThemeId: number;
}

export const THEME_QUESTION_ID = '999';
