<div class="form-parcours-question" *ngIf="question">
  <h3 *ngIf="question.title" class="form-parcours-question__title">{{ question.title }}</h3>
  <div class="form-parcours-question__form" [class.form-parcours-question__form--bigger-gap]="question.hasBiggerGap">
    <div class="form-parcours-question__item" *ngFor="let option of question.options; let index = index">
      <label
        class="form-parcours-question__label"
        [ngClass]="{
          'form-parcours-question__label--without-icon': !option.iconUrl,
          'form-parcours-question__label--is-active': option.key === question.answer,
        }"
        [for]="option.key"
      >
        <img
          *ngIf="option.iconUrl"
          class="form-parcours-question__option-icon"
          [src]="'../../../../assets/icons/' + warm + option.iconUrl"
          alt=""
        />
        <span
          class="form-parcours-question__option-text"
          [class.form-parcours-question__option-text--small]="question.isSmallOptinText"
          [innerHTML]="option.label"
        ></span>
        <input
          type="radio"
          [id]="option.key"
          [value]="option.key"
          [name]="option.key"
          [(ngModel)]="question.answer"
          [ngModelOptions]="{ standalone: true }"
          (change)="onChange(question)"
        />
      </label>
    </div>
  </div>
</div>
