import { SITES, WidgetCalculatorConfig } from '../../models/widget-calculator.models';

export const ENV_PROD: WidgetCalculatorConfig = {
  production: true,
  api: {
    costEstimator: 'https://costestimator.effy-connect.fr/api/v3/',
    precarityLevel: 'https://precaritylevel.api.effy.fr/',
  },
  sitesUri: {
    [SITES.CALCULEO]: 'https://www.calculeo.fr',
    [SITES.LPE]: 'https://www.laprimeenergie.fr',
    [SITES.EFFY]: 'https://www.effy.fr',
    [SITES.QE]: 'https://www.quelleenergie.fr',
  },
};
