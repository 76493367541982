import { WORK_VALUES } from '../../values/works.values';

export const WORKS_LABEL_VALUES = {
  [WORK_VALUES.AtticInsulation]: 'Isolation des combles',
  [WORK_VALUES.WallInsulation]: 'Isolation des murs',
  [WORK_VALUES.RoofTerrace]: 'Isolation des toitures-terrasses',
  [WORK_VALUES.DoubleGlazing]: 'Fenêtres',
  [WORK_VALUES.AirWaterHeatPump]: 'Pompe à chaleur air/eau',
  [WORK_VALUES.GeothermalHeatPump]: 'Pompe à chaleur géothermique',
  [WORK_VALUES.WoodBoiler]: 'Chaudière à bûches',
  [WORK_VALUES.WoodPelletBoiler]: 'Chaudière à bûches',
  [WORK_VALUES.WoodStove]: 'Poêle à bûches',
  [WORK_VALUES.PelletBoiler]: 'Chaudière à granulés',
  [WORK_VALUES.PelletStove]: 'Poêle à granulés',
  [WORK_VALUES.CombinedSolarSystem]: 'Système solaire combiné',
  [WORK_VALUES.ThermodynamicWaterHeater]: 'Chauffe-eau thermodynamique',
};
