import { NgClass, NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, inject, input, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  DirectionArtisticDirective,
  EffyButtonComponent,
  EffyButtonSize,
  EffyButtonStyle,
  ThemeHelperService,
} from '@effy-tech/angular-common';
import { BasicThemedComponentDirective, EffyCommonModule } from '@effy-tech/common';
import { WebsiteThemes } from '../../enums/website-themes';
import { ILink } from '../../interfaces/link';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';

@Component({
  selector: 'nscf-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, EffyCommonModule, SafeHtmlPipe, EffyButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DirectionArtisticDirective],
  host: {
    '[class.is-centered]': 'centered()',
  },
})
export class CtaButtonComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() text: string;
  @Input() redirection = true;
  @Input() btnClass = '';
  @Input() btnIcon: string;
  @Input() @HostBinding('class.full-width') fullWidth: boolean = false;
  protected readonly withArrow = input(false);
  protected readonly centered = input(false, { transform: booleanAttribute });
  btnClasses: Array<string> = [];
  href: string;

  // New Direction Artistic inputs (could be renamed)
  effyStyle = input<EffyButtonStyle>(EffyButtonStyle.PRIMARY);
  effySize = input<EffyButtonSize>(EffyButtonSize.LARGE);
  protected readonly isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);

  private _ctaUrl: ILink;

  get ctaUrl(): ILink {
    return this._ctaUrl;
  }

  @Input() set ctaUrl(value: ILink) {
    this._ctaUrl = value;
    this.href = this.redirection ? this._ctaUrl?.url : 'javascript:void(0)';
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    // @TODO : Handle it better
    if (this.theme === WebsiteThemes.lpe && this.btnClass !== '') {
      // Use cta-secondary instead of cta-primary.
      this.btnClass = this.btnClass.replace('cta-primary', 'cta-secondary');
    }
    this.btnClasses = [this.btnClass, ...(this._ctaUrl?.options?.attributes?.class ?? []), 'nscf-btn'];
  }
}
