import { defaultsDeep, forEach, map } from 'lodash-es';
import { IChapter } from '../../components/chapter/chapter';
import { IPageDefault, IPageGuide, TypeNamePageGuide } from '../../interfaces/pages';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class PageGuidePresenter extends PageDefaultPresenter implements IPagePresenter {
  parse(data: any): IPageGuide {
    const defaultPage: IPageDefault = super.parse(data);
    // merge defaultPage with extra Page data
    return defaultsDeep(
      <IPageGuide>{
        __typename: TypeNamePageGuide,
        chapo: data['chapo'] || '',
        chapters: this.getChapters(data['cards']),
      },
      defaultPage
    );
  }

  private getChapters(data: Array<any>): Array<IChapter> {
    const chapters: Array<IChapter> = [];
    forEach(data, item => {
      const chapter = {
        title: item.entity.title || '',
        description: item.entity.description || '',
        url: LinkPresenter.getLink(item.entity.titleLink),
        cover: MediaImagePresenter.getImage(item.entity.cover),
        links: map(item.entity.links, link => {
          return LinkPresenter.getLink(link);
        }),
      };

      chapters.push(chapter);
    });
    return chapters;
  }
}
