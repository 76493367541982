@switch (selector) {
  @case ('h1') {
    <h1 class="h1-title" [ngClass]="addedClass" [id]="id" nscfSummaryScrollAction>
      {{ title }}
    </h1>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  }
  @case ('h2') {
    <h2 class="h2-title" [ngClass]="addedClass" [id]="id" nscfSummaryScrollAction>
      {{ title }}
    </h2>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  }
}

<ng-template #separator>
  @if (title && isNewArtisticDirection() && shouldDisplaySeparator()) {
    <span class="tw-block tw-w-[60px] slg:tw-w-[100px] tw-h-[8px] tw-bg-secondary-color tw-mb-8"></span>
  }
</ng-template>
