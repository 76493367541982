import { RTEPresenter } from './rte.presenter';
import { IRteText } from '../../interfaces/rte-text';
import { IPreHeader } from '../../components/pre-header/pre-header';

export enum PreHeaderTheme {
  Discreet = 'discreet',
  Flash = 'flash',
}

export interface IPreHeaderDataBo {
  entity: {
    content: IRteText;
    color: PreHeaderTheme;
  };
}

export class PreHeaderPresenter {
  public static parse(data: IPreHeaderDataBo): IPreHeader | null {
    if (!data) {
      return null;
    }
    return {
      content: RTEPresenter.getRTEText(data.entity.content),
      isDark: data.entity.color === PreHeaderTheme.Flash,
    };
  }
}
