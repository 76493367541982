import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'frenchFullDate',
    standalone: true
})
export class FrenchFullDatePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe, @Inject(LOCALE_ID) private readonly locale: string) {}

  transform(value: number): string {
    const dateFormat = "dd/MM/YYYY à HH'h'mm";
    return this.datePipe.transform(value, dateFormat, null, this.locale);
  }
}
