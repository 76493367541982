import { MetaTagEntities } from '../../interfaces/pages';

export class UrlUtils {
  static getPathnameInPreview(metaTags: MetaTagEntities): string {
    try {
      const url = metaTags?.find(el => el.name === 'og:url')?.content;
      return new URL(url).pathname;
    } catch {
      return '';
    }
  }

  static isPreviewUrl(pathname: string): boolean {
    return pathname.startsWith('/previewbo');
  }
}
