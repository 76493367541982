<div class="container">
  <div class="text-video spacing-block-text">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <nscf-title
          *ngIf="element.title"
          [id]="'anchor_' + element.id"
          [selector]="element.isFirstTitle ? 'h1' : 'h2'"
          [title]="element.title"
          class="block-title text-center"
        ></nscf-title>
        <!-- Player wrapper / image call -->
        <div
          (click)="loadMedia($event, stepNumber)"
          *ngIf="element.video"
          [ngClass]="{
            loaded: isMediaLoaded(stepNumber) || !element.cover,
          }"
          class="tw-relative load-video"
        >
          @if (isNewArtisticDirectionTheme() && !isMediaLoaded(stepNumber) && element.cover) {
            <nscf-video-play-background />
          }
          <!-- Image call -->
          <ng-container *ngIf="!isMediaLoaded(stepNumber) || !element.cover">
            <img
              *ngIf="element.cover"
              [alt]="element.cover?.alt || ''"
              [src]="element.cover.url | safeResourceUrl"
              loading="lazy"
              class="w-100"
            />
          </ng-container>
          <!-- Iframe media -->
          <ng-container *ngIf="isMediaLoaded(stepNumber) || !element.cover">
            <iframe
              *ngIf="element.cover"
              [id]="'player-' + stepNumber"
              [src]="generateEmbedUrl(element.video, true) | safeResourceUrl"
              [title]="element?.title || ''"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="0"
            ></iframe>
            <iframe
              *ngIf="!element.cover"
              [id]="'player-' + stepNumber"
              [src]="generateEmbedUrl(element.video) | safeResourceUrl"
              [title]="element?.title || ''"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="0"
              loading="lazy"
            ></iframe>
          </ng-container>
        </div>

        <div *ngIf="element.legend" class="legend">
          <p>{{ element.legend }}</p>
        </div>
        @if (isNewArtisticDirectionTheme() && element.ctaLabel) {
          <a
            effy-button
            [size]="buttonSize.LARGE"
            [color]="buttonStyle.PRIMARY"
            [attr.href]="element?.ctaUrl?.url"
            [attr.rel]="element?.ctaUrl?.options?.attributes?.rel"
            [attr.target]="element?.ctaUrl?.options?.attributes?.target"
            [attr.id]="element?.ctaUrl?.options?.attributes?.id"
            [ngClass]="element?.ctaUrl?.options?.attributes?.class"
            class="tw-mt-8 tw-mx-auto"
            [innerHTML]="element?.ctaLabel"
          ></a>
        } @else {
          <nscf-cta-button
            *ngIf="element.ctaLabel"
            [btnClass]="'cta-primary big-btn'"
            [ctaUrl]="element.ctaUrl"
            [text]="element.ctaLabel"
            [theme]="theme"
          ></nscf-cta-button>
        }
      </div>
    </div>
  </div>
</div>
