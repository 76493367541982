import { IRteText } from '../../../interfaces/rte-text';
import { ILink } from '../../../interfaces/link';

export const TypeNameSubBlockTables = 'ParagraphTableau';

export interface ISubBlockTables {
  __typename: 'ParagraphTableau';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  text?: IRteText;
  ctaLabel?: string;
  ctaUrl?: ILink;
}
