<div class="container p-0">
  <div class="cardification">
    <div class="cardification__filters_wrapper">
      <ng-container *ngIf="isBigDesktop && filters.length > 1">
        @if (isNewDA()) {
          <mat-tab-group (selectedTabChange)="changeFilterTab($event.tab.textLabel)" mat-align-tabs="start" mat-stretch-tabs="false">
            @for (filter of filters; track $index) {
              <mat-tab [label]="filter.title"></mat-tab>
            }
          </mat-tab-group>
        } @else {
          <div
            *ngFor="let filter of filters; index as index"
            class="cardification__filter"
            (click)="changeFilter(filter)"
            [ngClass]="{ 'cardification__filter--active': filter.id === currentFilter.id }"
          >
            <span>{{ filter.title }}</span>
          </div>
        }
      </ng-container>
      <div *ngIf="isBrowser && !isBigDesktop && filters.length > 1" class="cardification__select">
        @if (isNewDA()) {
          <mat-form-field appearance="outline">
            <mat-select [value]="filters[0].id" (selectionChange)="changeFilterSelect($event.value)">
              @for (filter of filters; track $index; let last = $last) {
                <ng-container>
                  <mat-option [value]="filter.id">{{ filter.title }}</mat-option>
                  @if (!last) {
                    <mat-divider class="tw-w-11/12 mx-auto"></mat-divider>
                  }
                </ng-container>
              }
            </mat-select>
          </mat-form-field>
        } @else {
          <nscf-select
            [source]="filters"
            [current]="currentFilter"
            [placeHolder]="filters[0].title"
            [displayedProperty]="'title'"
            (selectionChanged)="changeFilter($event)"
          >
          </nscf-select>
        }
      </div>
    </div>
    <div *ngIf="animate" [@fadeInCardsAnimation]="'in'">
      <div class="cardification__wrapper-cards">
        <div class="cardification__wrapper-card" *ngFor="let card of visibleCards; index as stepNumber">
          <nscf-medias-card [theme]="theme" [stepNumber]="stepNumber" [slide]="card"></nscf-medias-card>
        </div>
      </div>
      <div
        class="cardification__cta-button"
        *ngIf="currentFilter.id === 'tous' && cards.length > pageSize && visibleCards.length < cards.length"
      >
        <nscf-cta-button
          [theme]="theme"
          [redirection]="false"
          (click)="loadMore()"
          [btnClass]="'tag-secondary big-btn'"
          [text]="'Voir Plus'"
          [ctaUrl]="null"
        ></nscf-cta-button>
      </div>
    </div>
  </div>
</div>
