import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StoredResponse } from '../interfaces/stored-response';
import { ConfigService } from '../modules/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(
    private readonly transferState: TransferState,
    private readonly configService: ConfigService,
    @Inject(PLATFORM_ID) readonly platformId: Object
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key = req.url.replace(this.configService.getEnvironment().graphqlApiEndpoint, '');
    if (req.method !== 'GET' || !isPlatformBrowser(this.platformId)) {
      return next.handle(req);
    }
    const storedResponse: StoredResponse = this.transferState.get(makeStateKey(key), null);
    if (storedResponse) {
      const response = new HttpResponse(storedResponse);
      return of(response);
    }
    return next.handle(req);
  }
}
