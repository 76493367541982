import { Component } from '@angular/core';

@Component({
    selector: 'nscf-regulatory-phone',
    templateUrl: './regulatory-phone.component.html',
    styleUrls: ['./regulatory-phone.component.scss'],
    standalone: true
})
export class RegulatoryPhoneComponent {
  phoneNumber = 3456;
}
