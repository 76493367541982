import { ILink, ILinkAttributes } from '../../interfaces/link';

export class LinkPresenter {
  public static getLink(data: any): ILink {
    // Avoid empty / undefined data.
    if (!data) {
      return null;
    }
    return {
      url: (data['url'] && data['url'].path) || '',
      title: data['title'] || '',
      options: <ILinkAttributes>{
        attributes: {
          id: data['options']?.attributes?.id ? data['options']?.attributes?.id.replace(/ /g, '-') : undefined,
          target: data['options']?.attributes?.target ?? '_self',
          rel: data['options']?.attributes?.rel ?? undefined,
          class: data['options']?.attributes?.class ?? [],
        },
      },
    };
  }
}
