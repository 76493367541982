import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvgComponent } from './svg/svg.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'nscf-star',
    templateUrl: 'star.component.html',
    styleUrls: ['star.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, SvgComponent]
})
export class StarComponent implements OnInit {
  starItems: Array<string> = [];
  ratingNb: number;
  color: string;
  @Input() rating = 0;
  @Input() maxScore = 0;
  @Input() alt: string;
  @Input() isVerifiedReviews: boolean;
  @Input() disableLazyLoad = false;

  @Output() ratingClicked: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    this.color = this.isVerifiedReviews ? '#ff9000' : '#ffde3b';
    this.ratingNb = Number(this.rating);

    if (!this.alt) {
      this.alt = `${this.rating} sur ${this.maxScore}`;
    }
  }

  onClick(): void {
    this.ratingClicked.emit();
  }

  counter(count: number): Array<number> {
    return Array.from({ length: count }, (v, k) => k + 1);
  }
}
