import { IArticlePromo } from '../../components/article-blocs/article-promo/article-promo';
import { ICtaLink } from '@effy-tech/acquisition-header';
import { IRteText } from '../../interfaces/rte-text';
import { WebsiteThemes } from '../../enums/website-themes';
import { RTEPresenter } from '../commons/rte.presenter';
import { LinkPresenter } from '../commons/link.presenter';

export interface PromoBlockEntity {
  __typename: string;
  entityType: string;
  showPromotionalBlock: boolean;
  promotionalBlockBody: IRteText;
  promotionalBlockCta: ICtaLink;
  promotionalBlockColor: WebsiteThemes;
}

export interface Paragraphs {
  entity: PromoBlockEntity;
}

export interface FieldParagraphsEntity {
  __typename: string;
  entityType: string;
  paragraphs: Paragraphs;
}

export interface FieldReusableParagraph {
  entity: FieldParagraphsEntity;
}

export interface FieldReusableParagraphEntity {
  __typename: string;
  id: number;
  fieldReusableParagraph: FieldReusableParagraph;
}

export interface IBOPromoBlock {
  entity?: PromoBlockEntity | FieldReusableParagraphEntity;
}

export class BlockPromoPresenter {
  public static parsePromoBlock(data?: IBOPromoBlock): IArticlePromo {
    if (!data) {
      return null;
    }
    const entity: PromoBlockEntity = this.getEntityBlockPromo(data);

    return {
      showPromoBlock: entity?.showPromotionalBlock,
      text: RTEPresenter.getRTEText(entity?.promotionalBlockBody),
      link: LinkPresenter.getLink(entity?.promotionalBlockCta),
      site: entity?.promotionalBlockColor,
    };
  }

  private static getEntityBlockPromo(data: IBOPromoBlock): PromoBlockEntity {
    return this.isFieldReusableParagraphEntity(data.entity) ? data.entity.fieldReusableParagraph.entity.paragraphs.entity : data.entity;
  }

  private static isFieldReusableParagraphEntity(
    entity: PromoBlockEntity | FieldReusableParagraphEntity,
  ): entity is FieldReusableParagraphEntity {
    return 'fieldReusableParagraph' in entity;
  }
}
