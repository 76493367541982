import { LocationValues } from '../../values/location.values';

interface LocationLabelValues {
    [key: string]: string;
  }

export const LOCATION_LABEL_VALUES: LocationLabelValues = {
    [LocationValues.IleDeFrance]: 'Ile de France',
    [LocationValues.OtherRegions]: 'Autres régions',
  };
