<div class="spacing-block" *ngIf="data.show" id="anchor_verified_reviews">
  <div class="w-100">
    <nscf-title
      [selector]="data.isFirstTitle ? 'h1' : 'h2'"
      class="text-center"
      [title]="data.title"
      [id]="'anchor_verified_reviews_title'"
    >
    </nscf-title>
    <nscf-verified-reviews></nscf-verified-reviews>
  </div>
</div>
