<div class="spacing-block">
  <div class="top-header__background-cover" [ngStyle]="urlCover" *ngIf="data" #cover>
    <div class="top-header__desktop top-header__mobile container">
      <div class="top-header">
        <span>
          <nscf-title *ngIf="data.title" [selector]="'h1'" [title]="data.title" [id]="'anchor_' + data.id"></nscf-title>
          <div id="top-of-site-pixel-anchor"></div>
          <div class="top-header__subtitle text-center">
            {{ data.subtitle }}
          </div>
          <div appCtaAction></div>
          <nscf-cta-button [theme]="theme" [btnClass]="'cta-primary big-btn'" [text]="data.buttonText"
            [ctaUrl]="data.ctaUrl"></nscf-cta-button>
          <nscf-trustpilot-micro-star *ngIf="data.enableTrustPilot"></nscf-trustpilot-micro-star>
          <div class="top-header__contract">{{ data.termsTitle }}</div>
          <nscf-liseret [theme]="theme"></nscf-liseret>
          <div id="slider" class="slider">
            <nscf-top-page-carousel [theme]="theme" [hidden]="!isBrowser" [topPage]="data"></nscf-top-page-carousel>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>