@if (isNewArtisticDirectionTheme()) {
  @if (title && questions?.length) {
    <h3 class="tw-font-700 tw-text-new-xl slg:tw-text-new-2xl tw-mb-4">{{ title }} : vos questions</h3>
    <span class="tw-w-[60px] tw-h-[8px] tw-mb-6 tw-bg-secondary-color"></span>
    <div class="tw-flex tw-flex-col tw-gap-y-6">
      <a effy-link *ngFor="let item of questions | slice: 0 : nbMaxQuestionsDisplayed" [attr.href]="item.url">
        <mat-icon svgIcon="chevron-right" class="tw-w-4 tw-h-4 tw-text-[16px] tw-flex tw-flex-shrink-0 tw-self-start" /><span
          [innerHTML]="item.text"
        >
          {{ item.text }}</span
        ></a
      >
    </div>
  }
} @else {
  <div
    *ngIf="title && !!questions?.length"
    class="ui-heading-questions"
    [ngStyle]="{ 'background-image': !isNewArtisticDirectionTheme() ? 'url(' + fieldImage?.url + ')' : '' }"
  >
    <h3 class="ui-heading-questions__title">{{ title }} : vos questions</h3>
    <nscf-liseret [theme]="theme" [isCentered]="true"></nscf-liseret>

    <div class="ui-heading-questions__list">
      <div *ngFor="let item of questions | slice: 0 : nbMaxQuestionsDisplayed" class="ui-heading-questions__item">
        <nscf-link [url]="item.url" [text]="item.text" [theme]="theme"></nscf-link>
      </div>
    </div>
  </div>
}
