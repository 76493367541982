import { IBlockPresenter } from '../presenter';
import {
  IVerifiedReviewsContainer,
  TypeNameBlockVerifiedReviews,
} from '../../components/verified-reviews-container/verified-reviews-container';

export class BlockVerifiedReviewsContainerPresenter implements IBlockPresenter {
  parse(data: any): IVerifiedReviewsContainer {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: IVerifiedReviewsContainer = {
      __typename: TypeNameBlockVerifiedReviews,
      title: data['title'],
      show: data['show'],
    };

    return block;
  }
}
