<div class="container" *ngIf="phoneInsert?.phoneNumber || isRegulatory">
  <div class="phone-block" [ngClass]="{ 'phone-block--regulatory': isRegulatory }">
    <div class="phone-block__phone-part">
      <p class="phone-block__help  {{ isDesktop ? 'phone-block__desktop-color' : 'phone-block__mobile-color' }}">
        {{ phoneInsert?.phoneHook }}
      </p>
      <ng-container *ngIf="isDesktop" [ngTemplateOutlet]="phoneText"></ng-container>
    </div>

    <div class="phone-block__text-part">
      <ng-container *ngIf="isRegulatory; else normalPhone">
        <nscf-regulatory-phone></nscf-regulatory-phone>
      </ng-container>

      <ng-template #normalPhone>
        <a [href]="'tel:' + phoneInsert?.phoneNumber" class="phone-block__phone">
          {{ phoneInsert?.phoneNumber }}
        </a>
      </ng-template>
    </div>

    <ng-container *ngIf="!isDesktop" [ngTemplateOutlet]="phoneText"></ng-container>
  </div>
</div>

<ng-template #phoneText>
  <p *ngIf="phoneInsert?.accompanimentPhone?.value"
    class="phone-block__text {{ isDesktop ? 'phone-block__desktop-color' : 'phone-block__mobile-color' }}"
    [innerHTML]="phoneInsert.accompanimentPhone.value"></p>
</ng-template>