import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { CarouselComponent } from '../carousel/carousel.component';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { IMedias } from './medias';
import { MediasCardComponent } from './medias-card/medias-card.component';
import { MediasCardificationComponent } from './medias-cardification/medias-cardification.component';
import { LiseretComponent } from '../liseret/liseret.component';
import { DirectionArtisticDirective, EffyButtonComponent, ThemeHelperService } from '@effy-tech/angular-common';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'nscf-medias-component',
    templateUrl: 'medias.component.html',
    styleUrls: ['medias.component.scss'],
    standalone: true,
    imports: [NgIf, TitleComponent, RteComponent, NgClass, CarouselComponent, MediasCardComponent, MediasCardificationComponent, LiseretComponent, EffyButtonComponent],
    hostDirectives: [DirectionArtisticDirective],
})
export class MediasComponent extends BasicThemedComponentDirective {
  readonly isNewArtisticDirection = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  @Input() data: IMedias;
  @Input() carrousel: boolean;
  @Input() isBrowser: boolean;

  constructor() {
    super();
  }
}
