import { TrustpilotRates } from "../../interfaces";
import { IRating } from "../../services/rich-results/rich-results.values";

export class AggregateRatingUtil {
  static mapTrustpilotRatesToAggregateRatings(trustpilotRates: TrustpilotRates): IRating {
    return {
      ratingValue: trustpilotRates.score.trustScore,
      ratingCount: trustpilotRates.numberOfReviews.usedForTrustScoreCalculation,
      worstRating: 1,
      bestRating: 5, 
    }
  }
}