import { ISocialLink, SupportedSocialMedia } from './footer';

export const ctaSubject = 'Un projet ?';
export const socialsLinkText = 'Suivez nous';

export const getSocialLinks = (isPro = false): Array<ISocialLink> => {
  return [
    {
      name: SupportedSocialMedia.Facebook,
      link: isPro ? 'https://www.facebook.com/EffyPartenaires' : 'https://www.facebook.com/effygroupe',
    },
    {
      name: SupportedSocialMedia.Twitter,
      link: 'https://twitter.com/EffyGroupe',
    },
    {
      name: SupportedSocialMedia.Linkedin,
      link: 'https://www.linkedin.com/company/effy',
    },
  ];
};
