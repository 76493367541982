<div *ngIf="data?.isActive" class="container">
  <div class="newsletter">
    <picture>
      <source media="(min-width: 1024px)" srcset="assets/backgrounds/newsletter-desktop.webp" width="423" height="420" />
      <img loading="lazy" src="assets/backgrounds/newsletter-mobile.webp" width="344" height="168" alt="Exemples de newsletter" />
    </picture>
    <div class="newsletter__content">
      <p class="newsletter__title">
        <span class="newsletter__title--display">Profitez des </span> meilleurs conseils
        <span class="newsletter__title--black">pour faire des économies d’énergie</span>
      </p>
      <p class="newsletter__subtitle">
        {{ isNewArtisticDirection() ? "Interviews d'experts - Astuces - Actualités" : "Interviews d'experts · Astuces · Actualités" }}
      </p>
      <div>
        <div *ngIf="!isSuccessfullySubscribed" class="newsletter__form">
          @if (isNewArtisticDirection()) {
            <form [formGroup]="emailFG">
              <mat-form-field>
                <mat-label>Votre adresse mail*</mat-label>
                <input matInput [formControl]="emailFG.controls.email" />
              </mat-form-field>
            </form>
            <button
              class="newsletter__form-button-new-da"
              effy-button
              [color]="buttonStyle"
              [size]="buttonSize"
              (click)="subscribeNewsletter()"
              [disabled]="emailFC.invalid"
            >
              Je m'abonne
            </button>
          } @else {
            <div>
              <input
                class="newsletter__form-input"
                id="mail"
                type="text"
                [formControl]="emailFC"
                placeholder="Votre adresse mail*"
                required
              />
              <p *ngIf="emailFC.invalid && emailFC.touched" class="newsletter__form-error">Veuillez renseigner un e-mail valide.</p>
            </div>
            <button class="newsletter__form-button" [disabled]="emailFC.invalid" (click)="subscribeNewsletter()">
              {{ "JE M'ABONNE" }}
            </button>
          }
        </div>
        <div *ngIf="isSuccessfullySubscribed" class="submitted-message">
          <p>Votre inscription est bien enregistrée. Merci et à bientôt !</p>
        </div>
      </div>
      <p class="newsletter__notice">Pas d’inquiétude, vous pouvez vous désabonner de notre newsletter à tout moment !</p>

      <div class="social-network">
        <p class="social-network-text">
          Suivez nous également<br />
          sur nos réseaux sociaux
        </p>
        <div class="social-network-icons">
          <a *ngFor="let item of newsletterNetworkConfig" [href]="item.href" target="_blank">
            <img [src]="item.src" width="36" height="36" [attr.alt]="item.alt" loading="lazy" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
