import { CurrencyPipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AmountService {

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
  ) { }

  formatAmount(amount: number): string {
    return new CurrencyPipe(this.locale).transform(
      amount,
      'EUR',
      'symbol',
      '1.0-0',
      this.locale
    );
  }
}
