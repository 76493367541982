<a class="dropdown__wrapper active" id="bc_dropdown">
  <span> . . . </span>
  <div class="dropdown" [hidden]="!isVisible">
    <div *ngFor="let link of links">
      <a *ngIf="link.url" [href]="link.url">
        <p>{{ link.label | truncate: 45 }}</p>
      </a>
      <p *ngIf="!link.url">{{ link.label | truncate: 45 }}</p>
    </div>
  </div>
</a>
