import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { toPercentage } from '../../transforms/transforms';

@Component({
  selector: 'nscf-scroll-progress-bar',
  standalone: true,
  templateUrl: './scroll-progress-bar.component.html',
  styleUrls: ['./scroll-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollProgressBarComponent extends BasicThemedComponentDirective {
  @Input({ transform: toPercentage }) progress = 0;
}
