import { LocationValues } from '../values/location.values';
import { LOCATION_LABEL_VALUES } from './labels/location.labels';

export interface CustomRadioModel {
  value: LocationValues;
  label: string;
  name: string;
  checked: boolean;
}

export const LOCATION_CONFIG: CustomRadioModel[] = [
  {
    value: LocationValues.IleDeFrance,
    label: LOCATION_LABEL_VALUES[LocationValues.IleDeFrance],
    name: 'location',
    checked: false
  },
  {
    value: LocationValues.OtherRegions,
    label: LOCATION_LABEL_VALUES[LocationValues.OtherRegions],
    name: 'location',
    checked: true
  }
];

