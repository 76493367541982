import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { LoggerService } from '@effy-tech/common/loggers';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { TopPageCategorieComponent } from '../../components/categorie/top-page-categorie/top-page-categorie.component';
import { IPageCategorie } from '../../interfaces/pages';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { DynamicPageDirective } from '../dynamic-page';

@Component({
  selector: 'nscf-categorie-page',
  templateUrl: 'categorie.component.html',
  styleUrls: ['categorie.component.scss'],
  standalone: true,
  imports: [NgIf, BreadcrumbComponent, TopPageCategorieComponent],
  hostDirectives: [DirectionArtisticDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriePageComponent extends DynamicPageDirective implements OnInit, OnDestroy {
  @Input() data: IPageCategorie;

  @ViewChild('mainContent', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(
    protected readonly logger: LoggerService,
    protected readonly deviceManagerService: DeviceManagerService
  ) {
    super(logger, deviceManagerService);
  }

  ngOnInit() {
    this.getDeviceType().subscribe();
  }

  buildPage(): void {
    this.clearPage();
    const dynamicComponentsData = super.fieldBlocksAsDynamicComponentData(this.data.blocks);
    super.appendDynamicComponentData(this.container, this.addActualityIdToComponents(dynamicComponentsData), false);
  }

  clearPage(): void {
    this.container.clear();
  }

  private addActualityIdToComponents(dynamicComponentsData) {
    return dynamicComponentsData.map(componentData => {
      return { ...componentData, content: { ...componentData.content, actualiteThemeId: this.data.actualiteThemeId } };
    });
  }

  ngOnDestroy() {
    this.doUnsubscribe();
  }
}
