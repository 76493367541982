import { map, split, trim } from 'lodash-es';
import { ICardSummary } from '../../interfaces/cards-summary';
import { ITheme } from '../../interfaces/taxonomy';
import { ImagesService } from '../../services/images-service/images.service';
import { CardSimplifiedPresenter } from './shared/card-simplified.presenter';

export class CardificationNewsPresenter {
  static mapNewsCards(obj: any, themes, isThemesId, visibleThemes: Array<ITheme>): Array<ICardSummary> {
    const data = obj?.data?.cardificationGraphQLCardNHP?.results;
    return map(data, card => {
      const taxonomies = getTaxonomiesNews(card, themes, isThemesId, visibleThemes);
      const simplifiedCard = CardSimplifiedPresenter.mapCardBoToSimplifiedCard(card);
      return {
        taxonomies: taxonomies.slice(0, 1),
        nid: card.nid,
        created: card.created,
        wideCover: ImagesService.rewriteImagesUrl(card.wideCover),
        wideCoverWebp: ImagesService.rewriteImagesUrl(card.wideCoverWebp),
        ...simplifiedCard,
      };
    });
  }
}

function getTaxonomiesNews(card, themes, isThemesId, visibleThemes: Array<ITheme>): Array<string> {
  return getTaxonomies(card)
    .map(tid => visibleThemes?.filter(theme => theme.visibility).find(theme => +theme.tid === +tid)?.name)
    .filter(nameTheme => {
      return isThemesId || (nameTheme && themes.indexOf(nameTheme) === -1);
    });
}

function getTaxonomies(o: object): Array<string> {
  return split(o['theme'], ',').map(v => trim(v));
}
