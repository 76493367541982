import { IFieldMediaImage } from '../../../interfaces/field-media-image';
import { IRteText } from '../../../interfaces/rte-text';
import { ILink } from '../../../interfaces/link';

export const TypeNameTopPageCategorie = 'ParagraphCategorie';

export interface ITopPageCategorie {
  __typename: 'ParagraphCategorie';
  id?: number;
  title?: string;
  description?: IRteText;
  image?: IFieldMediaImage;
  ctaLabel?: string;
  ctaUrl?: ILink;
}
