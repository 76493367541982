<nscf-pre-footer *ngIf="data.showPreFooter" [theme]="theme"></nscf-pre-footer>
@if (isNewArtisticDirection()) {
  <footer class="tw-flex tw-flex-col tw-bg-main-color tw-relative">
    <button class="new-footer__arrow-scroll" (click)="scrollToTop()" aria-label="Faire défiler jusqu'en haut">
      <mat-icon [svgIcon]="'chevron-right'" class="new-footer__arrow-scroll-icon"></mat-icon>
    </button>
    <div class="tw-grid tw-grid-cols-1 smd:tw-grid-cols-[min-content,1fr] tw-gap-10 tw-px-8 tw-py-12 smd:tw-px-[50px] md:tw-px-[124px] smd:tw-py-[88px]">
      <div class="tw-flex tw-flex-col tw-items-center smd:tw-items-start tw-gap-20 tw-w-full smd:tw-w-[400px]">
        <mat-icon class="site-logo" [svgIcon]="siteLogo()"></mat-icon>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-10">
            <p class="tw-text-new-2xl smd:tw-text-new-3xl tw-font-700 tw-mb-0" [innerHTML]="content.ctaSubject | safeHtml"></p>
              <a [href]="content.cta.url" effy-button [color]="ctaStyle()" class="tw-w-full">
                @if (isSolar()) {
                  Je passe au solaire
                } @else {
                  {{ content.cta.title }}
                }
              </a>
          </div>
      </div>
      <div class="smd:tw-justify-self-end tw-w-fit tw-grid tw-gap-10 tw-grid-cols-1 smd:tw-grid-cols-2" [ngClass]="{'smd:tw-grid-cols-3': content.columns.length > 4}">
        @for(column of content.columns; track $index) {
          <div class="tw-min-w-[216px] tw-flex tw-flex-col tw-gap-4 tw-items-start">
            @for(columnLink of column.columnLinks; track $index) {
              @if (columnLink.mainLink) {
                @if ( columnLink.url) {
                  <a [href]="columnLink.url" class="tw-text-new-md tw-font-700">{{ columnLink.link }}</a>
                } @else {
                  <p class="tw-text-new-md tw-font-700 tw-mb-0">{{ columnLink.link }}</p>
                }
              }
              @else if (columnLink.url) {
                <a [href]="columnLink.url" class="column-link">
                  <span>{{ columnLink.link }}</span>
                  <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
                </a>
              }
            }
          </div>
        }
      </div>
      <div class="tw-flex tw-flex-col tw-gap-4">
        <div class="tw-text-new-xl tw-font-300 ">{{ content.siteLogo.title }}</div>
        <div class="tw-w-fit tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-6">
          @for (siteLogo of content.siteLogo.logos; track $index) {
            <a [href]="siteLogo.link">
              <mat-icon class="tw-h-8 smd:tw-h-12 tw-w-auto" [svgIcon]="siteLogo.icon"></mat-icon>
            </a>
          }
        </div>
      </div>
      <div class="smd:tw-justify-self-end tw-flex tw-flex-col tw-gap-10">
        <div class="tw-flex tw-flex-col smd:tw-flex-row tw-gap-4 smd:tw-gap-10">
          <span class="tw-text-new-xl tw-font-700">{{ content.socialsLink.text }}</span>
          <div class="tw-flex tw-gap-4">
            @for(socialLink of content.socialsLink.links; track $index) {
              <a [href]="socialLink.link" [attr.aria-label]="socialLink.name" class="tw-flex hover:tw-text-primary-color">
                <mat-icon class="tw-size-8" [svgIcon]="socialLink.name"></mat-icon>
              </a>
            }
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-4">
          @for(cguLink of content.cguLinks; track $index) {
            <a class="tw-text-new-sm tw-font-600 hover:tw-text-primary-color" [href]="cguLink.url">{{ cguLink.text }}</a>
          }
        </div>
      </div>
    </div>
    <div *ngIf="content?.banner" class="new-footer__banner">
      <div class="new-footer__container">
        <div class="new-footer__row">
          <div class="new-footer__col-12 new-footer__col-lg-8 new-footer__wrapper-banner">
            <p class="new-footer__banner-title">
              {{ content?.banner?.title }}
            </p>
            <p class="new-footer__banner-description">
              {{ content?.banner?.description }}
            </p>
          </div>
          <div class="new-footer__col-12 new-footer__col-lg-4">
            <a *ngIf="content?.banner?.cta" class="new-footer__banner-cta" [href]="content?.banner?.cta?.url">{{
              content?.banner?.cta?.title
              }}</a>
          </div>
        </div>
      </div>
    </div>
    @if(content?.banner) {
      <div [ngClass]="bannerTheme()" class="tw-flex tw-flex-col tw-items-center smd:tw-flex-row tw-justify-between tw-gap-5 tw-bg-primary-color tw-text-on-primary-color tw-px-8 smd:tw-px-[50px] md:tw-px-[124px] tw-py-6">
        <div class="tw-flex tw-flex-col">
          <p class="tw-text-new-xl tw-font-700 tw-mb-0">{{ content?.banner?.title }}</p>
          <p class="tw-text-new-lg tw-font-300 tw-mb-0">{{ content?.banner?.description }}</p>
        </div>
        <a [href]="content.cta.url" 
          effy-button
          [size]="bannerButtonSize()"
          class="tw-w-full smd:tw-w-[400px]" 
          [style.--effy-button-border-color]="!isPro() ?? '#ffffff'">
          <span>{{ content.cta.title }}</span>
          <mat-icon [svgIcon]="'chevron-right'" class="tw-flex"></mat-icon>
        </a>
      </div>
    }
  </footer>
} @else {
  <footer class="new-footer">
    <button class="new-footer__arrow-scroll" (click)="scrollToTop()" aria-label="Faire défiler jusqu'en haut"></button>
    <div class="new-footer__container new-footer__wrapper">
      <div class="new-footer__row">
        <div class="new-footer__col-12 new-footer__col-lg-4">
          <div class="new-footer__wrapper-title">
            <img loading="lazy" class="new-footer__logo" [src]="content?.logo?.url" [alt]="content?.logo.alt" />
            <p *ngIf="isDesktop() === false" class="new-footer__title" [innerHTML]="content.title | safeHtml">
            </p>
          </div>
          <template *ngIf="isDesktop()" [ngTemplateOutlet]="socialPlatform"
            [ngTemplateOutletContext]="{ socialPlatform: content.socialsLink }"></template>
          <div class="new-footer__wrapper-cta">
            <ng-container *ngIf="!isSolar(); else solarBlock">
              <p class="new-footer__cta-subject" [innerHTML]="content.ctaSubject | safeHtml"></p>
              <a class="new-footer__cta" [href]="content.cta.url">
                <nscf-link-button [theme]="theme" [link]="content?.cta"></nscf-link-button>
                <span class="new-footer__cta-text">
                  {{ content.cta.title }}
                </span>
              </a>
            </ng-container>
          </div>
          <ng-template #solarBlock>
            <ng-content></ng-content>
          </ng-template>
        </div>
        <div class="new-footer__col-12 new-footer__col-lg-8">
          <p *ngIf="isDesktop()" class="new-footer__title" [innerHTML]="content.title | safeHtml"></p>
          <div class="new-footer__wrapper-column" [ngClass]="'new-footer__wrapper-column-' + content.columns.length">
            <div *ngFor="let column of content.columns" class="new-footer__column">
              <div *ngFor="let columnLink of column.columnLinks">
                <p *ngIf="columnLink.mainLink && !columnLink.url"
                  [ngClass]="{ 'new-footer__column-link--black': columnLink.mainLink }">
                  {{ columnLink.link }}
                </p>
                <a *ngIf="columnLink.url" [ngClass]="{ 'new-footer__column-link--black': columnLink.mainLink }"
                  class="new-footer__column-link" [href]="columnLink.url">
                  {{ columnLink.link }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-footer__row">
        <div class="new-footer__col-12 new-footer__col-lg-4">
          <div class="new-footer__site-logo-title">{{ content.siteLogo.title }}</div>
          <div class="new-footer__wrapper-site-logo">
            <ng-container *ngFor="let siteLogo of content.siteLogo.logos">
              <a class="new-footer__site-logo-link" [href]="siteLogo.link">
                <img loading="lazy" class="new-footer__site-logo" [ngClass]="siteLogo.site" [src]="siteLogo.url"
                  [alt]="siteLogo.alt" />
              </a>
            </ng-container>
          </div>
        </div>
        <div class="new-footer__col-12 new-footer__col-lg-8">
          <template *ngIf="isDesktop()=== false" [ngTemplateOutlet]="socialPlatform"
            [ngTemplateOutletContext]="{ socialPlatform: content.socialsLink }"></template>
          <div class="new-footer__wrapper-cgu">
            <div *ngFor="let cguLink of content.cguLinks">
              <a class="new-footer__cgu-link" [href]="cguLink.url">{{ cguLink.text }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="content?.banner" class="new-footer__banner">
      <div class="new-footer__container">
        <div class="new-footer__row">
          <div class="new-footer__col-12 new-footer__col-lg-8 new-footer__wrapper-banner">
            <p class="new-footer__banner-title">
              {{ content?.banner?.title }}
            </p>
            <p class="new-footer__banner-description">
              {{ content?.banner?.description }}
            </p>
          </div>
          <div class="new-footer__col-12 new-footer__col-lg-4">
            <a *ngIf="content?.banner?.cta" class="new-footer__banner-cta" [href]="content?.banner?.cta?.url">{{
              content?.banner?.cta?.title
              }}</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  
  <ng-template #socialPlatform let-socialPlatform="socialPlatform">
    <div class="new-footer__wrapper-social-content">
      <div class="new-footer__social-title">{{ socialPlatform.text }}</div>
      <div class="new-footer__wrapper-social">
        <ng-container *ngFor="let socialLink of socialPlatform.links">
          <a [href]="socialLink.link" [attr.aria-label]="socialLink.name">
            <img height="36" width="36" [attr.alt]="socialLink.name" [src]="
                socialLink.isHovered
                  ? '/assets/new-footer/icon_' + socialLink.name + '.svg'
                  : '/assets/new-footer/icon_' + socialLink.name + '_white.svg'
              " (mouseenter)="socialLink.isHovered = true" (mouseleave)="socialLink.isHovered = false" loading="lazy" />
          </a>
        </ng-container>
      </div>
    </div>
  </ng-template>
}
