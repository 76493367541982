import { inject, Injectable } from '@angular/core';
import { ENV_DEV } from '../../configs/env/dev.config';
import { ENV_PROD } from '../../configs/env/prod.config';
import { ENV_RCT } from '../../configs/env/rct.config';
import { WIDGET_CALCULATOR_ENV } from '../../configs/injection-tokens.config';
import { ENVS, WidgetCalculatorConfig } from '../../models/widget-calculator.models';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private readonly env = inject(WIDGET_CALCULATOR_ENV);

  private config: WidgetCalculatorConfig;

  getEnv() {
    return this.env;
  }

  getConfig(): WidgetCalculatorConfig {
    if (this.config) {
      return this.config;
    }

    switch (this.env) {
      case ENVS.PROD:
        this.config = ENV_PROD;
        break;

      case ENVS.RCT:
        this.config = ENV_RCT;
        break;

      case ENVS.DEV:
      default:
        this.config = ENV_DEV;
        break;
    }

    return this.config;
  }
}
