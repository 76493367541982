import { forEach } from 'lodash-es';
import { IMediasCardsArticle, IMediasCardsVideo } from '../../components/medias/medias-card';
import { ImagesService } from '../../services/images-service/images.service';
import { PdfService } from '../../services/pdf/pdf.service';

export class CardificationMediaPresenter {
  static mapMediasCards(data: any, platformId: Object): Array<IMediasCardsArticle | IMediasCardsVideo> {
    const cards: any = []; // Array<IMediasCardsArticle | IMediasCardsVideo> = [];

    forEach(data, item => {
      let mainObject = {
        description: {
          format: 'html',
          value: item.description,
          processed: item.description,
        },
        picto: extractUrl(item.picto, platformId, true),
        name: item.name || '',
        date: item.date || '',
        id: item.id || '',
        type: item.type || '',
      };

      // Add specific cases for the different types
      switch (item.contentType) {
        case 'card_article':
          mainObject = {
            ...mainObject,
            ...{
              pdf: extractUrlFromPDFOrVideo(item.pdfCardArticle, platformId),
              url: item.urlCardArticle || '',
            },
          };
          break;
        case 'card_video':
          const cover = extractUrl(item.videoCoverCardVideo, platformId);
          mainObject = {
            ...mainObject,
            ...{
              video: extractUrlFromPDFOrVideo(item.videoCardVideo, platformId, true) || '',
              videoCover: cover
                ? {
                    url: cover.imageUrl,
                    alt: cover.imageAlt,
                  }
                : null,
            },
          };
          break;
        default:
          break;
      }

      cards.push(mainObject);
    });

    return cards;
  }
}

export function extractUrl(imageLink, platformId, isPicto?: boolean) {
  if (!platformId) {
    return;
  }

  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(imageLink, 'text/html');

  if (!parsedHtml.images || !parsedHtml.images.length) {
    return;
  }

  const img = parsedHtml.images[0];
  const imgUrl = ImagesService.rewriteImagesUrl(img.src);

  if (isPicto) {
    return {
      url: imgUrl,
      alt: img.alt,
      height: img.height,
      width: img.width,
      title: img.title,
    };
  }

  return {
    imageUrl: imgUrl,
    imageAlt: img.alt,
  };
}

function extractUrlFromPDFOrVideo(pdfObj, platformId, isVideo?: boolean) {
  if (!platformId) {
    return;
  }

  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(pdfObj, 'text/html');

  if (!parsedHtml.links || !parsedHtml.links.length) {
    return;
  }

  const link = parsedHtml.links[0];

  if (isVideo) {
    return link.href;
  }

  return {
    url: PdfService.rewritePdfsUrl(link.href),
  };
}
