import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, HostBinding, input, Input, OnInit } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { Observable } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import { DeviceManagerService, DeviceType } from '../../../../services/device-manager/device-manager.service';
import { IColorCardsDiscovery } from '../discovery-categorie';
import { ICardsDiscoveryCategorie } from './cards-discovery-categorie';
import { DirectionArtisticDirective, EffyLinkComponent, EffyLinkSize, EffyLinkStyle } from '@effy-tech/angular-common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'nscf-cards-discovery-categorie',
  templateUrl: './cards-discovery-categorie.component.html',
  styleUrls: ['./cards-discovery-categorie.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgStyle, NgClass, AsyncPipe, EffyLinkComponent, MatIcon],
  hostDirectives: [DirectionArtisticDirective]
})
export class CardsDiscoveryCategorieComponent extends BasicThemedComponentDirective implements OnInit {
  @Input()
  cards: ICardsDiscoveryCategorie;

  @Input()
  color: IColorCardsDiscovery;

  isNewArtisticDirection = input<boolean>(false);

  isDesktop$: Observable<boolean> = this.deviceManagerService.deviceType$().pipe(
    map((deviceType: DeviceType) => deviceType.isDesktop),
    take(1),
    share()
  );
  linkStyle = EffyLinkStyle;
  linkSize = EffyLinkSize;

  linkText$: Observable<string> = this.isDesktop$.pipe(map((isDesktop: boolean) => this.computeLinkText(isDesktop)));

  classColor: 'white' | 'blue';

  @HostBinding('class') get HeadingClass(): string {
    return `cards-discovery-categorie cards-discovery-categorie--${this.classColor} ${this.theme}`;
  }

  constructor(private readonly deviceManagerService: DeviceManagerService) {
    super();
  }

  ngOnInit(): void {
    this.classColor = this.color === IColorCardsDiscovery.BLUE ? 'blue' : 'white';
  }

  computeWaveUrl$(index: number): Observable<string> {
    return this.isDesktop$.pipe(
      map(
        (isDesktop: boolean) => `../../../../../assets/backgrounds/wave-${index}-${this.classColor}-${isDesktop ? 'desktop' : 'mobile'}.svg`
      )
    );
  }

  private computeLinkText(isDesktop: boolean): string {
    return isDesktop ? 'En savoir plus' : 'Lire';
  }
}
