<div class="container article-breadcrumb" *ngIf="items && items.length">
  <div class="col-lg-8">
    <div class="breadcrumb-effy" [ngClass]="{ 'breadcrumb-effy--mobile': !isDesktop }">
      <p class="breadcrumb-guide" *ngIf="!isDesktop">Vous êtes ici :</p>
      <ol class="breadcrumb-list">
        <ng-container *ngIf="isBrowser; else showItemsServer">
          <li class="breadcrumb-list-item">
            <a [href]="items[0].url">{{ items[0].label | truncate: 45 }}</a>
            @if(isNewArtisticDirection()) {
              <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
            }
          </li>
          <li *ngIf="items.length > 3" class="breadcrumb-list-item">
            <nscf-dropdown [links]="items | slice: 1:-1" class="bc-drop"></nscf-dropdown>
            @if(isNewArtisticDirection()) {
              <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
            }
          </li>
          <ng-container *ngIf="items.length > 2">
            <li
              *ngFor="let item of items | slice: -2 | slice: (items.length > 3 ? -1 : 0); let index = index; last as lastIndex"
              class="breadcrumb-list-item"
              [class.active]="lastIndex"
            >
              <a [href]="item.url" *ngIf="item.url">{{ item.label | truncate: 45 }}</a>
              <a *ngIf="!item.url" hidden href="/">{{ item.label | truncate: 45 }}</a>
              <span *ngIf="!item.url">{{ item.label | truncate: 45 }}</span>
              @if(!lastIndex && isNewArtisticDirection()) {
                <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
              }
            </li>
          </ng-container>
          <ng-container *ngIf="items.length === 2">
            <li
              *ngFor="let item of items | slice: -1; let index = index; last as lastIndex"
              class="breadcrumb-list-item"
              [class.active]="lastIndex"
            >
              <a [href]="item.url" *ngIf="item.url">{{ item.label | truncate: 45 }}</a>
              <a *ngIf="!item.url" hidden href="/">{{ item.label | truncate: 45 }}</a>
              <span *ngIf="!item.url">{{ item.label | truncate: 45 }}</span>
              @if(!lastIndex && isNewArtisticDirection()) {
                <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
              }
              
            </li>
          </ng-container>
        </ng-container>
      </ol>
    </div>
  </div>
</div>

<ng-template #showItemsServer>
  <li *ngFor="let item of items; let index = index; last as lastIndex" class="breadcrumb-list-item" [class.active]="lastIndex">
    <a [href]="item.url" *ngIf="item.url">{{ item.label | truncate: 45 }}</a>
    <a *ngIf="!item.url" hidden href="/">{{ item.label | truncate: 45 }}</a>
    <span *ngIf="!item.url">{{ item.label | truncate: 45 }}</span>
  </li>
</ng-template>
