import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { TitleComponent } from '../../title/title.component';
import { ISubBlockImage } from './images';

@Component({
  selector: 'nscf-images',
  templateUrl: 'images.component.html',
  styleUrls: ['images.component.scss'],
  standalone: true,
  imports: [TitleComponent, NgIf, CtaButtonComponent],
})
export class ImagesComponent extends BasicThemedComponentDirective {
  @Input() element: ISubBlockImage;

  constructor() {
    super();
  }
}
