@if (isNewArtisticDirectionTheme()) {
  @if (element.sticky) {
    <div [ctaUrl]="element?.ctaUrl" [replaceElements]="true" [text]="element?.ctaLabel" appCtaAction></div>
  }
  <nscf-cta-button class="cta-button" [ctaUrl]="element?.ctaUrl" [text]="element?.ctaLabel"></nscf-cta-button>
  @if (element?.ctaConditions) {
    <p class="tw-text-new-xs">{{ element.ctaConditions }}</p>
  }
} @else {
  <div class="container">
    <div class="sub-block-cta__wrapper spacing-block-text">
      <div *ngIf="element.sticky" [ctaUrl]="element?.ctaUrl" [replaceElements]="true" [text]="element?.ctaLabel" appCtaAction></div>
      <nscf-cta-button
        class="cta-button"
        [btnClass]="'cta-primary big-btn full-width'"
        [ctaUrl]="element?.ctaUrl"
        [text]="element?.ctaLabel"
        [theme]="theme"
      ></nscf-cta-button>
      <p class="sub-block-cta__cgu">{{ element?.ctaConditions }}</p>
    </div>
  </div>
}
