<div class="container-fluid">
  <article class="bref-container">
    <div class="bref__title-container">
      <span class="h2">{{ data.title }}</span>
      <span class="bref__liseret"></span>
    </div>
    <div *ngIf="data.description" class="bref__content-container">
      <span [innerHTML]="data.description"></span>
    </div>
  </article>
</div>