<div class="spacing-block">
  <div id="opinion" class="d-flex align-items-center opinion-block__background-cover" [ngStyle]="urlCover" *ngIf="data" #cover>
    <div class="opinion-block__desktop opinion-block__mobile container">
      <div>
        <nscf-title
          [selector]="data.isFirstTitle ? 'h1' : 'h2'"
          class="text-center"
          [title]="data.title"
          [id]="'anchor_' + data.id"
        ></nscf-title>
        <div>
          <div>
            <div class="opinion-block__subtitle">
              {{ data.chapo }}
            </div>
          </div>
          <div class="opinion-block" nscfLazyLoadMaterial>
            <div *ngFor="let op of data.cardsOpinions; let isLast = last" class="opinion-item">
              <div *ngIf="!isLast">
                <mat-card>
                  <div class="opinion-block__opinion-top-card opinion-item">
                    <div class="top">
                      <nscf-star [rating]="op.score" [maxScore]="data.maxScore"></nscf-star>
                    </div>
                    <div class="top right">
                      {{ op.delay }}
                    </div>
                  </div>
                  <div class="opinion-block__opinion-name">
                    {{ op.title }}
                  </div>
                  <div class="opinion-block__context">
                    {{ op.context }}
                  </div>
                  <div class="opinion-block__opinion">&laquo; {{ op.opinion }} &raquo;</div>
                </mat-card>
              </div>
              <div *ngIf="isLast">
                <mat-card class="d-flex justify-content-center align-items-center opinion-block__last-card">
                  <div class="w-100">
                    <div class="opinion-block__opinion-name opinion-block__ctaTitle">
                      {{ op.title }}
                    </div>
                    <div class="opinion-block__cta">
                      <nscf-cta-button
                        [theme]="theme"
                        [btnClass]="'cta-primary big-btn'"
                        [text]="op.buttonText"
                        [ctaUrl]="op.ctaUrl"
                      ></nscf-cta-button>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
