@if (isNewDirectionArtistic()) {
  @if (data?.questions?.length) {
    <p class="tw-text-new-2xl slg:tw-text-new-3xl tw-font-700 tw-mb-[16px] slg:tw-mb-[24px]">{{ data.title }}</p>
    <span class="tw-block tw-w-[60px] slg:tw-w-[100px] tw-h-[8px] tw-bg-secondary-color tw-mb-[16px] slg:tw-mb-[24px]"></span>
    @for (question of data.questions; track question.property; let isLast = $last) {
      <nscf-form-parcours-question
        [ngClass]="{ 'tw-mb-[16px]': !isLast }"
        (selectAnswer)="onChangeAnswer()"
        [question]="question"
      ></nscf-form-parcours-question>
    }
  }
} @else {
  <div class="form-parcours">
    <div class="form-parcours__container" *ngIf="data?.questions?.length">
      <p class="form-parcours__title">{{ data.title }}</p>
      <nscf-liseret [theme]="theme"></nscf-liseret>
      <div
        class="form-parcours__question-wrapper"
        *ngFor="let question of data.questions; let isFirst = first"
        [class.form-parcours__question-wrapper--font]="isFirst"
      >
        <nscf-form-parcours-question (selectAnswer)="onChangeAnswer()" [question]="question"></nscf-form-parcours-question>
      </div>
    </div>
    <nscf-trustpilot-micro-star></nscf-trustpilot-micro-star>
  </div>
}
