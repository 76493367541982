import { IHeadingQuestions } from '../../components/heading-questions/heading-questions';

export interface IQuestionsThemeBo {
  entity: {
    nameFront: string;
  };
  targetId: number;
}

export interface IQuestionsThemeQuestionsBo {
  data: {
    articleQuestionsGraphqlView: {
      results: {
        nid: string;
        title: string;
        url: string;
      }[];
    };
  };
}

// @dynamic
export class QuestionsPresenter {
  public static getQuestions(data: IQuestionsThemeBo): IHeadingQuestions {
    return { title: QuestionsPresenter.getTitle(data), currentThemeId: this.getCurrentThemId(data) };
  }

  private static getTitle(data: IQuestionsThemeBo): string {
    return data?.entity?.nameFront;
  }

  private static getCurrentThemId(data: IQuestionsThemeBo): number {
    return data?.targetId;
  }
}
