import { IRteText } from './rte-text';
import { ILink } from './link';
import { IFieldMediaImage } from './field-media-image';
import { INewTopPageCards } from './new-top-page-cards';

export const TypeNameBlockNewTopPage = 'ParagraphNewTopPage';

export interface INewTopPage {
  __typename: 'ParagraphNewTopPage';
  id?: number;
  title?: string;
  enableTrustPilot: boolean;
  description?: IRteText; // chapo
  buttonText?: string; // CTA-Bouton
  ctaUrl?: ILink;
  termsTitle?: string; // CTA-Conditions générales
  cardsArguments?: Array<INewTopPageCards>;
  video?: string;
  mediaCover?: IFieldMediaImage;
  descriptionMobile?: IRteText;
  textUnderCta?: IRteText;
}
