import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';

@Component({
  selector: 'nscf-liseret',
  templateUrl: 'liseret.component.html',
  styleUrls: ['liseret.component.scss'],
  standalone: true,
  hostDirectives: [DirectionArtisticDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiseretComponent extends BasicThemedComponentDirective {
  @Input() isCentered = false;

  constructor() {
    super();
  }
}
