import { NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { find } from 'lodash-es';

@Component({
  selector: 'nscf-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgFor, SlicePipe],
  hostDirectives: [DirectionArtisticDirective]
})
export class SelectComponent<T extends { id: string | number }> {
  @Input() source: T[];
  @Input() displayedProperty: string;
  @Input() current: T;
  @Input() placeHolder: string;
  @Output() selectionChanged: EventEmitter<T> = new EventEmitter<T>();
  @Output() selected: T;

  selectChangeHandler(event: any) {
    const newVal = find(this.source, v => {
      return v.id.toString() === event.target.value;
    });
    this.selectionChanged.emit(newVal);
  }
}
