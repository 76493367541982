
export interface InputNumberButtonModel {
    type: number;
    label: string;
}

// eslint-disable-next-line no-shadow
export enum InputNumberButtonTypes {
    increment = 1,
    decrement = -1,
}

export const inputNumberMin = 1;
export const inputNumberMax = 25;

export const INPUT_NUMBER_BUTTON_CONFIG: InputNumberButtonModel[] = [
    {
        type: InputNumberButtonTypes.decrement,
        label: '-'
    },
    {
        type: InputNumberButtonTypes.increment,
        label: '+'
    }
];

export const INPUT_NUMBER_DEFAULT_VALUE = 2;
