import { Injectable } from "@angular/core";
import { UserSubscribeNewsletterSource, UserSubscribeNewsletterRequestModel } from "../../interfaces/newsletter";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../../modules/config/config.service";

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
  ) {}
  subscribeToNewsletter(email: string, source: UserSubscribeNewsletterSource) {
    const body: UserSubscribeNewsletterRequestModel = {
      email,
      optInNewsletter: true,
      source,
    };

    return this.httpClient.post(this.configService.getNewsletterApiUrl(), body);
  }
}