<nav *ngIf="navbar" aria-label="Main" class="header-navbar">
  <ul class="header-navbar__links">
    <li class="header-navbar__item" *ngFor="let link of navbar?.links; let index = index" (mouseover)="loadImages()">
      <span class="arrow-menu" *ngIf="link?.menu?.list?.items.length > 0 && !isNewArtisticDirection()"></span>
      <nscf-header-link
        [index]="index"
        [link]="link"
        [isDesktop]="isDesktop"
        [theme]="theme"
        (checked)="onChecked($event)"
      ></nscf-header-link>
    </li>
  </ul>
  <ng-container *ngIf="!isDesktop && !isSubMenuMobileOpened">
    <nscf-connection [connection]="connection" [theme]="theme" [scrollOverHeight]="scrollOverHeight()" class="tw-block tw-mb-4"></nscf-connection>
    <nscf-header-cta-button
      *ngIf="linkPart"
      [btnClass]="'cta-primary big-btn'"
      [ctaLink]="linkPart"
      [theme]="theme"
    ></nscf-header-cta-button>
    <nscf-new-phone-block [phoneInsert]="phoneInsert" [theme]="theme" [isDesktop]="false"></nscf-new-phone-block>
  </ng-container>
</nav>
