import { isPlatformServer } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  AggregateRatingUtil,
  ConfigService,
  IconsService,
  RichResultsService,
  SchemaTypes,
  TrustpilotRates,
  TrustpilotRatesService,
  UtmCampaignComponent,
} from '@effy-tech/acquisition-core';
import { EffyIconsService, ThemeHelperService } from '@effy-tech/angular-common';
import { take } from 'rxjs';

@Component({
  selector: 'acq-app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, UtmCampaignComponent],
})
export class AppComponent implements OnInit {
  private readonly configService = inject(ConfigService);
  private readonly effyIconsService = inject(EffyIconsService);
  private readonly iconsService = inject(IconsService);
  private readonly trustpilotRatesService = inject(TrustpilotRatesService);
  private readonly richResultsService = inject(RichResultsService);
  private readonly themeHelperService = inject(ThemeHelperService);
  platformID = inject(PLATFORM_ID);

  ngOnInit() {
    this.configService.initConfig();
    this.initIconsServices();
    this.themeHelperService.init();

    if (isPlatformServer(this.platformID)) {
      this.richResultsService.setJsonLd(SchemaTypes.ORGANIZATION, null);
      this.trustpilotRatesService
        .getTrustpilotRates()
        .pipe(take(1))
        .subscribe((trustpilotRates: TrustpilotRates) => {
          const rates = AggregateRatingUtil.mapTrustpilotRatesToAggregateRatings(trustpilotRates);
          this.richResultsService.setJsonLd(SchemaTypes.SOFTWARE_APPLICATION, rates);
        });
    }
  }

  private initIconsServices() {
    this.iconsService.init();
    this.effyIconsService.init();
  }
}
