import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { RteComponent } from '../../rte/rte.component';
import { TitleComponent } from '../../title/title.component';
import { ISubBlockTables } from './tables';

@Component({
    selector: 'nscf-tables',
    templateUrl: 'tables.component.html',
    styleUrls: ['tables.component.scss'],
    standalone: true,
    imports: [TitleComponent, RteComponent, NgIf, CtaButtonComponent, NgClass, EffyButtonComponent],
})
export class TablesComponent extends BasicThemedComponentDirective {
  @Input() element: ISubBlockTables;
  protected readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonColor = EffyButtonStyle;

  constructor() {
    super();
  }
}
