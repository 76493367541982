import { Location, NgIf, NgClass, NgFor, AsyncPipe, SlicePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IHeadingArticle, IHeadingArticles } from './heading-article';
import { RequestName } from '../../graphql/graphql';
import { map } from 'rxjs/operators';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { GetDataService } from '../../graphql/graphql.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { LazyLoadMaterialDirective } from '../../directives/lazy-load-material/lazy-load-material.directive';

@Component({
    selector: 'nscf-heading-article',
    templateUrl: './heading-article.component.html',
    styleUrls: ['./heading-article.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgFor, LazyLoadMaterialDirective, MatExpansionModule, AsyncPipe, SlicePipe]
})
export class HeadingArticleComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() bigViewPort: boolean;
  @Input() isBrowser: boolean;
  @Input() data: {
    headingThemeId: string;
  };
  nbMaxItemDisplayedAtFirst = 4;
  showAllItems: boolean;
  visibleHeading: Observable<boolean> = of(true);
  currentPath: string;
  linksArticles$: Observable<IHeadingArticles>;

  constructor(private readonly location: Location, private readonly getDataService: GetDataService) {
    super();
    this.currentPath = this.getPathFromUrl(this.location.path());
  }

  ngOnInit(): void {
    this.showAllItems = !this.isBrowser;
    this.linksArticles$ = this.getHeadingArticles();
  }

  private getHeadingArticles() {
    return this.getDataService.getRequestData(RequestName.HEADING_ARTICLES, { theme: [this.data.headingThemeId] }).pipe(
      map(result => result?.data?.articleHeadingGraphqlView?.results),
      map(this.mapDataHeadingArticles),
    );
  }

  private mapDataHeadingArticles(listlinks: ListLinksBo[] = []): IHeadingArticles {
    return listlinks.map(listlinks => ({
      text: listlinks?.fieldTextPlain1v1,
      path: listlinks?.entityUrl?.path,
    }));
  }

  onClickViewMore(): void {
    this.showAllItems = !this.showAllItems;
  }

  isCurrentPath(item: IHeadingArticle): boolean {
    return this.currentPath === item.path;
  }

  private getPathFromUrl(url: string): string {
    // @TODO : Put it in utils file and test it !
    return url.split('?')[0];
  }
}

interface ListLinksBo {
  entityUrl: {
    path: string;
  };
  fieldTextPlain1v1: string;
}
