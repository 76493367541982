<div class="star-bloc" [title]="rating" (click)="onClick()">
  <span *ngFor="let item of counter(maxScore)">
    <span *ngIf="ratingNb > item - 1 && ratingNb < item; else fullstar" class="star-bloc__star">
      <nscf-svg [offset]="(1 + ratingNb - item) * 10" [color]="color"></nscf-svg>
    </span>
    <ng-template #fullstar>
      <span *ngIf="ratingNb >= item; else emptyStar" class="star-bloc__star">
        <nscf-svg offset="10" [color]="color"></nscf-svg>
      </span>
      <ng-template #emptyStar>
        <span class="star-bloc__star">
          <nscf-svg offset="0" [color]="color"></nscf-svg>
        </span>
      </ng-template>
    </ng-template>
  </span>
</div>
