import { Devices } from '../../enums/devices.enum';

export interface IBreadCrumbItem {
  label: string;
  url: string;
}

export interface IBreadCrumbItems {
  url?: string;
  __typename?: string;
  extra?: Array<Devices>;
  items: IBreadCrumbItem[];
  isInArticlePage?: boolean;
}

export const IBreadCrumbName = 'ParagraphBreadcrumbComponent';
