import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { EffyCommonModule } from '@effy-tech/common';
import { VideoGenerator } from '../../../../classes/video-generator/video-generator';
import { SafeResourceUrlPipe } from '../../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { IVideoText } from './video-text';

@Component({
  selector: 'nscf-video-text',
  templateUrl: './video-text.component.html',
  styleUrls: ['./video-text.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, EffyCommonModule, SafeResourceUrlPipe],
  hostDirectives: [DirectionArtisticDirective],
})
export class VideoTextComponent extends VideoGenerator implements OnInit {
  @Input() data: IVideoText;
  @Input() isDesktop: boolean;
  loadedMedia: boolean;
  element; //@TODO : It's not existing...

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadedMedia = this.data.video && !this.data.cover;
  }

  loadMedia(event: MouseEvent) {
    event.preventDefault();
    if (this.isMediaLoaded()) {
      event.stopPropagation();
    } else {
      this.loadedMedia = true;
    }
  }

  isMediaLoaded(): boolean {
    return this.loadedMedia;
  }

  generateEmbedUrl(videoUrl: string, autoplay?: boolean): string {
    return super.generateEmbedUrl(videoUrl, autoplay);
  }
}
