<div class="container">
  <div class="text-image spacing-block-text">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <nscf-title
          [id]="'anchor_' + element.id"
          [selector]="element.isFirstTitle ? 'h1' : 'h2'"
          [title]="element.title"
          class="block-title text-center"
        ></nscf-title>
        <div class="image-bloc">
          <img *ngIf="element.image" [alt]="element.image.alt" [src]="element.image.url" loading="lazy" class="w-100" />
        </div>
        <div *ngIf="element.legend" class="legend">
          <p>{{ element.legend }}</p>
        </div>
        <nscf-cta-button
          *ngIf="element.ctaLabel && element.ctaUrl"
          [btnClass]="'cta-primary big-btn'"
          [ctaUrl]="element.ctaUrl"
          [text]="element.ctaLabel"
          [theme]="theme"
        >
        </nscf-cta-button>
      </div>
    </div>
  </div>
</div>
