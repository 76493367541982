<a class="card" [href]="url">
  <div class="card__cover-container">
    <img *ngIf="img; else imgPlaceholder" [src]="img" [alt]="imgAlt" loading="lazy" class="card__cover" />
    <ng-template #imgPlaceholder>
      <div class="card__cover-placeholder"></div>
    </ng-template>
  </div>
  <div class="card__texts-container">
    @if (isNewArtisticDirection()) {
      <span effy-hashtag *ngFor="let taxonomy of taxonomies"> #{{ taxonomy | lowercase }} </span>
    } @else {
      <span class="card__texts-taxonomy" *ngFor="let taxonomy of taxonomies">#{{ taxonomy | lowercase }} </span>
    }
    <p class="card__texts-title" [innerHTML]="title"></p>
  </div>
</a>
