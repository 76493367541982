<div class="spacing-block engagement stats-numbers">
  <div class="container numbers" [ngClass]="'numbers-'+size()">
      @if(isNewArtisticDirectionTheme()){
          <nscf-title [id]="'anchor_' + data.id" [selector]="data.isFirstTitle ? 'h1' : 'h2'" [title]="data.title" class="numbers__title"/>
          <div class="liseret"></div>
          <nscf-rte [data]="data.chapo" [theme]="theme" class="numbers__chapo"/>
          <div class="numbers__cards-container">
             <div class="numbers__card" *ngFor="let cardNumber of data.cardsNumbers">
                 <div *ngIf="cardNumber.image" class="numbers__block-img">
                     <img [alt]="cardNumber.image.alt" [src]="cardNumber.image.url" loading="lazy" />
                 </div>
                 <p *ngIf="cardNumber.title" class="numbers__card-title">{{ cardNumber.title }}</p>
                 <p *ngIf="cardNumber.chapo" class="numbers__card-description">{{ cardNumber.chapo }}</p>
             </div>
          </div>
      } @else {
          <nscf-title [id]="'anchor_' + data.id" [selector]="data.isFirstTitle ? 'h1' : 'h2'" [title]="data.title" class="text-center"/>
          <nscf-rte [data]="data.chapo" [theme]="theme"/>
          <div class="number__wrapper-cards">
              <div
                      *ngFor="let cardNumber of data.cardsNumbers"
                      [ngClass]="!cardNumber.chapo || !cardNumber.image ? 'number__card--without-chapo' : ''"
                      class="number__card"
              >
                  <div class="number__card-wrapper">
                      <div *ngIf="cardNumber.image" class="numbers__block-img">
                          <img [alt]="cardNumber.image.alt" [src]="cardNumber.image.url" loading="lazy" style="width: 70px; height: 70px" />
                      </div>
                      <p *ngIf="cardNumber.title" class="number__card-title">{{ cardNumber.title }}</p>
                      <p *ngIf="cardNumber.chapo" class="number__card-description">{{ cardNumber.chapo }}</p>
                  </div>
                  <nscf-liseret [theme]="theme"></nscf-liseret>
              </div>
          </div>
      }
  </div>
</div>
