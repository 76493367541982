import { animate, style, trigger, transition, state, keyframes, group } from '@angular/animations';

export const apparitionTopLeft = trigger('apparitionTopLeft', [
  state(
    'smallerL',
    style({
      'transform-origin': '{{transformOrigin}}',
      display: 'block',
    }),
    { params: { transformOrigin: 'top left', delay: '1000ms' } },
  ),
  state('largerL', style({})),
  transition(
    'smallerL <=> largerL',
    group([
      animate(
        '{{delay}} ease-in-out',
        keyframes([style({ transform: 'scale(0)', opacity: 0 }), style({ transform: 'scale(1)', opacity: 1 })]),
      ),
    ]),
  ),
]);

export const apparitionTopRight = trigger('apparitionTopRight', [
  state(
    'smallerR',
    style({
      'transform-origin': '{{transformOrigin}}',
    }),
    {
      params: { transformOrigin: 'top right', delay: '1000ms' },
    },
  ),
  state(
    'largerR',
    style({
      'transform-origin': '{{transformOrigin}}',
    }),
    {
      params: { transformOrigin: 'top right', delay: '1000ms' },
    },
  ),
  transition(
    'smallerR <=> largerR',
    group([
      animate(
        '{{delay}} ease-in-out',
        keyframes([style({ transform: 'scale(0)', opacity: 0 }), style({ transform: 'scale(1)', opacity: 1 })]),
      ),
    ]),
  ),
]);

export const slideInOut = trigger('slideInOut', [
  state(
    '*',
    style({
      visibility: 'hidden',
    }),
    {
      params: { delay: '1000ms' },
    },
  ),
  state(
    'slideIn',
    style({
      'clip-path': 'inset(0 50% 0 0)',
      visibility: 'hidden',
    }),
    {
      params: { delay: '1000ms' },
    },
  ),
  state(
    'slideOut',
    style({
      'clip-path': 'polygon(48% 46%, 50% 52%, 36% 73%, 2% 73%, 2% 25%)',
    }),
  ),
  transition(
    'slideIn => slideOut',
    group([
      animate(
        '{{delay}}',
        keyframes([
          style({
            'clip-path': 'inset(0 0 0 50%)',
            visibility: 'visible',
          }),
          style({
            'clip-path': 'inset(0 50% 0 0)',
            visibility: 'visible',
          }),
        ]),
      ),
    ]),
  ),
]);

export const apparitionBottomTop = trigger('apparitionBottomTop', [
  state(
    'bottom',
    style({
      'transform-origin': '{{transformOrigin}}',
      display: 'block',
    }),
    { params: { transformOrigin: 'bottom', delay: '700ms' } },
  ),
  state('top', style({})),
  transition(
    'bottom <=> top',
    group([
      animate(
        '{{delay}} ease-in-out',
        keyframes([style({ transform: 'translateY(200px)', opacity: 0 }), style({ transform: 'translateY(0)', opacity: 1 })]),
      ),
    ]),
  ),
]);

export const fadeInCardsAnimation = trigger('fadeInCardsAnimation', [
  state('in', style({ opacity: 1 })),
  transition(':enter', [style({ opacity: 0 }), animate(400)]),
  transition(':leave', animate(400, style({ opacity: 0 }))),
]);
