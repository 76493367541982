import { IBlockPresenter } from '../presenter';
import { IBlockCalendly } from '../../components/calendly/calendly';

export class BlockCalendlyPresenter implements IBlockPresenter {
  parse(data: Pick<IBlockCalendly, '__typename'>): IBlockCalendly {
    if (!data) {
      return null;
    }
    return {
      __typename: data.__typename,
    };
  }
}
