import { WORK_VALUES } from '@effy-tech/widget-calculator';
import { IWidgetCalculator } from '../../components/widget-calculator/widget-calculator';

export class WidgetCalculatorPresenter {
  public static getDataWidget(data: any): IWidgetCalculator {
    if (!data) {
      return <IWidgetCalculator>{};
    }
    return {
      workType: WORK_VALUES[data['screenMPR']?.entity?.id] || null,
      isActive: data['widgetMPR']
    };
  }
}
