import { IFieldMediaImage } from './field-media-image';
import { ILink } from './link';
import { ITaxonomy } from './taxonomy';

export const TypeNameBlockHub = 'ParagraphHub';
export const TypeNameSubBlockCardsSummary = 'CardsSummary';

export interface ICardSummary extends ICardSummarySimplified {
  mediaCover?: IFieldMediaImage;
  cardType?: string;
  total?: number;
}

export interface ICardsSummary {
  __typename?: string;
  cardsSummary: Array<ICardSummary>;
}

export interface ICardSummarySimplified {
  title: string;
  description: string;
  image: string;
  imageWebp?: string;
  imageAlt: string;
  link: ILink;
  wideCover?: string;
  wideCoverWebp?: string;
  created?: Date | string;
  type?: string;
  taxonomies?: string[];
}

export enum CardificationType {
  AUTO = 'auto',
  MANUEL = 'manuel',
}
