import { AsyncPipe, isPlatformBrowser, NgFor, NgIf, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, PLATFORM_ID, signal } from '@angular/core';
import { toSignal } from "@angular/core/rxjs-interop";
import { DirectionArtisticDirective, EffyButtonComponent, ThemeHelperService } from "@effy-tech/angular-common";
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { IsInViewportDirective } from '../../directives/is-in-viewport/is-in-viewport.directive';
import { IParagraphCtaSticky, IParagraphCtaStickyColor } from '../../presenters/blocks/cta-sticky/cta-sticky.presenter';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { CtaStickyWithQuestionsComponent } from '../cta-sticky-with-questions/cta-sticky-with-questions.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';

@Component({
  selector: 'nscf-cta-sticky',
  templateUrl: './cta-sticky.component.html',
  styleUrls: ['./cta-sticky.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AsyncPipe,
    UpperCasePipe,
    IsInViewportDirective,
    NgTemplateOutlet,
    TrustpilotMicroStarComponent,
    CtaStickyWithQuestionsComponent,
    EffyButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DirectionArtisticDirective]
})
export class CtaStickyComponent extends BasicThemedComponentDirective {
  get data(): IParagraphCtaSticky {
    return this._data;
  }
  @Input() set data(value: IParagraphCtaSticky) {
    this._data = value;
    this.secondary.set(value.color === IParagraphCtaStickyColor.Green)
  }

  private _data: IParagraphCtaSticky;
  readonly secondary = signal<boolean>(false);
  readonly isNewArtisticDirectionTheme = toSignal(inject(ThemeHelperService).isNewArtisticDirection$);
  protected readonly isMobileOrTablet$ = inject(DeviceManagerService).isMobileOrTablet();
  protected readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  @HostBinding('class.secondary') get hostClasses() {
    return this.secondary()
  };

  priceContainerIsInViewport = true;
  questionContainerIsInViewport = true;

  isInViewport(event: boolean): void {
    this.priceContainerIsInViewport = event;
  }

  isQuestionInViewport(event: boolean): void {
    this.questionContainerIsInViewport = event;
  }
}
