// Request API
export enum ICostEstimatorPrecarityLevel {
  VeryModest = 'very_modest',
  Modest = 'modest',
  Intermediate = 'intermediate',
  HighIncome = 'high_income',
}

export enum ICostEstimatorDpeScore {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum ICostEstimatorOperatorCode {
  Aerovoltaic = 'aerovoltaic',
  AirAirHeatPump = 'air_air_heat_pump',
  AirWaterHeatPump = 'air_water_heat_pump',
  CombinedSolarSystem = 'combined_solar_system',
  DoubleFlowCmv = 'double_flow_cmv',
  FireplaceInsert = 'fireplace_insert',
  HybridHeatPump = 'hybrid_heat_pump',
  PhotovoltaicPanel = 'photovoltaic_panel',
  SolarWaterHeater = 'solar_water_heater',
  StorageHeater = 'storage_heater',
  ThermodynamicWaterHeater = 'thermodynamic_water_heater',
  WoodBoiler = 'wood_boiler',
  WoodStove = 'wood_stove',
  DoubleGlazing = 'double_glazing',
  GeothermalHeatPump = 'geothermal_heat_pump',
  RoofTerraceInsulation = 'roof_terrace_insulation',
  CondensingOilBoiler = 'condensing_oil_boiler',
  FloorInsulation = 'floor_insulation',
  WallInsulation = 'wall_insulation',
  AtticsInsulation = 'attics_insulation',
}

export enum ICostEstimatorWallInsulationType {
  cladding = 'cladding',
  coated = 'coated',
  from_indoor = 'from_indoor',
}

export enum ICostEstimatorBoilerType {
  Wood = 'wood',
  Pellet = 'pellet',
}

export interface ICostEstimatorBodyRequestAPI {
  precarity_level: ICostEstimatorPrecarityLevel;
  owner_status: string;
  housing_age: string;
  offer_type?: 'liberte' | 'serenite';
  verbose?: boolean;
  docstamp_date?: string; // eg. 2022-01-01
  total_cost?: number;
  dpe_score?: ICostEstimatorDpeScore;
  zip_code?: string;
  additional_works?: {
    operation_name: ICostEstimatorOperatorCode;
    wall_insulation_type?: ICostEstimatorWallInsulationType;
    boiler_type?: ICostEstimatorBoilerType;
  };
}

// Response API
export interface IOffer {
  premiums: {
    renov: number;
    cee: number;
  };
  unit_cost: {
    average: number;
    min: number;
    max: number;
  };
  total_cost: number;
  remaining_cost: number;
  total_premium: number;
  quantity: number;
}

export interface ICostEstimatorResponseAPI {
  offre_liberte: IOffer;
  offre_serenite: IOffer;
}
