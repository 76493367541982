import { Component, Input } from '@angular/core';
import { IContactLinks } from './author.contact';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nscf-author-contact',
    templateUrl: './author-contact.component.html',
    styleUrls: ['./author-contact.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class AuthorContactComponent {
  @Input() links?: IContactLinks;
}
