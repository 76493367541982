<div class="border-container">
  <div class="carousel-wrapper" #carouselWrapper (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd()">
    <div class="carousel" #carousel>
      <img *ngFor="let src of imagesSrc | slice:0:(sliceEnd$ | async); let i = index; let isFirst = first;" [src]="src"
        alt="" (click)="openCarouselOnImageIndex.emit(i)" [attr.fetchPriority]="isFirst ? 'high' : 'auto'"
        [attr.loading]="isFirst ? 'eager' : 'lazy'">
    </div>
    <span *ngIf="imagesSrc.length > 1" class="photo-counter">{{ currentPhotoIndex + 1 }} / {{ imagesSrc.length }}</span>
  </div>
</div>