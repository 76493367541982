import { NgIf, isPlatformServer } from '@angular/common';
import { Component, Input, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { DirectionArtisticDirective } from '@effy-tech/angular-common';
import { IBreadCrumbName } from '../../components/breadcrumb/breadcrumb';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { TypeNameBlockCtaSticky } from '../../components/cta-sticky/cta-sticky';
import { CtaStickyComponent } from '../../components/cta-sticky/cta-sticky.component';
import { TopPageMosaicComponent } from '../../components/top-page-mosaic/top-page-mosaic.component';
import { DynamicComponentData } from '../../interfaces/dynamic-types';
import { IPageWork } from '../../interfaces/pages';
import { BlockTopPageMosaicPresenter } from '../../presenters/blocks/top-page-mosaic/top-page-mosaic.presenter';
import { RichResultsService } from '../../services/rich-results/rich-results.service';
import { SchemaTypes } from '../../services/rich-results/rich-results.values';
import { WebPagesUtil } from '../../utils/web-pages/web-pages.utils';
import { DynamicPageDirective } from '../dynamic-page';

@Component({
  selector: 'nscf-work-page',
  templateUrl: 'work.component.html',
  styleUrls: ['work.component.scss'],
  standalone: true,
  imports: [NgIf],
  hostDirectives: [DirectionArtisticDirective],
})
export class WorkPageComponent extends DynamicPageDirective implements OnInit {
  @Input() data: IPageWork;

  @ViewChild('topContent', { read: ViewContainerRef, static: true })
  topContent: ViewContainerRef;
  @ViewChild('breadcrumbContent', { read: ViewContainerRef, static: true })
  breadcrumbContent: ViewContainerRef;
  @ViewChild('mainContent', { read: ViewContainerRef, static: true })
  mainContent: ViewContainerRef;
  @ViewChild('asideContent', { read: ViewContainerRef, static: true })
  asideContent: ViewContainerRef;
  @ViewChild('beforeFooterContent', { read: ViewContainerRef, static: true })
  beforeFooterContent: ViewContainerRef;

  hasAsideContent = true;
  hasTopPageContent = true;

  private readonly platformId = inject(PLATFORM_ID);
  private readonly richResultsService = inject(RichResultsService);

  ngOnInit(): void {
    this.getDeviceType().subscribe();
    if (isPlatformServer(this.platformId)) {
      this.richResultsService.setJsonLd(
        SchemaTypes.WEB_PAGE,
        WebPagesUtil.dataToWepPageWorkMapper(this.data, this.deviceManagerService.getFullUrlSSr(), this.theme)
      );
    }
  }

  buildPage(): void {
    // clear page
    this.clearPage();

    // Top contents
    const topContent = this.getTopContent();
    this.hasTopPageContent = !!topContent;
    const shouldSetFirstTitleOnOtherBlocks = !this.hasTopPageContent;
    if (this.hasTopPageContent) {
      super.appendDynamicComponentData(this.topContent, topContent, shouldSetFirstTitleOnOtherBlocks);
    }

    // Main contents.
    // Extract dynamic components.
    let mainDynamicComponentsData = super.fieldBlocksAsDynamicComponentData(this.data.blocks);

    // Aside
    const asideContent = this.getAsideContent();
    this.hasAsideContent = !!asideContent;
    if (this.hasAsideContent) {
      if (this.isDesktop) {
        // In desktop, cta sticky is in aside so we have to remove from mainDynamicComponentsData
        mainDynamicComponentsData = [
          ...mainDynamicComponentsData.filter(componentData => componentData?.typename !== TypeNameBlockCtaSticky),
        ];
        super.appendDynamicComponentData(this.asideContent, asideContent, shouldSetFirstTitleOnOtherBlocks);
      }
    }

    // then append them to the ViewContainerRef
    super.appendDynamicComponentData(this.mainContent, mainDynamicComponentsData, shouldSetFirstTitleOnOtherBlocks);

    // Breadcrumb Content.
    const breadcrumbContent = this.isDesktop ? this.breadcrumbContent : this.beforeFooterContent;
    super.appendDynamicComponentData(breadcrumbContent, this.getBreadcrumbContent(), shouldSetFirstTitleOnOtherBlocks);
  }

  clearPage(): void {
    this.topContent?.clear();
    // this.summaryContentMobile?.clear();
    this.breadcrumbContent?.clear();
    this.mainContent?.clear();
    this.asideContent?.clear();
    // this.bottomContent?.clear();
    this.beforeFooterContent?.clear();
  }

  getBreadcrumbContent(): DynamicComponentData[] {
    return [
      {
        type: BreadcrumbComponent,
        content: { ...this.data.breadcrumb },
        typename: IBreadCrumbName,
      },
    ];
  }

  getAsideContent(): DynamicComponentData[] | null {
    const ctaStickyBlock = this.data?.blocks?.find(block => block.__typename === TypeNameBlockCtaSticky);
    return ctaStickyBlock
      ? [
          {
            type: CtaStickyComponent,
            content: { extra: { data: ctaStickyBlock } },
            typename: TypeNameBlockCtaSticky,
          },
        ]
      : null;
  }

  getTopContent(): DynamicComponentData[] | null {
    const topBlock = this.data?.topBlock?.entity;
    return topBlock
      ? [
          {
            type: TopPageMosaicComponent,
            content: { extra: { data: new BlockTopPageMosaicPresenter().parse(topBlock) } },
            typename: 'ParagraphTopPageMozaique',
          },
        ]
      : null;
  }
}
