import { ICardSummarySimplified } from '../../../interfaces/cards-summary';
import { ImagesService } from '../../../services/images-service/images.service';

export interface ICardificationSimplifiedResultBo extends ICover {
  title: string;
  metatag: string;
  link: string;
  theme: string;
  created: string;
  type: string;
}

export interface ICover {
  cover: string;
  coverWebp: string;
  coverAlt: string;
}

export class CardSimplifiedPresenter {
  static mapCardBoToSimplifiedCard(cardBo: ICardificationSimplifiedResultBo): ICardSummarySimplified {
    return {
      title: cardBo.title,
      image: ImagesService.rewriteImagesUrl(cardBo.cover),
      imageWebp: ImagesService.rewriteImagesUrl(cardBo.coverWebp),
      imageAlt: cardBo.coverAlt,
      description: JSON.parse(cardBo.metatag).description,
      link: {
        url: cardBo.link,
      },
      created: cardBo.created,
      type: cardBo.type
    };
  }
}

export function removeActualPageCard(cards: Array<ICardSummarySimplified>, url: string): Array<ICardSummarySimplified> {
  return cards.filter(card => card.link !== url);
}
