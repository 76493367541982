import { random } from 'lodash-es';
import { IMap } from '../../components/map/map';
import { IPosition } from '../../interfaces/position';
export class MapPresenter {
  public static getDataMap(data: any): IMap {
    // avoid empty / undefined data
    if (!data) {
      return <IMap>{};
    }
    return {
      id: data['id'] || random(1, 9000),
      isActive: data['map'],
      locationMap: data['locationMap'] ? data['locationMap'] : IPosition.LEFT,
      region: data['region'],
    };
  }
}
