import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[nscfSummaryScrollAction]',
    standalone: true
})
export class SummaryScrollDirective {
  public static readonly SUMMARY_SCROLLING_CLASS = 'sum-scrolling-point';

  constructor(private readonly el: ElementRef) {
    this.el.nativeElement.classList.add(SummaryScrollDirective.SUMMARY_SCROLLING_CLASS);
  }
}
